import React, {useMemo} from 'react';
import {OddsDisplay} from '../odds';
import {Table} from 'semantic-ui-react';
import {keyBy} from 'lodash/collection';
import {getMinuteFromItem} from '../../utils';
import {providerMatchMarketMap} from '../../utils/markets';
import {useSelector} from 'react-redux';
import {Label} from 'semantic-ui-react';

export default function FeedEventGrid({items, match, market, onRowSelect}) {
    const {providerMarkets} = useSelector(state => state.application);

    const thresholdKeys = (market?.meta && Object.keys(market?.meta?.thresholds)) || [];
    const marketThresholdIndex = useMemo(() => providerMatchMarketMap(providerMarkets, match), [providerMarkets, match]);
    const decimalOdd = (odd) => {
        if (odd && !isNaN(odd)) {
            return odd / 1000;
        }
        return odd;
    }
    const getItemRow = (item, index) => {
        const thresholdKeys = (market && Object.keys(market.meta.thresholds)) || [];
        return (
            <Table.Row key={index}>
                <Table.Cell>
                    {item.id}
                </Table.Cell>
                <Table.Cell>
                    {getMinuteFromItem(item)}
                </Table.Cell>
                <Table.Cell>
                    {item.score}
                </Table.Cell>
                {thresholdKeys.map((threshold, thIndex) => {
                    const odds = item.outcome_map[threshold];
                    return (
                        <Table.Cell key={`th-${thIndex}`}>
                            <Label
                                basic
                                size='mini'
                                key={index}
                                title={item.label}
                            >{decimalOdd(odds)}</Label>
                        </Table.Cell>
                    )
                })}
                <Table.Cell>
                    {item.status}
                </Table.Cell>
                <Table.Cell>
                    {item?.delivery ? (
                        <span>
                            {item?.delivery?.log?.subject}<br/>
                            {item?.delivery?.log?.body}<br/>
                            {item?.delivery?.log?.cta}
                        </span>
                    ) : false}
                </Table.Cell>
                {/* {thresholdKeys.map((threshold, thIndex) => {
                    const thresholds = keyBy(item.outcomes, o => o.label)
                    const label = marketThresholdIndex[market.id][threshold];
                    // console.log(thIndex, thresholds, item, label, marketThresholdIndex[market.id])
                    return (
                        <Table.Cell key={`th-${thIndex}`}>
                            <OddsDisplay outcomes={[thresholds[label]]}/>
                        </Table.Cell>
                    )
                })} */}
            </Table.Row>
        )
    }


    return (
        <Table>
            <Table.Header>
                <Table.Row>
                    <Table.HeaderCell>ID</Table.HeaderCell>
                    <Table.HeaderCell>Minute</Table.HeaderCell>
                    <Table.HeaderCell>Score</Table.HeaderCell>
                    {thresholdKeys.map((threshold, index) => {
                        return (
                            <Table.HeaderCell key={index}>{threshold}</Table.HeaderCell>
                        )
                    })}
                    <Table.HeaderCell>Status</Table.HeaderCell>
                    <Table.HeaderCell>&nbsp;</Table.HeaderCell>
                </Table.Row>
            </Table.Header>
            <Table.Body>
                {items.map((item, index) => {
                    return getItemRow(item, index)
                })}
            </Table.Body>
        </Table>
    )

}
