import React from 'react';
import {Dropdown} from 'semantic-ui-react'
import {Channels} from '../../constants';
import {isArray} from 'lodash/lang';

export default function({name, value, onChange, selection, allowEmpty, multiple}) {
    selection = selection === undefined ? true : selection;
    const channels = Object.keys(Channels).map((channelId, index)=>{
        const channel = Channels[channelId];
        return {value: channel.id, text: channel.name, key: index}
    })
    const optionChannels = allowEmpty ? [{value: '', text: 'Any', key: -1}].concat(channels) : channels;
    const dropdownValue = multiple ? (isArray(value) ? value : [value]) : value;
    return (
        <Dropdown
            clearable
            selection={selection}
            placeholder='Delivery Channel'
            name={name || 'channel'}
            multiple={!!multiple}
            value={dropdownValue}
            options={optionChannels}
            onChange={onChange}
        />
    )
}