import React, {useState, useEffect} from 'react';
import {Link, useParams, useHistory} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import {fetchLeague, fetchSeason} from '../../redux/actions/league';
import dateFormat from 'dateformat';
import {
    Table, Dropdown, Segment, Label
} from 'semantic-ui-react';

export default function LeagueTable({}) {
    const {leagueId, seasonId} = useParams();
    const {providerId, sportId} = useSelector(state => state.application);
    const history = useHistory();
    const dispatch = useDispatch();
    const {currentLeague, currentSeason} = useSelector(state => state.leagues)
    useEffect(() => {
        fetchSeason(sportId, leagueId, seasonId)(dispatch)
    }, [leagueId, seasonId])

    return (
        <React.Fragment>
            <Segment>
                <Dropdown
                    search
                    selection
                    placeholder='Seasons'
                    value={seasonId}
                    options={(currentLeague?.seasons || []).map((season, ind) => ({
                        text: season.name,
                        value: season.id
                    }))}
                    onChange={(ev, {value}) => {
                        history.replace(`/league/${leagueId}/tables/${value}`);
                    }}
                />
            </Segment>
            <Table>
                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell>Day</Table.HeaderCell>
                        <Table.HeaderCell>Date</Table.HeaderCell>
                        <Table.HeaderCell textAlign="right">Home</Table.HeaderCell>
                        <Table.HeaderCell textAlign="left">Away</Table.HeaderCell>
                        <Table.HeaderCell textAlign="right">Half Time</Table.HeaderCell>
                        <Table.HeaderCell></Table.HeaderCell>
                    </Table.Row>
                </Table.Header>
                <Table.Body>
                    {(currentSeason?.table || []).map((item, index) => (
                        <Table.Row key={`r${index}`}>
                            <Table.Cell>{item.match.day}</Table.Cell>
                            <Table.Cell>{dateFormat(item.match.date, 'dd-mm-yyyy HH:MM')}</Table.Cell>
                            <Table.Cell textAlign="right">
                                <span style={{fontWeight: item.score.home > item.score.away ? 'bolder': 'normal'}}>{item.home}</span>
                                <Label basic pointing="left">{item.score.home}</Label>
                            </Table.Cell>
                            <Table.Cell textAlign="left">
                                <Label basic pointing="right">{item.score.away}</Label>
                                <span style={{fontWeight: item.score.home < item.score.away ? 'bolder': 'normal'}}>{item.away}</span>
                            </Table.Cell>
                            <Table.Cell textAlign="right">
                                {item.ht_score.home} - {item.ht_score.away}
                            </Table.Cell>
                            <Table.Cell>
                                <Link to={`/match/${item.match.id}`}>Match</Link>
                            </Table.Cell>
                        </Table.Row>
                    ))}
                </Table.Body>
            </Table>
        </React.Fragment>
    )
}