import React, {useState} from 'react';
import {useInput} from '../../hooks';
import {Link} from 'react-router-dom';
import {isEmpty} from 'lodash/lang';
import { useSelector } from 'react-redux';
import { DEFAULT_FILTER } from './constants';
import {
    Form,
    Button,
    Radio,
} from 'semantic-ui-react';
import {
    LanguageSelector,
    ChannelSelector,
    ContentTypeSelector,
    ContentGroupSelector,
    ContentCategorySelector,
    MarketSelector
} from '../../components/selectors';

export default function({onFilter, onToggleTagPanel, onEditContent}) {
    const [values, dispatch] = useInput(DEFAULT_FILTER)
    const {content, application} = useSelector(state => state)

    const handleFilterChange = (ev, {value, name, checked}) => {
        console.log(value, name, checked)
        dispatch({name: name, value: value})
    }
    const getContentGroups = () => {
        if (!isEmpty(content.contentGroups)) {
            const groups = content.contentGroups.map((group, index) => {
                return {
                    value: group.id,
                    text: group.name,
                    key: index
                }
            })
            return [{value: '', text: '', key: -1}].concat(groups)
        }
        return [];
    }

    const contentCategories = [{name: 'All', id: ''}].concat(content.contentCategories)
    const providerLanguages = application.currentProvider && application.currentProvider.meta.languages;

    return (
        <Form>
            <Form.Group widths='equal'>
                <Form.Field>
                    <label>Language</label>
                    <LanguageSelector
                        name='language_code'
                        languages={providerLanguages}
                        onChange={handleFilterChange}
                        value={values.language_code}
                        allowEmpty={true}
                    />
                </Form.Field>
                <Form.Field>
                    <label>Content Type</label>
                    <ContentTypeSelector
                        name='content_type'
                        onChange={handleFilterChange}
                        value={values.content_type}
                        allowEmpty={true}
                    />
                </Form.Field>
                <Form.Field>
                    <label>Delivery Channel</label>
                    <ChannelSelector
                        name='channel_id'
                        onChange={handleFilterChange}
                        value={values.channel_id}
                        multiple={true}
                        allowEmpty={true}
                    />
                </Form.Field>
                <Form.Field>
                    <label>Group</label>
                    <ContentGroupSelector
                        name='group_id'
                        onChange={handleFilterChange}
                        value={values.group_id}
                        options={getContentGroups()}
                    />
                </Form.Field>
                <Form.Field>
                    <label>Category</label>
                    <ContentCategorySelector
                        name='category_id'
                        value={values.category_id}
                        multiple={false}
                        onChange={handleFilterChange}
                        options={contentCategories.map((category, index) => {
                            return {
                                text: category.name,
                                value: category.id,
                                key: `cat-${index}`
                            }
                        })}
                    />
                </Form.Field>
                <Form.Field>
                    <label>&nbsp;</label>
                    <Button secondary onClick={() => onFilter(values)}>Filter</Button>
                </Form.Field>
            </Form.Group>
            <Form.Group>
                <Form.Field>
                    <label>Market Type</label>
                    <MarketSelector
                        name='market_type_id'
                        value={values.market_type_id}
                        onChange={handleFilterChange}
                        multiple={false}
                        options={application.marketTypes.map((mType, index) => {
                            return {
                                text: mType.name,
                                value: mType.id,
                                key: index
                            }
                        })}
                    />
                </Form.Field>
                <Form.Field>
                    <label>Market</label>
                    <MarketSelector
                        name='market_id'
                        value={values.market_id}
                        onChange={handleFilterChange}
                        multiple={false}
                        options={application.providerMarkets.map((market, index) => {
                            return {
                                text: market.name,
                                value: market.id,
                                key: index
                            }
                        })}
                    />
                </Form.Field>
                <Form.Field>
                    <Radio
                        name="auto_build"
                        checked={values.auto_build === ''}
                        value={''}
                        onClick={handleFilterChange}
                        label="All"/><br/>
                    <Radio
                        name="auto_build"
                        checked={values.auto_build === 'true'}
                        value={'true'}
                        onClick={handleFilterChange}
                        label="In auto build"/><br/>
                    <Radio
                        name="auto_build"
                        checked={values.auto_build === 'false'}
                        value={'false'}
                        onClick={handleFilterChange}
                        label="Not in auto build"/>
                </Form.Field>
                <Form.Field>
                    <label>&nbsp;</label>
                    <Button
                        onClick={onEditContent}
                        primary>New Content</Button>
                </Form.Field>
                <Form.Field>
                    <label>&nbsp;</label>
                    <Link to='/cms/create/group/'>
                        <Button primary>New Grouped Content</Button>
                    </Link>
                </Form.Field>
                <Form.Field>
                    <label>&nbsp;</label>
                    <Button
                        onClick={onToggleTagPanel}
                        secondary>Tags</Button>
                </Form.Field>
                <Form.Field>
                    <label>&nbsp;</label>
                    <Link to="/cms/builder/">
                        <Button secondary>
                            Playground
                        </Button>
                    </Link>
                </Form.Field>
            </Form.Group>
        </Form>
    )
}