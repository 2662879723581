export default [
    {value: 'en', text: 'English', flag:'gb', key: '1-en'},
    {value: 'dk', text: 'Danish', flag: 'dk', key: '2-dk'},
    {value: 'se', text: 'Swedish', flag: 'se', key: '3-se'},
    {value: 'no', text: 'Norweigian', flag: 'no', key: '4-no'},
    {value: 'fr', text: 'French', flag: 'fr', key: '5-fr'},
    {value: 'nl', text: 'Dutch', flag: 'nl', key: '6-nl'},
    {value: 'fi', text: 'Finnish', flag: 'fi', key: '7-fi'},
    {value: 'it', text: 'Italian', flag: 'it', key: '8-it'},
    {value: 'es', text: 'Spanish', flag: 'es', key: '9-es'},
    {value: 'es-mx', text: 'Mexican Spanish', flag: 'mx', key: '10-mx'},
    {value: 'es-co', text: 'Colombian Spanish', flag: 'co', key: '11-co'},
    {value: 'es-pa', text: 'Panaman Spanish', flag: 'pa', key: '12-pa'},
    {value: 'ro', text: 'Romanian', flag: 'ro', key: '13-ro'},
]
