import React from 'react';
import PropTypes from 'prop-types';
import {
    Input
} from 'semantic-ui-react';

class LoginForm extends React.PureComponent {
	static propTypes = {
		onSubmit: PropTypes.func.isRequired,
		error: PropTypes.string
	}

	state = {
		email: '',
		password: '',
		error: this.props.error || null
	}

	login = (event) => {
        event.preventDefault();
        const {email, password} = this.state;
		this.props.onSubmit(email, password);
	}

	handleChange = (event, {name, value}) => {
		let newState = {
			[name]: value
		};
		this.setState(newState);
	}

	submitOnEnter = (ev) => {
		if (ev.keyCode === 13) {
			this.login(ev);
		}
		return true;
	}

	render() {
        const {email, password} = this.state;
		return (
			<div className="ui middle aligned center aligned grid" style={{marginTop:'10%'}}>
				<div className="column" style={{maxWidth:550}}>
					<h2 className="ui blue header">
						<div className="content">
							BetSense
						</div>
					</h2>
					{this.state.error ? this.state.error : false}
					<form className="ui large form">
						<div className="ui stacked segment">
							<div className="field">
								<div className="ui left icon input">
									<i className="user icon"></i>
                                    <Input
                                        name='email'
                                        onChange={this.handleChange}
                                        value={email}
                                        placeholder='Email'
                                    />
									{/* <input type="text" name="email" ref="email"
										onChange={this.handleChange}
										onKeyDown={this.submitOnEnter}
										value={this.state.email}
										placeholder="E-mail address"/> */}
					  			</div>
							</div>
							<div className="field">
					  			<div className="ui left icon input">
									<i className="lock icon"></i>
                                    <Input
                                        type='password'
                                        name='password'
                                        onChange={this.handleChange}
                                        value={password}
                                        placeholder='Password'
                                    />
									{/* <input type="password" name="password" ref="password"
										onChange={this.handleChange}
										onKeyDown={this.submitOnEnter}
										value={this.state.password}
										placeholder="Password"/> */}
					  			</div>
							</div>
							<div className="ui fluid large blue submit button"
								 onClick={this.login}>Login</div>
				  		</div>
				  		<div className="ui error message"></div>
					</form>
			  	</div>
			</div>
		);

	}
}

export default LoginForm;
