import React from 'react';
import PropTypes from 'prop-types'
import {groupBy} from 'lodash/collection';
import {isEmpty} from 'lodash/lang';
import {connect} from 'react-redux';
import {parseContent} from '../../redux/actions/content';
import {addToBasket} from '../../redux/actions/app';
import PreviewItem from './item';
import {
    Container,
    List,
    Segment,
    Dropdown,
    Accordion,
    Label,
    Loader,
} from 'semantic-ui-react';

class ContentPreview extends React.Component {
    static propTypes = {
        matchContent: PropTypes.array,
        onEdit: PropTypes.func,
        live: PropTypes.bool,
        matchId: PropTypes.string,
    }

    static defaultProps = {
        matchContent: [],
        live: false
    }

    state = {
        activePanel: null,
        contentGroupKey: 'market.name'
    }

    componentDidMount() {
        const {live} = this.props;
        if (live) {
            this.livePreview();
        }
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        const {live} = this.props;
        if (!live && nextProps.live) {
            this.livePreview();
        }
    }

    livePreview = () => {
        const {application, matchContent, matchId} = this.props;
        const {providerId, sportId} = application;
        const contentItems = matchContent.map((item) => {
            return {
                ...item.content,
                market: item.market,
                context: {
                    score: `${item.node_id}*`,
                    minutes: '0*'
                }
            }
        })
        // const marketId = matchContent.length > 0 && matchContent[0].market ? matchContent[0].market.id : null
        parseContent(providerId, sportId, contentItems, matchId)(this.props.dispatch);
    }

    handleChange = (ev, {name, value}) => {
        this.setState({
            [name]: value
        })
    }

    getPreviewContent = () => {
        const {matchContent, live} = this.props;
        const {parsedContent} = this.props.content;
        if (live && !isEmpty(parsedContent)) {
            return matchContent.map((item, index) => {
                item.content = parsedContent[index];
                return item;
            })
        }
        return matchContent;
    }


    groupedContent = () => {
        const {contentGroupKey} = this.state;
        const groupKeyParts = contentGroupKey.split('.');
        const groupingItems = this.getPreviewContent();
        const content = groupBy(groupingItems, (item) => {
            const groupValue = groupKeyParts.length === 2 ?
                item[groupKeyParts[0]] : item[contentGroupKey];
            const value = groupKeyParts.length === 2 ?
                groupValue[groupKeyParts[1]] : groupValue;
            return value;
        })
        return content
    }

    editContent = (content) => {
        this.props.onEdit(content);
    }

    deleteContent = (content) => {
        this.props.onDelete(content);
    }

    addToBasket = (content) => {
        this.props.dispatch(addToBasket(content));
    }

    getPanels = (groupedContent) => {
        const {live} = this.props;
        const {contentGroupKey} = this.state;
        const groupKeys = Object.keys(groupedContent);
        const panels = groupKeys.map((groupKey, gIndex) => {
            return {
                key: `ph${gIndex}`,
                title: {
                    content: <Label basic>{groupKey}</Label>
                },
                content: {
                    content: (
                        <List>
                            {groupedContent[groupKey].map((item, index) => {
                                return (
                                    <List.Item key={index}>
                                        <List.Content>
                                            <List.Header>
                                                {item.node_id}
                                                {
                                                    item.sub_node ?
                                                    ` [Set: ${item.sub_node}] ` :
                                                    false
                                                }
                                                {
                                                    contentGroupKey !== 'market.name' ?
                                                    ` (${item.market.name})` :
                                                    ` (${item.channel.name} - ${item.language.name})`
                                                }
                                            </List.Header>
                                            <List.Description>
                                                <PreviewItem item={item}/>
                                            </List.Description>
                                            { !live ? (
                                                <List.Description>
                                                    <button className="link regular" onClick={()=>this.editContent(item)}>Edit</button> |
                                                    <button className="link regular" onClick={()=>this.deleteContent(item)}>Delete</button> |
                                                    <button className="link regular" onClick={()=>this.addToBasket(item)}>Add to basket</button>
                                                </List.Description>
                                            ) : false}
                                        </List.Content>
                                    </List.Item>
                                )
                            })}
                        </List>
                    )
                }
            }
        })
        return panels;
    }

    render() {
        const {contentGroupKey} = this.state;
        const {live, content} = this.props;
        const groupedContent = this.groupedContent()
        //const groupKeys = Object.keys(groupedContent);
        const accordionPanels = this.getPanels(groupedContent);
        return (
            <Container>
                <Segment attached='top'>
                    Group by {' '}
                    <Dropdown
                        inline
                        name='contentGroupKey'
                        value={contentGroupKey}
                        options={[
                            {text: 'Market', value: 'market.name'},
                            {text: 'Language', value: 'language.name'},
                            {text: 'Channel', value: 'channel.name'},
                        ]}
                        onChange={this.handleChange}
                    />
                </Segment>
                <Segment attached='top'>
                    { live && content.parsing ? (
                        <Loader active={true} title="Parsing content..."/>
                    ) : (
                        <Accordion panels={accordionPanels} defaultActiveIndex={0} styled/>
                    ) }
                </Segment>
            </Container>
        )
    }
}

export default connect(state => {
    return {
        application: state.application,
        content: state.content
    }
})(ContentPreview);
