import {Actions} from '../../constants';
import {createAction, createReceiver, createTransition} from './utils';
import {Service} from '../../service';


export function fetchContentCategories(providerId, sportId) {
	return function(dispatch) {
		dispatch(createAction(Actions.CONTENTS.GET_CATEGORIES));
		return Service.doGet({
			url: 'contentCategories',
			urlParams: {
				providerId,
				sportId,
			}
		}).then(
			(response) => dispatch(createAction(Actions.CONTENTS.GET_CATEGORIES_SUCCESS, response, null))
		).catch(
			(response) => dispatch(createAction(Actions.CONTENTS.GET_CATEGORIES_FAIL, null, response)) );
	}
}

export function fetchContents(providerId, sportId, matchId=null, filter={}, start=0, sort='') {
	return function(dispatch) {
		dispatch(createAction(Actions.CONTENTS.GET));
		const params = {
			start,
			sort,
			match_id: matchId,
			...filter
        };
		return Service.doGet({
			url: 'contentTemplates',
			params: params,
			urlParams: {
				providerId,
				sportId,
			}
		}).then(
			(response) => dispatch(createReceiver('CONTENTS', response, params))
		).catch(
			(response) => dispatch(createAction(Actions.CONTENTS.GET_FAIL, null, response)) );
	}
}

export function fetchContent(providerId, sportId, contentId) {
	return function(dispatch) {
		dispatch(createAction(Actions.CONTENT.GET, {id: contentId}));
		if (contentId && !isNaN(contentId)) {
			return Service.doGet({
				url: 'content',
				urlParams: {
					providerId,
					sportId,
					contentId
				}
			}).then(
				(response) => dispatch(createAction(Actions.CONTENT.GET_SUCCESS, response, null))
			).catch(
				(response) => dispatch(createAction(Actions.CONTENT.GET_FAIL, null, response)) );
		}
	}
}

export function fetchContentGroup(providerId, sportId, groupId) {
	return function(dispatch) {
		dispatch(createAction(Actions.CONTENTS.GET_GROUP));
		return Service.doGet({
			url: 'contentGroup',
			urlParams: {
				providerId,
				sportId,
				groupId,
			}
		}).then(
			(response) => dispatch(createAction(Actions.CONTENTS.GET_GROUP_SUCCESS, response, null))
		).catch(
			(response) => dispatch(createAction(Actions.CONTENTS.GET_GROUP_FAIL, null, response)) );
	}
}

export function fetchContentGroups(providerId, sportId) {
	return function(dispatch) {
		dispatch(createAction(Actions.CONTENTS.GET_GROUPS));
		return Service.doGet({
			url: 'contentGroups',
			urlParams: {
				providerId,
				sportId,
			}
		}).then(
			(response) => dispatch(createAction(Actions.CONTENTS.GET_GROUPS_SUCCESS, response, null))
		).catch(
			(response) => dispatch(createAction(Actions.CONTENTS.GET_GROUPS_FAIL, null, response)) );
	}
}


export function createContent(providerId, sportId, contentData) {
	return function(dispatch) {
		dispatch(createAction(Actions.CONTENT.CREATE));
		const {image} = contentData;
		if (image) {
			console.log("imag", image)
			delete contentData.image;
		}
		return Service.doPost({
			url: 'contentCreate',
            params: contentData,
            urlParams: {
				providerId,
				sportId,
			}
		}, image)
		.then( (response) => dispatch(createAction(Actions.CONTENT.CREATE_SUCCESS, response, null)) )
		.catch( (response) => dispatch(createAction(Actions.CONTENT.CREATE_FAIL, contentData, response)) );
	}
}

export function updateContent(providerId, sportId, contentId, contentData) {
	return function(dispatch) {
		dispatch(createAction(Actions.CONTENT.UPDATE));
		return Service.doPost({
			url: 'content',
            params: contentData,
            urlParams: {
				providerId,
				sportId,
				contentId
			}
		})
		.then( (response) => dispatch(createAction(Actions.CONTENT.UPDATE_SUCCESS, response, null)) )
		.catch( (response) => dispatch(createAction(Actions.CONTENT.UPDATE_FAIL, contentData, response)) );
	}
}

export function createContentGroup(providerId, sportId, contentData) {
	return function(dispatch) {
		dispatch(createAction(Actions.CONTENTS.CREATE_GROUP));
		return Service.doPost({
			url: 'contentGroups',
            params: contentData,
            urlParams: {
				providerId,
				sportId,
			}
		})
		.then( (response) => dispatch(createAction(Actions.CONTENTS.CREATE_GROUP_SUCCESS, response, null, createTransition('/cms'))) )
		.catch( (response) => dispatch(createAction(Actions.CONTENTS.CREATE_GROUP_FAIL, contentData, response)) );
	}
}

export function updateContentGroup(providerId, sportId, groupId, contentData) {
	return function(dispatch) {
		dispatch(createAction(Actions.CONTENTS.UPDATE_GROUP));
		return Service.doPost({
			url: 'contentGroup',
            params: contentData,
            urlParams: {
				providerId,
				sportId,
				groupId
			}
		})
		.then( (response) => dispatch(createAction(Actions.CONTENTS.UPDATE_GROUP_SUCCESS, response, null, createTransition('/cms'))) )
		.catch( (response) => dispatch(createAction(Actions.CONTENTS.UPDATE_GROUP_FAIL, contentData, response)) );
	}
}

export function deleteContent(providerId, sportId, contentId) {
	return function(dispatch) {
		dispatch(createAction(Actions.CONTENT.DELETE));

		return Service.doDelete({
			url: 'content',
            urlParams: {
				providerId,
				sportId,
				contentId,
			}
		})
		.then( (response) => dispatch(createAction(Actions.CONTENT.DELETE_SUCCESS, response, null, createTransition('/cms'))) )
		.catch( (response) => dispatch(createAction(Actions.CONTENT.DELETE_FAIL, null, response)) );
	}
}


export function fetchQueryTags(providerId, sportId) {
	return function(dispatch) {
		dispatch(createAction(Actions.CONTENT.GET_QUERY_TAGS));
		return Service.doGet({
			url: 'queryTags',
			urlParams: {
				providerId,
				sportId,
			}
		}).then(
			(response) => dispatch(createAction(Actions.CONTENT.GET_QUERY_TAGS_SUCCESS, response, null))
		).catch(
			(response) => dispatch(createAction(Actions.CONTENT.GET_QUERY_TAGS_FAIL, null, response))
		);
	}
}


export function parseContent(providerId, sportId, contents, matchId=null, context=null, params={}) {
	return function(dispatch) {
		dispatch(createAction(Actions.CONTENT.PARSE));
		return Service.doPost({
			url: 'parseContent',
            params: {
            	content: contents,
            	match_id: matchId,
				context: context,
				...params
            },
            urlParams: {
				providerId,
				sportId,
			}
		})
		.then( (response) => dispatch(createAction(Actions.CONTENT.PARSE_SUCCESS, response, null)) )
		.catch( (response) => dispatch(createAction(Actions.CONTENT.PARSE_FAIL, null, response)) );
	}
}


export function toggleAutoBuild(providerId, sportId, contentId) {
	return function(dispatch) {
		dispatch(createAction(Actions.CONTENT.TOGGLE_AUTO_BUILD));
		return Service.doPost({
			url: 'toggleAutoBuild',
            urlParams: {
				providerId,
				sportId,
				contentId,
			}
		})
		.then( (response) => dispatch(createAction(Actions.CONTENT.TOGGLE_AUTO_BUILD_SUCCESS, response, null)) )
		.catch( (response) => dispatch(createAction(Actions.CONTENT.TOGGLE_AUTO_BUILD_FAIL, null, response)) );
	}
}


export function toggleActive(providerId, sportId, contentId) {
	return function(dispatch) {
		dispatch(createAction(Actions.CONTENT.TOGGLE_ACTIVE));
		return Service.doPost({
			url: 'toggleContentActive',
            urlParams: {
				providerId,
				sportId,
				contentId,
			}
		})
		.then( (response) => dispatch(createAction(Actions.CONTENT.TOGGLE_ACTIVE_SUCCESS, response, null)) )
		.catch( (response) => dispatch(createAction(Actions.CONTENT.TOGGLE_ACTIVE_FAIL, null, response)) );
	}
}


export function contentCompile(providerId, sportId, params) {
	return function(dispatch) {
		dispatch(createAction(Actions.CONTENT.COMPILE));
		return Service.doPost({
			url: 'contentCompile',
            urlParams: {
				providerId,
				sportId,
			},
			params: params
		})
		.then( (response) => dispatch(createAction(Actions.CONTENT.COMPILE_SUCCESS, response, null)) )
		.catch( (response) => dispatch(createAction(Actions.CONTENT.COMPILE_FAIL, null, response)) );
	}
}