import React from 'react';
import PropTypes from 'prop-types';
import history from '../../utils/history';
import {MarketSelector} from '../../components/selectors';
import {MatchConnector} from '../../components/match-connector';
import {
    Container, Menu, Icon, Popup
} from 'semantic-ui-react';


class MatchWrapper extends React.Component {
    static propTypes = {
        match: PropTypes.object,
        title: PropTypes.string,
        activeTab: PropTypes.string,
        markets: PropTypes.array,
        marketId: PropTypes.number,
        onMarketSelect: PropTypes.func,
        onMatchCopy: PropTypes.func,
    }

    getMatchActions = () => {
        return [
            {name: 'graph', label: 'Graph', path: '/match/:matchId', icon: 'line chart'},
            {name: 'thresholds', label: 'Thresholds', path: '/match/:matchId/thresholds', icon: 'sort'},
            {name: 'feed', label: 'Feed', path: '/match/:matchId/feed', icon: 'table'},
            {name: 'content', label: 'Content', path: '/match/:matchId/content', icon: 'newspaper'},
            {name: 'delivery', label: 'Delivey', path: '/match/:matchId/delivery', icon: 'send'},
            {name: 'stats', label: 'Stats', path: '/events/:matchId', icon: 'numbered list'},
            {name: 'addstat', label: 'Add Stat', path: '/event/:matchId/edit', icon: 'add'},
            {name: 'copyContent', label: 'Copy from another match', icon: 'copy', onClick: this.handleCopy},
        ]
    }

    go = (path) => {
        const {match} = this.props;
        history.replace(path.replace(':matchId', match.id))
    }

    handleMarketChange = (ev, {name, value}) => {
        const {onMarketSelect} = this.props;
        if (onMarketSelect) {
            onMarketSelect(ev, {name, value});
        }
    }

    handleCopy = (item) => {
        const {match, onMatchCopy} = this.props;
        const sourceMatchId = prompt('Enter source match id');
        if (onMatchCopy && !!sourceMatchId) {
            onMatchCopy(sourceMatchId, match.id);
        }
    }

    render() {
        const {
            match,
            children,
            title,
            activeTab,
            panelControls,
            markets,
            marketId,
        } = this.props;
        const matchActions = this.getMatchActions();
        const matchMarkets = markets && markets.map((market, index) => {
            return {
                key: market.id,
                value: market.id,
                text: market.name
            }
        });
        const provider_names = match.provider_names.join(', ');
        const matchStart = match.start && new Date(match.start).toLocaleString();

        return (
            <Container fluid style={{width:'100vw'}}>
                <Menu attached='top' borderless>
                    <Menu.Item header>
                        Match: {(match.provider && match.provider.provider_name) || match.name} ({match.provider_count}: {provider_names})
                        {title ? `: ${title}` : false}
                    </Menu.Item>
                    <Menu.Item>
                        <span>{matchStart}</span>
                        <span style={{marginLeft: 15}}>
                            [
                                <span className="all-copy">{match.event_id}</span> /
                                <span className="all-copy">{match.league_unique_id}</span>
                            ]
                        </span>
                    </Menu.Item>
                    <Menu.Menu position='right' icon='labeled'>
                        { panelControls ? (
                            <Menu.Item>
                            {panelControls}
                            </Menu.Item>
                        ) : false}
                        <Menu.Item>
                            <MatchConnector
                                matchId={match.id}
                                name={match.name}
                            />
                        </Menu.Item>
                        <Menu.Item>
                            <MarketSelector
                                options={matchMarkets}
                                disabled={['feed', 'graph'].indexOf(activeTab) === -1}
                                value={marketId}
                                onChange={this.handleMarketChange}
                            />
                        </Menu.Item>
                        {matchActions.map((item, index) => {
                            return (
                                <Popup key={index} trigger={(
                                    <Menu.Item name={item.name} onClick={() => {
                                        if (item.path) {
                                            this.go(item.path);
                                        } else if (item.onClick) {
                                            item.onClick(item);
                                        }
                                    }}>
                                        <Icon name={item.icon}/>
                                    </Menu.Item>
                                )} content={item.label}/>
                            )
                        })}
                    </Menu.Menu>
                </Menu>
                {children}
            </Container>
        )
    }
}

export default MatchWrapper;