import React from 'react';
import {connect} from 'react-redux'
import {updateProvider, fetchProviders} from  '../../redux/actions/app';
import {
    Grid, Header, Loader, Button
} from 'semantic-ui-react';


class ProviderSetup extends React.Component {
    updateProvider = (ev, {name, value}) => {
        ev.preventDefault();
        const params = {
            active: value === 'active'
        }
        updateProvider(name, 'provider', params)(this.props.dispatch).then((res) => {
            updateProvider(name, 'providerCtrl', params)(this.props.dispatch).then((res) => {
                fetchProviders()(this.props.dispatch)
            })
        })
    }

    render() {
        const {
            application,
        } = this.props;
        const {
            allProviders,
            providersLoadings
        } = application;
        if (providersLoadings ) {
            return (<Loader active={true}/>)
        }
        return (
            <React.Fragment>
                <Header as='h2'>Providers</Header>

                <Grid celled='internally' columns='equal'>
                    {allProviders.map((provider, index) => {
                        return (
                            <Grid.Row key={index}>
                                <Grid.Column>
                                    {provider.name}
                                </Grid.Column>
                                <Grid.Column>
                                    <Button
                                        name={provider.id}
                                        value={provider.active ? 'inactive' : 'active'}
                                        color={provider.active ? 'green' : 'red'}
                                        content={provider.active ? 'Active' : 'Inactive'}
                                        onClick={this.updateProvider}
                                    />
                                </Grid.Column>
                            </Grid.Row>
                        )
                    })}
                </Grid>
            </React.Fragment>
        )
    }
}

export default connect(state => {
    return {
        application: state.application,
    }
})(ProviderSetup);
