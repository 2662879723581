import React from 'react';
import {connect} from 'react-redux'
import {
    fetchMatch,
    fetchMatchNextOffer,
    fetchMatchMarkets,
    fetchMatchEvents,
    copyMatchContent
} from '../../redux/actions/match';
import {fetchContents} from '../../redux/actions/content';
import {isEmpty} from 'lodash/lang';
import {MatchGraph} from '../../components/match-graph';
import {FeedEventGrid} from '../../components/matches-grid';
import MatchWrapper from './match-wrapper';
import {
    Grid, Loader, Button
} from 'semantic-ui-react';

const RUN_STATE_ON = 1;
const RUN_STATE_OFF = 0;

class MatchPage extends React.Component {
    static defaultProps = {
        timerIntervalCount: 5000
    }

    state = {
        timerIntervalCount: this.props.timerIntervalCount,
        intervalId: null,
        runState: RUN_STATE_OFF,
        marketId: 1,
        showCreative: false,
    }

    componentDidMount() {
        const {matchId} = this.props.match.params;
        if (matchId) {
            this.loadMatch(matchId);
        }
        const intervalId = setInterval(this.timer, this.state.timerIntervalCount);
        this.setState({intervalId: intervalId});
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps.match.params.matchId !== this.props.match.params.matchId) {
            this.loadMatch(nextProps.match.params.matchId);
        }
    }

    componentWillUnmount() {
        clearInterval(this.state.intervalId);
    }

    loadMatch = (matchId) => {
        const {providerId, sportId} = this.props.application;
        const {marketId} = this.state;
        fetchMatch(providerId, sportId, matchId)(this.props.dispatch).then(res => {
            fetchContents(providerId, sportId, matchId)(this.props.dispatch);
            fetchMatchMarkets(providerId, sportId, matchId)(this.props.dispatch);
            fetchMatchEvents(providerId, sportId, matchId, {market_id: marketId})(this.props.dispatch);
        });
    }

    timer = () => {
        // fetch events
        const {runState, marketId} = this.state;
        if (runState === RUN_STATE_ON && marketId) {
            const {providerId, sportId} = this.props.application;
            const {matchGraph} = this.props.matches;
            const {matchId} = this.props.match.params;
            const {marketId} = this.state;
            fetchMatchNextOffer(providerId, sportId, matchId, matchGraph.lastOfferId || '-', marketId)(this.props.dispatch);
        }
    }

    toggleRunState = () => {
        this.setState({
            runState: this.state.runState === RUN_STATE_OFF ? RUN_STATE_ON : RUN_STATE_OFF
        })
    }

    getContentOptions = () => {
        return (item) => {
            return [
                {
                    icon: 'soccer',
                    title: 'Matches',
                    path: `/league/${item.id}/matches`
                }
            ]
        }
    }

    handleMarketChange = (ev, {name, value}) => {
        this.setState({
            marketId: value
        }, () => {
            this.fetchEvents();
        })
    }

    handleMatchCopy = (fromMatchId, toMatchId) => {
        console.log("MATCH COPY", fromMatchId, toMatchId)
        const {providerId, sportId} = this.props.application;
        copyMatchContent(providerId, sportId, fromMatchId, toMatchId)(this.props.dispatch);
    }

    fetchEvents = () => {
        const {providerId, sportId} = this.props.application;
        const {matchId} = this.props.match.params;
        const {marketId} = this.state;
        const params = {
            market_id: marketId,
            reverse: true, 
            limit: 1000,
            inplay_only: 'true',
        }
        fetchMatchEvents(providerId, sportId, matchId, params)(this.props.dispatch);
    }

    selectedMarket = () => {
        const {marketId} = this.state;
        const {providerMarkets} = this.props.application;
        const market = providerMarkets.filter(item => item.id === marketId);
        return market
    }

    render() {
        const {
            matches
        } = this.props;
        const {matchFeedEvents} = matches;
        const {
            marketId,
            runState,
            showCreative,
        } = this.state;
        const {providerId} = this.props.application;
        const {matchId} = this.props.match.params;
        const selectedMarket = this.selectedMarket();
        if (matches.loading || !matches.currentMatch || isEmpty(matches.currentMatch)) {
           return (<Loader active={true}/>)
        }
        
        return (
            <MatchWrapper
                activeTab='graph'
                match={matches.currentMatch}
                markets={matches.matchMarkets}
                marketId={marketId}
                onMarketSelect={this.handleMarketChange}
                onMatchCopy={this.handleMatchCopy}>
                <Grid>
                    <Grid.Row>
                        <Grid.Column>
                            <Button content={runState === RUN_STATE_ON ? 'Running' : 'Stopped'} onClick={this.toggleRunState}/>
                            <Button content={showCreative ? 'Hide Creative' : 'Show Creative'} onClick={() => {
                                this.setState({showCreative: !showCreative});
                            }}/>
                            {showCreative ? (
                                <iframe 
                                    src={`https://ctrl.betsense.io/api/1/${providerId}/match/${marketId || 600}/banner/`} 
                                    style={{width:'100%', height: 240}}></iframe>
                            ) : false}
                            <MatchGraph
                                match={matches.currentMatch}
                                events={matchFeedEvents.slice().reverse()}
                                totalEvents={matchFeedEvents.length}
                                market={selectedMarket[0]}
                            />
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row>
                        <Grid.Column>
                            <FeedEventGrid
                                market={selectedMarket[0]}
                                match={matches.currentMatch}
                                items={matchFeedEvents}
                            />
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            </MatchWrapper>
        )
    }
}

export default connect(state => {
    return {
        application: state.application,
        matches: state.matches,
        leagues: state.leagues,
	}
})(MatchPage);
