import {Actions} from '../../constants';
import {find, keyBy} from 'lodash/collection';

const initialAppState = {
	ready: false,
	loading: false,  // global loading state
	errors: [],  // global app errors
	providerId: parseInt(localStorage.getItem('providerId') || 1, 10),  // current provider id
	sportId: parseInt(localStorage.getItem('sportId') || 1, 10),  // current sport id
	currentProvider: null,
	providersLoading: false,
	allProviders: [],  // all available odd providers (active or not)
	activeProviders: [],  // all available and active odd providers
	allSports: [],
	sportIndex: {}, // sports indexed by id
	providerSports: [], // all provider sports
	providerMarkets: [],
	marketTypes: [],
	rules: [],
	user: {},
	token: null,
	authenticating: false,
	authenticated: true,
	searching: false,
	searchResults: {},
	savedThresholds: [],
	currentSavedThreshold: {},
	basket: {
		id: null,
		items: [],
		content: [],
		saved: false,
	},
};

function currentProvider(providerId, providers) {
	let _current = null;
	providers.forEach((provider) => {
		if (provider.id === providerId) {
			_current = provider;
		}
	})
	return _current;
}

function providerSports(providerId, providers) {
	providers.forEach((provider) => {
		if (provider.id === providerId) {
			return provider.sports;
		}
	})
	return [];
}

function application(state=initialAppState, action) {
	switch(action.type) {
		case Actions.APP.SET_PROVIDER:
			return {
				...state,
				providerId: action.payload.providerId,
				currentProvider: currentProvider(action.payload.providerId, state.activeProviders),
				providerSports: providerSports(action.payload.providerId, state.activeProviders)
			}

		case Actions.APP.SET_SPORT:
			return {
				...state,
				sportId: action.payload.sportId
			}

		case Actions.APP.ADD_ERROR:
			let errors = state.errors || [];
			if (action.payload.error) {
				let exists = find(errors, (item)=>{
					return action.payload.error.message === item.message;
				})
				if (!exists) {
					errors.push(action.payload.error);
				}
			}
			return {
				...state,
				loading: false,
				errors: errors
			}

		case Actions.APP.ADD_ERRORS:
			return {
				...state,
				loading: false,
				errors: action.payload
			}

		case Actions.APP.CLEAR_ERRORS:
			return {
				...state,
				errors: []
			}

		case Actions.APP.GET_PROVIDERS:
			return {
				...state,
				providersLoading: true,
				allProviders: [],
				activeProviders: [],
			}

		case Actions.APP.GET_PROVIDERS_SUCCESS:
			const providersPayload = action.payload.response.results;
			return {
				...state,
				providersLoading: false,
				allProviders: providersPayload,
				activeProviders: providersPayload.filter(provider => provider.active),
				currentProvider: currentProvider(state.providerId, providersPayload),
			}

		case Actions.APP.GET_PROVIDERS_FAIL:
			return {
				...state,
				providersLoading: false,
				error: action.payload.error
			}

		// case Actions.APP.GET_PROVIDER:
		// 	return {
		// 		...state,
		// 		providersLoading: true,
		// 		currentProvider: {}
		// 	}

		// case Actions.APP.GET_PROVIDER_SUCCESS:
		// 	return {
		// 		...state,
		// 		providersLoading: false,
		// 		//allProviders: action.payload.response.results,
		// 		currentProvider: currentProvider(state.providerId, action.payload.response.results),
		// 	}

		// case Actions.APP.GET_PROVIDER_FAIL:
		// 	return {
		// 		...state,
		// 		providersLoading: false,
		// 		error: action.payload.error
		// 	}

		case Actions.APP.AUTHENTICATE:
			return {
				...state,
				user: {},
				authenticating: true
			}

		case Actions.APP.AUTHENTICATE_SUCCESS:
			if (action.payload.response &&
				action.payload.response.token) {
				localStorage.setItem('token', action.payload.response.token);
			}
			return {
				...state,
				user: action.payload.response.user,
				token: action.payload.response.token,
				authenticating: false,
				authenticated: true
			}

		case Actions.APP.AUTHENTICATE_FAIL:
			return {
				...state,
				user: {},
				authenticating: false,
				authenticated: false,
				error: action.payload.error
			}

		case Actions.APP.GET_SPORTS:
			return {
				...state,
				loading: true,
			}

		case Actions.APP.GET_SPORTS_SUCCESS:
			return {
				...state,
				loading: false,
				ready: true,
				allSports: action.payload.response.results,
				sportIndex: keyBy(action.payload.response.results, v => v.id)
			}

		case Actions.APP.GET_SPORTS_FAIL:
			return {
				...state,
				loading: false,
				error: action.payload.error
			}

		case Actions.APP.GET_MARKETS:
			return {
				...state,
				loading: true,
			}

		case Actions.APP.GET_MARKETS_SUCCESS:
			return {
				...state,
				loading: false,
				providerMarkets: action.payload.response.results,
				marketIndex: keyBy(action.payload.response.results, v => v.id)
			}

		case Actions.APP.GET_MARKETS_FAIL:
			return {
				...state,
				loading: false,
				error: action.payload.error
			}

		case Actions.APP.GET_MARKET_TYPES:
			return {
				...state,
				loading: true,
			}

		case Actions.APP.GET_MARKET_TYPES_SUCCESS:
			return {
				...state,
				loading: false,
				marketTypes: action.payload.response.results,
			}

		case Actions.APP.GET_MARKET_TYPES_FAIL:
			return {
				...state,
				loading: false,
				error: action.payload.error
			}

		case Actions.APP.GET_RULES:
			return {
				...state
			}

		case Actions.APP.GET_RULES_SUCCESS:
			return {
				...state,
				rules: action.payload.response.results
			}

		case Actions.APP.GET_RULES_FAIL:
			return {
				...state,
				error: action.payload.error
			}


		case Actions.APP.SEARCH:
			return {
				...state,
				searching: true,
			}

		case Actions.APP.SEARCH_SUCCESS:
			return {
				...state,
				searching: false,
				searchResults: action.payload.response.results
			}

		case Actions.APP.SEARCH_FAIL:
			return {
				...state,
				searching: false,
				error: action.payload.error,
				searchResults: {}
			}

		case Actions.APP.ADD_BASKET:
			return {
				...state,
				basket: {
					...state.basket,
					items: state.basket.items.concat([action.payload.content])
				}
			}

		case Actions.APP.REMOVE_FROM_BASKET:
			return {
				...state,
				basket: {
					...state.basket,
					items: state.basket.items.filter(item => item.id !== action.payload.content.id)
				}
			}

		case Actions.APP.BASKET_TOGGLE_HOLD:
			const updatedItems = [...state.basket.items]
			updatedItems[action.payload.index].hold = !Boolean(updatedItems[action.payload.index].hold);
			return {
				...state,
				basket: {
					...state.basket,
					items: updatedItems
				}
			}

		case Actions.APP.GET_SAVED_THRESHOLDS:
			return {
				...state,
				savedThresholds: []
			}

		case Actions.APP.DELETE_SAVED_THRESHOLDS_SUCCESS:
		case Actions.APP.GET_SAVED_THRESHOLDS_SUCCESS:
			return {
				...state,
				savedThresholds: action.payload.response.results
			}

		case Actions.APP.GET_SAVED_THRESHOLDS_FAIL:
			return {
				...state,
				savedThresholds: [],
				error: action.payload.error
			}

		default:
			return state;
	}
}

export default application;
