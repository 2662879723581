import React from 'react';
import PropTypes from 'prop-types';
import {Dropdown} from 'semantic-ui-react';
import {connect} from 'react-redux'
import {range} from 'lodash/util';
import {productOf} from '../../utils';
import {compact} from 'lodash/array';


class TennisPointSelector extends React.Component {
    static propTypes = {
        name: PropTypes.string,
        value: PropTypes.array,
        excludeScores: PropTypes.array,
        onChange: PropTypes.func,
        placeholder: PropTypes.string
    }

    static defaultProps = {
        value: [],
        excludeScores: []
    }

    state = {
        value: this.props.value,
        leafNodes: null
    }

    leafNodes = null

    componentDidMount() {
        const {application} = this.props;
        const sport = application.sportIndex[application.sportId];
        if (sport.scores && sport.scores.leaf_nodes) {
            this.leafNodes = sport.scores.leaf_nodes;
        } else if (sport.scores.max_leaf_node) {
            this.leafNodes = this.getScoreOptions();
        }
    }

    handleChange = (ev, {name, value}) => {
        this.setState({
            value: value
        }, () => {
            this.props.onChange(ev, {name, value})
        })
    }

    getScoreOptions = () => {
        const {sportId, sportIndex} = this.props.application;
        const sportScoreMap = sportIndex[sportId].scores;
        const scores = range(0, sportScoreMap.max_leaf_node + 1, sportScoreMap.leaf_node_increment || 1);
        const scoreOptions = productOf(scores, scores);
        return this.normaliseScoreOptions(scoreOptions);
    }

    getOptions = () => {
        const options = this.leafNodes && this.leafNodes.map((set, index) => {
            return {
                text: set,
                value: set,
                key: index
            }
        })
        return options;
    }

    normaliseScoreOptions = (scores) => {
        const {excludeScores} = this.props;
        const scoreOptions = [
            // {
            //     text: 'PRE',
            //     value: 'PRE',
            //     key: 'PRE'
            // }
        ].concat(compact(scores.map((score, index) => {
            score = `H${score[0]}-A${score[1]}`;
            if (excludeScores.indexOf(score) === -1) {
                return score;
            } else {
                return false;
            }
        })))
        return scoreOptions;
    }

    render() {
        const {
            name,
            placeholder
        } = this.props;
        const {
            value
        } = this.state;
        if (!this.leafNodes) {
            return false;
        }
        return (
            <Dropdown
                selection
                search
                multiple
                fluid
                placeholder={placeholder}
                name={name || 'leaf_node'}
                value={value || []}
                options={this.getOptions()}
                onChange={this.handleChange}
            />
        )
    }
}

export default connect(state => {
    return {
        application: state.application
	}
})(TennisPointSelector);
