import {Actions} from '../../constants';

const initialTeamsState = {
    loading: false,
    items: [],
    total: 0,  // total resultset size
    limit: 0,  // page size
    currentTeam: null,
    connectorSearchItems: [], // team search items when using the team connector
    connectorLoading: false,
    teamLastMatchApex: {},
};

function teams(state=initialTeamsState, action) {
    switch(action.type) {
        case Actions.TEAMS.GET:
            return {
                ...state,
                loading: true
            };

        case Actions.TEAMS.GET_SUCCESS:
            return {
                ...state,
                loading: false,
                items: action.payload.response.results,
                total: action.payload.response.total,
                limit: action.payload.response.limit,
            }

        case Actions.TEAMS.GET_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload.error
            }

        case Actions.TEAM.GET:
            return {
                ...state,
                loading: true
            };

        case Actions.TEAM.GET_SUCCESS:
            return {
                ...state,
                loading: false,
                currentTeam: action.payload.response.result
            }

        case Actions.TEAM.GET_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload.error
            }

        case Actions.TEAM.CONNECT_SEARCH:
            return {
                ...state,
                connectorSearchItems: [],
                connectorLoading: true
            };

        case Actions.TEAM.CONNECT_SEARCH_SUCCESS:
            return {
                ...state,
                connectorLoading: false,
                connectorSearchItems: action.payload.response.results,
                total: action.payload.response.total,
                limit: action.payload.response.limit,
            }

        case Actions.TEAM.CONNECT_SEARCH_FAIL:
            return {
                ...state,
                connectorLoading: false,
                connectorSearchItems: [],
                error: action.payload.error
            }

        case Actions.TEAM.LAST_MATCH_APEX:
            console.log("PAYLOAD", action.payload)
            const newState = {
                ...state,
            }
            newState.teamLastMatchApex[action?.payload?.teamId] = {}
            return newState

        case Actions.TEAM.LAST_MATCH_APEX_SUCCESS:
            const newState2 = {
                ...state,
            }
            newState2.teamLastMatchApex[action?.payload?.teamId] = action?.payload?.response?.result
            return newState2

        default:
            return state;
    }
}

export default teams;
