import {Actions} from '../../constants';
import {Service} from '../../service';
import {
	createAction,
	createTransition,
	createReceiver,
	getSortParams
} from './utils';


export function fetchMatches(providerId, sportId, leagueId=null, start=0, sort='-start', timeFrame=null, date=null, keyword=null, matchIds=null) {
	return function(dispatch) {
		dispatch(createAction(Actions.MATCHES.GET));
		const params = {
			start,
			...getSortParams(sort)
        };
		const url = leagueId === null ? 'matches' : 'leagueMatches';
		if (timeFrame) {
			params['timeframe'] = timeFrame;
		}
		if (matchIds) {
			params['match_id'] = matchIds;
		}
		if (date) {
			params['date'] = date;
		}
		if (keyword) {
			params['q'] = keyword;
		}
		return Service.doGet({
			url: url,
			params: params,
			urlParams: {
				providerId,
                sportId,
                leagueId,
			}
		}).then(
			(response) => dispatch(createReceiver('MATCHES', response, params))
		).catch(
			(response) => dispatch(createAction(Actions.MATCHES.GET_FAIL, null, response)) );
	}
}

export function fetchSeasonMatches(sportId, leagueId, seasonId, teamId=null, sort='-start') {
	return function(dispatch) {
		dispatch(createAction(Actions.MATCHES.GET));
		const params = {
			team_id: teamId,
			...getSortParams(sort)
		};
		return Service.doGet({
			url: 'seasonMatches',
			params: params,
			urlParams: {
                sportId,
				leagueId,
				seasonId,
			}
		}).then(
			(response) => dispatch(createReceiver('MATCHES', response, params))
		).catch(
			(response) => dispatch(createAction(Actions.MATCHES.GET_FAIL, null, response)) );
	}
}


export function fetchMatch(providerId, sportId, matchId=null) {
	return function(dispatch) {
		dispatch(createAction(Actions.MATCH.GET));
		return Service.doGet({
			url: 'match',
			urlParams: {
				providerId,
                sportId,
                matchId,
			}
		}).then(
			(response) => dispatch(createAction(Actions.MATCH.GET_SUCCESS, response, null))
		).catch(
			(response) => dispatch(createAction(Actions.MATCH.GET_FAIL, null, response)) );
	}
}


export function fetchMatchContent(providerId, sportId, matchId) {
	return function(dispatch) {
		dispatch(createAction(Actions.MATCH.GET_CONTENT));
		return Service.doGet({
			url: 'matchContent',
			urlParams: {
				providerId,
                sportId,
                matchId,
			}
		}).then(
			(response) => dispatch(createAction(Actions.MATCH.GET_CONTENT_SUCCESS, response, null))
		).catch(
			(response) => dispatch(createAction(Actions.MATCH.GET_CONTENT_FAIL, null, response)) );
	}
}


export function fetchMatchMarkets(providerId, sportId, matchId) {
	return function(dispatch) {
		dispatch(createAction(Actions.MATCH.GET_MARKETS));
		return Service.doGet({
			url: 'matchMarkets',
			urlParams: {
				providerId,
                sportId,
                matchId,
			}
		}).then(
			(response) => dispatch(createAction(Actions.MATCH.GET_MARKETS_SUCCESS, response, null))
		).catch(
			(response) => dispatch(createAction(Actions.MATCH.GET_MARKETS_FAIL, null, response)) );
	}
}


export function saveMatchContent(providerId, sportId, matchId, values) {
	return function(dispatch) {
		dispatch(createAction(Actions.MATCH.SAVE_CONTENT));
		return Service.doPost({
			url: 'matchContent',
			urlParams: {
				providerId,
                sportId,
                matchId,
			},
			params: values
		}).then(
			(response) => dispatch(createAction(Actions.MATCH.SAVE_CONTENT_SUCCESS, response, null))
		).catch(
			(response) => dispatch(createAction(Actions.MATCH.SAVE_CONTENT_FAIL, null, response)) );
	}
}


export function fetchMatchThresholds(providerId, sportId, matchId) {
	return function(dispatch) {
		dispatch(createAction(Actions.MATCH.GET_THRESHOLDS));
		return Service.doGet({
			url: 'matchThresholds',
			urlParams: {
				providerId,
                sportId,
                matchId,
			}
		}).then(
			(response) => dispatch(createAction(Actions.MATCH.GET_THRESHOLDS_SUCCESS, response, null))
		).catch(
			(response) => dispatch(createAction(Actions.MATCH.GET_THRESHOLDS_FAIL, null, response)) );
	}
}


export function saveMatchThresholds(providerId, sportId, matchId, values) {
	return function(dispatch) {
		dispatch(createAction(Actions.MATCH.SAVE_THRESHOLDS));
		return Service.doPost({
			url: 'matchThresholds',
			urlParams: {
				providerId,
                sportId,
                matchId,
			},
			params: values
		}).then(
			(response) => dispatch(createAction(Actions.MATCH.SAVE_THRESHOLDS_SUCCESS, response, null))
		).catch(
			(response) => dispatch(createAction(Actions.MATCH.SAVE_THRESHOLDS_FAIL, null, response)) );
	}
}


export function deleteMatchThresholds(providerId, sportId, matchId, thresholdsId=null) {
	return function(dispatch) {
		dispatch(createAction(Actions.MATCH.DELETE_THRESHOLDS));
		return Service.doDelete({
			url: 'matchThresholds',
			urlParams: {
				providerId,
                sportId,
                matchId,
			},
			params: {thresholds_id: thresholdsId}
		}).then(
			(response) => dispatch(createAction(Actions.MATCH.DELETE_THRESHOLDS_SUCCESS, response, null))
		).catch(
			(response) => dispatch(createAction(Actions.MATCH.DELETE_THRESHOLDS_FAIL, null, response)) );
	}
}

export function fetchMatchEvents(providerId, sportId, matchId, params={}) {
	return function(dispatch) {
		dispatch(createAction(Actions.MATCH.GET_EVENTS));
		return Service.doGet({
			url: 'matchFeedEvents',
			urlParams: {
				providerId,
                sportId,
                matchId
			},
			params: params
		}).then(
			(response) => dispatch(createAction(Actions.MATCH.GET_EVENTS_SUCCESS, response, null))
		).catch(
			(response) => dispatch(createAction(Actions.MATCH.GET_EVENTS_FAIL, null, response)) );
	}
}

export function fetchMatchDeliveries(providerId, sportId, matchId, params={}) {
	return function(dispatch) {
		dispatch(createAction(Actions.MATCH.GET_DELIVERIES));
		return Service.doGet({
			url: 'matchDeliveries',
			urlParams: {
				providerId,
                sportId,
                matchId
			},
			params: params
		}).then(
			(response) => dispatch(createAction(Actions.MATCH.GET_DELIVERIES_SUCCESS, response, null))
		).catch(
			(response) => dispatch(createAction(Actions.MATCH.GET_DELIVERIES_FAIL, null, response)) );
	}
}


export function fetchMatchNextOffer(providerId, sportId, matchId, offerId, marketId=null) {
	return function(dispatch) {
		dispatch(createAction(Actions.MATCH.NEXT_OFFER));
		const params = {}
		if (marketId) {
			params['market_id'] = marketId;
		}
		return Service.doGet({
			url: 'matchNextOffers',
			urlParams: {
				providerId,
                sportId,
                matchId,
                offerId
			},
			params: params
		}).then(
			(response) => dispatch(createAction(Actions.MATCH.NEXT_OFFER_SUCCESS, response, null))
		).catch(
			(response) => dispatch(createAction(Actions.MATCH.NEXT_OFFER_FAIL, null, response)) );
	}
}


export function deleteMatchContent(providerId, sportId, matchId, matchContentId) {
	return function(dispatch) {
		dispatch(createAction(Actions.MATCH.DELETE_CONTENT));
		return Service.doDelete({
			url: 'matchContentDelete',
            urlParams: {
				providerId,
				sportId,
				matchId,
				matchContentId,
			}
		})
		.then( (response) => dispatch(createAction(Actions.MATCH.DELETE_CONTENT_SUCCESS, response, null)) )
		.catch( (response) => dispatch(createAction(Actions.MATCH.DELETE_CONTENT_FAIL, null, response)) );
	}
}

export function fetchTemplatedMatches(providerId, sportId) {
	return function(dispatch) {
		dispatch(createAction(Actions.MATCHES.GET_TEMPLATED));
		return Service.doGet({
			url: 'templatedMatches',
			urlParams: {
				providerId,
                sportId
			}
		}).then(
			(response) => dispatch(createAction(Actions.MATCHES.GET_TEMPLATED_SUCCESS, response, null))
		).catch(
			(response) => dispatch(createAction(Actions.MATCHES.GET_TEMPLATED_FAIL, null, response)) );
	}
}


export function copyMatchContent(providerId, sportId, fromMatchId, toMatchId) {
	return function(dispatch) {
		dispatch(createAction(Actions.MATCH.COPY_MATCH_DATA));
		return Service.doPost({
			url: 'matchCopyData',
			urlParams: {
				providerId,
                sportId,
                fromMatchId,
                toMatchId
			}
		}).then(
			(response) => dispatch(createAction(Actions.MATCH.COPY_MATCH_DATA_SUCCESS, response, null))
		).catch(
			(response) => dispatch(createAction(Actions.MATCH.COPY_MATCH_DATA_FAIL, null, response)) );
	}
}



export function matchConnectSearch(providerId, sportId, fromMatchId, search=null, start=0, sort='-last_modified') {
    return function(dispatch) {
        dispatch(createAction(Actions.MATCH.CONNECT_SEARCH));
        const params = {
            start,
            q: search,
            ...getSortParams(sort)
        };
        return Service.doGet({
            url: 'matchConnectSearch',
            params: params,
            urlParams: {
                providerId,
                sportId,
                fromMatchId,
            }
        }).then(
            (response) => dispatch(createAction(Actions.MATCH.CONNECT_SEARCH_SUCCESS, response, null))
        ).catch(
            (response) => dispatch(createAction(Actions.MATCH.CONNECT_SEARCH_FAIL, null, response)) )
    }
}

/**
 * Assert that a match X from provider A, is actually the same as match Y from provider B.
 * This will create the connection via the backend API.
 * Note that match X will then be deactivated, and all references to it will be replaced
 * with match Y, which will also receive a new provider link to provider A.
 * Both source teams will be replaced with the target match's teams
 * @param {int} providerId
 * @param {int} fromTeamId
 * @param {int} toProviderId
 * @param {int} toTeamId
 */
export function connectMatch(sportId, providerId, fromMatchId, toMatchId) {
    return function(dispatch) {
        dispatch(createAction(Actions.MATCH.CONNECT));
        return Service.doPost({
            url: 'matchConnect',
            urlParams: {
                providerId,
                sportId,
                fromMatchId,
                toMatchId
            }
        }).then(
            (response) => dispatch(createAction(Actions.MATCH.CONNECT_SUCCESS, response, null,  createTransition(`/match/${toMatchId}/`)))
        ).catch(
            (response) => dispatch(createAction(Actions.MATCH.CONNECT_FAIL, null, response)) );
    }
}



export function fetchMatchOfferContent(providerId, sportId, offerId) {
	return function(dispatch) {
		dispatch(createAction(Actions.MATCH.OFFER_CONTENT));
		const params = {}
		return Service.doGet({
			url: 'offerContent',
			urlParams: {
				providerId,
                offerId
			},
			params: params
		}).then(
			(response) => dispatch(createAction(Actions.MATCH.OFFER_CONTENT_SUCCESS, response, null))
		).catch(
			(response) => dispatch(createAction(Actions.MATCH.OFFER_CONTENT_FAIL, null, response)) );
	}
}


export function fetchTrackers(providerId) {
	return function(dispatch) {
		dispatch(createAction(Actions.MATCHES.GET_TRACKERS));
		const params = {}
		return Service.doGet({
			url: 'trackers',
			urlParams: {
				providerId
			},
			params: params
		}).then(
			(response) => dispatch(createAction(Actions.MATCHES.GET_TRACKERS_SUCCESS, response, null))
		).catch(
			(response) => dispatch(createAction(Actions.MATCHES.GET_TRACKERS_FAIL, null, response)) );
	}
}

export function setTracker(tracker) {
	return createAction(Actions.MATCHES.SET_TRACKER, tracker, null);
}

export function setTrackerField(name, value) {
	return createAction(Actions.MATCHES.SET_TRACKER_FIELD, {name, value});
}

export function clearTracker() {
	return createAction(Actions.MATCHES.SET_TRACKER, null, null);
}



export function updateTracker(providerId, trackerId, params) {
    return function(dispatch) {
        dispatch(createAction(Actions.MATCHES.UPDATE_TRACKER));
        return Service.doPost({
            url: 'tracker',
            urlParams: {
                providerId,
                trackerId
            },
			params: params,
        }).then(
            (response) => dispatch(createAction(Actions.MATCHES.UPDATE_TRACKER_SUCCESS, response, null,  createTransition(`/trackers/`)))
        ).catch(
            (response) => dispatch(createAction(Actions.MATCHES.UPDATE_TRACKER_FAIL, null, response)) );
    }
}
