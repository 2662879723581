import React from 'react';
import PropTypes from 'prop-types';
import {ContactSelector, LineSelector} from '../selectors';
import ThresholdFields from './thresholds';
import {find} from 'lodash/collection';
import {isEqual} from 'lodash/lang';
import {
    Form, Header, Button, Input, Checkbox
} from 'semantic-ui-react';

class ThresholdsForm extends React.Component {
    static propTypes = {
        name: PropTypes.string,
        market: PropTypes.object,
        contacts: PropTypes.array,
        onChange: PropTypes.func,
        onSubmit: PropTypes.func,
        values: PropTypes.object,
    }

    static defaultProps = {
        market: null,
        values: {}
    }

    state = {
        values: this.props.values
    }

    static getDerivedStateFromProps(props, state) {
        if (!isEqual(props.values, state.values)) {
            return {
                values: props.values
            }
        }
        return null
    }

    handleChange = (ev, {name, value, type, checked}) => {
        const {values} = this.state;
        values[name] = type === 'checkbox' ? checked : value;
        this.setState({
            values
        }, () => {
            this.props.onChange(ev, {
                name: this.props.name,
                value: this.state.values
            })
        })
    }

    showThresholdLimitFields = () => {
        const {contactId} = this.state.values;
        const contact = find(this.props.contacts, contact => contact.id === contactId)
        return !!(contact && contact.channel.code === 'FEED');
    }

    render() {
        const {market, contacts} = this.props;
        const {values} = this.state;
        const {contactId, thresholds, line} = values;
        if (!market) {
            return false;
        }
        return (
            <Form>
                <Form.Field>
                    <Header as='h4'>
                        {market.name}
                        <Header.Subheader floated='right'>
                            <Button
                                primary
                                floated='right'
                                content='Submit'
                                onClick={() => {
                                    this.props.onSubmit(this.state.values);
                                }}
                            />
                        </Header.Subheader>
                    </Header>
                </Form.Field>
                <Form.Field>
                    <label>Contact</label>
                    <ContactSelector
                        name='contactId'
                        value={contactId || ''}
                        contacts={contacts}
                        onChange={this.handleChange}
                    />
                </Form.Field>
                <Form.Field>
                    <label>Save as a template</label>
                    <Input
                        name="saveAsTemplate"
                        placeholder="To save this as a template for future use, enter a name"
                        value={values.saveAsTemplate || ''}
                        onChange={this.handleChange}
                    />
                </Form.Field>
                <Form.Field>
                    <Checkbox
                        name="saveAsTemplateDefault"
                        value={''}
                        label='Set as default template'
                        checked={values.saveAsTemplateDefault}
                        onChange={this.handleChange}
                    />
                </Form.Field>
                { market.helper_args && market.helper_args.dynamic_line ? (
                    <Form.Field>
                        <label>Line</label>
                        <LineSelector
                            name='line'
                            value={line}
                            onChange={this.handleChange}
                        />
                    </Form.Field>
                ) : false}
                <Form.Field>
                    <label>Thresholds</label>
                    <ThresholdFields
                        name='thresholds'
                        market={market}
                        onChange={this.handleChange}
                        values={thresholds || {}}
                        showMarketLimits={this.showThresholdLimitFields()}
                    />
                </Form.Field>
            </Form>
        )
    }
}

export default ThresholdsForm;