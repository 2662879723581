import {Actions} from '../../constants';
import {createAction, createReceiver} from './utils';
import {Service} from '../../service';


export function fetchEvents(providerId, sportId, matchId, start=0, sort='') {
	return function(dispatch) {
		dispatch(createAction(Actions.EVENTS.GET));
		const params = {
			start,
			sort,
        };
		return Service.doGet({
			url: 'events',
			params: params,
			urlParams: {
				providerId,
                sportId,
                matchId,
			}
		}).then(
			(response) => dispatch(createReceiver('EVENTS', response, params))
		).catch(
			(response) => dispatch(createAction(Actions.EVENTS.GET_FAIL, null, response)) );
	}
}

export function fetchEvent(providerId, sportId, eventId, start=0, sort='') {
	return function(dispatch) {
		dispatch(createAction(Actions.EVENT.GET));
		const params = {
			start,
			sort,
        };
		return Service.doGet({
			url: 'event',
			params: params,
			urlParams: {
				providerId,
				sportId,
			}
		}).then(
			(response) => dispatch(createAction(Actions.EVENT.GET_SUCCESS, response))
		).catch(
			(response) => dispatch(createAction(Actions.EVENT.GET_FAIL, null, response)) );
	}
}

export function createEvent(providerId, sportId, eventData) {
	return function(dispatch) {
		dispatch(createAction(Actions.EVENT.CREATE));
		return Service.doPost({
			url: 'event',
            params: eventData,
            urlParams: {
				providerId,
				sportId,
			}
		})
		.then( (response) => dispatch(createAction(Actions.EVENT.CREATE_SUCCESS, response, null)) )
		.catch( (response) => dispatch(createAction(Actions.EVENT.CREATE_FAIL, eventData, response)) );
	}
}

export function updateEvent(providerId, sportId, eventId, eventData) {
	return function(dispatch) {
		dispatch(createAction(Actions.EVENT.UPDATE));
		return Service.doPost({
			url: 'event',
            params: eventData,
            urlParams: {
				providerId,
                sportId,
                eventId
			}
		})
		.then( (response) => dispatch(createAction(Actions.EVENT.UPDATE_SUCCESS, response, null)) )
		.catch( (response) => dispatch(createAction(Actions.EVENT.UPDATE_FAIL, eventData, response)) );
	}
}

export function createOrUpdateEvents(providerId, sportId, matchId, events) {
	return function(dispatch) {
		dispatch(createAction(Actions.EVENT.UPDATE));
		return Service.doPost({
			url: 'events',
            params: {
				events
			},
            urlParams: {
				providerId,
                sportId,
                matchId
			}
		})
		.then( (response) => dispatch(createAction(Actions.EVENT.UPDATE_SUCCESS, response, null)) )
		.catch( (response) => dispatch(createAction(Actions.EVENT.UPDATE_FAIL, events, response)) );
	}
}

export function fetchEventTypes(providerId, sportId) {
	return function(dispatch) {
		dispatch(createAction(Actions.EVENTS.GET_TYPES));
		return Service.doGet({
			url: 'eventTypes',
			urlParams: {
				providerId,
				sportId,
			}
		}).then(
			(response) => dispatch(createAction(Actions.EVENTS.GET_TYPES_SUCCESS, response))
		).catch(
			(response) => dispatch(createAction(Actions.EVENTS.GET_TYPES_FAIL, null, response)) );
	}
}


export function deleteEvents(providerId, sportId, matchId, eventIds) {
	return function(dispatch) {
		dispatch(createAction(Actions.EVENTS.DELETE));
		return Service.doDelete({
			url: 'events',
            urlParams: {
				providerId,
                sportId,
                matchId
			},
			params: {
				event_ids: eventIds
			}
		})
		.then( (response) => dispatch(createAction(Actions.EVENTS.DELETE_SUCCESS, response, null)) )
		.catch( (response) => dispatch(createAction(Actions.EVENTS.DELETE_FAIL, null, response)) );
	}
}