import request from 'superagent-bluebird-promise';
import {getUrl} from './urls';
import { isEmpty } from 'lodash/lang';


/**
 * Returns an object containing request headers.
 * @param  {?object} headers
 * @param  {array} files included in this request (array of File objects)
 * @return {object}
 */
function _getHeaders(headers, files) {
	let _headers = {
		'Authorization': 'Token ' + localStorage.getItem('token'),
		...headers
	};
	// if files are not defined, set content type
	if (isEmpty(files)) {
		_headers['Content-Type'] = 'application/json';
	};
	return _headers;
}

/**
 * Parse successful API responses.
 * @param  {object} response
 * @return {mixed}
 */
function _callbackProcessor({ text: response }) {
	return JSON.parse(response);
}

/**
 * Use superagent to perform a request and return a promise.
 * @param  {string} method
 * @param  {object} params
 * @param  {array}  files Array of files included in this request
 * @param  {string} the name of the file field (defaults to 'file')
 * @return {Promise}
 */
function _doRequest(method, params, files, fileFieldName='file') {
	let req = null;
	const url = getUrl(params.url, params.urlParams);
	const requestData = params.params || {};
	const queryData = params.query || {};
	const headers = _getHeaders(params.headers, files);
	const callbackProcessor = params.callbackProcessor || _callbackProcessor;
	switch (method) {
		case 'GET':
			req = request.get(url).query(requestData || queryData);
			break;
		case 'POST':
			req = request.post(url);
			if (!isEmpty(files)) {
				console.log("FILES", files)
				req = req.set(headers); // attach headers now to prevent breaking the request
				//req.type('multipart/form-data');
				for (let i = 0; i < files.length; i++) {
					req = req.attach(fileFieldName, files[i], files[i].name);
				}
				 
				Object.keys(requestData).forEach((key) => {
					console.log(key, requestData[key], typeof requestData[key])
					// if (requestData[key] && typeof(requestData[key]) === 'object') {
					// 	req = req.field(key, JSON.stringify(requestData[key]));
					// } else {
						req = req.field(key, requestData[key] || '');
					// }
				})
				return req.then(callbackProcessor);
			} else {
				req = req.query(queryData).send(requestData);
			}
			break;
		case 'DELETE':
			req = request.del(url).query(requestData);
			break;
		default:
			if (process.env.NODE_ENV === 'development') {
				throw new Error(`"${method}" is not a valid method.`);
			}
	}
	return req
		.accept('application/json')
		.set(headers)
		.then(callbackProcessor);
}

/**
 * @param  {object} params
 * @return {Promise}
 */
export function doGet(params) {
	return _doRequest('GET', params);
}

/**
 * @param  {object} params
 * @param  {array}  Array of file objects
 * @return {Promise}
 */
export function doPost(params, files, fileFieldName='file') {
	return _doRequest('POST', params, files, fileFieldName);
}

/**
 * @param  {object} params
 * @return {Promise}
 */
export function doDelete(params) {
	return _doRequest('DELETE', params);
}

export default {
	doGet,
	doPost,
	doDelete
};
