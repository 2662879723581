import React from 'react';
// import PropTypes from 'prop-types';
// import { DropTarget } from 'react-dnd';
import {dragItems} from './constants';
import Card from './card';
import { useDrop } from 'react-dnd'


export default function DropContainer({items, onRemove, onEdit, onDrop}) {
    const [{isOver}, dropRef] = useDrop({
        accept: dragItems.CARD,
        collect: monitor => ({
            isOver: monitor.isOver(),
            isOverCurrent: monitor.isOver({ shallow: true }),
        }),
        drop: ({content, index, type}) => {
            onDrop(null, {
                name: 'contents',
                value: content
            })
        }
    })
    const itemOrder = ['title', 'content', 'cta', 'image'];
    const itemsDisplay = itemOrder.map((itemType, typeIndex) => {
        return items?.[itemType]?.map((item, index) => {
            return (
                <Card
                    key={`${typeIndex}-${index}`}
                    index={index}
                    content={item}
                    inUse={true}
                    onRemove={onRemove}
                    onEdit={onEdit}
                    onChange={onDrop}
                />
            )
        })
    })
    const style = isOver ? {
        backgroundColor: '#ededed',
        minHeight: 300
    } : {minHeight: 300};
    return (
        <div ref={dropRef} className='contentDropZoneContainer' style={style}>
            {itemsDisplay}
        </div>
    )
}
 