import React from 'react';
import {Dropdown} from 'semantic-ui-react';

export default function({name, value, options, onChange, multiple}) {
    return (
        <Dropdown
            search
            selection
            clearable
            multiple={multiple || false}
            placeholder='Category'
            name={name || 'category_id'}
            value={value}
            options={options || []}
            onChange={onChange}
        />
    )
}