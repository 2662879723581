import React from 'react';
import PropTypes from 'prop-types';
import {
    MarketSelector,
    LanguageSelector,
    LanguagesSelector,
    ChannelSelector,
    ScoreSelector,
    MatchSubNodeSelector,
    TennisPointSelector,
    LinkTargetSelector,
    RuleSelector,
} from '../selectors';
import {
    Form,
    Button,
    Modal,
    TextArea,
    Dropdown,
    Input,
} from 'semantic-ui-react';
import {isEmpty} from 'lodash/lang';
import {DropContainer} from '../content-cards';
import {LinkTargets} from '../../constants';
import {remove} from 'lodash/array';
import {keyBy} from 'lodash/collection';


function contentToState(content) {
    return {
        editMode: !isEmpty(content),
        matchContentId: content.id || null,
        marketId: (content.market && content.market.id) ? [content.market.id] : [],
        marketTypeId: (content.market_type && content.market_type.id) ? [content.market_type.id] : [],
        channelId: (content.channel && content.channel.id) || [1],
        ruleSetId: (content.ruleset && content.ruleset.id) || null,
        language: (content.language && content.language.code) || 'en',
        languageLimiter: [],
        scores: content.node_id && !isEmpty(content.node_id) ? [content.node_id] : [],
        subNode: content.sub_node && !isEmpty(content.sub_node) ? [content.sub_node] : [],
        leafNode: content.leaf_node && !isEmpty(content.leaf_node) ? [content.leaf_node] : [],
        linkTarget: content.link_target || null,
        noMultiLang: true,
        items: !isEmpty(content.content) ? content.content : {
            title: [],
            content: [],
            cta: [],
            image: [],
        }
    };
}

class MatchContentForm extends React.Component {
    static propTypes = {
        onDrop: PropTypes.func,
        markets: PropTypes.array,
        marketTypes: PropTypes.array,
        onSubmit: PropTypes.func,
        matchContent: PropTypes.array,
        content: PropTypes.object,
        onChange: PropTypes.func,
        getUsedNodes: PropTypes.array
    }

    static defaultProps = {
        markets: [],
        content: {},
        getUsedNodes: [],
    }

    state = {
        adHocContent: false,
        editMode: false,
        editContent: null,
        marketId: [],
        marketTypeId: [],
        channelId: 1,
        ruleSetId: null,
        language: 'en',
        languageLimiter: [],
        scores: [],
        subNode: [],
        leafNode: [],
        linkTarget: null,
        noMultiLang: true,
        macro: '',
        items: {
            title: [],
            content: [],
            cta: [],
            image: [],
        }
    }

    static getDerivedStateFromProps(props, state) {
        if (props.content) {
            return contentToState(props.content);
        }
        return null;
    }

    handleAdHocTypeChange = (ev, {name, value}) => {
        this.setState({
            adHocContent: value
        })
    }

    setValue = (content) => {
        this.setState({
            editMode: !isEmpty(content),
            matchContentId: content.id || null,
            marketId: (content.market && content.market.id) ? [content.market.id] : [],
            channelId: (content.channel && content.channel.id) || [1],
            ruleSetId: (content.ruleset && content.ruleset.id) || null,
            language: (content.language && content.language.code) || 'en',
            languageLimiter: [],
            scores: content.node_id && !isEmpty(content.node_id) ? [content.node_id] : [],
            subNode: content.sub_node && !isEmpty(content.sub_node) ? [content.sub_node] : [],
            leafNode: content.leaf_node && !isEmpty(content.leaf_node) ? [content.leaf_node] : [],
            linkTarget: content.link_target || null,
            noMultiLang: true,
            items: !isEmpty(content.content) ? content.content : {
                title: [],
                content: [],
                cta: [],
                image: [],
            }
        })
    }

    handleContentChange = (ev, {name, value, index}) => {
        const contents = this.state.items;
        if (!isNaN(index)) {
            contents[value.content_type][index] = value;
        } else {
            contents[value.content_type].push(value);
        }
        this.setState({
            items: contents
        })
    }

    handleContentRemove = (content) => {
        const items = this.state.items;
        remove(items[content.content_type], value => value.id === content.id);
        this.setState({
            items
        })
    }

    handleContentEdit = (content) => {
        this.setState({
            editContent: content
        })
    }

    handleContentTextChange = (ev, {name, value}) => {
        const {editContent} = this.state;
        editContent[name] = value;
        this.handleContentEdit(editContent);
    }

    handleContentSave = () => {
        const {editContent, adHocContent} = this.state;
        const {onUpdateContent} = this.props;
        if (onUpdateContent) {
            onUpdateContent(editContent);
        }
        if (adHocContent) {
            this.handleContentChange(null, {value: editContent})
        }
        this.handleContentEdit(null);
    }

    adHocContent = () => {
        this.handleContentEdit({
            content:'',
            content_type: this.state.adHocContent || 'content',
            language: '',
            link_target: this.state.linkTarget || ''
        })
    }

    handleChange = (ev, {name, value, type, checked}) => {
        if (name === 'noMultiLang') {
            value = !this.state.noMultiLang;
        } else if (type === 'checkbox') {
            value = checked;
        } else {
            value = value || [];
        }
        this.setState({
            [name]: value
        }, () => {
            this.propagateChange(ev, {name, value});
        })
    }

    propagateChange = (ev, {name, value}) => {
        if (this.props.onChange) {
            const contentState = this.state;
            this.props.onChange(ev, {
                name,
                value,
                contentState
            })
        }
    }

    handleSubmit = (ev) => {
        ev.preventDefault();
        const values = {}
        values.channel_id = this.state.channelId;
        values.market_id = this.state.marketId;
        values.market_type_id = this.state.marketTypeId;
        values.language = this.state.language;
        values.scores = this.state.scores;
        values.content = this.state.items;
        values.ruleset_id = this.state.ruleSetId;
        values.link_target = this.state.linkTarget;
        values.sub_node = this.state.subNode;
        values.leaf_node = this.state.leafNode;
        values.macro = this.state.macro;
        if (this.state.matchContentId) {
            values.match_content_id = this.state.matchContentId;
        }
        if (this.state.noMultiLang) {
            values.no_multi_lang = true;
        } else {
            values.languages = this.state.languageLimiter;
        }
        this.props.onSubmit(values)
    }

    getCurrentMarketScores = () => {
        const {marketId, channelId, editMode} = this.state;
        const {matchContent} = this.props;
        const firstMarketId = marketId.length > 0 ? marketId[0] : null;
        if (!firstMarketId || editMode) {
            return [];
        }
        const marketItems = matchContent.filter(item => item.market.id === firstMarketId && item.channel.id === channelId);
        const scores = marketItems.map(item => item.node_id)
        return scores;
    }

    getEditModal = () => {
        const {editContent, adHocContent, linkTarget} = this.state;
        if (!!editContent) {
            return (
                <Modal
                    open={!!editContent}
                >
                    <Modal.Header>
                        {adHocContent ? 'Add' : 'Edit'} match {editContent.content_type}
                        {adHocContent ? false : (`: / ${editContent.channel} / ${editContent.language}`)}
                    </Modal.Header>
                    <Modal.Content>
                        <Form>
                            { editContent.content_type === 'cta' ? (
                                <Form.Field>
                                    <label>Link Target</label>
                                    <LinkTargetSelector
                                        name='link_target'
                                        onChange={this.handleContentTextChange}
                                        value={editContent.link_target || linkTarget}
                                    />
                                </Form.Field>
                            ) : false}
                            <Form.Field>
                                <TextArea
                                    name='content'
                                    rows={8}
                                    value={editContent.content}
                                    onChange={this.handleContentTextChange}
                                />
                            </Form.Field>
                        </Form>
                    </Modal.Content>
                    <Modal.Actions>
                        <Button negative onClick={()=>{this.handleContentEdit(null)}}>Cancel</Button>
                        <Button
                            positive
                            icon='checkmark'
                            labelPosition='right'
                            content='Save'
                            onClick={this.handleContentSave}/>
                    </Modal.Actions>
                </Modal>
            )
        }
        return false;
    }

    getMarketLinkTargets = () => {
        const {markets, match} = this.props;
        const {marketId, marketTypeId} = this.state;
        const linkTargetsSet = new Set([]);;
        const linkTargets = keyBy(LinkTargets, 'value');
        const selectedMarkets = !isEmpty(marketTypeId)
            ? markets.filter(market => marketTypeId.indexOf(market.type && market.type.id) > -1)
            : markets.filter(market => marketId.indexOf(market.id) > -1);
        const options = [];
        selectedMarkets.forEach((market) => {
            if (market && market.meta) {
                if (market.meta.player_market && match.meta.participants) {
                    Object.keys(match.meta.participants).forEach((teamName, teamIndex) => {
                        options.push((
                            <Dropdown.Header key={teamName}>{teamName}</Dropdown.Header>
                        ));
                        match.meta.participants[teamName].forEach((player, playerIndex) => {
                            options.push({
                                text: player.name,
                                value: player.tag,
                                key: player.tag
                            })
                        })
                    })
                } else if (market.meta.thresholds) {
                    Object.keys(market.meta.thresholds).forEach((key) => {
                        if (!linkTargetsSet.has(key) && linkTargets[key]) {
                            linkTargetsSet.add(key);
                            options.push(linkTargets[key])
                        }
                    })
                }
            }
        })

        return options

    }

    resetContent = (ev) => {
        ev.preventDefault();
        this.setState(contentToState({}));
    }


    render() {
        const {
            markets,
            marketTypes,
        } = this.props;
        const {
            marketId,
            marketTypeId,
            channelId,
            language,
            languageLimiter,
            scores,
            subNode,
            leafNode,
            items,
            linkTarget,
            ruleSetId,
            noMultiLang,
            editMode,
            adHocContent,
            macro,
        } = this.state;
        const currentMarketScores = this.getCurrentMarketScores();
        const marketLinkTarget = this.getMarketLinkTargets();
        return (
            <Form>
                <Form.Field>
                    {editMode ? (
                    <Button floated='left' secondary onClick={this.resetContent} content="Reset"/>
                    ) : (
                        <Button.Group secondary size="tiny" floated='left' >
                            <Button onClick={this.adHocContent} content="Add"/>
                            <Dropdown options={[
                                {key: 'title', value: 'title', text: 'Title'},
                                {key: 'content', value: 'content', text: 'Content'},
                                {key: 'cta', value: 'cta', text: 'CTA'},
                            ]} floating button className='icon'
                            value={adHocContent || 'content'}
                            onChange={this.handleAdHocTypeChange}/>
                        </Button.Group>
                    )}
                    <Button floated='right' size="tiny" primary onClick={this.handleSubmit} content="Submit"/>

                    {editMode ? false : (
                        <div>
                            <Button
                                toggle
                                floated="right"
                                size="tiny"
                                name="noMultiLang"
                                active={noMultiLang}
                                negative={!noMultiLang}
                                content="No Multi Lang"
                                onClick={this.handleChange}/>
                            {!noMultiLang ? (
                                <LanguagesSelector
                                    name='languageLimiter'
                                    value={languageLimiter}
                                    multiple={true}
                                    onChange={this.handleChange}
                                />
                            ) : false}
                        </div>
                    )}
                </Form.Field>
                <Form.Group>
                    <Form.Field>
                        <Dropdown
                            multiple
                            selection
                            search
                            name="marketTypeId"
                            placeholder="Market Types"
                            onChange={this.handleChange}
                            value={marketTypeId}
                            options={marketTypes.map((mType, index) => {
                                return {
                                    key: mType.id,
                                    text: mType.name,
                                    value: mType.id,
                                    market_type: mType,
                                }
                            })}
                        />
                    </Form.Field>
                    <Form.Field>
                        <MarketSelector
                            name='marketId'
                            value={marketId}
                            onChange={this.handleChange}
                            multiple={true}
                            options={markets.map((market, index) => {
                                return {
                                    text: market.name,
                                    value: market.id,
                                    key: index,
                                    market: market
                                }
                            })}
                        />
                    </Form.Field>
                </Form.Group>
                <Form.Group>
                    <Form.Field>
                        <ChannelSelector
                            name='channelId'
                            placeholder='Channel'
                            value={channelId}
                            allowEmpty={true}
                            multiple={true}
                            onChange={this.handleChange}
                        />
                    </Form.Field>
                    <Form.Field>
                        <LanguageSelector
                            name='language'
                            placeholder='Language'
                            value={language}
                            onChange={this.handleChange}
                        />
                    </Form.Field>
                </Form.Group>
                <Form.Group>
                    <Form.Field>
                        <LinkTargetSelector
                            name='linkTarget'
                            placeholder='Link Target'
                            value={linkTarget}
                            options={this.getMarketLinkTargets()}
                            onChange={this.handleChange}
                        />
                    </Form.Field>
                    <Form.Field>
                        <RuleSelector
                            name='ruleSetId'
                            placeholder='Rule'
                            value={ruleSetId}
                            marketId={marketId}
                            marketTypeId={marketTypeId}
                            onChange={this.handleChange}
                        />
                    </Form.Field>
                </Form.Group>
                <Form.Field>
                    <ScoreSelector
                        name='scores'
                        value={scores}
                        placeholder='Score'
                        excludeScores={currentMarketScores}
                        onChange={this.handleChange}
                    />
                </Form.Field>
                <Form.Field>
                    <MatchSubNodeSelector
                        placeholder='Game Score'
                        name='subNode'
                        value={subNode}
                        onChange={this.handleChange}
                    />
                </Form.Field>
                <Form.Field>
                    <TennisPointSelector
                        placeholder='Match Points'
                        name='leafNode'
                        value={leafNode}
                        onChange={this.handleChange}
                    />
                </Form.Field>
                <Form.Field>
                    <DropContainer
                        items={items}
                        onRemove={this.handleContentRemove}
                        onEdit={this.handleContentEdit}
                        onChange={this.handleChange}
                        onDrop={this.handleContentChange}/>
                    {this.getEditModal()}
                </Form.Field>
                <Form.Field>
                    <label>Macro:</label>
                    <Input
                        name="macro"
                        value={macro || ''}
                        onChange={this.handleChange}
                        placeholder="key1=replace-value,key2=replace-value"
                    />
                </Form.Field>
            </Form>
        )
    }
}

export default MatchContentForm;