import React from 'react';
import {Link} from 'react-router-dom';
import history from '../../utils/history';
import {DataGrid} from '../../components/grid';
import {connect} from 'react-redux'
import {
    fetchMatches,
    fetchTemplatedMatches
} from '../../redux/actions/match';
import {fetchLeague} from '../../redux/actions/league';
import dateFormat from 'dateformat';
import {LeagueSelector} from '../../components/selectors';
import {
    Segment, Header, Form, Input, Checkbox
} from 'semantic-ui-react';
import {
  DateInput,
} from 'semantic-ui-calendar-react';

class MatchesGrid extends React.Component {
    state = {
        date: '',
        templated: false,
    }

    componentDidMount() {
        const {providerId, sportId} = this.props.application;
        const {leagueId} = this.props.match.params;
        if (leagueId) {
            fetchLeague(providerId, sportId, leagueId)(this.props.dispatch);
        }
        this.loadMatches(providerId, sportId, leagueId);
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        const {application, match} = this.props;
        const {providerId, sportId} = nextProps.application;
        const {leagueId} = nextProps.match.params;
        if (providerId !== application.providerId ||
            sportId !== application.sportId ||
            leagueId !== match.params.leagueId) {
            if (leagueId && leagueId !== match.params.leagueId) {
                fetchLeague(providerId, sportId, leagueId)(this.props.dispatch);
            }
            this.loadMatches(providerId, sportId, leagueId);
        }
    }

    getOptions = () => {
        return (item) => {
            return [
                {path: `/match/${item.id}`, icon: 'line chart'},
                {path: `/match/${item.id}/thresholds`, icon: 'sort'},
                {path: `/match/${item.id}/feed`, icon: 'table'},
                {path: `/match/${item.id}/content`, icon: 'newspaper'},
                {path: `/match/${item.id}/delivery`, icon: 'send'},
                {path: `/events/${item.id}`, icon: 'numbered list'},
                {path: `/event/${item.id}/edit`, icon: 'add'},
            ]
        }
    }

    getHeader = () => {
        const {match, leagues} = this.props;
        const {date} = this.state;
        if (match.params.leagueId && leagues.currentLeague) {
            return (
                <Header as='h2'>
                    {this.props.leagues.currentLeague.name}
                    <Header.Subheader>Matches</Header.Subheader>
                </Header>
            )
        }
        return (
            <Segment raised clearing>
            <Header as='h2'>Matches</Header>
                <Form>
                    <Form.Group widths={3}>
                        <Form.Field>
                            <LeagueSelector
                                width='auto'
                                multiple={false}
                                onChange={this.handleLeagueSelect}
                            />
                        </Form.Field>
                        <Form.Field>
                            <DateInput
                                name="date"
                                placeholder="Match Date"
                                closable={true}
                                onChange={this.handleDateFilter}
                                dateFormat='YYYY-MM-DD'
                                value={date}
                            />
                        </Form.Field>
                        <Form.Field>
                            <Input
                                name='keyword'
                                placeholder='Keyword or Match Id'
                                onKeyPress={this.handleKeywordFilter}
                            />
                        </Form.Field>
                        <Form.Field>
                            <Checkbox
                                name='templated'
                                label='Content only'
                                onClick={this.handleContentFilter}
                            />
                        </Form.Field>
                    </Form.Group>
                </Form>
            </Segment>
        )
    }

    loadMatches = (providerId, sportId, leagueId=null) => {
        fetchMatches(providerId, sportId, leagueId, 0, '-start', leagueId ? 'ALL' : null)(this.props.dispatch);
        fetchTemplatedMatches(providerId, sportId)(this.props.dispatch);
    }

    handleLeagueSelect = (ev, {name, value}) => {
        history.replace(`/league/${value}/matches`);
    }

    handleContentFilter = (ev, {name ,value, checked}) => {
        const {providerId, sportId} = this.props.application;
        const {leagueId} = this.props.match.params;
        this.setState({
            templated: checked
        }, () => {
            fetchTemplatedMatches(providerId, sportId)(this.props.dispatch).then((response) => {
                const matchIds = response.payload.response.results;
                fetchMatches(providerId, sportId, leagueId, 0, '-start', 'ALL', null, value, matchIds)(this.props.dispatch);
            })
        })
    }

    handleKeywordFilter = (ev) => {
        if (ev.nativeEvent.keyCode === 13) {
            const {providerId, sportId} = this.props.application;
            const {leagueId} = this.props.match.params;
            const value = ev.target.value;
            fetchMatches(providerId, sportId, leagueId, 0, '-start', 'ALL', null, value)(this.props.dispatch);
        }

    }

    handleDateFilter = (ev, {name, value}) => {
        const {providerId, sportId} = this.props.application;
        const {leagueId} = this.props.match.params;
        this.setState({
            date: value
        }, () => {
            fetchMatches(providerId, sportId, leagueId, 0, '-start', 'ALL', value)(this.props.dispatch);
        })
    }

    handlePagination = (start) => {
        const {providerId, sportId} = this.props.application;
        const {leagueId} = this.props.match.params;
		fetchMatches(providerId, sportId, leagueId, start)(this.props.dispatch);
    }

    handleSorting = ({name, direction}) => {
        const {providerId, sportId} = this.props.application;
        const {leagueId} = this.props.match.params;
        const sortString = `${direction === 'descending' ? '-' : ''}${name}`;
		fetchMatches(providerId, sportId, leagueId, 0, sortString)(this.props.dispatch);
	}

    render() {
        const {matches} = this.props;
        return (
            <Segment basic raised>
                {this.getHeader()}
                <DataGrid
                    headers={[
                        {name: 'id', title: 'Id', sortable: true},
                        {name: 'home', title: 'Home', sortable: true, renderer: ({id, home}) => {
                            return (
                                <Link to={`/match/${id}`}>{home.name}</Link>
                            )
                        }},
                        {name: 'away', title: 'Away', sortable: true, renderer: ({id, away}) => {
                            return (
                                <Link to={`/match/${id}`}>{away.name}</Link>
                            )
                        }},
                        {name: 'start', title: 'Start Time', sortable: true, renderer: ({start}) => {
                            return dateFormat(start)
                        }},
                        {name: 'league', title: 'League', sortable: true, renderer: ({league}) => {
                            return (
                                <Link
                                    to={`/league/${league.id}/matches`}
                                    title={league.path}>
                                    {league.provider_name}
                                </Link>
                            )
                        }},
                        {name: 'event_id', title: 'Event Id'},
                        {name: 'league', title: 'League Id', renderer: ({league}) => league.unique_id},
                        {name: null, title: 'Options', options: this.getOptions()}
                    ]}
                    loading={matches.loading}
                    highlightRows={matches.templatedMatches}
                    items={matches.items}
                    totalRows={matches.total}
                    pageSize={matches.limit}
                    onPaginate={this.handlePagination}
                    onSort={this.handleSorting}
                />
            </Segment>
        )
    }
}

export default connect(state => {
    return {
        application: state.application,
        matches: state.matches,
        leagues: state.leagues
	}
})(MatchesGrid);
