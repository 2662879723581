import React from 'react';
import {Link} from 'react-router-dom';
import {ProviderSelector} from '../components/providers';
import {SportSelector} from '../components/sports';
import {SearchField} from '../components/search';
import {Basket} from '../components/basket';
import {authenticate, fetchAllSports} from '../redux/actions/app';
import {connect} from 'react-redux'
import LoginForm from './login-form';
import {
    Sidebar, Segment, Menu,
    Container, Icon, Dropdown,
    Loader,
} from 'semantic-ui-react';
import history from '../utils/history';

class Layout extends React.Component {
    state = {
        sideBarVisible: true
    }

    componentDidMount() {
        fetchAllSports()(this.props.dispatch);
    }

    render() {
        const {sideBarVisible} = this.state;
        const {currentProvider, loading, providersLoading} = this.props.application;
        const {authenticated, ready} = this.props.application;
        if (!authenticated) {
            return (
                <LoginForm
                    onSubmit={(email, pass) => {
                        authenticate(email, pass)(this.props.dispatch).then((res) => {
                            if (res.payload.response.success) {
                                document.location.reload();
                            }
                        })
                    }}
                />
            )
        } // else if (loading || providersLoading) {
        //     return (
        //         <Loader active={true}/>
        //     )
        // }
        return (
            <Container fluid className='mainContent'>
                <Menu inverted attached='top' className='topHeader'>
                    <Menu.Item header>
                        BetSense
                    </Menu.Item>
                    <Menu.Item header>
                        {currentProvider && currentProvider.name}
                    </Menu.Item>
                    <Menu.Menu position='right'>
                        <Menu.Item>
                            <Basket/>
                        </Menu.Item>
                        <Menu.Item>
                            <SearchField/>
                            {/* <Input
                                name='search'
                                icon='search'
                                placeholder='Search...'
                                onChange={this.handleSearch}
                            /> */}
                        </Menu.Item>
                        <Menu.Item>
                            <SportSelector/>
                        </Menu.Item>
                        <Menu.Item>
                            <ProviderSelector/>
                        </Menu.Item>
                        <Dropdown item icon='settings'>
                            <Dropdown.Menu>
                                <Dropdown.Item onClick={()=>history.push('/settings/')}>Settings</Dropdown.Item>
                                <Dropdown.Item>Logout</Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>
                    </Menu.Menu>
                </Menu>
                <Container fluid className='mainContent' attached='top'>
                    <Sidebar.Pushable as={Segment}>
                        <Sidebar
                            as={Menu}
                            className='leftMenu'
                            animation='push'
                            width='thin'
                            visible={sideBarVisible}
                            vertical >

                            <Menu.Item link>
                                <Link to='/'>
                                    <Icon name='home'/> Home
                                </Link>
                            </Menu.Item>
                            <Menu.Item link>
                                <Link to='/leagues'>
                                    <Icon name='soccer'/> Leagues
                                </Link>
                            </Menu.Item>
                            <Menu.Item link>
                                <Link to='/matches'>
                                    <Icon name='soccer'/> Matches
                                </Link>
                            </Menu.Item>
                            <Menu.Item>
                                <Link to='/cms'>
                                    <Icon name='users'/> CMS
                                </Link>
                            </Menu.Item>
                            <Menu.Item>
                                <Link to='/contacts'>
                                    <Icon name='users'/> Contacts
                                </Link>
                            </Menu.Item>
                            <Menu.Item>
                                <Link to='/markets'>
                                    <Icon name='setting'/> Markets
                                </Link>
                            </Menu.Item>
                            <Menu.Item>
                                <Link to='/teams'>
                                    <Icon name='users'/> Teams
                                </Link>
                            </Menu.Item>
                            <Menu.Item>
                                <Link to='/reports'>
                                    <Icon name='list'/> Reports
                                </Link>
                            </Menu.Item>
                            <Menu.Item>
                                <Link to='/trackers'>
                                    <Icon name='target'/> Trackers
                                </Link>
                            </Menu.Item>
                        </Sidebar>
                        <Sidebar.Pusher>
                            <Segment basic className='innerContent'>
                                {ready && !loading && !providersLoading ? (
                                    this.props.children
                                ) : <Loader active/>}
                            </Segment>
                        </Sidebar.Pusher>
                    </Sidebar.Pushable>
                </Container>
            </Container>
        )
    }
}

export default connect(state => {
    return {
        application: state.application
	}
})(Layout);
