import React, { Component } from 'react';
import { Provider } from 'react-redux';
import * as Store from './redux/store';
import './App.css';
import 'semantic-ui-css/semantic.min.css';
import { DndProvider } from 'react-dnd'
import HTML5Backend from 'react-dnd-html5-backend'
import history from './utils/history';
import {Main} from './pages';


const store = Store.configureStore(Store.initialState);
class App extends Component {
  render() {
    return (
      <DndProvider backend={HTML5Backend} >
      <Provider store={store}>
        <Main history={history}/>
      </Provider>
      </DndProvider>
    );
  }
}

export default App// DragDropContext(HTML5Backend)(App);
