import React from 'react';
import PropTypes from 'prop-types';
import {Menu, Icon} from 'semantic-ui-react';

class Pagination extends React.PureComponent {
	static propTypes = {
		/**
		 * The total number of rows returned from the query
		 * @type {number}
		 */
		totalRows: PropTypes.number,
		/**
		 * Number of rows in a page
		 * @type {number}
		 */
		pageSize: PropTypes.number,
		/**
		 * Pagination event handler
		 * @type {function}
		 */
		onPaginate: PropTypes.func.isRequired
	}

	state = {
		numOfPages: 0,
		page: 0
	}

	static getDerivedStateFromProps(props, state) {
		const {totalRows, pageSize} = props;
		if (totalRows && pageSize) {
			const numOfPages = Math.ceil(totalRows / pageSize);
			if (numOfPages !== state.numOfPages) {
				return {
					numOfPages
				}
			}
		}
		return null;
	}

	setPage = (page) => {
		const _page = page || this.state.page || 0;
		const start = this.props.pageSize * _page;
		this.props.onPaginate(start);
	}

	handleChange = (ev, {name}) => {
		ev.preventDefault();
		this.setState({
			page: parseInt(name, 10)
		}, () => {
			this.setPage(name);
		})
	}

	/**
	 * Return an object containing all the relevant pagination paramaters
	 * from nextPage handlers, to classNames to which page range is to be
	 * shown .
	 * @return {objcet} an object with the following keys: prevPageClass,
	 * nextPageClass, prevPage, nextPage, startPage, endPage
	 * }
	 */
	getPaginationParams = () => {
		const visiblePages = 10; // number of page links visible
		const pageModifier = 5; // how many pages before and after current page
		const startPage = this.state.page > pageModifier ?
						  this.state.page - pageModifier :
						  1;
		const endPage = this.state.numOfPages > visiblePages
						&& startPage + visiblePages < this.state.numOfPages ?
						startPage + visiblePages -1 :
						this.state.numOfPages-1;
		return {
            prevPage: {
                name: `${this.state.page - 1}`,
                disabled: this.state.page-1 < 0
            },
            nextPage: {
                name: `${this.state.page + 1}`,
                disabled: this.state.page+1 >= this.state.numOfPages
            },
            startPage: startPage,
			endPage: endPage,
			endPageElipsis: endPage >= this.state.numOfPages-1 ? '' : '...',
			startPageElipsis: startPage < 2 ? '' : '...'
		}
    }

    getPages = (params) => {
        const pages = [];
        for (let i=params.startPage; i<=params.endPage; i++) {
			const {page} = this.state;
			pages.push(
                <Menu.Item
                    key={i}
                    name={`${i}`}
                    active={page===i}
                    onClick={this.handleChange}>
                    {i+1}
                </Menu.Item>
            )
        }
        return pages;
    }

    render() {
        const params = this.getPaginationParams();
		const pages = this.getPages(params);
		const {page} = this.state;
        return (
            <Menu pagination>
                <Menu.Item name='prev' {...params.prevPage} onClick={this.handleChange}>
                    <Icon name='left chevron'/>
                </Menu.Item>
				<Menu.Item
                    key='first_page'
                    name='0'
                    active={page===0}
                    onClick={this.handleChange}>1</Menu.Item>
                {pages}
                <Menu.Item name='next' {...params.nextPage} onClick={this.handleChange}>
                    <Icon name='right chevron'/>
                </Menu.Item>
            </Menu>
        )
    }
}

export default Pagination;
