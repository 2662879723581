export default [
    {
        value: 'historical_favourite',
        text: 'Historical Favourite',
        key: 'bh-historical_favourite'
    },
    {
        value: 'all',
        text: 'All outcomes',
        key: 'bh-all'
    },
    {
        value: 'odd_favourite',
        text: 'Live Odds Favourite',
        key: 'bh-odd_favourite'
    }
]