import React from 'react';
import {Link} from 'react-router-dom';
import {DataGrid} from '../../components/grid';
import {connect} from 'react-redux';
import {TimerButton} from '../../components/timer-button';
import {
    fetchContacts,
    deleteContact,
    contactToggleActiveState
} from '../../redux/actions/contact';
import {
    Segment, Header, Button, Icon
} from 'semantic-ui-react';


class ContactsGrid extends React.Component {
    componentDidMount() {
        const {providerId, sportId} = this.props.application;
        this.loadcontacts(providerId, sportId);
    }

    loadcontacts = (providerId, sportId) => {
        fetchContacts(providerId, sportId)(this.props.dispatch);
    }

    getOptions = () => {
        const {providerId, sportId} = this.props.application;
        return (item) => {
            return [
                {
                    icon: 'user', title: 'Edit',
                    path: `/contacts/${item.id}/edit`
                },
                {
                    icon: 'user', title: item.active ? 'Deactivate' : 'Activate',
                    onClick: () => {
                        contactToggleActiveState(providerId, sportId, item.id)(this.props.dispatch)
                        .then(res => fetchContacts(providerId, sportId)(this.props.dispatch))
                    }
                },
                {
                    component: TimerButton,
                    onClick: () => this.handleDelete(item.id)
                }

            ]
        }
    }

    handleDelete = (contactId) => {
        const {providerId, sportId} = this.props.application;
        deleteContact(providerId, sportId, contactId)(this.props.dispatch)
    }

    getHeader = () => {
        return (
            <Segment clearing>
                <Header as='h2' floated='left'>
                    <Icon name='users'/>
                    <Header.Content>
                        Contacts
                    </Header.Content>
                </Header>
                <Header as='h2' floated='right'>
                    <Link to='/contacts/edit'>
                        <Button primary>Add Contact</Button>
                    </Link>
                </Header>
            </Segment>
        )
    }

    renderDestination = (item) => {
        if (item.channel.id === 1) {
            return item.device_id || item.segment || item.list_id;
        } else if (item.channel.id === 2) {
            return item.email;
        } else if (item.channel.id === 3) {
            return item.twitter;
        } else {
            return '';
        }
    }

    render() {
        const {contacts} = this.props;
        return (
            <Segment basic raised>
                {this.getHeader()}
                <DataGrid
                    headers={[
                        {name: 'id', title: 'Id'},
                        {name: 'name', title: 'Name'},
                        {name: 'channel.name', title: 'Channel'},
                        {title: 'Destination', renderer: this.renderDestination},
                        {title: 'Active', renderer: (item) => {
                            return item.active ? 'Yes' : 'No'
                        }},
                        {name: null, title: 'Options', options: this.getOptions()}
                    ]}
                    items={contacts.items}
                    totalRows={contacts.total}
                    pageSize={contacts.limit}
                />
            </Segment>
        )
    }
}

export default connect(state => {
    return {
        application: state.application,
        contacts: state.contacts
	}
})(ContactsGrid);
