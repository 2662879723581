/**
 * Return a mapping of provider market index where the keys are the
 * provider labels mapped to their betsense tags.
 * @param {Array} providerMarkets
 * @param {Object} match
 */
export function providerMatchMarketMap(providerMarkets, match) {
    const marketIndex = {}
    const homeName = match.home.name;
    const awayName = match.away.name;
    providerMarkets.forEach((market) => {
        marketIndex[market.id] = {}
        const outcomeMap = market?.meta?.market_map;
        if (outcomeMap) {
            Object.keys(outcomeMap).forEach(tag => {
                if (outcomeMap[tag] && outcomeMap[tag].replace) {
                    const label = outcomeMap[tag]
                        .replace('HOME_NAME', homeName)
                        .replace('AWAY_NAME', awayName);
                    marketIndex[market.id][tag] = label;
                }
            })
        }
    })
    return marketIndex;
}