export const APIS = {
    eventstore: process.env.REACT_APP_API_EVENTSTORE,
    cms: process.env.REACT_APP_API_CMS,
    betsense: process.env.REACT_APP_API_BETSENSE,
    auth: process.env.REACT_APP_API_AUTH,
}

export default {
    authenticate: {
        api: 'auth',
        method: 'authenticate'
    },
    providers: {
        api: 'eventstore',
        method: 'core/providers'
    },
    provider: {
        api: 'eventstore',
        method: 'core/provider/:providerId'
    },
    providerCtrl: {
        api: 'betsense',
        method: 'core/provider/:providerId'
    },
    sports: {
        api: 'eventstore',
        method: 'core/sports'
    },
    markets: {
        api: 'betsense',
        method: ':providerId/:sportId/markets'
    },
    market: {
        api: 'betsense',
        method: 'core/:providerId/:sportId/market/:marketId'
    },
    marketTypes: {
        api: 'betsense',
        method: 'core/:sportId/marketypes',
    },
    templatedMatches: {
        api: 'betsense',
        method: ':providerId/:sportId/matches/content'
    },
    search: {
        api: 'eventstore',
        method: ':providerId/:sportId/search'
    },
	searchMatch: {
        api: 'eventstore',
        method: ':providerId/:sportId/search/match'
    },
    leagues: {
        api: 'eventstore',
        method: ':providerId/:sportId/leagues'
    },
    league: {
        api: 'eventstore',
        method: ':providerId/:sportId/leagues/:leagueId'
    },
    leagueSeason: {
        api: 'eventstore',
        method: ':sportId/league/:leagueId/table/:seasonId'
    },
    leagueSeason2: {
        api: 'eventstore',
        method: ':sportId/league/:leagueId/table'
    },
    leagueSeasonByTeam: {
        api: 'eventstore',
        method: ':sportId/league/:leagueId/table/:seasonId/team/:teamId'
    },
    matches: {
        api: 'eventstore',
        method: ':providerId/:sportId/matches'
    },
    match: {
        api: 'eventstore',
        method: ':providerId/:sportId/matches/:matchId'
    },
    query: {
        api: 'eventstore',
        method: ':sportId/events/query'
    },
    queryTags: {
        api: 'eventstore',
        method: ':providerId/:sportId/events/tags'
    },
    teams: {
        api: 'eventstore',
        method: ':providerId/:sportId/teams'
    },
    leagueProviderTeams: {
        api: 'eventstore',
        method: ':providerId/:sportId/teams/league/:leagueId'
    },
    leagueTeams: {
        api: 'eventstore',
        method: ':sportId/teams/league/:leagueId'
    },
    seasonTeams: {
        api: 'eventstore',
        method: ':sportId/teams/season/:seasonId'
    },
    team: {
        api: 'eventstore',
        method: ':providerId/:sportId/teams/:teamId'
    },
    teamConnectSearch: {
        api: 'eventstore',
        method: ':providerId/:sportId/teams/connect/:fromTeamId'
    },
    teamConnect: {
        api: 'eventstore',
        method: ':providerId/:sportId/teams/connect/:fromTeamId/to/:toTeamId'
    },
    matchConnectSearch: {
        api: 'eventstore',
        method: ':providerId/:sportId/matches/connect/:fromMatchId'
    },
    matchConnect: {
        api: 'eventstore',
        method: ':providerId/:sportId/matches/connect/:fromMatchId/to/:toMatchId'
    },
    teamLastMatchApex: {
        api: 'eventstore',
        method: ':sportId/events/apex/:leagueId/:teamId'
    },
    matchFeedEvents: {
        api: 'betsense',
        method: ':providerId/:sportId/match/:matchId/feed'
    },
    matchDeliveries: {
        api: 'betsense',
        method: ':providerId/:sportId/delivery/match/:matchId'
    },
    matchContent: {
        api: 'betsense',
        method: ':providerId/:sportId/match/:matchId/content'
    },
    matchContentDelete: {
        api: 'betsense',
        method: ':providerId/:sportId/match/:matchId/content/:matchContentId'
    },
    matchMarkets: {
        api: 'betsense',
        method: ':providerId/:sportId/match/:matchId/markets'
    },
    matchThresholds: {
        api: 'betsense',
        method: ':providerId/:sportId/match/:matchId/thresholds'
    },
    savedThresholds: {
        api: 'betsense',
        method: ':providerId/thresholds'
    },
    deleteSavedThresholds: {
        api: 'betsense',
        method: ':providerId/thresholds/:thresholdId/delete'
    },
    marketSavedThresholds: {
        api: 'betsense',
        method: ':providerId/thresholds/:marketId'
    },
    matchCopyData: {
        api: 'betsense',
        method: ':providerId/:sportId/match/:toMatchId/copy/from/:fromMatchId'
    },
    leagueMatches: {
        api: 'eventstore',
        method: ':providerId/:sportId/league/:leagueId/matches'
    },
    seasonMatches: {
        api: 'eventstore',
        method: ':sportId/matches/league/:leagueId/season/:seasonId'
    },
    events: {
        api: 'eventstore',
        method: ':providerId/:sportId/events/match/:matchId'
    },
    eventTypes: {
        api: 'eventstore',
        method: ':providerId/:sportId/events/types'
    },
    contentCategories: {
        api: 'cms',
        method: ':providerId/:sportId/content/categories'
    },
    contentTemplates: {
        api: 'cms',
        method: ':providerId/:sportId/contents'
    },
    content: {
        api: 'cms',
        method: ':providerId/:sportId/content/:contentId'
    },
    contentCreate: {
        api: 'cms',
        method: ':providerId/:sportId/content'
    },
    contentDelete: {
        api: 'cms',
        method: ':providerId/:sportId/content/:contentId'
    },
    contentGroup: {
        api: 'cms',
        method: ':providerId/:sportId/content/groups/:groupId'
    },
    contentGroups: {
        api: 'cms',
        method: ':providerId/:sportId/content/groups'
    },
    parseContent: {
        api: 'cms',
        method: ':providerId/:sportId/content/parse'
    },
    toggleAutoBuild: {
        api: 'cms',
        method: ':providerId/:sportId/content/:contentId/auto/toggle'
    },
    toggleContentActive: {
        api: 'cms',
        method: ':providerId/:sportId/content/:contentId/active/toggle'
    },
    contentCompile: {
        api: 'cms',
        method: ':providerId/:sportId/content/compiler'
    },
    contacts: {
        api: 'betsense',
        method: ':providerId/:sportId/contacts'
    },
    contact: {
        api: 'betsense',
        method: ':providerId/:sportId/contact/:contactId'
    },
    contactCreate: {
        api: 'betsense',
        method: ':providerId/:sportId/contact'
    },
    contactToggle: {
        api: 'betsense',
        method: ':providerId/:sportId/contact/:contactId/toggleactive'
    },
    segments: {
        api: 'betsense',
        method: ':providerId/segments/:contactId'
    },
    lists: {
        api: 'betsense',
        method: ':providerId/lists'
    },
    rules: {
        api: 'betsense',
        method: ':providerId/:sportId/rules'
    },
    matchNextOffer: {
        api: 'betsense',
        method: ':providerId/:sportId/match/:matchId/offer/:offerId'
    },
    matchNextOffers: {
        api: 'betsense',
        method: ':providerId/:sportId/match/:matchId/offers/:offerId'
    },
    deliveries: {
        api: 'betsense',
        method: ':providerId/:sportId/delivery'
    },
    logstats: {
        api: 'betsense', 
        method: ':providerId/delivery/stats/:logId'
    },
    offerContent: {
        api: 'betsense',
        method: ':providerId/match/offer/:offerId/compiler'
    },
    trackers: {
        api: 'betsense',
        method: ':providerId/match/trackers'
    },
    tracker: {
        api: 'betsense',
        method: ':providerId/match/tracker/:trackerId'
    },
};