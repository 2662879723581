import React from 'react';
import PropTypes from 'prop-types';
import {Dropdown} from 'semantic-ui-react';
import {connect} from 'react-redux'
import {fetchSegments} from '../../redux/actions/contact';
import {isEmpty} from 'lodash/lang';


class SegmentSelector extends React.Component {
    static propTypes = {
        name: PropTypes.string,
        value: PropTypes.string,
        onChange: PropTypes.func,
    }

    static defaultProps = {
        name: 'segment_id',
        value: '',
        contactId: null,
        onChange: () => {}
    }

    componentDidMount() {
        if (this.props.contactId) {
            this.loadSegments(this.props.contactId);
        }
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps.contactId !== this.props.contactId) {
            this.loadSegments(nextProps.contactId)
        }
    }

    loadSegments = (contactId) => {
        const {segments, segmentsLoading} = this.props.contacts;
        const {providerId} = this.props.application;
        if (isEmpty(segments) && contactId && !segmentsLoading) {
            fetchSegments(providerId, contactId)(this.props.dispatch);
        }
    }

    handleChange = (ev, {name, value}) => {
        const {onChange} = this.props;
        onChange(ev, {
            name: this.props.name,
            value: value
        })
    }

    getOptions = () => {
        const {segments} = this.props.contacts;
        if (segments && !isEmpty(segments)) {
            return segments.map((seg, index) => {
                return {
                    value: `${seg.id}`,
                    text: seg.display_name,
                    key: index
                }
            })
        }
        return [];
    }

    render() {
        const {
            name,
            value,
            contacts
        } = this.props;
        const options = this.getOptions(); 
        return (
            <Dropdown
                search
                selection
                clearable
                placeholder='Segments'
                multiple={false}
                name={name}
                value={value}
                loading={contacts.segmentsLoading}
                options={options}
                onChange={this.handleChange}
            />
        )
    }
}


export default connect(state => {
    return {
        contacts: state.contacts,
        application: state.application
    }
})(SegmentSelector);
