import React from 'react';
import {connect} from 'react-redux'
import {fetchContent, createContent, updateContent} from '../../redux/actions/content';
import {ContentTemplateForm} from '../../components/forms';
import {
    Header, Grid
} from 'semantic-ui-react';

class ContentForm extends React.Component {
    componentDidMount() {
        const {contentId} = this.props.match.params;
        const {providerId, sportId} = this.props.application;
        if (contentId) {
            fetchContent(providerId, sportId, contentId)(this.props.dispatch);
        }
    }

    submitForm = (ev, {name, value}) => {
        ev.preventDefault();
        const {providerId, sportId} = this.props.application;
        if (value.id) {
            updateContent(providerId, sportId, value.id, value)(this.props.dispatch);
        } else {
            createContent(providerId, sportId, value)(this.props.dispatch);
        }
    }

    render() {
        const {content, application} = this.props;
        const providerLanguages = application.currentProvider && application.currentProvider.meta.languages;
        return (
            <div>
                <Header as='h2'>
                    Content
                </Header>
                <Grid style={{height:'100vh'}}>
                    <Grid.Row columns={2}>
                        <Grid.Column>
                            <ContentTemplateForm
                                content={content.currentContent}
                                providerLanguages={providerLanguages}
                                onSubmit={this.submitForm}/>
                        </Grid.Column>
                        <Grid.Column>

                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            </div>
        )
    }
}

export default connect(state => {
    return {
        application: state.application,
        content: state.content
	}
})(ContentForm);
