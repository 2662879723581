import React from 'react';
import {DataGrid} from '../../components/grid';
import {connect} from 'react-redux'
import {fetchLeagues} from '../../redux/actions/league';
import {
    Segment, Header, Input, Button
} from 'semantic-ui-react';


class LeaguesGrid extends React.Component {
    state = {
        search: ''
    }

    componentDidMount() {
        const {providerId, sportId} = this.props.application;
        fetchLeagues(providerId, sportId)(this.props.dispatch);
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        const {providerId, sportId} = nextProps.application;
        if (providerId !== this.props.application.providerId ||
            sportId !== this.props.application.sportId) {
            fetchLeagues(providerId, sportId)(this.props.dispatch);
        }
    }

    getOptions = () => {
        return (item) => {
            return [
                {
                    icon: 'soccer', title: 'Matches',
                    path: `/league/${item.id}/matches`
                },
                {
                    icon: 'table', title: 'Tables',
                    path: `/league/${item.id}/tables`
                },
                {
                    icon: 'table', title: 'Stats',
                    path: `/league/${item.id}/stats`
                },
                {
                    icon: 'table', title: 'Stats 2',
                    path: `/league/${item.id}/stats2`
                }
            ]
        }
    }

    search = (ev, {name, value}) => {
        const {providerId, sportId} = this.props.application;
        const {search} = this.state;
        if (name === 'search') {
            this.setState({search: value});
        } else if (name === 'searchButton') {
            fetchLeagues(providerId, sportId, search)(this.props.dispatch);
        }
    }

    getHeader = () => {
        const {search} = this.state;
        return (
            <Segment raised clearing>
                <Header as='h2' floated='left'>Leagues</Header>
                <Header as='h5' floated='right'>
                    <Input
                        name='search'
                        placeholder='Search Leagues...'
                        onChange={this.search}
                        value={search}
                        labelPosition='right'
                        label={
                            <Button
                                icon='search'
                                name='searchButton'
                                size='tiny'
                                onClick={this.search}
                            />
                        }
                    />
                </Header>
            </Segment>
        )
    }

    handlePagination = (start) => {
        const {providerId, sportId} = this.props.application;
        const {search} = this.state;
		fetchLeagues(providerId, sportId, search, start)(this.props.dispatch);
    }

    handleSorting = ({name, direction}) => {
        const {providerId, sportId} = this.props.application;
        const {search} = this.state;
        const sortString = `${direction === 'descending' ? '-' : ''}${name}`;
		fetchLeagues(providerId, sportId, search, 0, sortString)(this.props.dispatch);
	}

    render() {
        const {leagues} = this.props;
        return (
            <Segment basic raised>
                {this.getHeader()}
                <DataGrid
                    headers={[
                        {name: 'id', title: 'Id', sortable: true},
                        {name: 'name', title: 'League', sortable: true, renderer: (league) => {
                            return league.provider_name || league.name;
                        }},
                        {name: 'path', title: 'Path', sortable: true},
                        {name: 'unique_id', title: 'Unique Id', sortable: false},
                        {name: 'event_sync', title: 'Sync?', sortable: false, renderer: (league) => {
                            return league.event_sync ? "Yes" : "No";
                        }},
                        {name: null, title: 'Options', options: this.getOptions()}
                    ]}
                    items={leagues.items}
                    totalRows={leagues.total}
                    pageSize={leagues.limit}
                    onPaginate={this.handlePagination}
                    onSort={this.handleSorting}
                />
            </Segment>
        )
    }
}

export default connect(state => {
    return {
        application: state.application,
        leagues: state.leagues
	}
})(LeaguesGrid);
