import React from 'react';
import PropTypes from 'prop-types';
import {endsWith} from 'lodash/string';
import {
    Table, Input
} from 'semantic-ui-react';


class ThresholdFields extends React.Component {
    static propTypes = {
        market: PropTypes.object,
        name: PropTypes.string,
        onChange: PropTypes.func,
        showMarketLimits: PropTypes.bool,
        values: PropTypes.object
    }

    static defaultProps = {
        values: {}
    }

    state = {
        values: this.props.values || {}
    }

    static getDerivedStateFromProps(props, state) {
        if (props.values !== state.values) {
            return {
                values: props.values || {}
            }
        }
        return null;
    }

    handleChange = (ev, {name, value}) => {
        const {onChange} = this.props;
        const {values} = this.state;
        values[name] = value;
        this.setState(values, () => {
            onChange(ev, {
                name: this.props.name,
                value: this.normaliseThresholds(values)
            })
        })
    }

    // convert to floats and ints
    normaliseThresholds = (values) => {
        const keys = Object.keys(values);
        const normalised = {};
        // eslint-disable-next-line
        keys.map((key) => {
            if (values[key] && values[key].indexOf) { // string
                normalised[key] = values[key].indexOf('.') > -1 ?
                    parseFloat(values[key]) :
                    parseInt(values[key], 10)
            } else {
                normalised[key] = values[key];
            }
        })
        return normalised
    }

    render() {
        const {market, showMarketLimits} = this.props;
        const {values} = this.state;
        const {thresholds} = market.meta;
        const keys = Object.keys(thresholds);
        return (
            <Table definition basic='very' collapsing columns={4}>
                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell/>
                        <Table.HeaderCell>Value</Table.HeaderCell>
                        <Table.HeaderCell>
                            Min
                        </Table.HeaderCell>
                        <Table.HeaderCell>
                            Max
                        </Table.HeaderCell>
                    </Table.Row>
                </Table.Header>
                <Table.Body>
                    {keys.map((thresholdKey, index) => {
                        if (endsWith(thresholdKey, '_min') || endsWith(thresholdKey, '_max')) {
                            return false;
                        }
                        return (
                            <Table.Row key={index}>
                                <Table.Cell>
                                    {thresholdKey}
                                </Table.Cell>
                                <Table.Cell collapsing>
                                    <Input
                                        type='number'
                                        name={thresholdKey}
                                        value={values[thresholdKey] || ''}
                                        onChange={this.handleChange}
                                    />
                                </Table.Cell>
                                <Table.Cell collapsing>
                                    {showMarketLimits ? (
                                        <Input
                                        type='number'
                                            name={`${thresholdKey}_min`}
                                            value={values[`${thresholdKey}_min`] || ''}
                                            onChange={this.handleChange}
                                        />
                                    ) : false}
                                </Table.Cell>
                                <Table.Cell collapsing>
                                    {showMarketLimits ? (
                                        <Input
                                            type='number'
                                            name={`${thresholdKey}_max`}
                                            value={values[`${thresholdKey}_max`] || ''}
                                            onChange={this.handleChange}
                                        />
                                    ) : false}
                                </Table.Cell>
                            </Table.Row>
                        )
                    })}
                </Table.Body>
            </Table>
        )
    }
}

export default ThresholdFields;