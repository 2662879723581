import React, {useEffect, useState} from 'react';
import { Grid, Statistic } from 'semantic-ui-react';
import { query } from '../../redux/actions/query';
import {useDispatch, useSelector} from 'react-redux';


export default function SummaryStatDisplay({
    queryName,
    thresholds,
    matchIds,
    evalResult,
    params,
    focusOn,
}) {
    const {sportId} = useSelector(state => state.application);
    const [queryId, setQueryId] = useState(queryName);
    const [stats, setStats] = useState(null);
    const dispatch = useDispatch();
    useEffect(() => {
        if (evalResult) {
            query(sportId, queryName, params)(dispatch).then(response => {
                // console.log("EVAL",queryName,  response?.payload?.response?.result?.result, focusOn);
                setStats(evalResult(response?.payload?.response?.result?.result, focusOn));
                setQueryId(queryName);
            })
        }
    }, [thresholds, queryName, focusOn, matchIds])
    if (queryId !== queryName) {
        return <div>Loading...</div>
    }
    return (
        <Grid>
            <Grid.Row>
                <Grid.Column>
                    {stats?.total ? (
                        <Statistic color='grey'>
                            <Statistic.Value>
                                {stats?.total}
                            </Statistic.Value>
                            <Statistic.Label>Total</Statistic.Label>
                        </Statistic>

                    ) : false}
                </Grid.Column>
            </Grid.Row>
            <Grid.Row>
                <Grid.Column width={8}>
                    <div className="stats-widget">
                        {thresholds.map((threshold, index) => (
                            <Statistic.Group key={`${index}-grpL`}>
                                <Statistic key={`${index}-rate`}>
                                    <Statistic.Value>
                                        {stats?.[`${threshold}_ratio`]}%
                                    </Statistic.Value>
                                    <Statistic.Label>{threshold}</Statistic.Label>
                                </Statistic>
                                <Statistic key={`${index}-count`}>
                                    <Statistic.Value>
                                        {stats?.[`${threshold}_total`]}
                                    </Statistic.Value>
                                    <Statistic.Label>{threshold}</Statistic.Label>
                                </Statistic>
                            </Statistic.Group>
                        ))}
                    </div>
                </Grid.Column>
                <Grid.Column width={8}>
                    <div className="stats-widget">
                        {thresholds.map((threshold, index) => (
                            <Statistic.Group color='red' key={`${index}-grpR`}>
                                {stats?.[`${threshold}_neg_ratio`] ? (
                                    <Statistic key={`${index}-rate`}>
                                        <Statistic.Value>
                                            {stats?.[`${threshold}_neg_ratio`]}%
                                        </Statistic.Value>
                                        <Statistic.Label>{threshold}</Statistic.Label>
                                    </Statistic>
                                ) : false}
                                {stats?.[`${threshold}_neg_total`] ? (
                                    <Statistic key={`${index}-count`}>
                                        <Statistic.Value>
                                            {stats?.[`${threshold}_neg_total`]}
                                        </Statistic.Value>
                                        <Statistic.Label>{threshold}</Statistic.Label>
                                    </Statistic>
                                ) : false}
                            </Statistic.Group>
                        ))}
                    </div>
                </Grid.Column>
            </Grid.Row>
        </Grid>
    )
}