import {Actions} from '../../constants';

const initialMatchesState = {
    loading: false,
    items: [],
    total: 0,
    limit: 0,
    currentMatch: null,
    currentOfferContent: null,
    matchContent: [],
    matchMarkets: [],
    matchThresholds: [],
    matchFeedEvents: [],
    templatedMatches: [],
    totalFeedEvents: 0,
    feedEventsLimit: 0,
    deliveries: [],
    totalDeliveries: 0, 
    deliveriesLimit: 0,
    connectorLoading: false,
    connectorSearchItems: [],
    trackers: [],
    currentTracker: null,
    matchGraph: {
        lastOfferId: '-',
        items: []
    }
};

function matches(state=initialMatchesState, action) {
	switch(action.type) {
		case Actions.MATCHES.GET:
			return {
				...state,
                loading: true
			};

		case Actions.MATCHES.GET_SUCCESS:
			return {
				...state,
                loading: false,
                items: action.payload.response.results,
                total: action.payload.response.total,
                limit: action.payload.response.limit,
            }

        case Actions.MATCHES.GET_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload.error
            }

        case Actions.MATCHES.GET_TRACKERS:
            return {
                ...state,
                trackers: [],
                loading: true
            };

        case Actions.MATCHES.GET_TRACKERS_SUCCESS:
            return {
                ...state,
                loading: false,
                trackers: action.payload.response.results,
            }

        case Actions.MATCHES.GET_TRACKERS_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload.error
            }

        case Actions.MATCHES.SET_TRACKER:
            return {
                ...state,
                loading: false,
                currentTracker: action.payload
            }

        case Actions.MATCHES.SET_TRACKER_FIELD:
            return {
                ...state,
                currentTracker: {
                    ...state.currentTracker,
                    [action.payload.name]: action.payload.value
                }
            }

        case Actions.MATCH.GET:
			return {
				...state,
                loading: true,
                matchContent: [],
                matchMarkets: [],
                matchThresholds: []
			};

        case Actions.MATCH.GET_SUCCESS:
			return {
				...state,
                loading: false,
                currentMatch: action.payload.response.result
            }

        case Actions.MATCH.GET_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload.error
            }

        case Actions.MATCH.SAVE_CONTENT:
            return {
                ...state,
                loading: true
            }

        case Actions.MATCH.SAVE_CONTENT_SUCCESS:
            return {
                ...state,
                loading: false,
                matchContent: action.payload.response.results
            }

        case Actions.MATCH.SAVE_CONTENT_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload.error
            }

        case Actions.MATCH.GET_CONTENT:
            return {
                ...state,
                loading: true
            }

        case Actions.MATCH.GET_CONTENT_SUCCESS:
            return {
                ...state,
                loading: false,
                matchContent: action.payload.response.results
            }

        case Actions.MATCH.GET_CONTENT_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload.error
            }

        case Actions.MATCH.DELETE_CONTENT:
            return {
                ...state,
                loading: true
            }

        case Actions.MATCH.DELETE_CONTENT_SUCCESS:
            return {
                ...state,
                loading: false
            }

        case Actions.MATCH.DELETE_CONTENT_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload.error
            }

        case Actions.MATCH.GET_MARKETS:
            return {
                ...state,
                matchMarkets: [],
                loading: true
            }

        case Actions.MATCH.GET_MARKETS_SUCCESS:
            return {
                ...state,
                loading: false,
                matchMarkets: action.payload.response.results
            }

        case Actions.MATCH.GET_MARKETS_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload.error
            }


        case Actions.MATCH.GET_THRESHOLDS:
            return {
                ...state,
                loading: true,
                matchThresholds: []
            }

        case Actions.MATCH.GET_THRESHOLDS_SUCCESS:
            return {
                ...state,
                loading: false,
                matchThresholds: action.payload.response.results
            }

        case Actions.MATCH.GET_THRESHOLDS_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload.error
            }

        case Actions.MATCH.SAVE_THRESHOLDS:
            return {
                ...state,
                loading: true
            }

        case Actions.MATCH.SAVE_THRESHOLDS_SUCCESS:
            return {
                ...state,
                loading: false,
                matchThresholds: action.payload.response.results
            }

        case Actions.MATCH.SAVE_THRESHOLDS_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload.error
            }

        case Actions.MATCH.DELETE_THRESHOLDS:
            return {
                ...state,
                loading: true
            }

        case Actions.MATCH.DELETE_THRESHOLDS_SUCCESS:
            return {
                ...state,
                loading: false,
                matchThresholds: action.payload.response.results
            }

        case Actions.MATCH.DELETE_THRESHOLDS_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload.error
            }


        case Actions.MATCH.GET_EVENTS:
            return {
                ...state,
                loading: true
            }

        case Actions.MATCH.GET_EVENTS_SUCCESS:
            const matchGraphItem = state.matchGraph;
            matchGraphItem.items = action.payload.response.results;
            const resultSize = action.payload.response.results.length;
            const lastEvent = resultSize > 0 ? action.payload.response.results[resultSize-1] : null;
            matchGraphItem.lastOfferId = lastEvent && lastEvent.id;
            return {
                ...state,
                loading: false,
                matchFeedEvents: action.payload.response.results,
                totalFeedEvents: action.payload.response.total,
                feedEventsLimit: action.payload.limit,
                matchGraph: matchGraphItem
            }

        case Actions.MATCH.GET_EVENTS_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload.error
            }

        case Actions.MATCH.NEXT_OFFER:
            return {
                ...state,
            }

        case Actions.MATCH.NEXT_OFFER_SUCCESS:
            if (action.payload.response.results) {
                console.log("NEXT OFFER SUCCESS", action.payload);
                const {matchGraph, matchFeedEvents} = state;
                // matchGraph.lastOfferId = action.payload.response.result.id;
                // matchGraph.items.push(action.payload.response.result);
                // matchFeedEvents.push(action.payload.response.result);
                const newMatchGraph = action.payload.response.results.concat(matchGraph);
                const newMatchFeedEvents = action.payload.response.results.concat(matchFeedEvents);
                newMatchGraph.lastOfferId = action?.payload?.response?.results?.[0]?.id || matchGraph.lastOfferId;
                return {
                    ...state,
                    matchGraph: newMatchGraph,
                    matchFeedEvents: newMatchFeedEvents
                }
            } else {
                return state;
            }


        case Actions.MATCHES.GET_TEMPLATED:
            return {
                ...state,
                loading: true,
            }

        case Actions.MATCHES.GET_TEMPLATED_SUCCESS:
            return {
                ...state,
                loading: false,
                templatedMatches: action.payload.response.results
            }

        case Actions.MATCHES.GET_TEMPLATED_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload.error
            }

        case Actions.MATCH.GET_DELIVERIES:
            return {
                ...state,
                deliveries: []
            }

        case Actions.MATCH.GET_DELIVERIES_SUCCESS:
            return {
                ...state,
                deliveries: action.payload.response.results,
                deliveriesLimit: action.payload.limit,
                totalDeliveries: action.payload.response.total,
            }

        case Actions.MATCH.GET_DELIVERIES_FAIL:
            return {
                ...state,
                error: action.payload.error
            }

        case Actions.MATCH.CONNECT_SEARCH:
            return {
                ...state,
                connectorSearchItems: [],
                connectorLoading: true
            };

        case Actions.MATCH.CONNECT_SEARCH_SUCCESS:
            return {
                ...state,
                connectorLoading: false,
                connectorSearchItems: action.payload.response.results,
                total: action.payload.response.total,
                limit: action.payload.response.limit,
            }

        case Actions.MATCH.CONNECT_SEARCH_FAIL:
            return {
                ...state,
                connectorLoading: false,
                connectorSearchItems: [],
                error: action.payload.error
            }

        case Actions.MATCH.OFFER_CONTENT:
            return {
                ...state,
                currentOfferContent: null,
            }

        case Actions.MATCH.OFFER_CONTENT_SUCCESS:
            return {
                ...state,
                currentOfferContent: action.payload.response.content
            }

        case Actions.MATCH.OFFER_CONTENT_FAIL:
            return {
                ...state,
                currentOfferContent: null
            }

		default:
			return state;
	}
}

export default matches;
