import React from 'react';
import PropTypes from 'prop-types';
import {Input, Button} from 'semantic-ui-react';


export default class MinutesInput extends React.Component {
    static propTypes = {
        name: PropTypes.string,
        value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        onChange: PropTypes.func
    }

    state = {
        value: this.props.value,
        period: this.props.period,
        minutes: null,
        extraTime: null
    } //this.formatGameTime(item.game_time, item.period)

    componentDidMount() {
        if (this.props.value) {
            this.setState({
                ...this.parseMinutes(this.props.value)
            })
        }
    }

    parseMinutes = (value) => {
        let minutes, extraTime, fullTime;
        let {period} = this.state;
        if (period && typeof(value) === 'number') {
            value = this.formatGameTime(value, period);
        }
        const minuteParts = value.split('+');

        if (minuteParts.length === 2) {  // 45+3
            minutes = parseInt(minuteParts[0].trim(), 10);
            extraTime = parseInt(minuteParts[1].trim(), 10);
            fullTime = minutes + extraTime;
        } else {
            minutes = parseInt(minuteParts[0].trim(), 10);
            fullTime = minutes;
        }
        if (!period && !isNaN(minutes)) {
            period = minutes <= 45 ? 1 : 2;
        }
        return {
            minutes,
            period,
            extraTime,
            fullTime
        }
    }

    /**
     * receives a numeric minute and period
     * and returns a formatted string representation.
     * Minutes in over time appear as 45+3 or 90+2
     */
    xxformatGameTime = (minutes, period) => {
        if (minutes > 90) {
            return {value: `90+${minutes-90}`, period: 2};
        } else if (minutes > 45) {
            return {value: `${minutes}`, period: 2};
        } else if (minutes <= 45) {
            return {value: `${minutes}`, period: 1}
        } else {
            return {value: minutes.toString(), period: this.props.period};
        }
    }

    /**
     * receives a numeric minute and period
     * and returns a formatted string representation.
     * Minutes in over time appear as 45+3 or 90+2
     */
    formatGameTime = (minutes, period) => {
        if (period === 1 && minutes > 45) {
            return `45+${minutes-45}`;
        } else if (period === 2 && minutes > 90) {
            return `90+${minutes-90}`;
        } else {
            return minutes.toString();
        }
    }

    // expand minutes+extra to extrapolate period
    handleChange = (ev, {name, value}) => {
        const {
            minutes,
            period,
            extraTime,
            fullTime
        } = this.parseMinutes(value);
        this.setState({
            value,
            period,
            minutes,
            extraTime
        }, () => {
            if (this.props.onChange) {
                this.props.onChange(ev, {
                    name: this.props.name,
                    value: fullTime,
                    period: period,
                    minutes: minutes,
                    extraTime: extraTime
                });
            }
        })
    }

    togglePeriod = () => {
        const {period} = this.state;
        this.setState({
            period: period === 1 ? 2 : 1
        })
    }

    getLabelText = () => {
        const {period, extraTime} = this.state;
        if (period) {
            const periodStr = `Period ${period}`;
            if (!extraTime) {
                return periodStr;
            } else {
                return `${periodStr} (+${extraTime})    `
            }
        }
        return 'Period N/A';
    }

    render() {
        const {name} = this.props;
        const {value, period} = this.state;
        const formattedValue = this.formatGameTime(value || '', period);
        return (
            <Input
                type='text'
                name={name}
                value={formattedValue}
                onChange={this.handleChange}
                label={
                    <Button
                        content={this.getLabelText()}
                        onClick={this.togglePeriod}
                    />
                }
                labelPosition='right'
            />
        )
    }
}
