import {useReducer} from 'react';


export default function (initialValues={}) {
	const reducer = (state, action) => {
		if (action?.action == 'reset'){
			return action.value
		}
		return {
			...state,
			[action.name]: action.value
		}
	}
	return useReducer(reducer, initialValues);
}