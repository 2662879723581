import React, {useState} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import {toggleHold, removeFromBasket} from '../../redux/actions/app';
import {PreviewItem} from '../content-preview';
import {
    Portal,
    Message,
    Menu,
    List,
    Input,
    Segment,
    Header,
    Button,
    Icon
} from 'semantic-ui-react';


export default function Basket() {
    const [visible, setVisible] = useState(false);
    const [triggerCount, setTriggerCount] = useState(null);
    const {basket} = useSelector(state => state.application);
    const dispatch = useDispatch();

    return (
        <Portal
            closeOnTriggerClick
            openOnTriggerClick
            onOpen={() => setVisible(true)}
            onClose={() => setVisible(false)}
            trigger={
                <Button
                    basic
                    content={`${basket.items.length}`}
                    icon={<Icon name="shopping basket"/>}
                    negative={visible}
                    positive={!visible}
                />
            }

        >
            <Segment
                raised
                style={{
                    left: '40%',
                    position: 'fixed',
                    top: '30%',
                    zIndex: 1000,
                    width: 640,
                }}
            >
                <Header as="h3">Selected Content</Header>
                {basket.items.length === 0 ? (
                    <Message content='Add content items from different matches (see the Preview tab)'/>
                ) : false}
                <List
                    divided
                >
                    {basket.items.map((item, index) => {
                        return (
                            <List.Item
                                key={`basket-${index}`}
                            >
                                <List.Content>
                                    <List.Header>
                                        Match ID: {item.match_id}
                                    </List.Header>
                                    <List.Description>
                                        <PreviewItem item={item}/>
                                    </List.Description>
                                </List.Content>
                                <List.Content floated='right'>
                                    <List.Description>
                                        <button className="link regular" onClick={() => dispatch(removeFromBasket(item, index))}>Remove</button> |
                                        <button className="link regular" onClick={() => dispatch(toggleHold(item, index))}>
                                            {!!item.hold ? 'Send' : 'Hold'}
                                        </button>
                                    </List.Description>
                                </List.Content>
                            </List.Item>
                        )
                    })}
                </List>
                <Menu attached='bottom'>
                    <Menu.Item>
                        <Input
                            transparent
                            style={{width: 25}}
                            type='number'
                            size='small'
                            onChange={(ev, {value}) => setTriggerCount(value)}
                            value={triggerCount === null || triggerCount > basket.items.length ? basket.items.length : triggerCount}
                        /> Required to trigger
                    </Menu.Item>
                    <Menu.Menu position='right'>
                        <Menu.Item>
                            <Button
                                positive
                                disabled={basket.items.length === 0}
                                content="Save"
                                onClick={() => {}}
                            />
                        </Menu.Item>
                    </Menu.Menu>
                </Menu>
            </Segment>
        </Portal>
    )
}
