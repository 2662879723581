import { createStore, applyMiddleware } from 'redux';
import thunkMiddleware from 'redux-thunk';
import { createLogger } from 'redux-logger';
import createRootReducer from '../reducers/root';
import createTransitionMiddleware from '../middleware/transition';
import { routerMiddleware } from 'connected-react-router';
import errorMiddleware from '../middleware/error';
import history from '../../utils/history';

const loggerMiddleware = createLogger({
	/**
	 * Only log actions in dev mode.
	 */
	predicate: (getState, action) => process.env.NODE_ENV === 'development',
	collapsed: true,
	duration: true
});

// configure a store with middleware
export default function configureStore(initialState) {
	const transitionMiddleware = createTransitionMiddleware(history);
	const createStoreWithMiddleware = applyMiddleware(
		routerMiddleware(history),
		thunkMiddleware,
		loggerMiddleware,
		errorMiddleware,
		transitionMiddleware
	)(createStore);

	const store = createStoreWithMiddleware(createRootReducer(history), initialState);

	// Enable Webpack hot module replacement for reducers
	if (process.env.NODE_ENV === 'development') {
		if (module.hot) {
			module.hot.accept('../reducers/root', () => {
				const nextRootReducer = require('../reducers/root');
				store.replaceReducer(nextRootReducer);
			});
		}
	}

	return store;
}
