import {Actions} from '../../constants';
import {createAction, createReceiver, getSortParams} from './utils';
import {Service} from '../../service';


export function fetchDeliveries(providerId, sportId, channel=null, fromDate=null, toDate=null, leagueId=null,  start=0, sort='-created_at') {
    return function(dispatch) {
        dispatch(createAction(Actions.REPORTS.GET_DELIVERIES));
        const params = {
            start,
            ...getSortParams(sort)
        };
        if (channel) {
            params.channel = channel;
        }
        if (fromDate) {
            params.from_date = fromDate;
        }
        if (toDate) {
            params.to_date = toDate;
        }
        return Service.doGet({
            url: 'deliveries',
            params: params,
            urlParams: {
                providerId,
                sportId,
            }
        }).then(
            (response) => dispatch(createAction(Actions.REPORTS.GET_DELIVERIES_SUCCESS, response, null))
        ).catch(
            (response) => dispatch(createAction(Actions.REPORTS.GET_DELIVERIES_FAIL, null, response)) );
    }
}

export function refreshLogStats(providerId, logId) {
    return function(dispatch) {
        dispatch(createAction(Actions.REPORTS.REFRESH_STATS));
        return Service.doGet({
            url: 'logstats',
            urlParams: {
                providerId, 
                logId,
            }
        }).then(
            (response) => dispatch(createAction(Actions.REPORTS.REFRESH_STATS_SUCCESS, response, null))
        ).catch(
            (response) => dispatch(createAction(Actions.REPORTS.REFRESH_STATS_FAIL, null, response)) );
    }
}