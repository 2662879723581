import React from 'react';
import {Link} from 'react-router-dom';
import {DataGrid} from '../../components/grid';
import {connect} from 'react-redux'
import {fetchEvents, deleteEvents} from '../../redux/actions/event';
import {fetchMatch} from '../../redux/actions/match';
import {TimerButton} from '../../components/timer-button';
import {
    Segment,
    Header
} from 'semantic-ui-react';

class EventsGrid extends React.Component {
    componentDidMount() {
        const {providerId, sportId} = this.props.application;
        const {matchId} = this.props.match.params;
        if (matchId) {
            fetchMatch(providerId, sportId, matchId)(this.props.dispatch)
            .then(()=>{
                fetchEvents(providerId, sportId, matchId)(this.props.dispatch)
            });
        }
    }

    renderEventType = (item) => {
        const {events} = this.props;
        const eventType = events.eventTypes[item.type_id];
        return eventType && eventType.name;
    }

    getOptions = () => {
        return (item) => {
            return [
                {
                    component: TimerButton,
                    onClick: () => this.handleDelete(item.id)
                }
            ]
        }
    }

    handleDelete = (eventId) => {
        const {providerId, sportId} = this.props.application;
        const {matchId} = this.props.match.params;
        deleteEvents(providerId, sportId, matchId, [eventId])(this.props.dispatch);
    }

    getHeader = () => {
        const {matchId} = this.props.match.params;
        const {matches} = this.props;
        if (matches.currentMatch) {
            return (
                <Segment clearing>
                    <Header as='h2' floated='left'>
                        Statistical Events
                        <Header.Subheader>
                            <Link to={`/match/${matchId}`}>
                                {matches.currentMatch.name}
                            </Link>
                        </Header.Subheader>
                    </Header>
                    <Header as='h2' floated='right'>
                        <Header.Subheader>
                            <Link to={`/event/${matchId}/edit`}>
                                Edit
                            </Link>
                        </Header.Subheader>
                    </Header>
                </Segment>
            )
        }
        return false;
    }


    render() {
        const {events} = this.props;
        return (
            <Segment basic raised>
                {this.getHeader()}
                <DataGrid
                    headers={[
                        {name: 'id', title: 'Id'},
                        {name: 'type', title: 'Type', renderer: this.renderEventType},
                        {name: 'period', title: 'Game Period', align: 'center'},
                        {name: 'team', title: 'Team', renderer: (item) => item.team && item.team.name},
                        {name: 'game_time', title: 'Game Time'},
                        {name: 'extra_time', title: 'Extra Time'},
                        {name: 'tags', title: 'Tags'},
                        {name: 'timestamp', title: 'Timestamp'},
                        {name: null, title: 'Options', options: this.getOptions()}
                    ]}
                    items={events.items}
                />
            </Segment>
        )
    }
}

export default connect(state => {
    return {
        application: state.application,
        matches: state.matches,
        events: state.events
	}
})(EventsGrid);
