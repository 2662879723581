import React, {useEffect, useState} from 'react';
import {useHistory} from 'react-router-dom';
import {DataGrid} from '../../components/grid';
import {ChannelSelector} from '../../components/selectors';
import {Channels} from '../../constants';
import {useSelector, useDispatch} from 'react-redux';
import {
    fetchDeliveries, 
    refreshLogStats,
} from '../../redux/actions/report';
import {
    Segment, Header, Form, Input
} from 'semantic-ui-react';
import {DateInput} from 'semantic-ui-calendar-react';

export default function DeliveriesGrid() {
    const dispatch = useDispatch();
    const {providerId, sportId} = useSelector(state => state.application);
    const {deliveries, total} = useSelector(state => state.reports);

    const [channel, setChannel] = useState(1);
    const [fromDate, setFromDate] = useState('');
    const [toDate, setToDate] = useState('');

    useEffect(() => {
        fetchDeliveries(providerId, sportId, channel, fromDate, toDate, null, 0)(dispatch);
    }, [channel, fromDate, toDate])

    const getOptions = () => {
        return (item) => {
            console.log(item)
            return [
                {path: `/match/${item.match_id}/delivery`, icon: 'soccer'},
                {icon: 'refresh', onClick: () => {
                    refreshLogStats(providerId, item.id)(dispatch).then(() => {
                        fetchDeliveries(providerId, sportId, channel, fromDate, toDate, null, 0)(dispatch);
                    })
                }}
                
            ]
        }
    }

    return (
        <Segment basic raised>
            <Segment raised>
                <Header as='h2'>Reports</Header>
                <Form>
                    <Form.Group widths={3}>
                        <Form.Field>
                            <ChannelSelector
                                placeholder="Delivery channel"
                                value={channel}
                                onChange={(ev, {value}) => setChannel(value)}
                            />
                        </Form.Field>
                        <Form.Field>
                            <DateInput
                                name="fromDate"
                                placeholder="From date"
                                closable={true}
                                onChange={(ev, {value}) => setFromDate(value)}
                                dateFormat='YYYY-MM-DD'
                                value={fromDate}
                            />
                        </Form.Field>
                        <Form.Field>
                            <DateInput
                                name="toDate"
                                placeholder="To date"
                                closable={true}
                                onChange={(ev, {value}) => setToDate(value)}
                                dateFormat='YYYY-MM-DD'
                                value={toDate}
                            />
                        </Form.Field>
                    </Form.Group>
                </Form>
            </Segment>
            <DataGrid
                headers={[
                    {name: 'id', title: 'Id'},
                    {name: 'log.subject', title: 'Title'},
                    {name: 'channel', title: 'Channel'},
                    {name: 'market.name', title: 'Market'},
                    {name: 'contact', title: 'Contact'},
                    {name: 'language', title: 'Language'},
                    {name: 'node_id', title: 'Score'},
                    {name: 'sent_at', title: 'Sent At'},
                    {title: 'Sent', renderer: (item) => item?.stats?.report?.total?.sent || 'N/A'},
                    {title: 'Open', renderer: (item) => item?.stats?.report?.total?.open || 'N/A'},
                    {name: null, title: 'Options', options: getOptions()},
                ]}
                items={deliveries}
                totalRows={total}
                onPaginate={(start) => {
                    fetchDeliveries(providerId, sportId, channel, fromDate, toDate, null, start)(dispatch);
                }}
            />
        </Segment>
    )
}
