import {Actions} from '../../constants';

const initialLeaguesState = {
    loading: false,
    items: [],
    total: 0,  // total resultset size
    limit: 0,  // page size
    currentLeague: null,
    currentSeason: null,
    currentSeason2: null, // bank 2 for comparison
};

function leagues(state=initialLeaguesState, action) {
	switch(action.type) {
		case Actions.LEAGUES.GET:
			return {
				...state,
                loading: true
			};

		case Actions.LEAGUES.GET_SUCCESS:
			return {
				...state,
                loading: false,
                items: action.payload.response.results,
                total: action.payload.response.total,
                limit: action.payload.response.limit,
            }

        case Actions.LEAGUES.GET_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload.error
            }

        case Actions.LEAGUE.GET:
			return {
				...state,
                loading: true
			};

        case Actions.LEAGUE.GET_SUCCESS:
			return {
				...state,
                loading: false,
                currentLeague: action.payload.response.result
            }

        case Actions.LEAGUE.GET_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload.error
            }

        case Actions.LEAGUE.GET_SEASON:
        case Actions.LEAGUE.GET_SEASON2:
            return {
                ...state,
                loading: true,
            }

        case Actions.LEAGUE.GET_SEASON_SUCCESS:
            return {
                ...state,
                loading: false,
                currentLeague: action.payload.response.league,
                currentSeason: {
                    ...action.payload.response.season,
                    table: action.payload.response.table,
                }
            }

        case Actions.LEAGUE.GET_SEASON2_SUCCESS:
            return {
                ...state,
                loading: false,
                // currentLeague: action.payload.response.league,
                currentSeason2: {
                    ...action.payload.response.season,
                    table: action.payload.response.table,
                }
            }

        case Actions.LEAGUE.GET_SEASON_FAIL:
        case Actions.LEAGUE.GET_SEASON2_FAIL:
            return {
                ...state,
                loading: false,
            }

		default:
			return state;
	}
}

export default leagues;
