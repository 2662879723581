import React, {useState, useEffect} from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { DEFAULT_RELATIVE_THRESHOLDS } from '../../components/thresholds-form/constants';

import {
    fetchMatch,
    fetchMatchContent,
    fetchMatchThresholds,
    saveMatchThresholds,
    deleteMatchThresholds,
} from '../../redux/actions/match';
import {fetchContacts} from '../../redux/actions/contact';
import {compact} from 'lodash/array';
import {groupBy} from 'lodash/collection';
import MatchWrapper from './match-wrapper';
import {ThresholdsForm, RelativeThresholdsForm, RangeThresholdsForm} from '../../components/thresholds-form';
import {
    Grid, Segment, Header, Loader, List, Divider, Button
} from 'semantic-ui-react';


const default_threshold_values = {
    contactId: null,
    line: null,
    thresholds: {}
}

const THRESHOLD_VALUE = 'VALUE';
const THRESHOLD_RELATIVE = 'RELATIVE';
const THRESHOLD_RANGE = 'RANGE';
const THRESHOLD_TYPES = {
    [THRESHOLD_VALUE]: 'Value',
    [THRESHOLD_RANGE]: 'Range',
    [THRESHOLD_RELATIVE]: 'Relative',
}

function MarketItem({market, index, matchMarketThresholds, onSelect, onEdit}) {
    const thresholdTitle = (contactThreshold) => {
        return {
            contactName: (contactThreshold.contact && contactThreshold.contact.name) || 'Match Thresholds',
            hasContact: !!(contactThreshold.contact && contactThreshold.contact.name)
        }
    }

    return (
        <List.Item>
            <List.Content>
                <List.Header>
                    <button
                        className="link"
                        onClick={() => {
                            onSelect(market)
                        }}
                    >{market.name}</button>
                </List.Header>
                {matchMarketThresholds[market.id] ? (
                    <List.List>
                        {matchMarketThresholds[market.id].map((contactThresh, cIndex) => {
                            const {hasContact, contactName} = thresholdTitle(contactThresh);
                            return (
                                <List.Item key={cIndex}>
                                    <List.Icon name={hasContact ? 'id card' : 'trophy'} />
                                    <List.Content>
                                        <button className="link regular" onClick={() => {
                                            onEdit(contactThresh)
                                        }}>{contactThresh.type}: {contactName}</button>
                                    </List.Content>
                                </List.Item>
                            )
                        })}
                    </List.List>
                ) : false}
            </List.Content>
        </List.Item>

    )
}

export default function MatchThresholds({

}) {
    const [thresholdMode, setThresholdMode] = useState(THRESHOLD_VALUE);
    const [values, setValues] = useState(default_threshold_values);
    const [market, setMarket] = useState(null);
    const [allMarkets, setAllMarkets] = useState(false);
    const {providerId, sportId, providerMarkets} = useSelector(state => state.application);
    const {currentMatch, matchContent, matchThresholds} = useSelector(state => state.matches);
    const contacts = useSelector(state => state.contacts.items);
    const {matchId} = useParams();
    const dispatch = useDispatch()

    useEffect(() => {
        if (matchId) {
            fetchMatch(providerId, sportId, matchId)(dispatch)
            .then(() => {
                fetchContacts(providerId, sportId)(dispatch)
                .then(() => {
                    fetchMatchContent(providerId, sportId, matchId)(dispatch)
                    .then(() => {
                        fetchMatchThresholds(providerId, sportId, matchId)(dispatch)
                    });
                });
            })
        }
    }, [matchId])

    const submitForm = (formValues) => {
        const submitValues = formValues || values;
        submitValues.type = thresholdMode;
        submitValues.market_id = market.id;
        submitValues.contact_id = submitValues.contactId || null;
        saveMatchThresholds(providerId, sportId, matchId, submitValues)(dispatch);
    }

    const matchMarkets = matchContent.map(item => item.market.id);
    const activeMarkets = compact(providerMarkets.map((market, index) => {
        if (allMarkets) {
            return market;
        } else {
            return matchMarkets.indexOf(market.id) > -1 ? market : false
        }
    }))
    const matchMarketThresholds = groupBy(matchThresholds, item => item.market.id)
    if (!currentMatch) {
        return (<Loader active/>)
    }
    return (
        <MatchWrapper activeTab='thresholds' match={currentMatch} title='Thresholds'>
            <Grid celled='internally' columns='equal'>
                <Grid.Row>
                    <Grid.Column>
                        <Segment raised>
                            <Header as='h3'>Match/Market Thresholds 2</Header>
                            <List link>
                                <List.Item>
                                    <button className="link regular" onClick={() => setAllMarkets(!allMarkets)}>
                                        {
                                            allMarkets ?
                                                'Show Active Markets' :
                                                'Show All Markets'
                                        }
                                    </button>
                                    |
                                    <button className="link regular" onClick={() => {
                                        if (window.confirm('Are you sure?')) {
                                            deleteMatchThresholds(providerId, sportId, matchId)(dispatch).then(res => {
                                                setValues(default_threshold_values);
                                                setMarket(null);
                                                setThresholdMode(THRESHOLD_VALUE);
                                            });
                                        }
                                    }}>
                                        Delete all
                                    </button>
                                    <Divider/>
                                </List.Item>
                                {activeMarkets.map((market, index) => {
                                    return (
                                        <MarketItem
                                            key={index}
                                            market={market}
                                            matchMarketThresholds={matchMarketThresholds}
                                            onSelect={(market) => {
                                                //setValues({action: 'reset', value: thresholdMode == THRESHOLD_VALUE ? default_threshold_values : DEFAULT_RELATIVE_THRESHOLDS});
                                                setValues(thresholdMode === THRESHOLD_VALUE ? default_threshold_values : DEFAULT_RELATIVE_THRESHOLDS)
                                                setMarket(market);
                                            }}
                                            onEdit={(values) => {
                                                const contactId = values?.contact?.id || null;
                                                const thresholds = values?.thresholds || {};
                                                setThresholdMode(values?.type || THRESHOLD_VALUE);
                                                setValues({
                                                    line: values?.meta?.line || null,
                                                    contactId,
                                                    thresholds
                                                })
                                                setMarket(values?.market);

                                            }}
                                        />
                                    )
                                })}
                            </List>
                        </Segment>
                    </Grid.Column>
                    <Grid.Column>
                        {market ? (
                            <Segment basic clearing>
                                <Header floated="right">
                                <Button.Group>
                                <Button
                                    primary={thresholdMode == THRESHOLD_VALUE}
                                    onClick={() => setThresholdMode(THRESHOLD_VALUE)}
                                >Value Thresholds</Button>
                                <Button
                                    primary={thresholdMode == THRESHOLD_RELATIVE}
                                    onClick={() => setThresholdMode(THRESHOLD_RELATIVE)}
                                >Relative Thresholds</Button>
                                <Button
                                    primary={thresholdMode == THRESHOLD_RANGE}
                                    onClick={() => setThresholdMode(THRESHOLD_RANGE)}
                                >Range Thresholds</Button>
                                </Button.Group>
                                
                                </Header>
                                <Header floated='left' as='h2'>
                                    {`${THRESHOLD_TYPES[thresholdMode || THRESHOLD_VALUE]}`}
                                </Header>

                            </Segment>
                        ) : false}
                        {thresholdMode === THRESHOLD_VALUE ? (
                            <ThresholdsForm
                                name='values'
                                market={market}
                                contacts={contacts}
                                values={values}
                                onChange={(ev, {name, value}) => {

                                }}
                                onSubmit={submitForm}
                            />
                        ) : thresholdMode === THRESHOLD_RELATIVE ?  (
                            <RelativeThresholdsForm
                                name='values'
                                market={market}
                                contacts={contacts}
                                values={values}
                                onChange={(ev, {name, value}) => {

                                }}
                                onSubmit={submitForm}
                            />
                        ) : (
                            <RangeThresholdsForm
                                name='values' 
                                market={market}
                                contacts={contacts}
                                values={values}
                                onChange={(ev, {name, value}) => {

                                }}
                                onSubmit={submitForm}
                            />
                        )}
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        </MatchWrapper>
    )
}