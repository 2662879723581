export default {
	APP: {
		SET_PROVIDER: 'SET_PROVIDER',
		SET_SPORT: 'SET_SPORT',
		ADD_ERROR: 'ADD_APP_ERROR',
		ADD_ERRORS: 'ADD_APP_ERRORS',
		CLEAR_ERRORS: 'CLEAR_APP_ERRORS',
		GET_PROVIDERS: 'GET_PROVIDERS',
			GET_PROVIDERS_SUCCESS: 'GET_PROVIDERS_SUCCESS',
			GET_PROVIDERS_FAIL: 'GET_PROVIDERS_FAIL',
		GET_PROVIDER: 'GET_PROVIDER',
			GET_PROVIDER_SUCCESS: 'GET_PROVIDER_SUCCESS',
			GET_PROVIDER_FAIL: 'GET_PROVIDER_FAIL',
		AUTHENTICATE: 'AUTHENTICATE',
			AUTHENTICATE_SUCCESS: 'AUTHENTICATE_SUCCESS',
			AUTHENTICATE_FAIL: 'AUTHENTICATE_FAIL',
		GET_MARKETS: 'GET_MARKETS',
			GET_MARKETS_SUCCESS: 'GET_MARKETS_SUCCESS',
			GET_MARKETS_FAIL: 'GET_MARKETS_FAIL',
		GET_MARKET_TYPES: 'GET_MARKET_TYPES',
			GET_MARKET_TYPES_SUCCESS: 'GET_MARKET_TYPES_SUCCESS',
			GET_MARKET_TYPES_FAIL: 'GET_MARKET_TYPES_FAIL',
		GET_SPORTS: 'GET_SPORTS',
			GET_SPORTS_SUCCESS: 'GET_SPORTS_SUCCESS',
			GET_SPORTS_FAIL: 'GET_SPORTS_FAIL',
		GET_RULES: 'GET_RULES',
			GET_RULES_SUCCESS: 'GET_RULES_SUCCESS',
			GET_RULES_FAIL: 'GET_RULES_FAIL',
		SEARCH: 'SEARCH',
			SEARCH_SUCCESS: 'SEARCH_SUCCESS',
			SEARCH_FAIL: 'SEARCH_FAIL',
		UPDATE_MARKET: 'UPDATE_MARKET',
			UPDATE_MARKET_SUCCESS: 'UPDATE_MARKET_SUCCESS',
			UPDATE_MARKET_FAIL: 'UPDATE_MARKET_FAIL',
		UPDATE_PROVIDER: 'UPDATE_PROVIDER',
			UPDATE_PROVIDER_SUCCESS: 'UPDATE_PROVIDER_SUCCESS',
			UPDATE_PROVIDER_FAIL: 'UPDATE_PROVIDER_FAIL',
		ADD_BASKET: 'ADD_TO_BASKET',
		REMOVE_FROM_BASKET: 'REMOVE_FROM_BASKET',
		BASKET_TOGGLE_HOLD: 'TOGGLE_BASKET_HOLD',
		SAVE_BASKET: 'SAVE_BASKET',
			SAVE_BASKET_SUCCESS: 'SAVE_BASKET_SUCCESS',
			SAVE_BASKET_FAIL: 'SAVE_BASKET_FAIL',
		GET_SAVED_THRESHOLDS: 'GET_SAVED_THRESHOLDS',
			GET_SAVED_THRESHOLDS_SUCCESS: 'GET_SAVED_THRESHOLDS_SUCCESS',
			GET_SAVED_THRESHOLDS_FAIL: 'GET_SAVED_THRESHOLDS_FAIL',
		DELETE_SAVED_THRESHOLDS: 'DELETE_SAVED_THRESHOLDS',
			DELETE_SAVED_THRESHOLDS_SUCCESS: 'DELETE_SAVED_THRESHOLDS_SUCCESS',
			DELETE_SAVED_THRESHOLDS_FAIL: 'DELETE_SAVED_THRESHOLDS_FAIL',

	},
	/* ********************************************************************* */
	LEAGUES: {
		GET: 'GET_LEAGUES',
			GET_SUCCESS: 'GET_LEAGUES_SUCCESS',
			GET_FAIL: 'GET_LEAGUES_FAIL',
	},
	LEAGUE: {
		GET: 'GET_LEAGUE',
			GET_SUCCESS: 'GET_LEAGUE_SUCCESS',
			GET_FAIL: 'GET_LEAGUE_FAIL',
		GET_SEASON: 'GET_LEAGUE_SEASON',
			GET_SEASON_SUCCESS: 'GET_LEAGUE_SEASON_SUCCESS',
			GET_SEASON_FAIL: 'GET_LEAGUE_SEASON_FAIL',
		GET_SEASON2: 'GET_LEAGUE_SEASON2',
			GET_SEASON2_SUCCESS: 'GET_LEAGUE_SEASON2_SUCCESS',
			GET_SEASON2_FAIL: 'GET_LEAGUE_SEASON2_FAIL',
	},
	/* ********************************************************************* */
	MATCHES: {
		GET: 'GET_MATCHES',
			GET_SUCCESS: 'GET_MATCHES_SUCCESS',
			GET_FAIL: 'GET_MATCHES_FAIL',
		GET_TEMPLATED: 'GET_TEMPLATED_MATCHES',
			GET_TEMPLATED_SUCCESS: 'GET_TEMPLATED_MATCHES_SUCCESS',
			GET_TEMPLATED_FAIL: 'GET_TEMPLATED_MATCHES_FAIL',
		GET_TRACKERS: 'GET_TRACKERS',
			GET_TRACKERS_SUCCESS: 'GET_TRACKERS_SUCCESS',
			GET_TRACKERS_FAIL: 'GET_TRACKERS_FAIL',
		GET_TRACKER: 'GET_TRACKER',
			GET_TRACKER_SUCCESS: 'GET_TRACKER_SUCCESS',
			GET_TRACKER_FAIL: 'GET_TRACKER_FAIL',
		UPDATE_TRACKER: 'UPDATE_TRACKER',
			UPDATE_TRACKER_SUCCESS: 'UPDATE_TRACKER_SUCCESS',
			UPDATE_TRACKER_FAIL: 'UPDATE_TRACKER_FAIL',
		SET_TRACKER: 'SET_TRACKER',
		SET_TRACKER_FIELD: 'SET_TRACKER_FIELD',


	},
	MATCH: {
		GET: 'GET_MATCH',
			GET_SUCCESS: 'GET_MATCH_SUCCESS',
			GET_FAIL: 'GET_MATCH_FAIL',
		SAVE_CONTENT: 'SAVE_CONTENT',
			SAVE_CONTENT_SUCCESS: 'SAVE_CONTENT_SUCCESS',
			SAVE_CONTENT_FAIL: 'SAVE_CONTENT_FAIL',
		GET_CONTENT: 'GET_CONTENT',
			GET_CONTENT_SUCCESS: 'GET_CONTENT_SUCCESS',
			GET_CONTENT_FAIL: 'GET_CONTENT_FAIL',
		DELETE_CONTENT: 'DELETE_MATCH_CONTENT',
			DELETE_CONTENT_SUCCESS: 'DELETE_MATCH_CONTENT_SUCCESS',
			DELETE_CONTENT_FAIL: 'DELETE_MATCH_CONTENT_FAIL',
		GET_THRESHOLDS: 'GET_THRESHOLDS',
			GET_THRESHOLDS_SUCCESS: 'GET_THRESHOLDS_SUCCESS',
			GET_THRESHOLDS_FAIL: 'GET_THRESHOLDS_FAIL',
		SAVE_THRESHOLDS: 'SAVE_THRESHOLDS',
			SAVE_THRESHOLDS_SUCCESS: 'SAVE_THRESHOLDS_SUCCESS',
			SAVE_THRESHOLDS_FAIL: 'SAVE_THRESHOLDS_FAIL',
		DELETE_THRESHOLDS: 'DELETE_THRESHOLDS',
			DELETE_THRESHOLDS_SUCCESS: 'DELETE_THRESHOLDS_SUCCESS',
			DELETE_THRESHOLDS_FAIL: 'DELETE_THRESHOLDS_FAIL',
		GET_MARKETS: 'GET_MATCH_MARKETS',
			GET_MARKETS_SUCCESS: 'GET_MATCH_MARKETS_SUCCESS',
			GET_MARKETS_FAIL: 'GET_MATCH_MARKETS_FAIL',
		GET_EVENTS: 'GET_MATCH_EVENTS',
			GET_EVENTS_SUCCESS: 'GET_MATCH_EVENTS_SUCCESS',
			GET_EVENTS_FAIL: 'GET_MATCH_EVENTS_FAIL',
		NEXT_OFFER: 'GET_MATCH_NEXT_OFFER',
			NEXT_OFFER_SUCCESS: 'GET_MATCH_NEXT_OFFER_SUCCESS',
			NEXT_OFFER_FAIL: 'GET_MATCH_NEXT_OFFER_FAIL',
		GET_DELIVERIES: 'GET_MATCH_DELIVERIES',
			GET_DELIVERIES_SUCCESS: 'GET_MATCH_DELIVERIES_SUCCESS',
			GET_DELIVERIES_FAIL: 'GET_MATCH_DELIVERIES_FAIL',
		COPY_MATCH_DATA: 'COPY_MATCH_DATA',
			COPY_MATCH_DATA_SUCCESS: 'COPY_MATCH_DATA_SUCCESS',
			COPY_MATCH_DATA_FAIL: 'COPY_MATCH_DATA_FAIL',
		CONNECT: 'CONNECT_MATCH',
			CONNECT_SUCCESS: 'CONNECT_MATCH_SUCCESS',
			CONNECT_FAIL: 'CONNECT_MATCH_FAIL',
		CONNECT_SEARCH: 'CONNECT_SEARCH_MATCH',
			CONNECT_SEARCH_SUCCESS: 'CONNECT_SEARCH_MATCH_SUCCESS',
			CONNECT_SEARCH_FAIL: 'CONNECT_SEARCH_MATCH_FAIL',
		OFFER_CONTENT: 'OFFER_CONTENT',
			OFFER_CONTENT_SUCCESS: 'OFFER_CONTENT_SUCCESS',
			OFFER_CONTENT_FAIL: 'OFFER_CONTENT_FAIL',
	},
	/* ********************************************************************* */
	EVENTS: {
		GET: 'GET_EVENTS',
			GET_SUCCESS: 'GET_EVENTS_SUCCESS',
			GET_FAIL: 'GET_EVENTS_FAIL',
		GET_TYPES: 'GET_EVENT_TYPES',
			GET_TYPES_SUCCESS: 'GET_EVENT_TYPES_SUCCESS',
			GET_TYPES_FAIL: 'GET_EVENT_TYPES_FAIL',
		DELETE: 'DELETE_EVENTS',
			DELETE_SUCCESS: 'DELETE_EVENTS_SUCCESS',
			DELETE_FAIL: 'DELETE_EVENTS_FAIL',
	},
	EVENT: {
		GET: 'GET_EVENT',
			GET_SUCCESS: 'GET_EVENT_SUCCESS',
			GET_FAIL: 'GET_EVENT_FAIL',
		CREATE: 'CREATE_EVENT',
			CREATE_SUCCESS: 'CREATE_EVENT_SUCCESS',
			CREATE_FAIL: 'CREATE_EVENT_FAIL',
		UPDATE: 'UPDATE_EVENT',
			UPDATE_SUCCESS: 'UPDATE_EVENT_SUCCESS',
			UPDATE_FAIL: 'UPDATE_EVENT_FAIL',
	},
	/* ********************************************************************* */
	CONTENTS: {
		GET: 'GET_CONTENTS',
			GET_SUCCESS: 'GET_CONTENTS_SUCCESS',
			GET_FAIL: 'GET_CONTENTS_FAIL',
		GET_GROUP: 'GET_GROUP',
			GET_GROUP_SUCCESS: 'GET_GROUP_SUCCESS',
			GET_GROUP_FAIL: 'GET_GROUP_FAIL',
		GET_GROUPS: 'GET_GROUPS',
			GET_GROUPS_SUCCESS: 'GET_GROUPS_SUCCESS',
			GET_GROUPS_FAIL: 'GET_GROUPS_FAIL',
		CREATE_GROUP: 'CREATE_GROUP',
			CREATE_GROUP_SUCCESS: 'CREATE_GROUP_SUCCESS',
			CREATE_GROUP_FAIL: 'CREATE_GROUP_FAIL',
		UPDATE_GROUP: 'UPDATE_GROUP',
			UPDATE_GROUP_SUCCESS: 'UPDATE_GROUP_SUCCESS',
			UPDATE_GROUP_FAIL: 'UPDATE_GROUP_FAIL',
		GET_CATEGORIES: 'GET_CONTENT_CATEGORIES',
			GET_CATEGORIES_SUCCESS: 'GET_CONTENT_CATEGORIES_SUCCESS',
			GET_CATEGORIES_FAIL: 'GET_CONTENT_CATEGORIES_FAIL',
	},
	CONTENT: {
		GET: 'GET_CONTENT',
			GET_SUCCESS: 'GET_CONTENT_SUCCESS',
			GET_FAIL: 'GET_CONTENT_FAIL',
		GET_TYPES: 'GET_CONTENT_TYPES',
			GET_TYPES_SUCCESS: 'GET_CONTENT_TYPES_SUCCESS',
			GET_TYPES_FAIL: 'GET_CONTENT_TYPES_FAIL',
		CREATE: 'CREATE_CONTENT',
			CREATE_SUCCESS: 'CREATE_CONTENT_SUCCESS',
			CREATE_FAIL: 'CREATE_CONTENT_FAIL',
		UPDATE: 'UPDATE_CONTENT',
			UPDATE_SUCCESS: 'UPDATE_CONTENT_SUCCESS',
			UPDATE_FAIL: 'UPDATE_CONTENT_FAIL',
		DELETE: 'DELETE_CONTENT',
			DELETE_SUCCESS: 'DELETE_CONTENT_SUCCESS',
			DELETE_FAIL: 'DELETE_CONTENT_FAIL',
		GET_QUERY_TAGS: 'GET_QUERY_TAGS',
			GET_QUERY_TAGS_SUCCESS: 'GET_QUERY_TAGS_SUCCESS',
			GET_QUERY_TAGS_FAIL: 'GET_QUERY_TAGS_FAIL',
		PARSE: 'PARSE',
			PARSE_SUCCESS: 'PARSE_SUCCESS',
			PARSE_FAIL: 'PARSE_FAIL',
		TOGGLE_AUTO_BUILD: 'TOGGLE_AUTO_BUILD',
			TOGGLE_AUTO_BUILD_SUCCESS: 'TOGGLE_AUTO_BUILD_SUCCESS',
			TOGGLE_AUTO_BUILD_FAIL: 'TOGGLE_AUTO_BUILD_FAIL',
		TOGGLE_ACTIVE: 'TOGGLE_ACTIVE',
			TOGGLE_ACTIVE_SUCCESS: 'TOGGLE_ACTIVE_SUCCESS',
			TOGGLE_ACTIVE_FAIL: 'TOGGLE_ACTIVE_FAIL',
		COMPILE: 'CONTENT_COMPILE',
			COMPILE_SUCCESS: 'CONTENT_COMPILE_SUCCESS',
			COMPILE_FAIL: 'CONTENT_COMPILE_FAIL',
	},
	/* ********************************************************************* */
	CONTACTS: {
		GET: 'GET_CONTACTS',
			GET_SUCCESS: 'GET_CONTACTS_SUCCESS',
			GET_FAIL: 'GET_CONTACTS_FAIL',
		GET_SEGMENTS: 'GET_SEGMENTS',
			GET_SEGMENTS_SUCCESS: 'GET_SEGMENTS_SUCCESS',
			GET_SEGMENTS_FAIL: 'GET_SEGMENTS_FAIL',
		GET_LISTS: 'GET_LISTS',
			GET_LISTS_SUCCESS: 'GET_LISTS_SUCCESS',
			GET_LISTS_FAIL: 'GET_LISTS_FAIL',
	},
	CONTACT: {
		GET: 'GET_CONTACT',
			GET_SUCCESS: 'GET_CONTACT_SUCCESS',
			GET_FAIL: 'GET_CONTACT_FAIL',
		CREATE: 'CREATE_CONTACT',
			CREATE_SUCCESS: 'CREATE_CONTACT_SUCCESS',
			CREATE_FAIL: 'CREATE_CONTACT_FAIL',
		UPDATE: 'UPDATE_CONTACT',
			UPDATE_SUCCESS: 'UPDATE_CONTACT_SUCCESS',
			UPDATE_FAIL: 'UPDATE_CONTACT_FAIL',
		DELETE: 'DELETE_CONTACT',
			DELETE_SUCCESS: 'DELETE_CONTACT_SUCCESS',
			DELETE_FAIL: 'DELETE_CONTACT_FAIL',
		TOGGLE_ACTIVE: 'TOGGLE_ACTIVE_CONTACT',
			TOGGLE_ACTIVE_SUCCESS: 'TOGGLE_ACTIVE_CONTACT_SUCCESS',
			TOGGLE_ACTIVE_FAIL: 'TOGGLE_ACTIVE_CONTACT_FAIL',
	},
	/* ********************************************************************* */
	TEAMS: {
		GET: 'GET_TEAMS',
			GET_SUCCESS: 'GET_TEAMS_SUCCESS',
			GET_FAIL: 'GET_TEAMS_FAIL',
	},
	TEAM: {
		GET: 'GET_TEAM',
			GET_SUCCESS: 'GET_TEAM_SUCCESS',
			GET_FAIL: 'GET_TEAM_FAIL',
		UPDATE: 'UPDATE_TEAM',
			UPDATE_SUCCESS: 'UPDATE_TEAM_SUCCESS',
			UPDATE_FAIL: 'UPDATE_TEAM_FAIL',
		CONNECT: 'CONNECT_TEAM',
			CONNECT_SUCCESS: 'CONNECT_TEAM_SUCCESS',
			CONNECT_FAIL: 'CONNECT_TEAM_FAIL',
		CONNECT_SEARCH: 'CONNECT_SEARCH_TEAM',
			CONNECT_SEARCH_SUCCESS: 'CONNECT_SEARCH_TEAM_SUCCESS',
			CONNECT_SEARCH_FAIL: 'CONNECT_SEARCH_TEAM_FAIL',
		LAST_MATCH_APEX: 'LAST_MATCH_APEX',
			LAST_MATCH_APEX_SUCCESS: 'LAST_MATCH_APEX_SUCCESS',
			LAST_MATCH_APEX_FAIL: 'LAST_MATCH_APEX_FAIL',
	},
	QUERY: {
		EXECUTE: 'EXEC_QUERY',
		EXECUTE_SUCCESS: 'EXEC_QUERY_SUCESS',
		EXECUTE_FAIL: 'EXEC_QUERY_FAIL',
	},
	/* ********************************************************************* */
	REPORTS: {
		GENERATE: 'GENERATE_REPORT',
			GENERATE_SUCCESS: 'GENERATE_REPORT_SUCCESS',
			GENERATE_FAIL: 'GENERATE_REPORT_FAIL',
		GET_DELIVERIES: 'GET_DELIVERIES',
			GET_DELIVERIES_SUCCESS: 'GET_DELIVERIES_SUCCESS',
			GET_DELIVERIES_FAIL: 'GET_DELIVERIES_FAIL',	
		REFRESH_STATS: 'REFRESH_STATS',
			REFRESH_STATS_SUCCESS: 'REFRESH_STATS_SUCCESS',
			REFRESH_STATS_FAIL: 'REFRESH_STATS_FAIL',

	},

}