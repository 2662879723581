import {Actions} from '../../constants';

const initialContactsState = {
    loading: false,
    items: [],
    total: 0,
    currentContact: null,
    segments: [],
    segmentsLoading: false,
    lists: [],
    listsLoading: false,
};


function contacts(state=initialContactsState, action) {
	switch(action.type) {
		case Actions.CONTACTS.GET:
			return {
				...state,
                loading: true,
                currentContact: null
			};

		case Actions.CONTACTS.GET_SUCCESS:
			return {
				...state,
                loading: false,
                items: action.payload.response.results,
                total: action.payload.response.total,
            }

        case Actions.CONTACTS.GET_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload.error
            }

        case Actions.CONTACT.GET:
			return {
				...state,
                loading: true
			};

		case Actions.CONTACT.GET_SUCCESS:
			return {
				...state,
                loading: false,
                currentContact: action.payload.response.result
            }

        case Actions.CONTACT.GET_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload.error
            }

        case Actions.CONTACT.CREATE:
			return {
				...state,
                loading: true
			};

		case Actions.CONTACT.CREATE_SUCCESS:
			return {
				...state,
                loading: false,
                currentContact: action.payload.response.result
            }

        case Actions.CONTACT.CREATE_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload.error
            }

        case Actions.CONTACT.UPDATE:
			return {
				...state,
                loading: true
			};

		case Actions.CONTACT.UPDATE_SUCCESS:
			return {
				...state,
                loading: false,
                currentContact: action.payload.response.result
            }

        case Actions.CONTACT.UPDATE_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload.error
            }

        case Actions.CONTACT.DELETE:
            return {
                ...state,
                loading: true
            };

        case Actions.CONTACT.DELETE_SUCCESS:
            return {
                ...state,
                loading: false,
                items: action.payload.response.results,
                total: action.payload.response.total,
            }

        case Actions.CONTACT.DELETE_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload.error
            }

        case Actions.CONTACTS.GET_SEGMENTS:
            return {
                ...state,
                segmentsLoading: true
            }

        case Actions.CONTACTS.GET_SEGMENTS_SUCCESS:
            return {
                ...state,
                segmentsLoading: false,
                segments: action.payload.response.results
            }

        case Actions.CONTACTS.GET_SEGMENTS_FAIL:
            return {
                ...state,
                segmentsLoading: false,
                error: action.payload.error
            }

            case Actions.CONTACTS.GET_LISTS:
                return {
                    ...state,
                    listsLoading: true
                }
    
            case Actions.CONTACTS.GET_LISTS_SUCCESS:
                return {
                    ...state,
                    listsLoading: false,
                    lists: action.payload.response.results
                }
    
            case Actions.CONTACTS.GET_LISTS_FAIL:
                return {
                    ...state,
                    listsLoading: false,
                    error: action.payload.error
                }

		default:
			return state;
	}
}

export default contacts;
