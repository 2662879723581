import {Actions} from '../../constants';

const initialReportState = {
    loading: false,
    deliveries: [],
    total: 0,  // total resultset size
    limit: 0,  // page size
    currentDelivery: null,
};

function reports(state=initialReportState, action) {
    switch(action.type) {
        case Actions.REPORTS.GET_DELIVERIES:
            return {
                ...state,
                loading: true
            };

        case Actions.REPORTS.GET_DELIVERIES_SUCCESS:
            return {
                ...state,
                loading: false,
                deliveries: action.payload.response.results,
                total: action.payload.response.total,
                limit: action.payload.response.limit,
            }

        case Actions.REPORTS.GET_DELIVERIES_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload.error
            }
            
        case Actions.REPORTS.REFRESH_STATS_SUCCESS: 
            return {
                ...state, 
            }
            
        default:
            return state;
    }
}

export default reports;
