import React from 'react';
import {Link} from 'react-router-dom';
import {connect} from 'react-redux'
import {
    fetchEvents,
    fetchEventTypes,
    createOrUpdateEvents} from '../../redux/actions/event';
import {fetchMatch} from '../../redux/actions/match';
import {isEmpty} from 'lodash/lang';
import {sortBy} from 'lodash/collection';
import {StatisticEventForm} from '../../components/statistic-event';
import {
    Header,
    Button,
    Segment
} from 'semantic-ui-react';


class EventForm extends React.Component {
    static emptyRecord = () => {
        return {
            id: null,
            period: null,
            game_time: null,
            type: 1,
            team: null,
            participant: null
        }
    }

    state = {
        events: null,
        home: null,
        away: null,
    }

    componentDidMount() {
        const {matchId} = this.props.match.params;
        const {providerId, sportId} = this.props.application;
        const {allEventTypes} = this.props.events;
        this.loadData(matchId);
        if (isEmpty(allEventTypes)) {
            fetchEventTypes(providerId, sportId)(this.props.dispatch);
        }
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        // console.log("NEXT PROPS", nextProps, this.props);
        // const nextMatchId = nextProps.match.params.matchId;
        // const thisMatchId = this.props.match.params.matchId;
        //console.log("============", nextMatchId, "--", nextMatchId !== thisMatchId, "--", thisMatchId, "=====================")
        // if (!isEmpty(nextProps.events.items) && !nextProps.events.loading) {
        //     console.log("LOADED", nextProps.events.items)
        //     this.setEventsFromProps(nextProps.events.items || []);
        // }
        // if (!isEmpty(nextProps.matches.currentMatch)) {
        //     this.setTeams(nextProps.matches.currentMatch);
        // }
        if (nextProps.match.params.matchId !== this.props.match.params.matchId) {
            this.loadData(nextProps.match.params.matchId);
        }
    }

    loadData = (matchId) => {
        const {providerId, sportId} = this.props.application;
        if (matchId) {
            this.setState({
                events: null,
                home: null,
                away: null,
            }, ()=> {
                fetchMatch(providerId, sportId, matchId)(this.props.dispatch)
                .then(()=>{
                    this.setTeams(this.props.matches.currentMatch);
                    fetchEvents(providerId, sportId, matchId)(this.props.dispatch)
                    .then(()=>{
                        this.setEventsFromProps(this.props.events.items || []);
                    })
                });
            });
        }
    }

    addRow = (ev) => {
        ev.preventDefault();
        const events = this.state.events || [];
        events.push(EventForm.emptyRecord());
        this.setState({
            events
        });
    }

    setEventsFromProps = (events) => {
        const items = events || this.props.events.items;
        const statEvents = sortBy(items, 'game_time').map((item, index) => {
            return {
                id: item.id,
                period: item.period,
                game_time: item.game_time,
                participant: item.participant && item.participant.id,
                team: item.team
            }
        })
        console.log("EVENTS", statEvents)
        this.setState({
            events: statEvents
        }, () => {
            console.log("STATE EVENTS NOW IS", this.state.events)
        })
    }

    setTeams = (match) => {
        this.setState({
            home: match.home,
            away: match.away,
        })
    }

    getHeader = () => {
        const {matchId} = this.props.match.params;
        const {matches} = this.props;
        if (matchId && matches && matches.currentMatch) {
            return (
                <Header as='h2'>
                    Statistical Events for {matches.currentMatch.name}
                    <Header.Subheader>
                        <Link to={`/match/${matchId}`}>
                            {this.props.matches.currentMatch.name}
                        </Link>
                    </Header.Subheader>
                </Header>
            )
        }
        return (
            <Header as='h2'>
                Statistical Events
            </Header>
        )
    }

    getEventTypeOptions = () => {
        const {events} = this.props;
        return events.allEventTypes && events.allEventTypes.map((etype, index) => {
            return {value: etype.id, text: etype.name, key: index}
        })
    }

    handleChange = (ev, {name, value}) => {
        const {events} = this.state;
        events[parseInt(name, 10)] = value;
        this.setState({
            events
        })
    }

    handlePeriodSelect = (ev, {name}) => {
        const {event} = this.state;
        if (event.period === parseInt(name, 10)) {
            event.period = null;
        } else {
            event.period = parseInt(name, 10);
        }
        this.setState({
            event
        })
    }

    handleTeamSelect = (ev, {name}) => {
        const {event} = this.state;
        if (event.team && event.team.id === this.state[name].id) {
            event.team = null;
        } else {
            event.team = this.state[name];
        }
        this.setState({
            event
        })
    }

    getTeamName = (teamLabel) => {
        if (this.state[teamLabel] && this.state[teamLabel].name) {
            return `[${this.state[teamLabel].name}]`;
        } else {
            return '';
        }
    }

    getSelectedTeamLabel = () => {
        const {event, home} = this.state;
        if (!event.team) {
            return null;
        }
        return event.team.id === home.id ? 'home' : 'away';
    }

    saveForm = (ev) => {
        ev.preventDefault();
        console.log(this.state.events);
        const {matchId} = this.props.match.params;
        const {providerId, sportId} = this.props.application;
        const {events} = this.state;
        createOrUpdateEvents(providerId, sportId, matchId, events)(this.props.dispatch).then((res) => {
            this.addRow(ev)
        });
    }

    render() {
        const eventTypes = this.getEventTypeOptions();
        const {currentMatch} = this.props.matches;
        const events = this.state.events || []
        const header = this.getHeader();
        console.log("EVENTS", events);
        return (
            <Segment basic raised>
                {header}
                {events.map((event, index) => {
                    console.log("STATFORM", event)
                    return (
                        <StatisticEventForm
                            key={index}
                            name={`${index}`}
                            event={event}
                            onChange={this.handleChange}
                            eventTypes={eventTypes}
                            match={currentMatch}
                        />
                    )
                })}
                <Segment clearing>
                    <Button
                        secondary
                        floated='left'
                        onClick={this.addRow}>Add Event</Button>
                    <Button
                        primary
                        floated='right'
                        onClick={this.saveForm}>Save</Button>
                </Segment>
            </Segment>
        )
    }
}

export default connect(state => {
    return {
        application: state.application,
        leagues: state.leagues,
        matches: state.matches,
        events: state.events
	}
})(EventForm);
