import React, {useEffect, useState} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import {useParams} from 'react-router-dom';
import {
    createContentGroup, 
    updateContentGroup,
    fetchContentCategories, 
    fetchContentGroup
} from '../../redux/actions/content';
import {LinkTargets} from '../../constants';
import {isEmpty} from 'lodash/lang';
import {find, keyBy} from 'lodash/collection';
import {
    ContentTypeSelector,
    ChannelSelector,
    LinkTargetSelector,
    MarketSelector,
    ContentCategorySelector,
} from '../../components/selectors';
import {
    Button,
    Segment,
    Form,
    Input,
    TextArea,
    Header,
    Loader
} from 'semantic-ui-react';

function mapContentGroup(groupItems) {
    const content = {};
    groupItems.forEach(item => {
        content[item.language] = item.content;
    })
    return content;
}

export default function GroupedContentForm({}) {
    const [contentType, setContentType] = useState('content');
    const [channelId, setChannelId] = useState(1);
    const [name, setName] = useState('');
    const [linkTarget, setLinkTarget] = useState('');
    const [markets, setMarkets] = useState([]);
    const [contentGroup, setContentGroup] = useState({});
    const [categoryIds, setCategoryIds] = useState([]);
    const {groupId} = useParams()
    const dispatch = useDispatch();
    const {
        currentProvider,
        providerMarkets,
        providerId, 
        sportId
    } = useSelector(state => state.application);
    const {contentCategories, currentContentGroup} = useSelector(state => state.content);
    
    useEffect(() => {
        if (isEmpty(contentCategories)) {
            fetchContentCategories(providerId, sportId)(dispatch);
        }
        if (groupId) {
            fetchContentGroup(providerId, sportId, groupId)(dispatch);
        }
    }, [])

    useEffect(() => {
        setContentGroup(mapContentGroup(currentContentGroup.content || []))
        setName(currentContentGroup.name);
        if (!isEmpty(currentContentGroup.content)) {
            const firstItem = currentContentGroup.content[0];
            setContentType(firstItem.content_type)
            setCategoryIds(firstItem.categories.map(cat => cat.id));
            setMarkets(firstItem.markets);
            setChannelId(firstItem.channel_id); 
            setLinkTarget(firstItem.link_target);
        }
    }, [currentContentGroup])

    const getMarketTargets = () => {
        if (isEmpty(markets)) {
            return null;
        } 
        const selectedMarket = find(providerMarkets, item => markets.indexOf(item.id) > -1);

        if (selectedMarket) {
            const marketKeys = Object.keys(selectedMarket.meta.thresholds)
            const options = LinkTargets.filter((item) => {
                return marketKeys.indexOf(item.value) > -1}
            )
            return options;
        }
        return LinkTargets;
    }
    
    return (
        <Segment>
            <Header as='h3'>{ groupId ? 'Edit' : false } Grouped Content</Header>
            {!currentProvider ? (
                <Loader active={true}/>
            ): (
                <Form>
                <Form.Field>
                    <Button floated='right' primary content="Submit" onClick={() => {
                        const contentData = {
                            name: name,
                            content_type: contentType,
                            channel_id: channelId,
                            content: contentGroup,
                            market_ids: markets,
                            link_target: linkTarget,
                            categories: categoryIds,
                        }
                        if (groupId) {
                            updateContentGroup(providerId, sportId, groupId, contentData)(dispatch);
                        } else {
                            createContentGroup(providerId, sportId, contentData)(dispatch);
                        }
                    }}/>
                </Form.Field> 
                <Form.Group>
                    <Form.Field>
                        <label>Group Name (optional)</label>
                        <Input
                            name='name'
                            onChange={(ev, {value}) => setName(value)}
                            value={name || ''}/>
                    </Form.Field>
                    <Form.Field>
                        <label>Content Type</label>
                        <ContentTypeSelector
                            name='contentType'
                            onChange={(ev, {value}) => setContentType(value)}
                            value={contentType}/>
                    </Form.Field>
                    <Form.Field>
                        <label>Delivery Channel</label>
                        <ChannelSelector
                            name='channelId'
                            onChange={(ev, {value}) => setChannelId(value)}
                            value={channelId}
                        />
                    </Form.Field>
                    <Form.Field>
                        <label>Category</label>
                        <ContentCategorySelector
                            name='categories'
                            value={categoryIds}
                            multiple={true}
                            onChange={(ev, {value}) => setCategoryIds(value)}
                            options={(contentCategories || []).map((category, index) => {
                                return {
                                    text: category.name,
                                    value: category.id,
                                    key: `cat-${index}`
                                }
                            })}
                        />
                    </Form.Field>
                    {['cta', 'content'].indexOf(contentType) > -1 ? (
                        <Form.Field>
                            <label>Link Target</label>
                            <LinkTargetSelector
                                name='link_target'
                                onChange={(ev, {value}) => setLinkTarget(value)}
                                value={linkTarget}
                                options={getMarketTargets()}
                            />
                        </Form.Field>
                    ) : false}

                </Form.Group>
                <Form.Field>
                <label>Markets</label>
                    <MarketSelector
                        name='markets'
                        value={markets || []}
                        onChange={(ev, {name, value}) => setMarkets(value)}
                        multiple={true}
                        options={providerMarkets.map((market, index) => {
                            return {
                                text: market.name,
                                value: market.id,
                                key: index
                            }
                        })}
                    />
                </Form.Field> 
                {currentProvider.meta.languages.map((language, index) => {
                    return (
                        <Form.Field key={index}>
                            <label>Content - {language}</label>
                            <TextArea
                                name={language}
                                rows={10}
                                value={contentGroup[language] || ''}
                                onChange={(ev, {name, value}) => {
                                    setContentGroup(previous => ({
                                        ...previous,
                                        [name]: value
                                    }));
                                }}/>
                        </Form.Field>
                    )
                })}
            </Form>
            )}
        </Segment>
    )
}

// class xGroupedContentForm extends React.Component {
//     state = {
//         contentType: 'content',
//         channelId: 1,
//         name: '',
//         contentGroup: {}
//     }

//     handleChange = (ev, {name, value}) => {
//         const {contentGroup} = this.state;
//         contentGroup[name] = value;
//         this.setState({
//             contentGroup
//         })
//     }

//     handlePropChange = (ev, {name, value}) => {
//         this.setState({
//             [name]: value
//         })
//     }

//     handleSubmit = () => {
//         const {providerId, sportId} = this.props.application;
//         const {contentType, channelId, name, contentGroup} = this.state;
//         createContentGroup(providerId, sportId, contentType, channelId, contentGroup, name)(this.props.dispatch);
//     }

//     getProviderGroupedContentForm = (provider) => {
//         const {meta} = provider;
//         const {languages} = meta;
//         const {contentGroup, channelId, name, contentType} = this.state;
//         return (
//             <Form>
//                 <Form.Field>
//                     <Button floated='right' primary onClick={this.handleSubmit} content="Submit"/>
//                 </Form.Field>
//                 <Form.Group>
//                     <Form.Field>
//                         <label>Group Name (optional)</label>
//                         <Input
//                             name='name'
//                             onChange={this.handlePropChange}
//                             value={name}/>
//                     </Form.Field>
//                     <Form.Field>
//                         <label>Content Type</label>
//                         <ContentTypeSelector
//                             name='contentType'
//                             onChange={this.handlePropChange}
//                             value={contentType}/>
//                     </Form.Field>
//                     <Form.Field>
//                         <label>Delivery Channel</label>
//                         <ChannelSelector
//                             name='channelId'
//                             onChange={this.handlePropChange}
//                             value={channelId}
//                         />
//                     </Form.Field>
//                 </Form.Group>
//                 {languages.map((language, index) => {
//                     return (
//                         <Form.Field key={index}>
//                             <label>Content - {language}</label>
//                             <TextArea
//                                 name={language}
//                                 rows={10}
//                                 value={contentGroup[language] || ''}
//                                 onChange={this.handleChange}/>
//                         </Form.Field>
//                     )
//                 })}
//             </Form>
//         )
//     }

//     getContent = () => {
//         const {currentProvider} = this.props.application;
//         console.log(currentProvider)
//         if (!currentProvider) {
//             return (<Loader active={true}/>)
//         } else {
//             return this.getProviderGroupedContentForm(currentProvider);
//         }
//     }

//     render() {
//         const content = this.getContent();
//         return (
//             <Segment>
//                 <Header as='h3'>Grouped Content</Header>
//                 {content}
//             </Segment>
//         )
//     }
// }

// export default connect(state => {
//     return {
//         application: state.application,
//         content: state.content
//     }
// })(GroupedContentForm);
