import React from 'react';
import {Label} from 'semantic-ui-react';
import {sortBy} from 'lodash/collection';


export function decimalOdd(odd)  {
    if (odd && !isNaN(odd)) {
        return odd / 1000;
    }
    return odd;
}

export default function({odds, outcomes, market}) {
    return (outcomes && sortBy(outcomes, ['label']).map((item, index) => {
        if (!item) {
            return false
        }

        if (item?.line && market?.line && item.line != market.line) {
            // console.warn(item?.line, market?.line, item, outcomes)
            return false
        }
        return (
            <Label
                basic
                size='mini'
                key={index}
                title={item.label}
            >{decimalOdd(item.odds)}</Label>
        )
    })) || []
}