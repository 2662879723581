import {Actions} from '../../constants';

const initialEventsState = {
    loading: false,
    items: [],
    total: 0,
    currentEvent: null,
    allEventTypes: [],
    eventTypes: {},  // cache holding event type index
};

function indexEventTypes(eventTypes) {
    const index = {};
    eventTypes.forEach((type) => {
        index[type.id] = type;
    })
    return index;
}


function events(state=initialEventsState, action) {
	switch(action.type) {
        case Actions.EVENTS.GET_TYPES:
            return {
                ...state,
                loading: true
            };

        case Actions.EVENTS.GET_TYPES_SUCCESS:
            return {
                ...state,
                loading: false,
                eventTypes: indexEventTypes(action.payload.response.results),
                allEventTypes: action.payload.response.results
            }

        case Actions.EVENTS.GET_TYPES_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload.error
            }

		case Actions.EVENTS.GET:
			return {
				...state,
                loading: true,
                items: [],
                total: 0
			};

		case Actions.EVENTS.GET_SUCCESS:
			return {
				...state,
                loading: false,
                items: action.payload.response.results,
                total: action.payload.response.total,
                eventTypes: indexEventTypes(action.payload.response.event_types)
            }

        case Actions.EVENTS.GET_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload.error
            }

        case Actions.EVENT.GET:
			return {
				...state,
                loading: true
			};

		case Actions.EVENT.GET_SUCCESS:
			return {
				...state,
                loading: false,
                currentEvent: action.payload.response.result
            }

        case Actions.EVENT.GET_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload.error
            }

        case Actions.EVENT.CREATE:
			return {
				...state,
                loading: true
			};

		case Actions.EVENT.CREATE_SUCCESS:
			return {
				...state,
                loading: false,
                currentEvent: action.payload.response.result
            }

        case Actions.EVENT.CREATE_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload.error
            }

        case Actions.EVENT.UPDATE:
			return {
				...state,
                loading: true
			};

		case Actions.EVENT.UPDATE_SUCCESS:
			return {
				...state,
                items: action.payload.response.results,
                total: action.payload.response.total,
                eventTypes: indexEventTypes(action.payload.response.event_types)
                // loading: false,
                // currentEvent: action.payload.response.result
            }

        case Actions.EVENT.UPDATE_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload.error
            }

        case Actions.EVENTS.DELETE:
            return {
                ...state,
                loading: true
            };

        case Actions.EVENTS.DELETE_SUCCESS:
            return {
                ...state,
                loading: false,
                items: action.payload.response.results,
                total: action.payload.response.total,
                eventTypes: indexEventTypes(action.payload.response.event_types)
            }

        case Actions.EVENTS.DELETE_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload.error
            }

		default:
			return state;
	}
}

export default events;
