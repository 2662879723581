import { SearchResults } from "semantic-ui-react";
import {
    POSITIVE,
    NEGATIVE,
    NEUTRAL,
    EMPTY
} from "./constants";


function evalFocus(isPositive, threshold, focus) {
    if (threshold === 'draw' && isPositive) {
        return NEUTRAL;
    } else if (isPositive && threshold === focus) {
        return POSITIVE;
    } else if (!isPositive && threshold === focus) {
        return NEGATIVE;
    } else {
        return EMPTY;
    }
}

const debugSummary = (result, focus) => {
    console.log("SUMMARY ->>> ", result, focus)
};

const teamToWinSummary = (result, focusOn) => {
    const totalWins = result.win_away + result.win_home;
    const total = result.total;
    console.log("SUMMARY", focusOn, result);
    const sideTotals = focusOn ? [total, total] : [result.total_home, result.total_away];
    const summary = {
        total: total,
        total_wins: totalWins,
        total_ratio:  (totalWins / total * 100).toFixed(),
        home_total: result.win_home,
        away_total: result.win_away,
        draw_total: result.draw,
        home_ratio: Math.round(result.win_home / sideTotals[0] * 100).toFixed(),
        away_ratio: Math.round(result.win_away / sideTotals[1] * 100).toFixed(),
        draw_ratio: Math.round(result.draw / total * 100).toFixed(),
        home_neg_ratio: Math.round(result.lose_home / sideTotals[0] * 100).toFixed(),
        away_neg_ratio: Math.round(result.lose_away / sideTotals[1] * 100).toFixed(),
        home_neg_total: result.lose_home,
        away_neg_total: result.lose_away,
    };
    return summary
}

const homeAwaySummary = (result) => {
    const totalWins = result.win_away + result.win_home;
    const total = result.total;
    const summary = {
        total: total,
        home_total: result.home,
        away_total: result.away,
        home_ratio: Math.round(result.home / total * 100).toFixed(),
        away_ratio: Math.round(result.away / total * 100).toFixed(),
    };
    return summary
}

const winBothHalvesSummary = (result) => {
    const total = result.total;
    const summary = {
        total: total,
        home_total: result.win_both_at_home,
        away_total: result.win_both_at_away,
        home_ratio: Math.round(result.win_both_at_home / total * 100).toFixed(),
        away_ratio: Math.round(result.win_both_at_away / total * 100).toFixed(),
    }
    return summary
}

const winToNilSummary = (result) => {
    const total = result.total;
    const summary = {
        total: total,
        home_total: result.win_to_nil_at_home,
        away_total: result.win_to_nil_at_away,
        home_ratio: Math.round(result.win_to_nil_at_home / total * 100).toFixed(),
        away_ratio: Math.round(result.win_to_nil_at_away / total * 100).toFixed(),
    }
    return summary
}

const overUnderSummary = (result) => {
    const total = result.total;
    const summary = {
        total: total,
        over_total: result.over,
        under_total: result.under,
        over_ratio: (result.over / total * 100).toFixed(),
        under_ratio: (result.under / total * 100).toFixed(),
    }
    return summary
}

const doubleChanceSummary = (result) => {
    const total = result.total;
    const summary = {
        total: total,
        home_draw_total: result.home_draw,
        home_away_total: result.home_away,
        draw_away_total: result.draw_away,
        home_draw_ratio: Math.round(result.home_draw / total * 100).toFixed(),
        home_away_ratio: Math.round(result.home_away / total * 100).toFixed(),
        draw_away_ratio: Math.round(result.draw_away / total * 100).toFixed(),
    }
    return summary
}

const yesNoSummary = (result) => {
    const total = result.total;
    const summary = {
        total: total,
        yes_total: result.yes,
        no_total: result.no,
        yes_ratio: Math.round(result.yes / total * 100).toFixed(),
        no_ratio: Math.round(result.no / total * 100).toFixed(),
    }
    console.log("RESULT", result, summary)
    return summary
}

const halfTimeFullTimeSummary = (result, focus) => {
    const tags = {
        draw_draw_total: result.draw_first - result.draw_match,
        draw_draw_ratio: Math.round((result.draw_first - result.draw_match) / result.total * 100).toFixed(),
    }
    if (!focus) {
        tags['home_home_total'] = result.win_first_and_match_at_home
        tags['away_away_total'] = result.win_first_and_match_at_away
        tags['draw_home_total'] = result.draw_first_and_win_at_home
        tags['draw_away_total'] = result.draw_first_and_win_at_away
        tags['home_draw_total'] = result.win_first_and_draw_match_at_home
        tags['away_draw_total'] = result.win_first_and_draw_match_at_away
        tags['home_away_total'] = result.win_first_and_lose_match_at_home
        tags['away_home_total'] = result.win_first_and_lose_match_at_away
        tags['home_home_ratio'] = Math.round(result.win_first_and_match_at_home / result.total * 100).toFixed()
        tags['away_away_ratio'] = Math.round(result.win_first_and_match_at_away / result.total * 100).toFixed()
        tags['draw_home_ratio'] = Math.round(result.draw_first_and_win_at_home / result.total * 100).toFixed()
        tags['draw_away_ratio'] = Math.round(result.draw_first_and_win_at_away / result.total * 100).toFixed()
        tags['home_draw_ratio'] = Math.round(result.win_first_and_draw_match_at_home / result.total * 100).toFixed()
        tags['away_draw_ratio'] = Math.round(result.win_first_and_draw_match_at_away / result.total * 100).toFixed()
        tags['home_away_ratio'] = Math.round(result.win_first_and_lose_match_at_home / result.total * 100).toFixed()
        tags['away_home_ratio'] = Math.round(result.win_first_and_lose_match_at_away / result.total * 100).toFixed()

    } else {
        const otherSide = focus === 'home' ? 'away': 'home';
        tags[`${focus}_${focus}_total`] = result[`win_first_and_match_at_${focus}`]
        tags[`${otherSide}_${otherSide}_total`] = result[`win_first_and_match_at_${otherSide}`]
        tags[`draw_${focus}_total`] = result[`draw_first_and_win_at_${focus}`]
        tags[`${focus}_draw_total`] = result[`win_first_and_draw_match_at_${focus}`]
        tags[`${focus}_${otherSide}`] = result[`win_first_and_lose_match_at_${focus}`]

        tags[`${focus}_${focus}_ratio`] = Math.round(result[`win_first_and_match_at_${focus}`] / result.total * 100).toFixed()
        tags[`${otherSide}_${otherSide}_ratio`] = Math.round(result[`win_first_and_match_at_${otherSide}`] / result.total * 100).toFixed()
        tags[`draw_${focus}_ratio`] = Math.round(result[`draw_first_and_win_at_${focus}`] / result.total * 100).toFixed()
        tags[`${focus}_draw_ratio`] = Math.round(result[`win_first_and_draw_match_at_${focus}`] / result.total * 100).toFixed()
        tags[`${focus}_${otherSide}_ratio`] = Math.round(result[`win_first_and_lose_match_at_${focus}`] / result.total * 100).toFixed()
    }
    return tags;
}

const doubleChanceOverUnderSummary = (result, focus) => {
    const tags = {};
    console.log('dcou summa', focus, result )
    const basetags = [
        'home_draw', 'home_away', 'draw_away'
    ]
    basetags.forEach(basetag => {
        tags[`${basetag}_over_total`] = result[`${basetag}_over`]
        tags[`${basetag}_under_total`] = result[`${basetag}_under`]
        tags[`${basetag}_over_ratio`] = Math.round(result[`${basetag}_over`] / result.total * 100).toFixed()
        tags[`${basetag}_under_ratio`] = Math.round(result[`${basetag}_under`] / result.total * 100).toFixed()
    })

    // tags.home_draw_over_total = result.home_draw_over;
    // tags.home_draw_over_ratio = Math.round(result.home_draw_over / result.total * 100).toFixed();
    // home_away_over:
    // draw_away_over:
    // home_draw_under:
    // home_away_under:
    // draw_away_under:
    return tags;
}

const totalGoalsSummary = (result, focus) => {
    const tags = {};
    const basetags = ['0_1', '2_3', '4_5', '6_']
    basetags.forEach(basetag => {
        const prefix = basetag === '6_' ? '6' : basetag;
        const value = result[basetag] || 0;
        tags[`${prefix}_total`] = value;
        tags[`${prefix}_ratio`] = Math.round(value / result.total * 100).toFixed();
    })
    return tags
}

const teamToWinOverUnderSummary = (result, focus) => {
    const tags = {
        home_over_total: result.wins_over_home,
        home_over_ratio: Math.round(result.wins_over_home / result.total_home * 100).toFixed(),
        away_over_total: result.wins_over_away,
        away_over_ratio: Math.round(result.wins_over_away / result.total_away * 100).toFixed(),
        draw_over_total: result.draws_over,
        draw_over_ratio: Math.round(result.draws_over / result.total * 100).toFixed(),
        home_under_total: result.wins_under_home,
        home_under_ratio: Math.round(result.wins_under_home / result.total_home * 100).toFixed(),
        away_under_total: result.wins_under_away,
        away_under_ratio: Math.round(result.wins_under_away / result.total_away * 100).toFixed(),
        draw_under_total: result.draws_under,
        draw_under_ratio: Math.round(result.draws_under / result.total * 100).toFixed(),
    };
    // const basetags = ['home', 'away', 'draw']
    // basetags.forEach(basetag => {
    //     const valueOver = result[`${basetag}_over`] || 0;
    //     const valueUnder = result[`${basetag}_under`] || 0;
    //     tags[`${basetag}_over_total`] = valueOver;
    //     tags[`${basetag}_under_total`] = valueUnder;
    //     tags[`${basetag}_over_ratio`] = Math.round(valueOver / result.total * 100).toFixed();
    //     tags[`${basetag}_under_ratio`] = Math.round(valueUnder / result.total * 100).toFixed();
    // })
    console.log("SUMMARY -> ", tags)
    return tags
}

const teamToWinBool = (result, focus) => {
    if (result.total === 0) {
        return {
            home: EMPTY,
            away: EMPTY,
            draw: EMPTY
        }
    }
    let [home, away] = [...EMPTY, EMPTY]
    const draw = evalFocus(result.draw === 1, 'draw');
    if (draw !== NEUTRAL) {
        home = evalFocus(result.win_home === 1, 'home', focus);
        away = evalFocus(result.win_away === 1, 'away', focus);
        if (home === EMPTY) {
            home = evalFocus(result.lose_home === 1, 'home', focus);
        }
    }
    return {
        home: {value: result.win_home === 1, state: home},
        away: {value: result.win_away === 1, state: away},
        draw: {value: result.draw === 1, state: draw}
    }
}


const homeAwayBool = (result, focus) => {
    if (result.total === 0) {
        return {
            home: EMPTY,
            away: EMPTY
        }
    }
    let [home, away] = [...EMPTY, EMPTY]
    return {
        home: {value: result.home === 1, state: home},
        away: {value: result.away === 1, state: away}
    }
}


const winBothHalvesBool = (result, focus) => {
    return {
        home: {value: focus === 'home' && result, state: result === 1 && focus === 'home' ? POSITIVE : EMPTY},
        away: {value: focus === 'away' && result, state: result === 1 && focus === 'away' ? POSITIVE : EMPTY}
    }
}


const winToNilBool = (result, focus) => {
    return {
        home: {value: focus === 'home' && result, state: result === 1 && focus === 'home' ? POSITIVE : EMPTY},
        away: {value: focus === 'away' && result, state: result === 1 && focus === 'away' ? POSITIVE : EMPTY}
    }
}


const overUnderBool = (result) => {
    const yes = result === 1;
    const no = result === 0;
    return {
        over: {value: yes, state: yes && POSITIVE},
        under: {value: no, state: no && NEGATIVE}
    }
}

const doubleChanceBool = (result) => {
    const hd = result.home_draw === 1;
    const ha = result.home_away === 1;
    const da = result.draw_away === 1;
    const draw = result.draw === 1;
    const home = result.home === 1;
    const away = result.away === 1;
    const win = result.win === 1;
    const lost = result.lost === 1;
    return {
        home_draw: {value: hd, state: hd && (draw ? NEUTRAL: home && win ? POSITIVE : EMPTY)},
        home_away: {value: ha, state: ha && (win && (home || away) ? POSITIVE : NEGATIVE)},
        draw_away: {value: da, state: da && (draw ? NEUTRAL : away ? NEGATIVE : EMPTY)}
    }
}

const yesNoBool = (result) => {
    const yes = result > 0;
    const no = result === 0;
    return {
        yes: {value: yes, state: yes && POSITIVE},
        no: {value: no, state: no && NEGATIVE}
    }
}

const firstToScoreBool = (result, focus) => {
    console.warn("---", result.first, result.home, result.away, focus)
    if (result.total === 0) {
        return {
            home: EMPTY,
            away: EMPTY,
            draw: EMPTY
        }
    }
    let [home, away] = [...EMPTY, EMPTY]
    const draw = evalFocus(result.draw === 1, 'draw');
    if (draw !== NEUTRAL) {
        home = evalFocus(result.first === 1, 'home', focus || 'home');
        away = evalFocus(result.first === 1, 'away', focus || 'away');
    }
    return {
        home: {value: result.home === 1, state: home},
        away: {value: result.away === 1, state: away},
        draw: {value: result.draw === 1, state: draw}
    }
}

const halfTimeFullTimeBool = (result, focus, mid) => {
    const otherSide = focus === 'away' ? 'home' : 'away';
    const tags = {
        'away_away': {value: false, state: EMPTY},
        'away_draw': {value: false, state: EMPTY},
        'home_draw': {value: false, state: EMPTY},
        'draw_home': {value: false, state: EMPTY},
        'draw_draw': {value: false, state: EMPTY},
        'home_away': {value: false, state: EMPTY},
        'away_home': {value: false, state: EMPTY},
        'draw_away': {value: false, state: EMPTY},
        'home_home': {value: false, state: EMPTY}
    }
    if (result['draw_first'] === 1 && result['draw_match'] === 1) {
        tags['draw_draw'] = {value: true, state: POSITIVE};
    } else if (result['draw_first'] === 1 && result['win_match'] === 1) {
        tags[`draw_${focus}`] = {value: true, state: POSITIVE};
    } else if (result['win_first'] === 1 && result['win_match'] === 1) {
        tags[`${focus}_${focus}`] = {value: true, state: POSITIVE};
    } else if (result['win_first'] === 1 && result['win_match'] === 0) {
        tags[`${focus}_${otherSide}`] = {value: true, state: POSITIVE};
    } else if (result['win_first'] === 0 && result['win_match'] === 1) {
        tags[`${otherSide}_${focus}`] = {value: true, state: POSITIVE};
    } else if (result['lose_first'] === 1 && result['lose_match'] === 1) {
        tags[`${otherSide}_${otherSide}`] = {value: false, state: NEGATIVE};
    } else if (result['draw_first'] === 1 && result['lose_match'] === 1) {
        tags[`draw_${otherSide}`] = {value: false, state: NEGATIVE};
    } else if (result['win_first'] === 0 && result['draw_match'] === 1) {
        tags[`${otherSide}_draw`] = {value: false, state: POSITIVE};
    } else if (result['win_first'] === 1 && result['draw_match'] === 1) {
        tags[`${focus}_draw`] = {value: false, state: POSITIVE};
    }
    // console.warn(mid, focus, result, tags)
    return tags;
}

const doubleChanceOverUnderBool = (result, focus) => {
    const tags = {
        home_draw_over: {value: false, state: EMPTY},
        home_away_over: {value: false, state: EMPTY},
        draw_away_over: {value: false, state: EMPTY},
        home_draw_under: {value: false, state: EMPTY},
        home_away_under: {value: false, state: EMPTY},
        draw_away_under: {value: false, state: EMPTY},
    };
    Object.keys(tags).forEach(item => {
        if (result[item] === 1) {
            tags[item].value = true;
            tags[item].state = POSITIVE;
        }
    })
    console.log('dcou bool', focus, result )
    return tags;
}

const totalGoalsBool = (result, focus) => {
    const tags = {
        '0_1': {value: false, state: EMPTY},
        '2_3': {value: false, state: EMPTY},
        '4_5': {value: false, state: EMPTY},
        '6_': {value: false, state: EMPTY},
    }
    Object.keys(tags).forEach(item => {
        if (result[item] === 1) {
            tags[item].value = true;
            tags[item].state = POSITIVE;
        }
    })
    return tags;
}

const teamToWinOverUnderBool = (result, focus) => {
    const tags = {
        'draw_over': {value: result[`draw_over_${focus}`] === 1, state: EMPTY},
        'home_over': {value: result.wins_over_home === 1 && focus === 'home', state: EMPTY},
        'away_over': {value: result.wins_over_away === 1 && focus === 'away', state: EMPTY},
        'draw_under': {value: result[`draw_under_${focus}`] === 1, state: EMPTY},
        'home_under': {value: result.wins_under_home === 1 && focus === 'home', state: EMPTY},
        'away_under': {value: result.wins_under_away === 1 && focus === 'away', state: EMPTY},
    }
    const BreakException = {}
    try {
        if (result.wins_over > 0 || result.loses_over > 0 || result.draws_over > 0) {
            Object.keys(tags).filter(key => key.indexOf('over') > -1).forEach(key => {
                const [side, ou] = key.split('_');
                tags[key].state = evalFocus(tags[key].value, side, focus)
                console.log(key, tags[key])
                if (tags[key].state !== EMPTY) {
                    throw BreakException;
                }
            })
        } else {
            Object.keys(tags).filter(key => key.indexOf('under') > -1).forEach(key => {
                const [side, ou] = key.split('_');
                tags[key].state = evalFocus(tags[key].value, side, focus)
                console.log(key, tags[key])
                if (tags[key].state !== EMPTY) {
                    throw BreakException;
                }
            })
        }
    } catch (e) {
        if (e != BreakException) {
            throw e;
        }
    }
    return tags;
}


export const marketQueryConfig = {
    1: {
        bool: teamToWinBool,
        summary: teamToWinSummary
    },
    7: {
        bool: teamToWinBool,
        summary: teamToWinSummary
    },
    15: {
        bool: teamToWinBool,
        summary: teamToWinSummary
    },
    // First to score
    12: {
        bool: firstToScoreBool,
        summary: teamToWinSummary
    },
    // Over under
    2: {
        bool: overUnderBool,
        summary: overUnderSummary
    },
    8: {
        bool: overUnderBool,
        summary: overUnderSummary
    },
    22: {
        bool: overUnderBool,
        summary: overUnderSummary
    },
    23: {
        bool: overUnderBool,
        summary: overUnderSummary
    },
    24: {
        bool: overUnderBool,
        summary: overUnderSummary
    },
    25: {
        bool: overUnderBool,
        summary: overUnderSummary
    },
    26: {
        bool: overUnderBool,
        summary: overUnderSummary
    },

    // Both teams to score
    10: {
        bool: yesNoBool,
        summary: yesNoSummary,
    },
    11: {
        bool: yesNoBool,
        summary: yesNoSummary,
    },

    // Double Chance
    3: {
        bool: doubleChanceBool,
        summary: doubleChanceSummary
    },

    // Double Chance over under
    5: {
        bool: doubleChanceOverUnderBool,
        summary: doubleChanceOverUnderSummary
    },

    // win both halves
    13: {
        bool: winBothHalvesBool,
        summary: winBothHalvesSummary
    },

    // Win to nil
    14: {
        bool: winToNilBool,
        summary: winToNilSummary
    },

    // Half time / Full time
    27: {
        bool: halfTimeFullTimeBool,
        summary: halfTimeFullTimeSummary
    },

    // total goals
    34: {
        bool: totalGoalsBool,
        summary: totalGoalsSummary
    },

    // team to win and over under
    35: {
        bool: teamToWinOverUnderBool,
        summary: teamToWinOverUnderSummary
    }
}