import {Actions} from '../../constants';
import {createAction, createTransition, createReceiver} from './utils';
import {Service} from '../../service';


export function fetchContacts(providerId, sportId, start=0, sort='') {
	return function(dispatch) {
		dispatch(createAction(Actions.CONTACTS.GET));
		const params = {
			start,
			sort,
        };
        const url = 'contacts';
		return Service.doGet({
			url: url,
			params: params,
			urlParams: {
				providerId,
                sportId
			}
		}).then(
			(response) => dispatch(createReceiver('CONTACTS', response, params))
		).catch(
			(response) => dispatch(createAction(Actions.CONTACTS.GET_FAIL, null, response)) );
	}
}

export function fetchContact(providerId, sportId, contactId=null) {
	return function(dispatch) {
		dispatch(createAction(Actions.CONTACT.GET));
		return Service.doGet({
			url: 'contact',
			urlParams: {
				providerId,
                sportId,
                contactId,
			}
		}).then(
			(response) => dispatch(createAction(Actions.CONTACT.GET_SUCCESS, response, null))
		).catch(
			(response) => dispatch(createAction(Actions.CONTACT.GET_FAIL, null, response)) );
	}
}

export function createContact(providerId, sportId, contactData) {
	return function(dispatch) {
		dispatch(createAction(Actions.CONTACT.CREATE));
		return Service.doPost({
			url: 'contactCreate',
            params: contactData,
            urlParams: {
				providerId,
				sportId,
			}
		})
		.then( (response) => dispatch(createAction(Actions.CONTACT.CREATE_SUCCESS, response, null, createTransition('/contacts'))) )
		.catch( (response) => dispatch(createAction(Actions.CONTACT.CREATE_FAIL, contactData, response)) );
	}
}

export function updateContact(providerId, sportId, contactId, contactData) {
	return function(dispatch) {
		dispatch(createAction(Actions.CONTACT.UPDATE));
		return Service.doPost({
			url: 'contact',
            params: contactData,
            urlParams: {
				providerId,
				sportId,
				contactId
			}
		})
		.then( (response) => dispatch(createAction(Actions.CONTACT.UPDATE_SUCCESS, response, null, createTransition('/contacts'))) )
		.catch( (response) => dispatch(createAction(Actions.CONTACT.UPDATE_FAIL, contactData, response)) );
	}
}

export function fetchSegments(providerId, contactId='') {
	return function(dispatch) {
		dispatch(createAction(Actions.CONTACTS.GET_SEGMENTS));
		return Service.doGet({
			url: 'segments',
			urlParams: {
				providerId,
				contactId,
			}
		}).then(
			(response) => dispatch(createAction(Actions.CONTACTS.GET_SEGMENTS_SUCCESS, response, null))
		).catch(
			(response) => dispatch(createAction(Actions.CONTACTS.GET_SEGMENTS_FAIL, null, response)) );
	}
}

export function fetchLists(providerId) {
	return function(dispatch) {
		dispatch(createAction(Actions.CONTACTS.GET_LISTS));
		return Service.doGet({
			url: 'lists',
			urlParams: {
				providerId 
			}
		}).then(
			(response) => dispatch(createAction(Actions.CONTACTS.GET_LISTS_SUCCESS, response, null))
		).catch(
			(response) => dispatch(createAction(Actions.CONTACTS.GET_LISTS_FAIL, null, response)) );
	}
}

export function deleteContact(providerId, sportId, contactId) {
	return function(dispatch) {
		dispatch(createAction(Actions.CONTACT.DELETE));
		return Service.doDelete({
			url: 'contact',
			urlParams: {
				providerId,
				sportId,
				contactId
			}
		}).then(
			(response) => dispatch(createAction(Actions.CONTACT.DELETE_SUCCESS, response, null))
		).catch(
			(response) => dispatch(createAction(Actions.CONTACT.DELETE_FAIL, null, response)) );
	}
}


export function contactToggleActiveState(providerId, sportId, contactId) {
	return function(dispatch) {
		dispatch(createAction(Actions.CONTACT.TOGGLE_ACTIVE));
		return Service.doPost({
			url: 'contactToggle',
            urlParams: {
				providerId,
				sportId,
				contactId
			}
		})
		.then( (response) => dispatch(createAction(Actions.CONTACT.TOGGLE_ACTIVE_SUCCESS, response, null)) )
		.catch( (response) => dispatch(createAction(Actions.CONTACT.TOGGLE_ACTIVE_FAIL, null, response)) );
	}
}
