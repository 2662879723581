import React from 'react';
import {connect} from 'react-redux'
import {Constants} from '../../constants';
import {sanitiseFormInput} from '../../utils';
import {
    fetchContact,
    updateContact,
    createContact,
    fetchLists
} from '../../redux/actions/contact';
import {
    ChannelSelector,
    LanguagesSelector,
    SportSelector,
    LeagueSelector,
    TeamSelector,
    SegmentSelector,
} from '../../components/selectors';
import {isEmpty} from 'lodash/lang';
import { range } from 'lodash';
import {
    Header,
    Button,
    Form,
    Segment,
    TextArea,
    List,
} from 'semantic-ui-react';


class ContactForm extends React.Component {
    static emptyRecord = () => {
        return {
            id: null,
            name: '',
            languages: ['en'],
            sport_id: 1,
            channel_id: 1,
            rate_limited: false,
            active: true,
            sub_all: false,
            email: '',
            twitter: '',
            device_id: '',
            segment: '',
            list_id: '',
            mobile: '',
            rate_limit_minutes: '',
            limiter: {
                max_market_match_push: '',
                max_match_push: '',
            }
        }
    }

    state = {
        contact: this.props.contacts.currentContact || ContactForm.emptyRecord(),
    }

    static getDerivedStateFromProps(props, state) {
        const {contacts} = props;
        if (contacts && !isEmpty(contacts.currentContact) && state.contact.id !== contacts.currentContact.id) {
            return {
                contact: props.contacts.currentContact
            }
        }
        return null;
    }

    componentDidMount() {
        const {match, dispatch} = this.props;
        const {contactId} = match.params;
        const {providerId, sportId} = this.props.application;
        if (contactId) {
            fetchContact(providerId, sportId, contactId)(dispatch);
        }
        fetchLists(providerId)(dispatch);
    }

    getHeader = () => {
        const {match} = this.props;
        const {contactId} = match.params;
        const {contacts} = this.props;
        if (contactId && contacts && contacts.currentContact) {
            return (
                <Header as='h2'>
                    Edit Contact
                    <Header.Subheader>
                        {contacts.currentContact.name}
                    </Header.Subheader>
                </Header>
            )
        }
        return (
            <Header as='h2'>
                Create Contact
            </Header>
        )
    }

    handleChange = (ev, {name, value, checked, type}) => {
        const {contact} = this.state;
        const nameParts = name.split('.');
        if (nameParts.length === 2) {
            contact[nameParts[0]][nameParts[1]] = value;
        } else {
            contact[name] = type === 'checkbox' ? checked : value;
        }
        this.setState({
            contact
        })
    }

    saveForm = () => {
        const {match} = this.props;
        const {contactId} = match.params;
        const {providerId, sportId} = this.props.application;
        const {contact} = this.state;
        if (contactId) {
            updateContact(providerId, sportId, contactId, contact)(this.props.dispatch);
        } else {
            createContact(providerId, sportId, contact)(this.props.dispatch);
        }
    }

    render() {
        const {application} = this.props;
        const {lists} = this.props.contacts;
        const providerLanguages = application.currentProvider && application.currentProvider.meta.languages;
        const selectedSport = application.sportIndex[application.sportId];
        const periods = range(1, selectedSport.periods + 1); // [1, 2]; // TODO: Take from sport
        const contact = sanitiseFormInput(this.state.contact, ['languages']);
        const channelId = contact.channel_id || 1;
        return (
            <Segment basic raised>
                {this.getHeader()}
                <Form>
                    <Form.Group>
                        <Form.Field>
                            <label>Channel</label>
                            <ChannelSelector
                                name='channel_id'
                                onChange={this.handleChange}
                                value={channelId}
                            />
                        </Form.Field>
                        <Form.Field>
                            <label>Sport</label>
                            <SportSelector
                                name='sport_id'
                                value={contact.sport_id}
                                onChange={this.handleChange}
                            />
                        </Form.Field>
                        <Form.Field>
                            <label>Name</label>
                            <Form.Input
                                name='name'
                                value={contact.name}
                                onChange={this.handleChange}
                            />
                        </Form.Field>
                    </Form.Group>
                    <Form.Group>
                        {Constants.CHANNELS[channelId].code === 'EMAIL' ? (
                            <Form.Field>
                                <label>Email</label>
                                <Form.Input
                                    name='email'
                                    value={contact.email || ''}
                                    onChange={this.handleChange}
                                />
                            </Form.Field>
                        ) : false}
                        {Constants.CHANNELS[channelId].code === 'TWITTER' ? (
                            <Form.Field>
                                <label>Twitter</label>
                                <Form.Input
                                    name='twitter'
                                    value={contact.twitter || ''}
                                    onChange={this.handleChange}
                                />
                            </Form.Field>
                        ) : false}
                        {/* <Form.Field>
                            <label>Mobile number</label>
                            <Form.Input
                                name='mobile'
                                value={contact.mobile}
                                onChange={this.handleChange}
                            />
                        </Form.Field> */}
                    </Form.Group>
                    {Constants.CHANNELS[channelId].code === 'PUSH' || Constants.CHANNELS[channelId].code == 'WHATSAPP' || Constants.CHANNELS[channelId].code == 'TELEGRAM' ? (
                        <Form.Group>
                            <Form.Field>
                                <label>Device Id</label>
                                <Form.Input
                                    name='device_id'
                                    value={contact.device_id}
                                    onChange={this.handleChange}
                                />
                            </Form.Field>
                            <Form.Field>
                                <label>Segment</label>
                                <SegmentSelector
                                    name='segment'
                                    value={contact.segment}
                                    onChange={this.handleChange}
                                    contactId={contact.id}
                                />
                                {/*<Form.Input
                                    name='segment'
                                    value={contact.segment}
                                    onChange={this.handleChange}
                                />*/}
                            </Form.Field>
                            <Form.Field>
                                <label>List Id</label>
                                <Form.Input
                                    name='list_id'
                                    value={contact.list_id}
                                    onChange={this.handleChange}
                                />
                            </Form.Field>
                        </Form.Group>
                    ) : false}
                    <Form.Group widths={"equal"}>
                        <Form.Field>
                            <label>Raw Filter</label>
                            <TextArea 
                                name="raw_filter"
                                value={contact.raw_filter}
                                onChange={this.handleChange}
                            />
                        </Form.Field>
                        <Form.Field>
                            <div style={{overFlow: "auto", height: "100%"}}>
                            <List>
                                <List.Content>
                                    {lists.map((list, index) => {
                                        return (
                                            <List.Item key={`i-${index}`}>{list.id} : {list.name}</List.Item>
                                        )
                                    })}
                                </List.Content>
                            </List>
                            </div>
                        </Form.Field>
                    </Form.Group>
                    <Form.Group>
                        <Form.Field>
                            <label>Languages</label>
                            <LanguagesSelector
                                name='languages'
                                languages={providerLanguages || []}
                                value={contact.languages}
                                onChange={this.handleChange}
                            />
                        </Form.Field>
                    </Form.Group>
                    <Form.Group>
                        <Form.Field>
                            <label>Active</label>
                            <Form.Checkbox
                                toggle
                                name='active'
                                label='Contact Active'
                                checked={!!contact.active}
                                onChange={this.handleChange}
                            />
                        </Form.Field>
                        <Form.Field>
                            <label>Subscribe to All</label>
                            <Form.Checkbox
                                toggle
                                name='sub_all'
                                label='Subscribe contact to all matches and leagues'
                                checked={!!contact.sub_all}
                                onChange={this.handleChange}
                            />
                        </Form.Field>
                        <Form.Field>
                            <label>Rate Limited</label>
                            <Form.Checkbox
                                toggle
                                name='rate_limited'
                                label='Rate limiter on'
                                checked={!!contact.rate_limited}
                                onChange={this.handleChange}
                            />
                        </Form.Field>

                    </Form.Group>
                    {contact.rate_limited ? (
                        <Form.Group>
                            <Form.Field>
                                <label>Rate Limiter Minutes</label>
                                <Form.Input
                                    type='number'
                                    name='rate_limit_minutes'
                                    value={contact.rate_limit_minutes || ''}
                                    onChange={this.handleChange}
                                />
                            </Form.Field>
                            <Form.Field>
                                <label>Max Pushes per Market per Match</label>
                                <Form.Input
                                    type='number'
                                    name='limiter.max_market_match_push'
                                    value={contact.limiter.max_market_match_push || ''}
                                    onChange={this.handleChange}
                                />
                            </Form.Field>
                            <Form.Field>
                                <label>Max Pushes per Match (All Markets)</label>
                                <Form.Input
                                    type='number'
                                    name='limiter.max_match_push'
                                    value={contact.limiter.max_match_push || ''}
                                    onChange={this.handleChange}
                                />
                            </Form.Field>
                        </Form.Group>
                    ) : false}
                    {contact.rate_limited && periods.map((period, index) => {
                        return (
                            <Form.Group key={index}>
                                <Form.Field>
                                    <label>Max per Market per Match Period {period}</label>
                                    <Form.Input
                                        type='number'
                                        name={`limiter.market_push_p${period}`}
                                        value={contact.limiter['market_push_p'+period] || ''}
                                        onChange={this.handleChange}
                                    />
                                </Form.Field>
                                <Form.Field>
                                    <label>Max per Match Period {period} (All Markets)</label>
                                    <Form.Input
                                        type='number'
                                        name={`limiter.period_push_p${period}`}
                                        value={contact.limiter['period_push_p'+period] || ''}
                                        onChange={this.handleChange}
                                    />
                                </Form.Field>
                            </Form.Group>
                        )
                    })}
                    <Form.Field>
                        <label>Leagues</label>
                        <LeagueSelector
                            name='league_id'
                        />
                    </Form.Field>
                    <Form.Field>
                        <label>Teams</label>
                        <TeamSelector
                            name='team_id'
                        />
                    </Form.Field>
                </Form>
                <Segment clearing>
                    <Button
                        primary
                        floated='right'
                        onClick={this.saveForm}>Save</Button>
                </Segment>
            </Segment>
        )
    }
}

export default connect(state => {
    return {
        application: state.application,
        contacts: state.contacts,
	}
})(ContactForm);
