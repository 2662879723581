import React, {useState, useEffect} from 'react';
import {useHistory} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import {parseContent, contentCompile} from '../../redux/actions/content';
import {fetchLeagues} from '../../redux/actions/league';
import {TagPanel} from '../../components/tags';
import {reverseMap} from '../../utils'; 
import {isEmpty} from 'lodash/lang';
import {
    TeamSelector2, 
    LeagueSelector,
    SegmentSelector
} from '../../components/selectors';
import {
    Header, 
    Grid,
    Form,
    TextArea,
    Button,
    Dropdown,
    Input,
    Segment,
} from 'semantic-ui-react';


export default function ContentBuilder() {
    const [home, setHome] = useState(null);
    const [away, setAway] = useState(null);
    const [league, setLeague] = useState(9);
    const [side, setSide] = useState(null);
    const [content, setContent] = useState('');
    const [parsed, setParsed] = useState('');
    const [market, setMarket] = useState(null); 
    const [minutes, setMinutes] = useState('');
    const [thresholds, setThresholds] = useState({});
    const [compiled, setCompiled] = useState([]);
    const dispatch = useDispatch();
    const history = useHistory();
    const {
        providerId, 
        sportId, 
        currentProvider, 
        providerMarkets,
        marketIndex,
    } = useSelector(state => state.application);
    const {items, loading} = useSelector(state => state.leagues);
    useEffect(() => {
        fetchLeagues(providerId, sportId, null, 0, 'last_modified', true)(dispatch);
    }, [])

    const submit = () => {
        console.log(home, away, league);
        const contentBlock = [{
            content: [content]
        }];
        const extraParams = {
            home_id: home, 
            away_id: away,
            league_id: league,
            side: side,
        }
        parseContent(providerId, sportId, contentBlock, null, null, extraParams)(dispatch).then((response) => {
            console.log(response)
            setParsed(response.payload.response.results[0].content[0].rendered)
        })
    }

    const formatOutcomes = () => {
        if (!market || !marketIndex[market])  {
            return []
        }
        const thisMarket = marketIndex[market];
        const thresholdKeys = Object.keys(thisMarket.meta.thresholds);
        const outcomeMap = reverseMap(thisMarket.outcome_mapping);
        return thresholdKeys.map(key => {
            return {
                operator_id: outcomeMap[key],
                label: outcomeMap[key],
                oddsDecimal: thresholds[key],
                odds: parseFloat(thresholds[key]) * 1000
            }
        })
    }

    const compiler = () => {
        const params = {
            home_id: home, 
            away_id: away, 
            league_id: league, 
            market_id: market, 
            offer: {
                minutes: minutes, 
                outcomes: formatOutcomes()
            }
        }
        contentCompile(providerId, sportId, params)(dispatch).then((response) => {
            if (response.payload.response && !isEmpty(response.payload.response.results)) {
                setCompiled(response.payload.response.results);
            }
        })

    }

    const leagueOptions = items.map(item => ({
            text: item.name, 
            value: item.id, 
            description: item.path, 
            key: item.id
    }))

    const thresholdFields = () => {
        const thisMarket = marketIndex[market];
        const thresholdKeys = Object.keys(thisMarket.meta.thresholds);
        const outcomeMap = reverseMap(thisMarket.outcome_mapping);
        return thresholdKeys.map((key, keyIndex) => (
            <Form.Field key={`thresh-${key}-${keyIndex}`}>
                <label>{key.replace('_', ' ')} [{outcomeMap[key]}] </label>
                <Input
                    fluid={false}
                    name={key}
                    value={thresholds[key] || ''}
                    placeholder={key}
                    onChange={(ev, {value}) => {
                        setThresholds({
                            ...thresholds,
                            [key]: value
                        });
                    }}
                />
            </Form.Field>
        ))
    }
    console.log(compiled)
    return (
        <Form>
            <Grid>
                <Grid.Row columns={5}>
                <Grid.Column>
                    <Form.Field>
                        <label>League</label>
                        <Dropdown
                            fluid
                            selection
                            clearable
                            placeholder='League'
                            value={league}
                            loading={loading}
                            options={leagueOptions}
                            onChange={(ev, {value}) => {
                                console.log("val", value)
                                setLeague(value)
                            }}
                        />
                    </Form.Field>
                </Grid.Column>
                <Grid.Column>
                    <Form.Field>
                        <label>Home</label>
                        <TeamSelector2
                            leagueId={league}
                            onSelect={(ev, {result}) => {
                                setHome(result.id)

                            }}
                        />
                    </Form.Field>
                </Grid.Column>
                <Grid.Column>
                    <Form.Field>
                        <label>Away</label>
                        <TeamSelector2
                            leagueId={league}
                            onSelect={(ev, {result}) => {
                                setAway(result.id)

                            }}
                        />
                    </Form.Field>
                </Grid.Column> 
                <Grid.Column>
                    <Form.Field>
                        <label>Select the market to generate for</label>
                        <Dropdown
                            fluid
                            selection
                            search
                            clearable
                            placeholder='Market'
                            value={market}
                            loading={false}
                            options={providerMarkets.map((market, marketIndex) => {
                                return {
                                    text: market.name, 
                                    value: market.id, 
                                    key: market.id
                                }
                            })}
                            onChange={(ev, {value}) => {
                                setThresholds({});
                                setMarket(value); 
                            }}
                        />
                    </Form.Field>
                </Grid.Column> 
                <Grid.Column>
                    <Form.Field>
                        <label>Side</label>
                        <Dropdown
                            selection
                            clearable
                            placeholder='Side'
                            value={side}
                            options={[
                                {text: 'Home', value: 'home'},
                                {text: 'Away', value: 'away'}
                            ]}
                            onChange={(ev, {value}) => {
                                setSide(value)
                            }}
                        />
                    </Form.Field>
                </Grid.Column>
                <Grid.Column>
                    <Form.Field>
                        <label>&nbsp;</label>
                        <Button 
                            onClick={() => {
                                history.push('/cms/')
                            }}
                            secondary
                            content="Back"
                        />
                    </Form.Field>
                </Grid.Column>
            </Grid.Row>
            { market ? (
                <Grid.Row columns={1}>
                    <Grid.Column>
                        <Header as="h4">Simulate offer parameters</Header>
                        <Form.Group>
                            <Form.Field>
                                <label>Minutes</label>
                                <Input 
                                    fluid={false}
                                    placeholder={'Minutes'}
                                    name="minutes"
                                    value={minutes || ''}
                                    onChange={(ev, {value}) => setMinutes(value)}
                                />
                            </Form.Field>
                            {thresholdFields()}                               
                        </Form.Group>
                    </Grid.Column>
                </Grid.Row>
            ) : false}
            <Grid.Row columns={2} divided>
                <Grid.Column>
                    <Header floated="left">
                        Content Tester
                        <Header.Subheader>
                            Test ad-hoc content with query tags
                        </Header.Subheader>
                    </Header> 
                    <Header floated="right">
                        <Button 
                            primary
                            content="Parse"
                            size="tiny"
                            onClick={() => {
                                submit();
                            }}
                        />    
                    </Header> 
                    <Form.Field>
                        <label>Content template</label>
                        <TextArea 
                            rows={10} 
                            name="template"
                            value={content}
                            onChange={(ev, {value}) => setContent(value)}
                        />
                    </Form.Field>
                    <Form.Field>
                        <label>Parsed content </label>
                        <TextArea 
                            disabled
                            rows={10} 
                            name="parsed"
                            defaultValue={parsed}
                        />
                    </Form.Field>
                    <TagPanel/>
                </Grid.Column>
                <Grid.Column>
                    <Header floated="left">
                        Content Tester
                        <Header.Subheader>
                            Generate a set of content options for a given market
                        </Header.Subheader>
                    </Header> 
                    <Header floated="right">
                        <Button 
                            primary
                            content="Select template"
                            size="tiny"
                            onClick={() => compiler()}
                        />
                    </Header>
                    
                    <Form.Field>
                        {compiled.map((item, index) => {
                            return (
                                <Segment raised key={index}>
                                    {item.title[0].content}<br/>
                                    {item.content[0].content}<br/>
                                    {item.cta[0].content}<br/>
                                </Segment> 
                            )
                        })}
                    </Form.Field>
                </Grid.Column>
            </Grid.Row>
        </Grid>
    </Form>
    )
}