import React, {setState, useState} from 'react';
import { useDrop } from 'react-dnd'
import {
    Button,
    Segment,
    Grid,
    Input,
    TextArea,
    Header,
    Loader
} from 'semantic-ui-react';
import { dragItems } from './constants';

export default function () {
    const [languages, setLanguages] = useState([]);
    const [contentType, setContentType] = useState(null)
    const [items, setItems] = useState([]);
    const [{isOver}, dropRef] = useDrop({
        accept: dragItems.CONTENT,
        collect: monitor => ({
            isOver: monitor.isOver(),
            isOverCurrent: monitor.isOver({shallow: true}),
        }),
        drop: ({content, index, type}) => {
            ondrop(null, {
                name: 'content',
                value: content
            })
        }
    })
    return (
        <Segment>
            <Grid>
                <Grid.Row>
                    {items.map((item, index) => {
                        return (
                            <div key={index}>{index}</div>
                        )
                    })}
                </Grid.Row>
            </Grid>
        </Segment>
    )
}