import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import {
    LanguageSelector,
    ContentTypeSelector,
    ChannelSelector,
    LinkTargetSelector,
    MarketSelector,
    ContentCategorySelector,
} from '../../components/selectors';
import {isEmpty} from 'lodash/lang';
import {find} from 'lodash/collection';
import {LinkTargets} from '../../constants';
import { FileInput } from '../file-upload';
import {
    Form, TextArea, Button, Checkbox, Dropdown,
} from 'semantic-ui-react';


function emptyRecord() {
    return {
        id: null,
        content: '',
        language: 'en',
        channel_id: null,
        content_type: 'content',
        side_context: null,
        image: null,
        link_target: '',
        categories: [],
        markets: [],
        market_types: [],
        provider_lock: false,
        auto_build: false,
    }
}

export default function ContentTemplateForm({
    content = emptyRecord(), categories = [], providerLanguages = null,
    onToggleTagPanel = () => {}, onSubmit = () => {}, 
    markets, marketTypes, 
}) {
    const [values, setValues] = useState(content || emptyRecord());

    useEffect(() => {
        if (content && content?.id !== values?.id) {
            setValues(content);
        }
    }, [content])

    const handleChange = (ev, {name, value, type, checked}) => {
        const newValues = {...values}
        newValues[name] = type === 'checkbox'? checked : value;
        setValues(newValues);
    }
 
    const getMarketTargets = () => { 
        if (isEmpty(values?.market_types)) {
            return null;
        }
        const selectedMarket = find(marketTypes, item => values.market_types.indexOf(item.id) > -1);
        if (selectedMarket) {
            const options = LinkTargets.filter((item, idx) => {
                return selectedMarket.outcomes.indexOf(item.value) > -1}
            )
            return options;
        }
        return LinkTargets;
    }

    const content_category_ids = (values?.categories || []).map(category => category.id || category)
    return (
        <Form>
            <Form.Group>
                <Form.Field>
                    <label>Content Type</label>
                    <ContentTypeSelector
                        onChange={handleChange}
                        value={values.content_type || ''}/>
                </Form.Field>
                <Form.Field>
                    <label>Language</label>
                    <LanguageSelector
                        onChange={handleChange}
                        value={values.language}
                        languages={providerLanguages}
                    />
                </Form.Field>
                <Form.Field>
                    <label>Delivery Channel</label>
                    <ChannelSelector
                        name='channel_id'
                        onChange={handleChange}
                        value={values.channel_id}
                    />
                </Form.Field>

            </Form.Group>
            <Form.Group>
                <Form.Field>
                    <label>Market Type</label>
                    <Dropdown
                        multiple
                        selection
                        name="market_types"
                        placeholder="Market Types"
                        onChange={handleChange}
                        value={values.market_types || []}
                        options={marketTypes.map((mType, index) => {
                            return {
                                key: mType.id,
                                text: mType.name,
                                value: mType.id,
                                market_type: mType,
                            }
                        })}
                    />
                </Form.Field>
                <Form.Field>
                    <label>Markets</label>
                    <MarketSelector
                        name='markets'
                        value={values.markets || []}
                        onChange={handleChange}
                        multiple={true}
                        options={markets.map((market, index) => {
                            return {
                                text: market.name,
                                value: market.id,
                                key: index
                            }
                        })}
                    />
                </Form.Field>
                {['cta', 'content'].indexOf(values.content_type) > -1 ? (
                    <Form.Field>
                        <label>Link Target</label>
                        <LinkTargetSelector
                            name='link_target'
                            onChange={handleChange}
                            value={values.link_target}
                            options={getMarketTargets()}
                        />
                    </Form.Field>
                ) : false}
            </Form.Group>
            <Form.Group>
                <Form.Field width={10}>
                    <label>Category</label>
                    <ContentCategorySelector
                        name='categories'
                        value={content_category_ids}
                        multiple={true}
                        onChange={handleChange}
                        options={categories.map((category, index) => {
                            return {
                                text: category.name,
                                value: category.id,
                                key: `cat-${index}`
                            }
                        })}
                    />
                </Form.Field>
                <Form.Field>
                    <label>Side Context</label>
                    <Dropdown
                        selection
                        clearable
                        name="side_context"
                        placeholder="Side Context"
                        onChange={handleChange}
                        value={values.side_context}
                        options={[
                            {key: 'scN', text: 'None', value: 'none'},
                            {key: 'scB', text: 'Both', value: 'both'},
                            {key: 'scH', text: 'Home', value: 'home'},
                            {key: 'scA', text: 'Away', value: 'away'},
                        ]}
                    />
                </Form.Field>
            </Form.Group>
            <Form.Group>
                <Form.Field>
                    <Checkbox
                        name='provider_lock'
                        label='Provider lock: this content will only be available to the selected provider (selecting a market will also lock to provider)'
                        onClick={handleChange}
                        checked={values.provider_lock}
                    />
                </Form.Field>
                <Form.Field>
                    <Checkbox
                        name='auto_build'
                        label='Include in auto-build'
                        onClick={handleChange}
                        checked={!!values.auto_build}
                    />
                </Form.Field>
            </Form.Group>
            <Form.Field>
                <label>Content</label>
                {values.content_type !== 'image' ? (
                    <TextArea
                        name='content'
                        rows={10}
                        value={values.content}
                        onChange={handleChange}/>
                    ) : (
                        <FileInput
                            as="file"
                            label="Image Upload!"
                            onChange={(ev, {value}) => {
                                handleChange(ev, {name: 'image', value: value})
                            }}
                            accept="image/*"
                            value={values.image || ''}
                        />
                    )}
            </Form.Field>
            <Form.Field>
                <Button primary onClick={(ev) => {
                    ev.preventDefault();
                    if (content?.categories?.length > 0 && content.categories[0]?.id) {
                        content.categories = content.categories.map(item => item.id)
                    }
                    onSubmit(ev, {
                        name: 'content',
                        value: values 
                    })
                }}>Submit</Button>
                <Button secondary onClick={onToggleTagPanel}>Query Tags</Button>
            </Form.Field>
        </Form>

    ) 
}
    
        