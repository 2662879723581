import React from 'react';

export default function PreviewItem({item}) {

    return (
        <React.Fragment>
            {['title', 'content', 'cta', 'image'].map((key, keyIndex) => {
                if (!item.content[key]) {
                    return false;
                }
                return (
                    <span key={`${key}_${keyIndex}`}>
                        {item.content[key].map(item => item.content)}
                    </span>
                )
            })}
        </React.Fragment>
    )
}