import React from 'react';
// import PropTypes from 'prop-types';
// import { DragSource } from 'react-dnd';
import {Item, TextArea} from 'semantic-ui-react';
import {dragItems} from './constants';
import {LinkTargetSelector} from '../selectors';
// import { getEmptyImage } from 'react-dnd-html5-backend';
import { useDrag } from 'react-dnd'

export default function ContentCard({isDragging, inUse, index, content, onRemove, onChange}) {
    const defaultStyle = {
        textDecoration: content.archived_at ? 'line-through' : 'none',
        clear: 'both',
        opacity: 1,
    };

    const [style, dragRef] = useDrag({
        item: { type: dragItems.CARD, content, index},
        collect: monitor => monitor.isDragging() ? {
            opacity: 0.5,
            backgroundColor: '#cccccc',
            border: '1px dashed black',
            clear: 'both'
        } : defaultStyle,
    })
    const isImage = content.content_type === 'image';
    const contentText = isImage ? (
        <img src={content.image.url} style={{maxWidth: 200}}/>
    ) : content.content;
    const contentChannel = content.channel;

    const handleChange = (ev, {name, value}) => {
        content[name] = value;
        onChange(ev, {value: content, index: index});
    }

    const getLinkTargetFooter = () => {
        const linkTarget = content.link_target;
        if (!inUse && linkTarget && linkTarget !== '') {
            return (<Item.Extra>Link to: {linkTarget}</Item.Extra>);
        } else if (inUse && linkTarget && linkTarget !== '') {
            return (
                <Item.Extra>
                    Link to:
                    <LinkTargetSelector
                        name='link_target'
                        inline={true}
                        onChange={handleChange}
                        value={linkTarget}
                    />
                </Item.Extra>
            )
        }
        return false;
    }

    return (
        <div ref={dragRef} className="ui item contentCard" style={style}>
            <Item.Content>
                {
                    inUse && !isImage ? (
                        <TextArea
                            name='content'
                            rows={2}
                            onChange={handleChange}
                            value={contentText}/>
                    ) : (
                        <div>
                            <span className="channel">{contentChannel}</span><br/>
                            <span className="content-text">{contentText}</span>
                        </div>
                    )
                }
                {content.group && content.group.name ? '*' : false}
                {inUse ? (
                    <div style={{float:'right'}}>
                        <button className="link"
                            onClick={() => onRemove(content)}
                            ><i className="ui icon remove"></i> Remove</button>

                    </div>
                ) : false}
                {getLinkTargetFooter()}
            </Item.Content>
        </div>
    )
}

 