import React from 'react';
import {connect} from 'react-redux'
import {
    fetchMatch,
    fetchMatchEvents,
    fetchMatchMarkets,
    fetchMatchOfferContent,
} from '../../redux/actions/match';
import {isEmpty} from 'lodash/lang';
import {DataGrid} from '../../components/grid';
import {OddsDisplay} from '../../components/odds';
import MatchWrapper from './match-wrapper';
import ReactJson from 'react-json-view';
import {
    Grid, Loader, Button
} from 'semantic-ui-react';


class MatchFeed extends React.Component {
    state = {
        rawViewData: null,
        selectedRow: null,
        marketId: null,
    }

    componentDidMount() {
        const {providerId, sportId} = this.props.application;
        const {matchId} = this.props.match.params;
        if (matchId) {
            fetchMatch(providerId, sportId, matchId)(this.props.dispatch)
            .then(() => {
                fetchMatchEvents(providerId, sportId, matchId)(this.props.dispatch);
                fetchMatchMarkets(providerId, sportId, matchId)(this.props.dispatch);
            })

        }
    }

    loadEvents = (start=0) => {
        const {providerId, sportId} = this.props.application;
        const {matchId} = this.props.match.params;
        const {marketId} = this.state;
        const params = {
            start: start
        }
        if (marketId) {
            params.market_id = marketId;
        }
        fetchMatchEvents(providerId, sportId, matchId, params)(this.props.dispatch);
    }

    handlePagination = (start) => {
		this.loadEvents(start);
    }

    handleRowSelection = (item, index) => {
        const {providerId, sportId} = this.props.application;
        const reviewData = (this.state.rawViewData && item.id === this.state.rawViewData.id) ? null : item;
        this.setState({
            rawViewData: reviewData,
            selectedRow: reviewData && item.id
        }, () => {
            fetchMatchOfferContent(providerId, sportId, item.id)(this.props.dispatch);
        })
    }

    hideRawView = () => {
        this.setState({
            rawViewData: null
        })
    }

    panelControls = () => {
        return (
            <Button.Group>
                <Button basic icon="refresh" onClick={() => this.loadEvents(0)}/>
            </Button.Group>
        )
    }

    setMarket = (marketId) => {
        this.setState({
            marketId
        }, () => {
            this.loadEvents();
        })
    }

    render() {
        const {
            matches,
            application,
        } = this.props;
        const {currentOfferContent} = matches;
        const {
            rawViewData,
            selectedRow,
            marketId,
        } = this.state;
        const {sportIndex, sportId} = application;
        const sportScoreMap = sportIndex && sportIndex[sportId].scores;
        const hasSubNodes = sportScoreMap && !!(sportScoreMap.sub_nodes || sportScoreMap.max_sub_node)

        if (!matches.currentMatch || isEmpty(matches.currentMatch)) {
           return (<Loader active={true}/>)
        }
        return (
            <MatchWrapper
                activeTab='feed'
                match={matches.currentMatch}
                markets={matches.matchMarkets}
                marketId={marketId}
                onMarketSelect={(ev, {value}) => { this.setMarket(value)}}
                panelControls={this.panelControls()}
                title='Feed'>
                <Grid>
                    <Grid.Column width={!!rawViewData ? 10 : 16}>
                        <DataGrid
                            headers={[
                                {name: 'id', title: 'Id'},
                                {name: 'market.name', title: 'Market'},
                                {name: 'contact_names', title: 'Targeting'},
                                {name: 'score', 'title': 'State', renderer: (item) => {
                                    if (!hasSubNodes) {
                                        return `${item.score}`;
                                    } else {
                                        const subNode = item.sub_node || '-';
                                        const leafNode = item.leaf_node || '-';
                                        return `${item.score} [${subNode} / ${leafNode}]`
                                    }
                                }},
                                {
                                    name: 'match_time',
                                    'title': 'Game Time',
                                    renderer: (item) => {
                                        if (item.prematch) {
                                            return 'PREMATCH'
                                        }
                                        const seconds = item.match_time % 60
                                        return `${Math.floor(item.match_time / 60)} ' ${seconds}`
                                    }},
                                {
                                    name: 'odds',
                                    title: 'Odds',
                                    renderer: (item) => {
                                        return OddsDisplay(item);
                                    }
                                },
                                {name: 'status', title: 'Action'},
                                {name: 'created_at', title: 'Created At'}
                            ]}
                            loading={matches.loading}
                            items={matches.matchFeedEvents}
                            totalRows={matches.totalFeedEvents}
                            pageSize={matches.feedEventsLimit}
                            onPaginate={this.handlePagination}
                            onRowSelect={this.handleRowSelection}
                            selectedRow={selectedRow}
                        />
                    </Grid.Column>
                    {rawViewData ? (
                        <Grid.Column width={6}>
                            <ReactJson
                                src={rawViewData.data}
                                theme='monokai'
                                displayDataTypes={false}
                            />
                            { currentOfferContent ? (
                                <ReactJson
                                    src={currentOfferContent}
                                    theme='light'
                                    displayDataTypes={false}
                                />
                            ) : false}
                        </Grid.Column>
                    ) : false}
                </Grid>
            </MatchWrapper>
        )
    }
}

export default connect(state => {
    return {
        application: state.application,
        matches: state.matches
	}
})(MatchFeed);
