import React from 'react';
import PropTypes from 'prop-types';
import {Dropdown} from 'semantic-ui-react';
import {connect} from 'react-redux'
import {evalDropdownDefaultValue} from '../../utils';
import {search} from '../../redux/actions/app';
// import {sortBy} from 'lodash/collection';
import {escapeRegExp} from 'lodash/string';

class LeagueSelector extends React.Component {
    static propTypes = {
        name: PropTypes.string,
        value: PropTypes.array,
        options: PropTypes.array,
        onChange: PropTypes.func,
        multiple: PropTypes.bool,
        selectOnNavigation: PropTypes.bool
    }

    static defaultProps = {
        name: 'league_id',
        value: [],
        options: [],
        multiple: true,
        selectOnNavigation: false,
        onChange: () => {}
    }

    state = {
        value: evalDropdownDefaultValue(this.props.value, this.props.multiple),
    }


    handleChange = (ev, {name, value}) => {
        const {onChange} = this.props;
        this.setState({
            value
        }, () => {
            onChange(ev, {
                name: this.props.name,
                value: value
            })
        })
    }

    handleSearch = (ev, {searchQuery}) => {
        const {providerId, sportId} = this.props.application;
        search(providerId, sportId, searchQuery, ['leagues'], 0, 'name')(this.props.dispatch);

    }

    getOptions = () => {
        const {searching, searchResults} = this.props.application;
        const results = (searchResults && searchResults.leagues && searchResults.leagues.results) || [];
        const options = results && results.map((item, index) => {
            return {
                value: item.id,
                text: item.title,
                description: item.description,
                key: `${index}-${item.id}`
            }
        })
        //const sortedLeagues = sortBy(options, item => item.description)
        return {
            searching: searching,
            options: options || []
        }
    }

    search = (options, query) => {
        const re = new RegExp(escapeRegExp(query), 'ig');
        return options.filter(item => re.test(item.text) || re.test(item.description))
    }

    render() {
        const {
            name,
            multiple,
            selectOnNavigation,
        } = this.props;
        const {
           value
        } = this.state;
        const {
            searching,
            options
        } = this.getOptions();
        return (
            <Dropdown
                fluid
                search={this.search}
                selection
                multiple={multiple}
                placeholder='Leagues'
                name={name}
                value={value}
                loading={searching}
                options={options}
                onChange={this.handleChange}
                onSearchChange={this.handleSearch}
                selectOnNavigation={selectOnNavigation}
            />
        )
    }
}


export default connect(state => {
    return {
        application: state.application,
        leagues: state.leagues
    }
})(LeagueSelector);
