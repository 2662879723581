import React, {useEffect, useState} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {useHistory} from 'react-router-dom';
import {DataGrid} from '../../components/grid';
import {fetchTrackers, setTracker, setTrackerField, clearTracker, updateTracker} from '../../redux/actions/match';
import {fetchContacts} from '../../redux/actions/contact';
import {
    Segment, Header, Form, Input, Button, Checkbox, Radio, Icon
} from 'semantic-ui-react';
import { ContactSelector, LanguageSelector } from '../../components/selectors';
import { parse } from 'superagent';

export default function TrackersGrid() {
    const dispatch = useDispatch();
    const {providerId, sportId} = useSelector(state => state.application);
    const contacts = useSelector(state => state.contacts.items);
    const {trackers, currentTracker} = useSelector(state => state.matches);
    const history = useHistory();

    useEffect(() => {
        fetchContacts(providerId, sportId)(dispatch).then(res => {
            fetchTrackers(providerId)(dispatch);
        })
    }, [])

    const getOptions = () => {
        return (item) => {
            return [
                {path: `/tracker/${item.id}/`, icon: 'edit', onClick: () => {
                    dispatch(setTracker(item));
                }},
            ]
        }
    }

    const isThresholdType = (value) => { 
        const _threshType = currentTracker?.spec?.thresholds_type;
        console.log(value, _threshType, currentTracker)
        if (typeof(_threshType) === 'string' && _threshType === value) {
            return true;
        } else if (typeof(_threshType) === 'object' && _threshType.indexOf(value) > -1) {
            return true;
        }
        return false;
    }

    const setThresholdType = (ev, {name, value, checked}) => {
        const spec = currentTracker.spec || {};
        if (typeof(spec?.thresholds_type) != 'object') {
            spec.thresholds_type = spec?.thresholds_type ? [spec.thresholds_type] : [];
        }
        if (checked) {
            spec.thresholds_type.push(value);
        } else {
            spec.thresholds_type.splice(spec.thresholds_type.indexOf(value), 1);
        }
        dispatch(setTrackerField('spec', spec))
    }

    return (
        <Segment basic raised>
            <Segment raised>
                <Header as='h2'>Trackers</Header>
                {currentTracker ? (
                    <Form>
                        <Form.Group widths={3}>
                            <Form.Field>
                                <label>Contact</label>
                                <ContactSelector
                                    name='contact_id'
                                    value={currentTracker?.contact_id || currentTracker?.contact?.id || ''}
                                    contacts={contacts}
                                    onChange={(ev, {name, value}) => {
                                        const contact = {id: value, name: ''}
                                        dispatch(setTrackerField(name, value))
                                    }}
                                />
                            </Form.Field>
                            <Form.Field>
                                <label>Language</label>
                                <LanguageSelector
                                    name="language"
                                    value={currentTracker?.language || 'en'}
                                    onChange={(ev, {name, value}) => {
                                        console.log(value)
                                        dispatch(setTrackerField(name, value))
                                    }}
                                />
                            </Form.Field>
                            <Form.Field>
                                <label>Match/Leaguge</label>
                                <Radio
                                    name="model_type"
                                    label="Match"
                                    value="M"
                                    checked={currentTracker.model_type == 'M'}
                                    onChange={(ev, {name, value, checked}) => {
                                        console.log(name, value, checked)
                                        dispatch(setTrackerField(name, value))
                                    }}
                                />
                                <Radio
                                    name="model_type"
                                    label="League"
                                    value="L"
                                    checked={currentTracker.model_type == 'L'}
                                    onChange={(ev, {name, value, checked}) => {
                                        dispatch(setTrackerField(name, value))
                                    }}
                                />
                            </Form.Field>
                            <Form.Field>
                                <label>Match/League ID</label>
                                <Input
                                    name="model_id"
                                    placeholder="Match or League Id"
                                    type='number'
                                    value={currentTracker.model_id}
                                    onChange={(ev, {name, value}) => {
                                        dispatch(setTrackerField(name, value));
                                    }}
                                />
                            </Form.Field>
                            <Form.Field>
                                <label>Min Score Thresh</label>
                                <Input
                                    name="min_score"
                                    placeholder="Minimum Limit %"
                                    type='number'
                                    value={currentTracker?.spec?.limiter?.min_score || ''}
                                    onChange={(ev, {name, value}) => {
                                        const spec = currentTracker.spec || {};
                                        spec.limiter = {min_score: parseInt(value)};
                                        dispatch(setTrackerField('spec', spec));
                                    }}
                                    label="%"
                                    labelPosition="right"
                                    style={{width:120}}
                                />
                            </Form.Field>
                            <Form.Field>
                                <label>Thresh Type</label>
                                 
                                <Checkbox
                                    name="thresholds_type"
                                    label="Value"
                                    value="VALUE"
                                    checked={isThresholdType('VALUE')}
                                    onChange={setThresholdType}
                                />
                                <Checkbox
                                    name="thresholds_type"
                                    label="Relative"
                                    value="RELATIVE"
                                    checked={isThresholdType('RELATIVE')}
                                    onChange={setThresholdType}
                                />
                                <Checkbox
                                    name="thresholds_type"
                                    label="Range"
                                    value="RANGE"
                                    checked={isThresholdType('RANGE')}
                                    onChange={setThresholdType}
                                />
                               
                            </Form.Field>
                            <Form.Field>
                                <label>Match State</label>
                                <Checkbox
                                    name="match_state"
                                    label="Prematch"
                                    value="P"
                                    checked={currentTracker?.spec?.match_state?.indexOf('P') > -1}
                                    onChange={(ev, {name, value, checked}) => {
                                        const spec = currentTracker.spec || {match_state: []};
                                        const state = new Set(spec?.match_state || []);
                                        if (checked) {
                                            state.add('P')
                                        } else {
                                            state.delete('P')
                                        }
                                        spec.match_state = Array.from(state.values())
                                        console.log(checked,    spec)
                                        dispatch(setTrackerField('spec', spec));
                                    }}
                                />
                                <Checkbox
                                    name="match_state"
                                    label="Inplay"
                                    value="I"
                                    checked={currentTracker?.spec?.match_state?.indexOf('I') > -1}
                                    onChange={(ev, {name, value, checked}) => {
                                        const spec = currentTracker.spec || {match_state: []};
                                        const state = new Set(spec?.match_state || []);
                                        if (checked) {
                                            state.add('I')
                                        } else {
                                            state.delete('I')
                                        }
                                        spec.match_state = Array.from(state.values())
                                        console.log(checked, spec)
                                        dispatch(setTrackerField('spec', spec));
                                    }}
                                />
                            </Form.Field>
                            <Form.Field>
                                <label>Active?</label>
                                <Checkbox
                                    slider
                                    name="active"
                                    label="Active"
                                    checked={currentTracker.active === true}
                                    onChange={(ev, {name, checked}) => {
                                        dispatch(setTrackerField(name, checked))
                                    }}
                                />
                            </Form.Field>
                        </Form.Group>
                        <Form.Group width={2}>
                            <Form.Field>
                                <Button
                                    negative
                                    content="Cancel"
                                    onClick={() => {dispatch(clearTracker())}}
                                />
                            </Form.Field>
                            <Form.Field>
                                <Button
                                    positive
                                    content="Save"
                                    onClick={() => {
                                        updateTracker(
                                            providerId,
                                            currentTracker.id,
                                            currentTracker,
                                        )(dispatch).then(res => {
                                            fetchTrackers(providerId)(dispatch);
                                        }).then(() => {
                                            dispatch(clearTracker())
                                        })
                                    }}
                                />
                            </Form.Field>
                        </Form.Group>
                    </Form>
                ) : false}
            </Segment>
            <DataGrid
                headers={[
                    {name: 'id', title: 'Id'},
                    {name: 'contact.name', title: 'Contact'},
                    {name: 'language', title: 'Language'},
                    {name: 'model_type', title: 'Type'},
                    {name: 'model_id', title: 'ID'},
                    {name: 'thresholds_type', title: 'Thresh Type', renderer: ({spec}) => {
                        return spec?.thresholds_type || "VALUE"; 
                    }},
                    {name: 'min_score', title: 'Threshold', renderer: ({spec}) => {
                        return spec?.limiter?.min_score || "";
                    }},
                    {name: 'match_state', title: 'State', renderer: ({spec}) => {
                        return (spec?.match_state || []).join(', ');
                    }},
                    {name: 'active', title: 'Active', renderer: ({active}) => {
                        return active ? <Icon name="check"/> : <Icon name="close"/>
                    }},
                    {name: null, title: 'Options', options: getOptions()},
                ]}
                items={trackers}
                totalRows={trackers?.lengthl || 0}
            />
        </Segment>
    )
}