import {Actions} from '../../constants';
import {createAction, createReceiver, getSortParams} from './utils';
import {Service} from '../../service';


export function fetchTeams(providerId, sportId, leagueId=null, seasonId=null, search=null, start=0, sort='-last_modified', allProviders=false) {
    return function(dispatch) {
        dispatch(createAction(Actions.TEAMS.GET));
        const params = {
            start,
            q: search,
            ...getSortParams(sort)
        };
        if (allProviders) {
            params['all_providers'] = true;
        }
        const urlParams = {}
        let urlKey = 'teams';
        if (!!leagueId) {
            urlParams.leagueId = leagueId;
            urlKey = providerId ? 'leagueProviderTeams': 'leagueTeams';
        }
        if (!!seasonId) {
            urlParams.seasonId = seasonId;
            urlKey = 'seasonTeams';
        }
        return Service.doGet({
            url: urlKey,
            params: params,
            urlParams: {
                providerId,
                sportId,
                ...urlParams
            }
        }).then(
            (response) => dispatch(createReceiver('TEAMS', response, params))
        ).catch(
            (response) => dispatch(createAction(Actions.TEAMS.GET_FAIL, null, response)) );
    }
}

export function fetchTeam(providerId, sportId, teamId) {
    return function(dispatch) {
        dispatch(createAction(Actions.TEAM.GET));
        return Service.doGet({
            url: 'team',
            urlParams: {
                providerId,
                sportId,
                teamId
            }
        }).then(
            (response) => dispatch(createAction(Actions.TEAM.GET_SUCCESS, response, null))
        ).catch(
            (response) => dispatch(createAction(Actions.TEAM.GET_FAIL, null, response)) );
    }
}


export function updateTeam(providerId, sportId, teamId, value) {
    return function(dispatch) {
        dispatch(createAction(Actions.TEAM.UPDATE));
        return Service.doPost({
            url: 'team',
            urlParams: {
                providerId,
                sportId,
                teamId
            },
            params: {
                value
            }
        }).then(
            (response) => dispatch(createAction(Actions.TEAM.UPDATE_SUCCESS, response, null))
        ).catch(
            (response) => dispatch(createAction(Actions.TEAM.UPDATE_FAIL, null, response)) );
    }
}


export function teamConnectSearch(providerId, sportId, fromTeamId, search=null, start=0, sort='-last_modified') {
    return function(dispatch) {
        dispatch(createAction(Actions.TEAM.CONNECT_SEARCH));
        const params = {
            start,
            q: search,
            ...getSortParams(sort)
        };
        return Service.doGet({
            url: 'teamConnectSearch',
            params: params,
            urlParams: {
                providerId,
                sportId,
                fromTeamId,
            }
        }).then(
            (response) => dispatch(createAction(Actions.TEAM.CONNECT_SEARCH_SUCCESS, response, null))
        ).catch(
            (response) => dispatch(createAction(Actions.TEAM.CONNECT_SEARCH_FAIL, null, response)) )
    }
}


/**
 * Assert that a team X from provider A, is actually the same as team Y from provider B.
 * This will create the connection via the backend API.
 * Note that team X will then be deactivated, and all references to it will be replaced
 * with team Y, which will also receive a new provider link to provider A.
 * @param {int} providerId
 * @param {int} fromTeamId
 * @param {int} toProviderId
 * @param {int} toTeamId
 */
export function connectTeam(sportId, providerId, fromTeamId, toTeamId) {
    return function(dispatch) {
        dispatch(createAction(Actions.TEAM.CONNECT));
        return Service.doPost({
            url: 'teamConnect',
            urlParams: {
                providerId,
                sportId,
                fromTeamId,
                toTeamId
            }
        }).then(
            (response) => dispatch(createAction(Actions.TEAM.CONNECT_SUCCESS, response, null))
        ).catch(
            (response) => dispatch(createAction(Actions.TEAM.CONNECT_FAIL, null, response)) );
    }
}



export function teamLastMatchApex(leagueId, teamId, marketId, outcome, side) {
    return function(dispatch) {
        dispatch(createAction(Actions.TEAM.LAST_MATCH_APEX));
        const params = {
            side,
            outcome,
            market_id: marketId,

        };
        console.log("params", params)
        return Service.doGet({
            url: 'teamLastMatchApex',
            params: params,
            urlParams: {
                sportId: 1,
                teamId,
                leagueId,
            }
        }).then(
            (response) => dispatch(createAction(Actions.TEAM.LAST_MATCH_APEX_SUCCESS, response, null))
        ).catch(
            (response) => dispatch(createAction(Actions.TEAM.LAST_MATCH_APEX_FAIL, null, response)) )
    }
}
