import React, {useState, useEffect} from 'react';
import {Link, useParams, useHistory} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import {fetchLeague, fetchSeason} from '../../redux/actions/league';
import {fetchTeams} from '../../redux/actions/team';
import {fetchSeasonMatches} from '../../redux/actions/match';
import {BinaryWidget, marketQueryConfig} from '../../components/markets';
import {CompiledStack} from '../../components/content';
import dateFormat from 'dateformat';
import {
    Table, Dropdown, Segment, Label, Button, Loader
} from 'semantic-ui-react';


export default function LeagueStats({}) {
    const {leagueId, seasonId, teamId, marketId} = useParams();
    const [homeAway, setHomeAway] = useState(null);
    const [otherTeam, setOtherTeam] = useState(null);
    const [selectedRow, setSelectedRow] = useState(null);
    const {
        providerId,
        sportId,
        providerMarkets,
        marketIndex
    } = useSelector(state => state.application);
    const history = useHistory();
    const dispatch = useDispatch();
    const {currentLeague, currentSeason, loading} = useSelector(state => state.leagues);
    const teams = useSelector(state => state.teams.items);
    const teamsLoading = useSelector(state => state.teams.loading);
    const matches = useSelector(state => state.matches.items);
    const showPending = false;
    // const matchesLoading = useSelector(state => state.matches.loading);
    useEffect(() => {
        fetchSeason(sportId, leagueId, seasonId, teamId, homeAway)(dispatch)
        if (seasonId) {
            fetchTeams(null, sportId, leagueId, seasonId, null, 0, '')(dispatch);
        }
        // if (market != marketId && marketIndex?.[marketId]) {
        //     setMarket(marketIndex[marketId]);
        // }
        // if (teamId) {
        //     fetchSeasonMatches(sportId, leagueId, seasonId, teamId);
        // }
    }, [leagueId, seasonId, teamId, homeAway])

    const generateContent = (index, match) => {
        if (index === selectedRow?.index) {
            setSelectedRow(null);
        } else {
            setSelectedRow({index, match})
        }
    }
    const marketData = marketIndex?.[marketId];
    const currentTeamId = parseInt(teamId);
    const marketTypeArgs = marketData?.type?.meta?.queries?.bool?.args || {};
    if (marketData?.line) {
        marketTypeArgs.line = marketData.line;
    }
    const currentTeams = {};
    (currentSeason?.table || []).map((item, index) => {
        if (showPending || item.score.home !== null || item.score.away !== null) {
            if (!currentTeams[item.home_id]) {
                currentTeams[item.home_id] = item.home;
            }
            if (!currentTeams[item.away_id]) {
                currentTeams[item.away_id] = item.away;
            }
        }
    })
    const otherTeamOptions = Object.keys(currentTeams).map((teamId, index) => (
        {
            value: teamId,
            text: currentTeams[teamId],
            key: index,
        }
    ))
    return (
        <React.Fragment>
            <Segment>
                <Dropdown
                    search
                    selection
                    placeholder='Seasons'
                    value={seasonId}
                    options={(currentLeague?.seasons || []).map((season, ind) => ({
                        text: season.name,
                        value: `${season.id}`,
                        key: `season-${season.id}`
                    }))}
                    onChange={(ev, {value}) =>
                        history.replace(`/league/${leagueId}/stats/${value}`)
                    }
                />
                <Dropdown
                    search
                    selection
                    placeholder={teamsLoading ? 'Loading' : 'Teams'}
                    value={teamId || ''}
                    options={teams?.map((team, index) => ({
                        value: `${team.id}`,
                        text: team.title,
                        key: `team-${index}`
                    }))}
                    onChange={(ev, {value}) =>
                        history.replace(`/league/${leagueId}/stats/${seasonId}/team/${value}`)
                    }
                />
                <Dropdown
                    search
                    selection
                    clearable
                    placeholder={teamsLoading ? 'Loading' : 'Other Team'}
                    value={otherTeam || ''}
                    options={otherTeamOptions}
                    onChange={(ev, {value}) =>
                        setOtherTeam(value)
                    }
                />
                <Dropdown
                    search
                    selection
                    placeholder='Market'
                    value={marketId || ''}
                    options={providerMarkets?.map((market, index) => ({
                        value: `${market.id}`,
                        text: market.name,
                        key: `market-${market.id}`
                    }))}
                    onChange={(ev, {value}) =>
                        history.replace(`/league/${leagueId}/stats/${seasonId}/team/${teamId}/market/${value}`)
                        //setMarket(marketIndex[value])
                    }
                />
                &nbsp;
                <Button.Group>
                    <Button
                        positive={homeAway === 'home'}
                        content="Home"
                        onClick={() => setHomeAway(homeAway === 'home' ? null : 'home')}
                    />
                    <Button.Or />
                    <Button
                        positive={homeAway === 'away'}
                        content="Away"
                        onClick={() => setHomeAway(homeAway === 'away' ? null : 'away')}
                    />
                </Button.Group>
            </Segment>
            { loading ? (
                <Loader active/>
            ) : (
                <Table compact="very">
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell>Date</Table.HeaderCell>
                            <Table.HeaderCell>Match</Table.HeaderCell>
                            <Table.HeaderCell>Full Time</Table.HeaderCell>
                            <Table.HeaderCell>Half Time</Table.HeaderCell>
                            <Table.HeaderCell>{marketData?.name}</Table.HeaderCell>
                            {otherTeam ? (
                                <Table.HeaderCell>Other team</Table.HeaderCell>
                            ) : false}
                            <Table.HeaderCell></Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>
                    <Table.Body>
                        {(currentSeason?.table || []).map((item, index) => {
                            if (showPending || item.score.home !== null || item.score.away !== null) {
                                if (!otherTeam || item.home_id === otherTeam || item.away_id === otherTeam) {

                                    return (
                                        <React.Fragment key={`r${index}`}>
                                        <Table.Row>
                                            <Table.Cell>{dateFormat(item.match.date, 'dd-mm-yyyy HH:MM')}</Table.Cell>
                                            <Table.Cell>
                                                <span style={{fontWeight: item.home_id === currentTeamId ? 'bold' : 'normal'}}>
                                                    {item.home}
                                                </span>
                                                -
                                                <span style={{fontWeight: item.away_id === currentTeamId ? 'bold' : 'normal'}}>
                                                    {item.away}
                                                </span>
                                            </Table.Cell>
                                            <Table.Cell>
                                                <Label basic>{item.score.home}</Label>
                                                <Label basic>{item.score.away}</Label>
                                            </Table.Cell>
                                            <Table.Cell>{item.ht_score.home} - {item.ht_score.away}</Table.Cell>
                                            <Table.Cell>
                                                {marketData?.type?.meta?.queries?.bool ? (
                                                    <BinaryWidget
                                                        match={item}
                                                        marketId={marketId}
                                                        focusOn={item.home_id === currentTeamId ? 'home' : 'away'}
                                                        queryName={marketData.type.meta.queries.bool.name}
                                                        thresholds={marketData.type?.outcomes || []}
                                                        params={{
                                                            limit_to_match_ids: [item.match.id],
                                                            team_id: currentTeamId,
                                                            ...marketTypeArgs
                                                        }}
                                                        evalResult={
                                                            marketQueryConfig?.[marketData.type.id].bool
                                                        }
                                                    />
                                                ) : false}
                                            </Table.Cell>
                                            {otherTeam ? (
                                                <Table.Cell>
                                                    {marketData?.type?.meta?.queries?.bool ? (
                                                        <BinaryWidget
                                                            match={item}
                                                            marketId={marketId}
                                                            focusOn={item.home_id === currentTeamId ? 'away' : 'home'}
                                                            queryName={marketData.type.meta.queries.bool.name}
                                                            thresholds={marketData.type?.outcomes || []}
                                                            params={{
                                                                limit_to_match_ids: [item.match.id],
                                                                team_id: otherTeam,
                                                                ...marketTypeArgs
                                                            }}
                                                            evalResult={
                                                                marketQueryConfig?.[marketData.type.id].bool
                                                            }
                                                        />
                                                    ) : false}
                                                </Table.Cell>
                                            ) : false}
                                            <Table.Cell>
                                                <Link to={`/match/${item.match.id}`}>Match</Link>
                                                {/* <Button
                                                    basic
                                                    icon="plus"
                                                    onClick={
                                                        () => {
                                                            generateContent(index, item);
                                                        }
                                                    }
                                                /> */}
                                            </Table.Cell>
                                        </Table.Row>
                                        { selectedRow?.index === index ? (
                                            <Table.Row>
                                                <Table.Cell></Table.Cell>
                                                <Table.Cell colSpan={5}>
                                                    <CompiledStack
                                                        providerId={providerId}
                                                        sportId={sportId}
                                                        marketId={marketId}
                                                        homeId={item.home_id}
                                                        awayId={item.away_id}
                                                        leagueId={leagueId}
                                                    />
                                                </Table.Cell>
                                            </Table.Row>
                                        ) : false}
                                        </React.Fragment>
                                    )
                                }
                            }
                        })}
                    </Table.Body>
                </Table>
            )}
        </React.Fragment>
    )
}