import React, {useEffect} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import {isEmpty} from 'lodash/lang';
import {fetchQueryTags} from '../../redux/actions/content';
import {
    List,
    Segment,
    Header
} from 'semantic-ui-react';

export default function TagPanel() {
    const {providerId, sportId} = useSelector(state => state.application);
    const {queryTags} = useSelector(state => state.content);
    const dispatch = useDispatch();
    useEffect(() => {
        if (isEmpty(queryTags)) {
            fetchQueryTags(providerId, sportId)(dispatch)
        }
    }, [])

    const copyToClipboard = (str) => {
        navigator.clipboard.writeText(str)
    }

    return (
        <Segment>
            <Header as="h3">Query Tags</Header>
            <List>
            {queryTags.map((tag, index) => {
                return (
                    <List.Item key={`tag-${index}`}>
                        <List.Header>{tag.name}</List.Header>
                        <List.Content>
                            <span className="all-copy" onClick={() => copyToClipboard(`{{home.${tag.key}}}`)}>{`{{home.${tag.key}}}`}</span> /
                            <span className="all-copy" onClick={() => copyToClipboard(`{{away.${tag.key}}}`)}>{`{{away.${tag.key}}}`}</span>
                        </List.Content>
                    </List.Item>
                )
            })}
            </List>
        </Segment>
    )
}