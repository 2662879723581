import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux'
import {
    connectTeam,
    teamConnectSearch,
} from '../../redux/actions/team';
import {Search} from 'semantic-ui-react';


class TeamConnector extends React.Component {
    static propTypes = {
        teamId: PropTypes.number.isRequired,
        name: PropTypes.string,
    }

    state = {
        value: '',
    }

    handleResultSelect = (ev, {result}) => {
        const {application, name, teamId, dispatch} = this.props;
        const {currentProvider, providerId, sportId} = application;
        this.setState({
            value: '',
        }, () => {
            const confirmed = window.confirm(`Are you sure you want to reassign ${name} (of ${currentProvider.name}) as ${result.title}?`)
            if (confirmed) {
                connectTeam(sportId, providerId, teamId, result.id)(dispatch)
            }
        })
    }

    handleSearchChange = (ev, {value}) => {
        const {application, teamId, dispatch} = this.props;
        const {providerId, sportId} = application;
        this.setState({
            value,
        }, () => {
            teamConnectSearch(providerId, sportId, teamId, value)(dispatch);
        })
    }

    resultRenderer = (team) => {
        const providers = team.providers.map(prov => prov.name).join(', ');
        return `${team.title} (${providers})`
    }

    render() {
        const {searchResults, loading} = this.props;
        const {value} = this.state;
        return (
            <Search
                loading={loading}
                onResultSelect={this.handleResultSelect}
                onSearchChange={this.handleSearchChange}
                results={searchResults}
                value={value}
                resultRenderer={this.resultRenderer}
            />
        )
    }
}

export default connect(state => {
    return {
        application: state.application,
        loading: state.teams.connectorLoading,
        searchResults: state.teams.connectorSearchItems
	}
})(TeamConnector);