import React, {useState, useEffect} from 'react';
import {Link, useParams, useHistory} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import {fetchLeague, fetchSeason} from '../../redux/actions/league';
import {fetchTeams, teamLastMatchApex} from '../../redux/actions/team';
import {parseContent, contentCompile} from '../../redux/actions/content';
import {fetchSeasonMatches} from '../../redux/actions/match';
import {BinaryWidget, marketQueryConfig, SummaryWidget} from '../../components/markets';
import {CompiledStack} from '../../components/content';
import {SelectionBehaviours} from '../../constants';
import dateFormat from 'dateformat';
import {
    Table, Dropdown, Segment, Label, Button, Loader, Grid, Input, Icon
} from 'semantic-ui-react';


export default function LeagueStats2({}) {
    const {leagueId, seasonId, teamId, otherTeamId, marketId} = useParams();
    const [homeAway, setHomeAway] = useState('home');
    const [homeAwayRight, setHomeAwayRight] = useState('away');
    const [apexHome, setApexHome] = useState(null);
    const [apexAway, setApexAway] = useState(null);
    const [apexHomeOutcome, setApexHomeOutcome] = useState(null);
    const [apexAwayOutcome, setApexAwayOutcome] = useState(null);
    const [lastX, setLastX] = useState(0);
    const [minScore, setMinScore] = useState(null);
    const [showTemplate, setShowTemplate] = useState(false);
    const [selectedRow, setSelectedRow] = useState(null);
    const [selectionBehaviour, setSelectionBehaviour] = useState('all');
    const [contentElements, setContentElements] = useState([])
    const {
        providerId,
        sportId,
        providerMarkets,
        marketIndex
    } = useSelector(state => state.application);
    const history = useHistory();
    const dispatch = useDispatch();
    const {currentLeague, currentSeason, currentSeason2, loading} = useSelector(state => state.leagues);
    const teams = useSelector(state => state.teams.items);
    const teamsLoading = useSelector(state => state.teams.loading);
    const matches = useSelector(state => state.matches.items);
    const showPending = [0, 0, 1]; // left, right, max
    const marketData = marketIndex?.[marketId];
    const marketOutcomes = marketData?.type?.outcomes || [];
    // const matchesLoading = useSelector(state => state.matches.loading);
    useEffect(() => {
        if (leagueId) {
            fetchLeague(providerId, sportId, leagueId)(dispatch).then(res => {
                fetchSeason(sportId, leagueId, seasonId, teamId, homeAway, 1, lastX)(dispatch);
                if (otherTeamId) {
                    fetchSeason(sportId, leagueId, seasonId, otherTeamId, homeAwayRight, 2, lastX)(dispatch)
                }
                if (seasonId) {
                    fetchTeams(null, sportId, leagueId, seasonId, null, 0, 'name')(dispatch);
                }
            })
            
            // compiler();
            // if (market != marketId && marketIndex?.[marketId]) {
            //     setMarket(marketIndex[marketId]);
            // }
            // if (teamId) {
            //     fetchSeasonMatches(sportId, leagueId, seasonId, teamId);
            // }
        }
    }, [leagueId, seasonId, teamId, otherTeamId, homeAway, homeAwayRight, lastX])

    useEffect(() => {
        if (teamId && marketId) {
            teamLastMatchApex(leagueId, teamId, marketId, apexHomeOutcome || 'home', 'home')(dispatch).then(res => {
                setApexHome(res?.payload?.response?.result?.apex);
            })
        }
    }, [teamId, marketId, apexHomeOutcome])
    useEffect(() => {
        if (teamId && marketId) {
            teamLastMatchApex(leagueId, otherTeamId, marketId, apexAwayOutcome || 'away', 'away')(dispatch).then(res => {
                setApexAway(res?.payload?.response?.result?.apex);
            })
        }
    }, [otherTeamId, marketId, apexAwayOutcome])

    const generateContent = (index, match) => {
        if (index === selectedRow?.index) {
            setSelectedRow(null);
        } else {
            setSelectedRow({index, match})
        }
    }

    const getMarketOutcomeOptions = () => {
        return marketOutcomes.map(outcomeKey => ({
            text: outcomeKey,
            value: outcomeKey,
            key: `bh-${outcomeKey}`
        }))
    }

    const generateBehaviour = () => {
        const behaviourOptions = [];
        SelectionBehaviours.forEach(item => behaviourOptions.push(item));
        // console.log(marketData?.type?.outcomes)
        const tmp = marketOutcomes.forEach(outcomeKey => behaviourOptions.push({
            text: outcomeKey,
            value: outcomeKey,
            key: `bh-${outcomeKey}`
        }))
        return behaviourOptions
    }

    const getLastXOptions = () => {
        return new Array(40).fill(null).map((el, index) => {
            return {
                text: index === 0 ? 'Season' : `Last ${index}`,
                key: index,
                value: index
            }
        })
    }

    const currentTeamId = parseInt(teamId);
    const currentOtherTeamId = parseInt(otherTeamId);
    const marketTypeArgs = marketData?.type?.meta?.queries?.bool?.args || {};
    const matchIds = {left: [], right: []};
    if (marketData?.line) {
        marketTypeArgs.line = marketData.line;
    }
    const statCounters = {left: [], right: []}
    const nextMatch = {left: null, right: null} 
    return (
        <React.Fragment>
            <Segment.Group>
            <Segment>
                <Dropdown
                    search
                    selection
                    placeholder='Seasons'
                    value={seasonId}
                    options={(currentLeague?.seasons || []).map((season, ind) => ({
                        text: season.name,
                        value: `${season.id}`,
                        key: `season-${season.id}`
                    }))}
                    onChange={(ev, {value}) =>
                        history.replace(`/league/${leagueId}/stats2/${value}/teams`)
                    }
                />
                <Dropdown
                    search
                    selection
                    placeholder={teamsLoading ? 'Loading' : 'Teams'}
                    value={teamId || ''}
                    options={teams?.map((team, index) => ({
                        value: `${team.id}`,
                        text: team.title,
                        key: `team-${index}`
                    }))}
                    onChange={(ev, {value}) =>
                        history.replace(`/league/${leagueId}/stats2/${seasonId}/teams/${value}/${otherTeamId}`)
                    }
                />
                <Dropdown
                    search
                    selection
                    placeholder={teamsLoading ? 'Loading' : 'Other Team'}
                    value={otherTeamId || ''}
                    options={teams?.map((team, index) => ({
                        value: `${team.id}`,
                        text: team.title,
                        key: `team-${index}`
                    }))}
                    onChange={(ev, {value}) =>
                        history.replace(`/league/${leagueId}/stats2/${seasonId}/teams/${teamId}/${value}`)
                    }
                />
                <Button
                    onClick={() => setShowTemplate(!showTemplate)}
                    primary={showTemplate}>
                    Show Templates
                </Button>
                <Input
                    type="number"
                    defaultValue={minScore || ""} 
                    style={{width:100}} 
                    icon={
                        <Icon 
                            name='percent' 
                            inverted 
                            circular 
                            link 
                            onClick={ev => setMinScore(ev.target.parentElement.children[0].value)}
                        />
                    }
                /> 
                <Label>
                    Home Apex: 
                    <Dropdown 
                        inline 
                        options={getMarketOutcomeOptions()}
                        value={apexHomeOutcome}
                        onChange={(ev, {value}) => setApexHomeOutcome(value)}
                    /> 
                    {apexHome}
                </Label>
                <Label>
                    Away Apex: 
                    <Dropdown 
                        inline 
                        options={getMarketOutcomeOptions()}
                        value={apexAwayOutcome}
                        onChange={(ev, {value}) => setApexAwayOutcome(value)}
                    /> 
                    {apexAway}
                </Label>
            </Segment>
            <Segment>

                <Dropdown
                    selection
                    placeholder='Last n Matches'
                    value={lastX}
                    options={getLastXOptions()}
                    onChange={(ev, {value}) => {
                        setLastX(value)
                    }}
                />

                <Dropdown
                    search
                    selection
                    placeholder='Market'
                    value={marketId || ''}
                    options={providerMarkets?.map((market, index) => ({
                        value: `${market.id}`,
                        text: market.name,
                        key: `market-${market.id}`
                    }))}
                    onChange={(ev, {value}) =>
                        history.replace(`/league/${leagueId}/stats2/${seasonId}/teams/${teamId}/${otherTeamId}/market/${value}`)
                        //setMarket(marketIndex[value])
                    }
                />
                &nbsp;
                <Dropdown
                    selection
                    placeholder='Selection Behaviour'
                    value={selectionBehaviour || ''}
                    options={generateBehaviour()}
                    onChange={(ev, {value}) => {
                        setSelectionBehaviour(value)
                    }}
                />
                &nbsp;
                {/* <Dropdown
                    selection
                    multiple
                    placeholder='Elements'
                    value={contentElements}
                    options={[
                        {text: 'Title', value: 'title', key: 'ce-title'},
                        {text: 'Content', value: 'content', key: 'ce-content'},
                        {text: 'CTA', value: 'cta', key: 'ce-cta'},
                    ]}
                    onChange={(ev, {value}) => {
                        setContentElements(value)
                    }}
                />
                &nbsp; */}
                <Button.Group>
                    <Button
                        positive={homeAway === 'home'}
                        content="Home"
                        onClick={() => setHomeAway(homeAway === 'home' ? null : 'home')}
                    />
                    <Button.Or />
                    <Button
                        positive={homeAway === 'away'}
                        content="Away"
                        onClick={() => setHomeAway(homeAway === 'away' ? null : 'away')}
                    />
                </Button.Group>
                &nbsp;
                <Button.Group>
                    <Button
                        positive={homeAwayRight === 'home'}
                        content="Home"
                        onClick={() => setHomeAwayRight(homeAwayRight === 'home' ? null : 'home')}
                    />
                    <Button.Or />
                    <Button
                        positive={homeAwayRight === 'away'}
                        content="Away"
                        onClick={() => setHomeAwayRight(homeAwayRight === 'away' ? null : 'away')}
                    />
                </Button.Group>
            </Segment>
            </Segment.Group>
            { loading ? (
                <Loader active/>
            ) : (

                <Grid>
                    <Grid.Row>
                    <Grid.Column width={8}>

                        <Table compact="very">
                            <Table.Header>
                                <Table.Row>
                                    <Table.HeaderCell>Date</Table.HeaderCell>
                                    <Table.HeaderCell>Match</Table.HeaderCell>
                                    <Table.HeaderCell>Full Time</Table.HeaderCell>
                                    <Table.HeaderCell>Half Time</Table.HeaderCell>
                                    <Table.HeaderCell>{marketData?.name}</Table.HeaderCell>
                                </Table.Row>
                            </Table.Header>
                            <Table.Body>
                                {(currentSeason?.table || []).map((item, index) => {
                                    if (showPending[0] < showPending[2] || item.score.home !== null || item.score.away !== null) {
                                        if (item.score.home === null || item.score.away === null) {
                                            showPending[0] += 1;
                                            nextMatch.left = item;
                                            console.log("====+++ NEXT MATCH L +++=== ", nextMatch)
                                        } else {
                                            matchIds.left.push(item.match.id);
                                        }
                                        return (
                                            <React.Fragment key={`r${index}`}>
                                            <Table.Row>
                                                <Table.Cell>
                                                    <Link to={`/match/${item.match.id}`}>{dateFormat(item.match.date, 'dd-mm-yyyy HH:MM')}</Link>
                                                </Table.Cell>
                                                <Table.Cell>
                                                    <span style={{fontWeight: item.home_id === currentTeamId ? 'bold' : 'normal'}}>
                                                        {item.home}
                                                    </span>
                                                    -
                                                    <span style={{fontWeight: item.away_id === currentTeamId ? 'bold' : 'normal'}}>
                                                        {item.away}
                                                    </span>
                                                </Table.Cell>
                                                <Table.Cell>
                                                    <Label basic>{item.score.home}</Label>
                                                    <Label basic>{item.score.away}</Label>
                                                </Table.Cell>
                                                <Table.Cell>{item.ht_score.home} - {item.ht_score.away}</Table.Cell>
                                                <Table.Cell>
                                                    {showPending[0] < showPending[2] && marketData?.type?.meta?.queries?.bool ? (
                                                        <BinaryWidget
                                                            match={item}
                                                            matchId={item.match.id}
                                                            marketId={marketId}
                                                            focusOn={item.home_id === currentTeamId ? 'home' : 'away'}
                                                            queryName={marketData.type.meta.queries.bool.name}
                                                            thresholds={marketData.type?.outcomes || []}
                                                            params={{
                                                                limit_to_match_ids: [item.match.id],
                                                                team_id: currentTeamId,
                                                                ...marketTypeArgs
                                                            }}
                                                            evalResult={
                                                                marketQueryConfig?.[marketData.type.id].bool
                                                            }
                                                        />
                                                    ) : false}
                                                </Table.Cell>

                                            </Table.Row>
                                            {/* { selectedRow?.index === index ? (
                                                <Table.Row>
                                                    <Table.Cell></Table.Cell>
                                                    <Table.Cell colSpan={4}>
                                                        <CompiledStack
                                                            providerId={providerId}
                                                            sportId={sportId}
                                                            marketId={marketId}
                                                            homeId={item.home_id}
                                                            awayId={item.away_id}
                                                            leagueId={leagueId}
                                                        />
                                                    </Table.Cell>
                                                </Table.Row>
                                            ) : false} */}
                                            </React.Fragment>
                                        )
                                    }
                                })}
                                <Table.Row>
                                <Table.Cell colSpan={5}>
                                    {marketData?.type ? (
                                        <SummaryWidget
                                            matchIds={matchIds.left}
                                            marketId={marketId}
                                            focusOn={homeAway}
                                            queryName={marketData?.type?.meta?.queries?.summary?.name}
                                            thresholds={marketData?.type?.outcomes || []}
                                            params={{
                                                limit_to_match_ids: matchIds.left,
                                                team_id: currentTeamId,
                                                ...marketTypeArgs
                                            }}
                                            evalResult={
                                                marketQueryConfig?.[marketData?.type?.id].summary
                                            }
                                        />
                                    ) : false}
                                </Table.Cell>
                            </Table.Row>
                            </Table.Body>
                        </Table>

                    </Grid.Column>
                <Grid.Column width={8}>

                    <Table compact="very">
                        <Table.Header>
                            <Table.Row>
                                <Table.HeaderCell>{marketData?.name}</Table.HeaderCell>
                                <Table.HeaderCell>Half Time</Table.HeaderCell>
                                <Table.HeaderCell>Full Time</Table.HeaderCell>
                                <Table.HeaderCell>Match</Table.HeaderCell>
                                <Table.HeaderCell>Date</Table.HeaderCell>
                            </Table.Row>
                        </Table.Header>
                        <Table.Body>
                            {(currentSeason2?.table || []).map((item, index) => {
                                if (showPending[1] < showPending[2] || item.score.home !== null || item.score.away !== null) {
                                    if (item.score.home === null || item.score.away === null) {
                                        showPending[1] += 1;
                                        nextMatch.right = item;
                                    } else {
                                        matchIds.right.push(item.match.id);
                                    }
                                    return (
                                        <React.Fragment key={`r${index}`}>
                                        <Table.Row>
                                            <Table.Cell>
                                                {showPending[1] < showPending[2] && marketData?.type?.meta?.queries?.bool ? (
                                                    <BinaryWidget
                                                        match={item}
                                                        matchId={item.match.id}
                                                        marketId={marketId}
                                                        focusOn={item.home_id === currentOtherTeamId ? 'home' : 'away'}
                                                        queryName={marketData.type.meta.queries.bool.name}
                                                        thresholds={marketData.type?.outcomes || []}
                                                        params={{
                                                            limit_to_match_ids: [item.match.id],
                                                            team_id: currentOtherTeamId,
                                                            ...marketTypeArgs
                                                        }}
                                                        evalResult={
                                                            marketQueryConfig?.[marketData.type.id].bool
                                                        }
                                                    />
                                                ) : false}
                                            </Table.Cell>
                                            <Table.Cell>{item.ht_score.home} - {item.ht_score.away}</Table.Cell>
                                            <Table.Cell>
                                                <Label basic>{item.score.home}</Label>
                                                <Label basic>{item.score.away}</Label>
                                            </Table.Cell>
                                            <Table.Cell>
                                                <span style={{fontWeight: item.home_id === currentOtherTeamId ? 'bold' : 'normal'}}>
                                                    {item.home}
                                                </span>
                                                -
                                                <span style={{fontWeight: item.away_id === currentOtherTeamId ? 'bold' : 'normal'}}>
                                                    {item.away}
                                                </span>
                                            </Table.Cell>
                                            <Table.Cell>
                                                <Link to={`/match/${item.match.id}`}>{dateFormat(item.match.date, 'dd-mm-yyyy HH:MM')}</Link>
                                            </Table.Cell>

                                        </Table.Row>
                                        {/* { selectedRow?.index === index ? (
                                            <Table.Row>
                                                <Table.Cell></Table.Cell>
                                                <Table.Cell colSpan={4}>
                                                    <CompiledStack
                                                        providerId={providerId}
                                                        sportId={sportId}
                                                        marketId={marketId}
                                                        homeId={item.home_id}
                                                        awayId={item.away_id}
                                                        leagueId={leagueId}
                                                    />
                                                </Table.Cell>
                                            </Table.Row>
                                        ) : false} */}
                                        </React.Fragment>
                                    )
                                }
                            })}
                            <Table.Row>
                                <Table.Cell colSpan={5}>
                                    {marketData?.type ? (
                                        <SummaryWidget
                                            matchIds={matchIds.right}
                                            marketId={marketId}
                                            focusOn={homeAwayRight}
                                            queryName={marketData?.type?.meta?.queries?.summary?.name}
                                            thresholds={marketData?.type?.outcomes || []}
                                            params={{
                                                limit_to_match_ids: matchIds.right,
                                                team_id: currentOtherTeamId,
                                                ...marketTypeArgs
                                            }}
                                            evalResult={
                                                marketQueryConfig?.[marketData?.type?.id]?.summary
                                            }
                                        />
                                    ) : false}
                                </Table.Cell>
                            </Table.Row>
                        </Table.Body>
                    </Table>

                </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                    <Grid.Column width={8}>
                        <CompiledStack
                            providerId={providerId}
                            sportId={sportId}
                            marketId={marketId}
                            homeId={teamId}
                            awayId={otherTeamId}
                            leagueId={leagueId}
                            selectionBehaviour={selectionBehaviour}
                            side={homeAway || 'none'}
                            principal='home'
                            matchCount={lastX === 0 ? null : lastX}
                            contentElements={contentElements}
                            matchId={nextMatch?.left?.match?.id || nextMatch?.right?.match?.id}
                            showTemplate={showTemplate}
                            minScore={minScore}
                        />
                    </Grid.Column>
                    <Grid.Column width={8}>
                        <CompiledStack
                            providerId={providerId}
                            sportId={sportId}
                            marketId={marketId}
                            homeId={teamId}
                            awayId={otherTeamId}
                            leagueId={leagueId}
                            selectionBehaviour={selectionBehaviour}
                            side={homeAwayRight || 'none'}
                            principal='away'
                            matchCount={lastX === 0 ? null : lastX}
                            contentElements={contentElements}
                            matchId={nextMatch?.left?.match?.id || nextMatch?.right?.match?.id}
                            showTemplate={showTemplate}
                            minScore={minScore}
                        />
                    </Grid.Column>
                </Grid.Row>
            </Grid>
            )}
        </React.Fragment>
    )
}