/**
 * A middleware to add occuring errors to a global app state error list.
 */
import {_addError} from '../actions/app';
// import {logout} from '../actions/user';
import history from '../../utils/history';


export default store => next => action => {
	if (action.error) {
		// issue a logout on 401 unauthorised errors
		if (action.error.status === 401) {
			// logout()(store.dispatch);
		} else if (action.error.status === 403) {
			action.error.message = "You do not have permission to perform this action";
			store.dispatch(_addError(action.error));
			if (!action.error.remain) {
				history.push('/')
			}
		} else if ([400, 500].indexOf(action.error.status) > -1) {
			store.dispatch(_addError({
				message: action.error.body.response.error
			}));
		} else {
			store.dispatch(_addError(action.error));
		}
	}
	return next(action);
}
