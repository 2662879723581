import React from 'react';
import { useInput } from '../../hooks';
import {ContactSelector, LineSelector} from '../selectors';
import RangeThresholdFields from './range-thresholds';
import {find} from 'lodash/collection';
import {
    Form, Header, Button, Input, Checkbox
} from 'semantic-ui-react';


const DEFAULT_FORM = {
    contactId: '', 
    saveAsTemplate: '',
    saveAsTemplateDefault: false, 
    line: '', 
    thresholds: {}
}

export default function({name, market, contacts, onChange, onSubmit, values}) {
    const [formValues, dispatch] = useInput(values || DEFAULT_FORM)
    
    const handleChange = (ev, {name, value}) => {
        dispatch({name, value})
    }

    const showThresholdLimitFields = () => {
        const {contactId} = formValues;
        const contact = find(contacts, contact => contact.id === contactId)
        return !!(contact && contact.channel.code === 'FEED');
    }


    return (
        <Form>
            <Form.Field>
                <Header as='h4'>
                    {market.name}
                    <Header.Subheader floated='right'>
                        <Button
                            primary
                            floated='right'
                            content='Submit'
                            onClick={() => {
                                onSubmit(formValues);
                            }}
                        />
                    </Header.Subheader>
                </Header>
            </Form.Field>
            <Form.Field>
                <label>Contact</label>
                <ContactSelector
                    name='contactId'
                    value={formValues.contactId}
                    contacts={contacts}
                    onChange={handleChange}
                />
            </Form.Field>
            <Form.Field>
                <label>Save as a template</label>
                <Input
                    name="saveAsTemplate"
                    placeholder="To save this as a template for future use, enter a name"
                    value={formValues.saveAsTemplate}
                    onChange={handleChange}
                />
            </Form.Field>
            <Form.Field>
                <Checkbox
                    name="saveAsTemplateDefault"
                    value={''}
                    label='Set as default template'
                    checked={formValues.saveAsTemplateDefault}
                    onChange={handleChange}
                />
            </Form.Field>
            { market.helper_args && market.helper_args.dynamic_line ? (
                <Form.Field>
                    <label>Line</label>
                    <LineSelector
                        name='line'
                        value={formValues.line}
                        onChange={handleChange}
                    />
                </Form.Field>
            ) : false}
            <Form.Field>
                <label>Thresholds</label>
                <RangeThresholdFields
                    name='thresholds'
                    market={market}
                    onChange={handleChange}
                    values={formValues.thresholds}
                    showMarketLimits={showThresholdLimitFields()}
                />
            </Form.Field>
        </Form>
    )
}

// class ThresholdsForm extends React.Component {
//     static propTypes = {
//         name: PropTypes.string,
//         market: PropTypes.object,
//         contacts: PropTypes.array,
//         onChange: PropTypes.func,
//         onSubmit: PropTypes.func,
//         values: PropTypes.object,
//     }

//     static defaultProps = {
//         market: null,
//         values: {}
//     }

//     state = {
//         values: this.props.values
//     }

//     static getDerivedStateFromProps(props, state) {
//         if (!isEqual(props.values, state.values)) {
//             return {
//                 values: props.values
//             }
//         }
//         return null
//     }

//     handleChange = (ev, {name, value, type, checked}) => {
//         const {values} = this.state;
//         values[name] = type === 'checkbox' ? checked : value;
//         this.setState({
//             values
//         }, () => {
//             this.props.onChange(ev, {
//                 name: this.props.name,
//                 value: this.state.values
//             })
//         })
//     }

//     showThresholdLimitFields = () => {
//         const {contactId} = this.state.values;
//         const contact = find(this.props.contacts, contact => contact.id === contactId)
//         return !!(contact && contact.channel.code === 'FEED');
//     }

//     render() {
//         const {market, contacts} = this.props;
//         const {values} = this.state;
//         const {contactId, thresholds, line} = values;
//         if (!market) {
//             return false;
//         }
//         return (
//             <Form>
//                 <Form.Field>
//                     <Header as='h4'>
//                         {market.name}
//                         <Header.Subheader floated='right'>
//                             <Button
//                                 primary
//                                 floated='right'
//                                 content='Submit'
//                                 onClick={() => {
//                                     this.props.onSubmit(this.state.values);
//                                 }}
//                             />
//                         </Header.Subheader>
//                     </Header>
//                 </Form.Field>
//                 <Form.Field>
//                     <label>Contact</label>
//                     <ContactSelector
//                         name='contactId'
//                         value={contactId || ''}
//                         contacts={contacts}
//                         onChange={this.handleChange}
//                     />
//                 </Form.Field>
//                 <Form.Field>
//                     <label>Save as a template</label>
//                     <Input
//                         name="saveAsTemplate"
//                         placeholder="To save this as a template for future use, enter a name"
//                         value={values.saveAsTemplate || ''}
//                         onChange={this.handleChange}
//                     />
//                 </Form.Field>
//                 <Form.Field>
//                     <Checkbox
//                         name="saveAsTemplateDefault"
//                         value={''}
//                         label='Set as default template'
//                         checked={values.saveAsTemplateDefault}
//                         onChange={this.handleChange}
//                     />
//                 </Form.Field>
//                 { market.helper_args && market.helper_args.dynamic_line ? (
//                     <Form.Field>
//                         <label>Line</label>
//                         <LineSelector
//                             name='line'
//                             value={line}
//                             onChange={this.handleChange}
//                         />
//                     </Form.Field>
//                 ) : false}
//                 <Form.Field>
//                     <label>Thresholds</label>
//                     <ThresholdFields
//                         name='thresholds'
//                         market={market}
//                         onChange={this.handleChange}
//                         values={thresholds || {}}
//                         showMarketLimits={this.showThresholdLimitFields()}
//                     />
//                 </Form.Field>
//             </Form>
//         )
//     }
// }

// export default ThresholdsForm;