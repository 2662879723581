import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {fetchTeams} from '../../redux/actions/team';
import {Search} from 'semantic-ui-react';
import {Service} from '../../service';
import {getSortParams} from '../../redux/actions/utils';


const DEFAULT_RESULTS = {
    loading: false,
    items: []
}

export default function TeamSelector({onSelect, leagueId}) {
    const [results, setResults] =  useState([]);
    const [loading, setLoading] = useState(false);
    const dispatch = useDispatch();
    const {providerId, sportId, currentProvider} = useSelector(state => state.application);

    const search = (ev, {value}) => {
        setLoading(true);
        const promise = Service.doGet({
            url: !!leagueId ? 'leagueProviderTeams' : 'teams',
            params: {
                q: value,
                all_providers: true,
                ...getSortParams('-last_modified'),
            },
            urlParams: {
                providerId,
                sportId,
                leagueId: leagueId,
            }
        }).then(
            (response) => {
                console.log(response)
                setLoading(false);
                setResults(response.response.results)
            }
        ).catch(
            (response) => setResults([])
        );


        // fetchTeams(providerId, sportId, null, null, value, 0, '-last_modified', true)(dispatch).then(response => {
        //     console.log(response)
        // })
    }

    return (
        <Search
            loading={loading}
            onResultSelect={onSelect}
            onSearchChange={search}
            results={results}
            resultRenderer={(team) => {
                const providers = team?.providers?.map(prov => prov.name).join(', ');
                return `${team.title} (${providers})`
            }}
        />
    )
}
//     handleResultSelect = (ev, {result}) => {
//         const {application, name, teamId, dispatch} = this.props;
//         const {currentProvider, providerId, sportId} = application;
//         this.setState({
//             value: '',
//         }, () => {
//             const confirmed = window.confirm(`Are you sure you want to reassign ${name} (of ${currentProvider.name}) as ${result.title}?`)
//             if (confirmed) {
//                 connectTeam(sportId, providerId, teamId, result.id)(dispatch)
//             }
//         })
//     }

//     handleSearchChange = (ev, {value}) => {
//         const {application, teamId, dispatch} = this.props;
//         const {providerId, sportId} = application;
//         this.setState({
//             value,
//         }, () => {
//             teamConnectSearch(providerId, sportId, teamId, value)(dispatch);
//         })
//     }

//     resultRenderer = (team) => {
//         const providers = team.providers.map(prov => prov.name).join(', ');
//         return `${team.title} (${providers})`
//     }

//     render() {
//         const {searchResults, loading} = this.props;
//         const {value} = this.state;
//         return (
//             <Search
//                 loading={loading}
//                 onResultSelect={this.handleResultSelect}
//                 onSearchChange={this.handleSearchChange}
//                 results={searchResults}
//                 value={value}
//                 resultRenderer={this.resultRenderer}
//             />
//         )
//     }
// }
