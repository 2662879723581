import React from 'react';
import {Dropdown} from 'semantic-ui-react'
import {LinkTargets} from '../../constants';


export default function({name, value, options, onChange, markets, inline}) {
    return (
        <Dropdown
            clearable
            selection={!!!inline}
            inline={!!inline}
            placeholder='Link Target'
            name={name || 'link_target'}
            value={value || ''}
            options={options || LinkTargets}
            onChange={onChange}
        />
    )
}
