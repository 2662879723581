import React from 'react';
import {Dropdown} from 'semantic-ui-react'

export default function({name, value, onChange}) {
    return (
        <Dropdown
            selection
            placeholder='Sport'
            name={name || 'sport'}
            value={value || 1}
            options={[
                {value: 1, text: 'Football', icon:'soccer'},
                {value: 2, text: 'Ice Hockey'},
                {value: 3, text: 'Tennis'},
                {value: 4, text: 'Basketball'},
            ]}
            onChange={onChange}
        />
    )
}