import React from 'react';
import {Dropdown} from 'semantic-ui-react';

export default function({name, value, onChange, contacts, selection}) {
    selection = selection === undefined ? true : selection;
    return (
        <Dropdown
            selection={selection}
            placeholder='Contact'
            name={name || 'contact_id'}
            value={value || ''}
            options={contacts.map((contact, index)=> {
                return {
                    value: contact.id,
                    text: contact.name,
                    key: index
                }
            })}
            onChange={onChange}
        />
    )
}