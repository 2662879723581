export const DEFAULT_RELATIVE_OUTCOME_THRESHOLDS = {
    direction: 'RISE',
    outcome: '',
    value: '',
    metric: 'VALUE',
    time: '',
    timeMetric: 'MINUTES',
    timeMetricType: 'START',
};

export const DEFAULT_RELATIVE_THRESHOLDS = {
    type: 'RELATIVE',
    saveAsTemplate: '',
    outcomes: {}
};