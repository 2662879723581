import React from 'react';
import {Dropdown} from 'semantic-ui-react'

export default function({name, value, onChange, selection, allowEmpty}) {
    selection = selection === undefined ? true : selection;
    let options = [
        {value: 'title', text: 'Title'},
        {value: 'content', text: 'Content'},
        {value: 'cta', text: 'CTA'},
        {value: 'image', text: 'Image'},
    ]
    if (allowEmpty) {
        options = [{value: '', text: 'Any'}].concat(options);
    }
    return (
        <Dropdown
            clearable
            selection={selection}
            placeholder='Content Type'
            name={name || 'content_type'}
            value={value}
            options={options}
            onChange={onChange}
        />
    )
}