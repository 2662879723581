import React from 'react';
import {Dropdown} from 'semantic-ui-react';
import {connect} from 'react-redux'
import {setSportContext} from '../../redux/actions/app';


class SportSelector extends React.Component {
    sportOptions = () => {
        const {currentProvider} = this.props.application;
        return currentProvider && currentProvider.sports.map((sport, index) => {
            return {
                value: sport.id,
                text: sport.name,
                key: index
            }
        })
    }

    handleChange = (ev, {name, value}) => {
        this.props.dispatch(setSportContext(value));
    }

    render() {
        const {sportId} = this.props.application;
        return (
            <Dropdown
                selection
                name='sportId'
                value={sportId}
                placeholder='Sports'
                onChange={this.handleChange}
                options={this.sportOptions()}
            />
        )
    }
}

export default connect(state => {
    return {
        application: state.application
	}
})(SportSelector);
