import React from 'react';
import {Link} from 'react-router-dom';
import {DataGrid} from '../components/grid';
import {connect} from 'react-redux'
import {fetchMatches} from '../redux/actions/match';
import dateFormat from 'dateformat';
import {Grid, Header} from 'semantic-ui-react';


class Home extends React.Component {
    componentDidMount() {
        const {providerId, sportId} = this.props.application;
        // const {leagueId} = this.props.match.params;
        fetchMatches(providerId, sportId, null, 0, 'start', 'FUTURE')(this.props.dispatch);
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        const {application} = this.props;
        const {providerId, sportId} = nextProps.application;
        if (providerId !== application.providerId ||
            sportId !== application.sportId) {
                fetchMatches(providerId, sportId, null, 0, 'start', 'FUTURE')(this.props.dispatch);
        }
    }

    handlePagination = (start) => {
        const {providerId, sportId} = this.props.application;
        fetchMatches(providerId, sportId, null, start, 'start', 'FUTURE')(this.props.dispatch);
    }

    render() {
        const {matches} = this.props;
        return (
            <Grid>
                <Grid.Row>
                    <Grid.Column>
                        <Header as='h2'>Future matches</Header>
                        <DataGrid
                            headers={[
                                {name: 'id', title: 'Id', sortable: true},
                                {name: 'name', title: 'Match', sortable: true, renderer: ({id, name}) => {
                                    return (
                                        <Link to={`/match/${id}`}>{name}</Link>

                                    )
                                }},
                                {name: 'start', title: 'Start Time', sortable: true, renderer: ({start}) => {
                                    return dateFormat(start)
                                }},
                                {name: 'league', title: 'League', sortable: true, renderer: ({league}) => {
                                    return (
                                        <Link
                                            to={`/league/${league.id}/matches`}
                                            title={league.path}>
                                            {league.provider_name}
                                        </Link>
                                    )
                                }},
                                {name: 'event_id', title: 'Event Id'},
                                {name: 'league', title: 'League Id', renderer: ({league}) => league.unique_id},
                            ]}
                            loading={matches.loading}
                            items={matches.items}
                            totalRows={matches.total}
                            pageSize={matches.limit}
                            onPaginate={this.handlePagination}
                        />
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        )
    }
}

export default connect(state => {
    return {
        application: state.application,
        matches: state.matches,
	}
})(Home);
