import {Actions} from '../../constants';

/**
 * Generate an action for a given type.
 * Optional payload and error arguments can help generate success/fail type
 * actions
 * @param  {string} actionId Unique id string for this action
 * @param  {any} payload  Payload object
 * @param  {Object} error Error
 * @param  {Object} meta Any meta data object to pass to the action
 * @return {Object}       Action object
 */
export function createAction(actionId, payload, error, meta) {
	const action = {
		type: actionId
	};
	if (payload !== undefined) {
		action.payload = payload;
	}
	if (error !== undefined) {
		action.error = error;
	}
	if (meta !== undefined) {
		action.meta = meta;
	}
	return action;
}

/**
 * Create a transition meta object to be used for the transition middleware
 * @param  {string} path Transition to this path
 * @return {Object}     Transition object
 */
export function createTransition(path) {
	return {
		transition: {
			path: path
		}
	}
}

/**
 * Create a receiver action used after a succesful filter operation
 * @param {string} key
 * @param {object} response
 * @param {object} params
 */
export function createReceiver(key, response, params) {
	return {
		type: Actions[key].GET_SUCCESS,
		payload: response,
		meta: {
			requestParams: params
		}
	}
}


export function getSortParams(sortString) {
	const sortDir = sortString && sortString[0] === '-' ? '-' : '';
	const sortCol = sortString.replace('-', '')
	return {
		order_by: sortCol,
		order_dir: sortDir
	}
}
