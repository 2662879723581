import React from 'react';
import {Link} from 'react-router-dom';
import { useDrag } from 'react-dnd';
import {DataGrid} from '../../components/grid';
import {connect} from 'react-redux'
import {ContentTemplateForm} from '../../components/forms';
import {TagPanel} from '../../components/tags';
import {Segment, Modal, Flag} from 'semantic-ui-react';
import {isEmpty} from 'lodash/lang';
import {keyBy} from 'lodash/collection';
import {TimerButton} from '../../components/timer-button';
import {ContentGrouper} from '../../components/content-grouper';
import {ContentFilter} from '../../components/content-filter';
import Languages from '../../constants/languages';
import {
    fetchContents,
    fetchContent,
    createContent,
    updateContent,
    deleteContent,
    fetchContentGroups,
    fetchContentCategories,
    toggleAutoBuild,
    toggleActive,
} from '../../redux/actions/content';
import {DEFAULT_FILTER} from '../../components/content-filter/constants';

const DND_ITEM_TYPE = 'row';

function DragHandle({key}) {
    const dragRef = React.useRef(null)
    const [{ isDragging }, drag, preview] = useDrag({
        item: { type: DND_ITEM_TYPE, key },
        collect: monitor => ({
            isDragging: monitor.isDragging(),
        }),
    })

    const opacity = isDragging ? 0 : 1

    //preview(drop(dropRef))
    drag(dragRef)
    return <div ref={dragRef}>MOVE</div>
}

class ContentGrid extends React.Component {
    marketMap = {}
    languageMap = {}

    state = {
        editModalVisible: false,
        categoryId: '',
        tagsVisible: false,
        filter: DEFAULT_FILTER,
    }

    componentDidMount() {
        const {providerId, sportId} = this.props.application;
        this.filter(this.state.filter);
        //fetchContents(providerId, sportId)(this.props.dispatch);
        fetchContentGroups(providerId, sportId)(this.props.dispatch);
        fetchContentCategories(providerId, sportId)(this.props.dispatch);
        this.languageMap = keyBy(Languages, 'value');
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        const {application} = this.props;
        const {providerId, sportId} = nextProps.application;
        if (providerId !== application.providerId || sportId !== application.sportId) {
            fetchContents(providerId, sportId, null, {})(this.props.dispatch)
        }
        this.marketMap = keyBy(application.providerMarkets, 'id');
        this.marketTypeMap = keyBy(application.marketTypes, 'id');
    }

    getOptions = () => {
        return (item) => {
            const {content} = this.props;
            return [
                {
                    icon: 'edit', title: 'Edit',
                    onClick: this.editContent.bind(null, item.id),
                    loading: content.loadingContentId === item.id
                },
                {
                    icon: item.auto_build ? 'check' : 'minus',
                    title: item.auto_build ? 'In Auto-Build' : 'Not in Auto-Build',
                    onClick: () => this.toggleAutoBuild(item.id),
                },
                {
                    icon: item.deliver ? 'send' : 'exclamation circle',
                    title: item.deliver ? 'Active' : 'Disabled',
                    onClick: () => this.toggleActive(item.id),
                },
                {
                    component: TimerButton,
                    onClick: () => this.handleDelete(item.id)
                },
                // {
                //     component: DragHandle
                // }
            ]
        }
    }

    toggleAutoBuild = (contentId) => {
        const {providerId, sportId} = this.props.application;
        toggleAutoBuild(providerId, sportId, contentId)(this.props.dispatch).then((res) => {
            // console.log(res)
        })
    }

    toggleActive = (contentId) => {
        const {providerId, sportId} = this.props.application;
        toggleActive(providerId, sportId, contentId)(this.props.dispatch).then((res) => {
            // console.log(res)
        })
    }


    handleDelete = (contentId) => {
        const {providerId, sportId} = this.props.application;
        deleteContent(providerId, sportId, [contentId])(this.props.dispatch).then((res) => {
            this.filter(this.state.filter);
        });
    }

    toggleModel = () => {
        this.setState({
            editModalVisible: !this.state.editModalVisible
        })
    }

    editContent = (contentId) => {
        const {providerId, sportId} = this.props.application;
        if (!isNaN(contentId)) {
            fetchContent(providerId, sportId, contentId)(this.props.dispatch).then((res)=>{
                this.toggleModel();
            });
        } else {
            fetchContent(providerId, sportId, null)(this.props.dispatch);
            this.toggleModel();
        }
    }

    editGroupedContent = () => {

    }

    submitForm = (ev, {name, value}) => {
        ev.preventDefault();
        const {providerId, sportId} = this.props.application;
        if (value.id) {
            updateContent(providerId, sportId, value.id, value)(this.props.dispatch).then(()=>{
                this.filter(this.state.filter);
                this.toggleModel();
            });
        } else {
            createContent(providerId, sportId, value)(this.props.dispatch).then(()=>{
                this.filter(this.state.filter);
                this.toggleModel();
            });
        }
    }

    getContentGroups = () => {
        const {content} = this.props;
        if (!isEmpty(content.contentGroups)) {
            const groups = content.contentGroups.map((group, index) => {
                return {
                    value: group.id,
                    text: group.name,
                    key: index
                }
            })
            return [{value: '', text: '', key: -1}].concat(groups)
        }
        return [];
    }

    getContentTemplates = (ev, {name, value}) => {

    }

    filter = (filter) => {
        const {providerId, sportId} = this.props.application;
        fetchContents(providerId, sportId, null, filter)(this.props.dispatch).then(res => {
            this.setState({
                ...this.state,
                filter
            })
        })
    }

    handlePagination = (start) => {
        const {providerId, sportId} = this.props.application;
        fetchContents(providerId, sportId, null, this.state.filter, start)(this.props.dispatch);
    }

    toggleTagPanel = (ev) => {
        if (ev) {
            ev.preventDefault();
        }
        this.setState({
            tagsVisible: !this.state.tagsVisible
        })
    }

    render() {
        const {content, application} = this.props;
        const {
            editModalVisible,
            tagsVisible,
        } = this.state;
        const providerLanguages = application.currentProvider && application.currentProvider.meta.languages;
        return (
            <div>
            <Segment raised>
                <ContentFilter
                    onFilter={this.filter}
                    onToggleTagPanel={this.toggleTagPanel}
                    onEditContent={this.editContent}
                />
            </Segment>
            <ContentGrouper/>
            <DataGrid
                headers={[
                    {name: 'id', title: 'Id'},
                    {name: 'channel', title: 'Channel'},
                    {name: 'content_type', title: 'Type'},
                    {name: 'content', title: 'Content', renderer: (item) => {
                        if (item.content_type === 'image') {
                            return <img src={item?.image?.url} width="50"/>
                        } else {
                            return item.content;
                        }
                    }},
                    {name: 'group.name', title: 'Group', renderer: (item) => {
                        if (item.group && item.group.id) {
                            return (
                                <Link to={`/cms/group/${item.group.id}/`}>
                                    {item.group.name}
                                </Link>
                            )
                        }
                        return false
                    }},
                    {name: 'side_context', title: 'Side'},
                    {name: 'market_types', title: 'Market Types', renderer: (item) => {
                        if (item.market_types && item.market_types.length > 0) {
                            if (!this.marketTypeMap) {
                                this.marketTypeMap = keyBy(application.marketTypes, 'id');
                            }
                            return item.market_types.map((marketTypeId) => {

                                return (this.marketTypeMap[marketTypeId] && this.marketTypeMap[marketTypeId].name) || ''
                            }).join(', ');
                        } else {
                            return 'All'
                        }
                    }},
                    {name: 'language', title: 'Language', renderer: (item) => {
                        if (item.language && this.languageMap[item.language]) {
                            return (
                                <span>
                                    <Flag name={this.languageMap[item.language].flag}/>
                                    {item.language}
                                </span>
                            )
                        }
                        return item.language;
                    }},
                    {name: 'link_target', title: 'Link Target'},
                    {name: 'provider', title: 'Provider', renderer: (item) => {
                        if (item.provider) {
                            return item.provider.name;
                        } else {
                            return 'All'
                        }
                    }},
                    {name: 'categories', title: 'Categories', renderer: (item) => {
                        const cats = (item.categories || []).map(cat=>cat.name);
                        return cats.join(', ');
                    }},
                    {name: null, title: 'Options', options: this.getOptions()}
                ]}
                items={content.items}
                totalRows={content.total}
                pageSize={content.limit}
                onPaginate={this.handlePagination}
            />
            <Modal
                dimmer
                closeIcon
                onClose={this.toggleModel}
                open={editModalVisible}>
                <Segment>
                    <ContentTemplateForm
                        content={content.currentContent}
                        onSubmit={this.submitForm}
                        markets={application.providerMarkets}
                        marketTypes={application.marketTypes}
                        categories={content.contentCategories}
                        providerLanguages={providerLanguages}
                        onToggleTagPanel={this.toggleTagPanel}
                    />
                </Segment>
            </Modal>
            <Modal
                dimmer
                closeIcon
                onClose={this.toggleTagPanel}
                open={tagsVisible}>
                <TagPanel/>
            </Modal>
            </div>
        )
    }
}

export default connect(state => {
    return {
        application: state.application,
        content: state.content
	}
})(ContentGrid);
