import React from 'react';
import {
    Segment,
    Grid,
    List
} from 'semantic-ui-react';

class ParticipantPanel extends React.Component {
    render() {
        const {match, onParticipantSelect} = this.props;
        const {participants} = match.meta;
        const columnKeys = Object.keys(participants);
        console.log(match)
        return (
            <Segment>
                <Grid columns='equal'>
                    {columnKeys.map((colKey, colIndx) => {
                        return (
                            <Grid.Column key={colIndx}>
                                <List link>
                                    <List.Item>
                                        <List.Header>{colKey}</List.Header>
                                    </List.Item>
                                    {participants[colKey].map((participant, index) => {
                                        return (
                                            <List.Item
                                                key={`${colIndx}-${index}`}
                                                onClick={() => {
                                                    onParticipantSelect(colKey, participant)
                                                }}>
                                                {participant.name}
                                            </List.Item>
                                        )
                                    })}
                                </List>
                            </Grid.Column>
                        )
                    })}
                </Grid>
            </Segment>
        )
    }

}

export default ParticipantPanel;