import React from 'react';
import {Dropdown} from 'semantic-ui-react';
import {connect} from 'react-redux'
import {fetchProviders, setProviderContext} from '../../redux/actions/app';


class ProviderSelector extends React.Component {
    componentDidMount() {
        fetchProviders()(this.props.dispatch);
    }

    providerOptions = () => {
        const {activeProviders} = this.props.application;
        return activeProviders.map((provider, index) => {
            return {
                value: provider.id,
                text: provider.name,
                key: index
            }
        })
    }

    handleChange = (ev, {name, value}) => {
        this.props.dispatch(setProviderContext(value));
    }

    render() {
        const {providerId} = this.props.application;
        return (
            <Dropdown
                selection
                name='providerId'
                value={providerId}
                placeholder='Operators'
                onChange={this.handleChange}
                options={this.providerOptions()}
            />
        )
    }
}

export default connect(state => {
    return {
        application: state.application
	}
})(ProviderSelector);
