import React, {useEffect, useState} from 'react';
import { Segment, Label, Button } from 'semantic-ui-react';
import { query } from '../../redux/actions/query';
import {useDispatch, useSelector} from 'react-redux';
import {POSITIVE, NEGATIVE, NEUTRAL, EMPTY, WIDGET_COLOURS} from './constants';


export default function StatDisplay({
    queryName,
    thresholds,
    matchId,
    evalResult,
    params,
    focusOn,
    match
}) {
    const {providerId, sportId} = useSelector(state => state.application);
    const [queryId, setQueryId] = useState(queryName);
    const [stats, setStats] = useState(null);
    const dispatch = useDispatch();
    useEffect(() => {
        query(sportId, queryName, params)(dispatch).then(response => {
            try {
                setStats(evalResult(response?.payload?.response?.result?.result, focusOn, matchId));
            } catch (e) {
                console.error("evalResult->", queryName, focusOn, response, e);
            }
            setQueryId(queryName);
        })
    }, [thresholds, queryName. focusOn, matchId])
    if (queryId != queryName) {
        return <div>Loading...</div>
    }
    return (
        <div className="stats-widget">
            {/* <div class="stats-widget-header">
                Both to score
            </div> */}
            <div className="stats-widget-row">
                {stats && thresholds.map((threshold, index) => {
                    const colorClass = WIDGET_COLOURS[stats[threshold].state] //stats[threshold] == undefined ? '' : stats[threshold] ? 'positive' : 'negative';
                    return (
                        <div
                            className={`stats-widget-cell ${colorClass}`}
                            key={`statthresh-${index}`}>
                            {threshold}
                        </div>
                    )
                })}
            </div>
        </div>
    )
}