import React from 'react';
import {connect} from 'react-redux'
import {updateMarket} from  '../../redux/actions/app';
import {MarketForm} from '../../components/market-form';
import { SavedThresholds } from '../../components/saved-thresholds';
import {
    Grid, Segment, Header, Loader, List, Button, Divider
} from 'semantic-ui-react';


class MarketSetup extends React.Component {
    state = {
        market: null,
    }

    handleChange = (ev, {name, value}) => {
        console.log("handleChange on main", name, value)
        this.setState({
            [name]: value
        })
    }

    selectMarket = (market) => {
        console.log("select market:", market);
        this.setState({
            market
        })
    }

    getMarketForm = (market) => {
        if (market) {
            return (
                <div>
                    <MarketForm
                        market={market}
                        onChange={this.handleChange}
                    />
                    <Button primary content='Save' onClick={this.handleSave}/>
                </div>
            )
        }
        return false;
    }

    handleSave = (ev) => {
        ev.preventDefault();
        const {providerId, sportId} = this.props.application;
        const {market} = this.state;
        updateMarket(providerId, sportId, market.id, market.meta)(this.props.dispatch);
    }

    render() {
        const {
            application,
        } = this.props;
        const {
            market
        } = this.state;
        const {
            providerMarkets,
            providersLoadings
        } = application;
        if (providersLoadings ) {
            return (<Loader active={true}/>)
        }
        return (
            <Grid celled='internally' columns='equal'>
                <Grid.Row>
                    <Grid.Column>
                        <Segment raised>
                            <Header as='h3'>Markets</Header>
                            <List link>
                                {providerMarkets.map((providerMarket, index) => {
                                    return (
                                        <List.Item
                                            as='a'
                                            key={index}
                                            active={market && providerMarket.id === market.id}
                                            market={providerMarket}
                                            onClick={() => {
                                                this.selectMarket(providerMarket)
                                            }}>
                                            {providerMarket.name}
                                        </List.Item>
                                    )
                                })}
                            </List>
                        </Segment>
                    </Grid.Column>
                    <Grid.Column>
                        {this.getMarketForm(market)}
                        <Divider/>
                        {market ? (
                            <SavedThresholds marketId={market.id}/>

                        ) : false}
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        )
    }
}

export default connect(state => {
    return {
        application: state.application,
    }
})(MarketSetup);
