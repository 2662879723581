import React from 'react';
import {connect} from 'react-redux'
import {
    fetchMatch,
    fetchMatchContent,
    fetchMatchMarkets,
    saveMatchContent,
    deleteMatchContent
} from '../../redux/actions/match';
import {
    fetchContents,
    fetchContentGroups,
    fetchContentCategories
} from '../../redux/actions/content';
import {fetchRules} from '../../redux/actions/app';
import {isEmpty} from 'lodash/lang';
import {MatchContentForm} from '../../components/content-form';
import {ContentCards} from '../../components/content-cards';
import {ContentPreview} from '../../components/content-preview';
import MatchWrapper from './match-wrapper';
import {ParticipantPanel} from '../../components/participants';
import {
    Grid, Form, Loader, Tab, Button, Confirm
} from 'semantic-ui-react';
import {
    ContentGroupSelector,
} from '../../components/selectors';
// import linkTarget from '../../components/selectors/link-target';


class MatchContent extends React.Component {
    state = {
        language: 'en',
        contentType: 'content',
        channelId: [1],
        marketId: [],
        groupId: '',
        linkTarget: '',
        editContent: null,
        activeTabIndex: 0,
        contentCategory: null,
        confirmDelete: false,
        deleteContent: null,
        participantPanel: false,
        showAutoBuild: false,
    }

    componentDidMount() {
        const {providerId, sportId} = this.props.application;
        const {matchId} = this.props.match.params;
        if (matchId) {
            fetchMatch(providerId, sportId, matchId)(this.props.dispatch).then(res => {
                this.loadContent();
                fetchMatchContent(providerId, sportId, matchId)(this.props.dispatch);
                fetchMatchMarkets(providerId, sportId, matchId)(this.props.dispatch);
            });

        }
        fetchRules(providerId, sportId)(this.props.dispatch);
        fetchContentGroups(providerId, sportId)(this.props.dispatch);
        fetchContentCategories(providerId, sportId)(this.props.dispatch);
    }

    onTabChange = (ev, {activeIndex}) => {
        this.setState({
            activeTabIndex: activeIndex
        })
    }

    loadContent = () => {
        const {providerId, sportId} = this.props.application;
        const {matchId} = this.props.match.params;
        const {
            language,
            contentType,
            channelId,
            groupId,
            contentCategory,
            marketId,
            linkTarget,
            showAutoBuild,
        } = this.state;
        const filter = {
            language_code: language,
            content_type: contentType,
            channel_id: channelId,
            group_id: groupId,
            category_id: contentCategory,
            market_id: marketId,
            link_target: linkTarget,
            auto_build: showAutoBuild,
            active: true
        }
        fetchContents(providerId, sportId, matchId, filter)(this.props.dispatch);
    }

    getContentOptions = () => {
        return (item) => {
            return [
                {
                    icon: 'soccer', title: 'Matches',
                    path: `/league/${item.id}/matches`
                }
            ]
        }
    }

    handleEdit = (content) => {
        this.setState({
            editContent: content,
            activeTabIndex: 0
        });
    }

    cancelDelete = () => {
        this.setState({
            confirmDelete: false,
            deleteContent: null
        })
    }

    toggleParticipantPanel = () => {
        this.setState({
            participantPanel: !this.state.participantPanel
        })
    }

    toggleShowAutoBuild = () => {
        this.setState({
            showAutoBuild: !this.state.showAutoBuild
        }, () => this.loadContent())
    }

    handleDelete = (content) => {
        const {confirmDelete, deleteContent} = this.state;
        const {providerId, sportId} = this.props.application;
        const {matchId} = this.props.match.params;

        if (!confirmDelete) {
            this.setState({
                confirmDelete: true,
                deleteContent: content
            })
        } else if (confirmDelete && deleteContent) {
            this.setState({
                confirmDelete: false,
                deleteContent: null
            }, () => {
                deleteMatchContent(providerId, sportId, matchId, deleteContent.id)(this.props.dispatch).then(() => {
                    fetchMatchContent(providerId, sportId, matchId)(this.props.dispatch);
                })
            });
        }
    }

    getContentGroups = () => {
        const {content} = this.props;
        if (!isEmpty(content.contentGroups)) {
            const groups = content.contentGroups.map((group, index) => {
                return {
                    value: group.id,
                    text: group.name,
                    key: index
                }
            })
            return [{value: '', text: '', key: -1}].concat(groups)
        }
        return [];
    }

    onFilterChange = (ev, {name, value, active}) => {
        if (name === 'contentCategory' && value === this.state.contentCategory) {
            value = null;
        }
        this.setState({
            [name]: value,
            participantPanel: false
        }, () => {
            this.loadContent()
        })
    }

    handleSubmit = (values) => {
        const {providerId, sportId} = this.props.application;
        const {matchId} = this.props.match.params;
        saveMatchContent(providerId, sportId, matchId, values)(this.props.dispatch).then(() => {
            if (!isEmpty(this.state.editContent)) {
                this.setState({
                    editContent: {}
                })
            }
        });
    }

    // handleUpdateContent = (content) => {
    //     const {matches} = this.props;
    //     const matchContent = matches.matchContent;
    // }

    handleContentChange = (ev, {name, value, contentState}) => {
        const filterFields = ['channelId', 'language', 'marketId', 'linkTarget'];
        if (filterFields.indexOf(name) > -1) {
            this.onFilterChange(ev, {name, value});
        }
    }

    onParticipantSelect = (teamName, participant) => {
        console.log('participants', teamName, participant)
    }

    render() {
        const {
            matches,
            content,
            application
        } = this.props;
        const {
            contentType,
            contentCategory,
            groupId,
            editContent,
            activeTabIndex,
            participantPanel,
            showAutoBuild,
        } = this.state;
        const {matchId} = this.props.match.params;
        if (matches.loading || !matches.currentMatch || isEmpty(matches.currentMatch)) {
           return (<Loader active={true}>Loading </Loader>)
        }
        return (

            <MatchWrapper
                activeTab='content'
                match={matches.currentMatch}
                markets={matches.matchMarkets}
                title='Content'>
                <Grid celled='internally' columns='equal'>
                    <Grid.Row>
                        <Grid.Column>
                            <Tab
                                activeIndex={activeTabIndex}
                                onTabChange={this.onTabChange}
                                panes={[
                                    {
                                        menuItem: 'Form',
                                        render: ()=> {
                                            return (
                                                <Tab.Pane>
                                                    <MatchContentForm
                                                        match={matches.currentMatch}
                                                        markets={application.providerMarkets}
                                                        marketTypes={application.marketTypes}
                                                        onSubmit={this.handleSubmit}
                                                        onUpdateContent={this.handleUpdateContent}
                                                        matchContent={matches.matchContent}
                                                        content={editContent}
                                                        onChange={this.handleContentChange}
                                                    />
                                                </Tab.Pane>
                                            )
                                        }
                                    },
                                    {
                                        menuItem: `Preview (${matches.matchContent.length})`,
                                        render: ()=> {
                                            return (
                                                <Tab.Pane>
                                                    <ContentPreview
                                                        matchContent={matches.matchContent}
                                                        onEdit={this.handleEdit}
                                                        onDelete={this.handleDelete}
                                                        matchId={matchId}
                                                    />
                                                </Tab.Pane>
                                            )
                                        }
                                    },
                                    // {
                                    //     menuItem: `Live Preview (${matches.matchContent.length})`,
                                    //     render: ()=> {
                                    //         return (
                                    //             <Tab.Pane>
                                    //                 <ContentPreview
                                    //                     matchContent={matches.matchContent}
                                    //                     matchId={matchId}
                                    //                     live={true}
                                    //                 />
                                    //             </Tab.Pane>
                                    //         )
                                    //     }
                                    // }
                                ]
                            }/>
                        </Grid.Column>
                        <Grid.Column>
                            <Form>
                                <Form.Group>
                                    <Form.Field>
                                        <Button.Group size='tiny'>
                                            <Button
                                                toggle
                                                active={contentType==='title'}
                                                name='contentType'
                                                value='title'
                                                content='Title'
                                                onClick={this.onFilterChange}/>
                                            <Button
                                                toggle
                                                active={contentType==='content'}
                                                name='contentType'
                                                value='content'
                                                content='Content'
                                                onClick={this.onFilterChange}/>
                                            <Button
                                                toggle
                                                active={contentType==='cta'}
                                                name='contentType'
                                                value='cta'
                                                content='CTA'
                                                onClick={this.onFilterChange}/>
                                            <Button
                                                toggle
                                                active={contentType==='image'}
                                                name='contentType'
                                                value='image'
                                                content='Image'
                                                onClick={this.onFilterChange}/>
                                        </Button.Group>

                                    </Form.Field>

                                    <Form.Field>
                                        <Button.Group size='tiny'>
                                            {content.contentCategories.map((category, index) => {
                                                return (
                                                    <Button
                                                        toggle
                                                        key={`btn-${index}`}
                                                        active={contentCategory===category.id}
                                                        name='contentCategory'
                                                        value={category.id}
                                                        content={category.name}
                                                        onClick={this.onFilterChange}
                                                    />
                                                )
                                            })}
                                        </Button.Group>

                                    </Form.Field>
                                    <Form.Field>
                                        <Button
                                            toggle
                                            size='tiny'
                                            active={participantPanel}
                                            name='participants'
                                            content='Participants'
                                            onClick={this.toggleParticipantPanel}/>
                                    </Form.Field>
                                    <Form.Field>
                                        <Button
                                            toggle
                                            size='tiny'
                                            active={showAutoBuild}
                                            name='showAutoBuild'
                                            content='Auto Build'
                                            onClick={this.toggleShowAutoBuild}/>
                                    </Form.Field>
                                    <Form.Field>
                                        <ContentGroupSelector
                                            name='groupId'
                                            selection={false}
                                            onChange={this.onFilterChange}
                                            value={groupId}
                                            options={this.getContentGroups()}
                                        />
                                    </Form.Field>

                                </Form.Group>
                            </Form>
                            {
                                participantPanel ?
                                <ParticipantPanel
                                    match={matches.currentMatch}
                                    onParticipantSelect={this.onParticipantSelect}
                                /> :
                                <ContentCards items={content.items}/>
                            }
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
                <Confirm
                    open={this.state.confirmDelete}
                    onCancel={this.cancelDelete}
                    onConfirm={this.handleDelete}
                />
            </MatchWrapper>
        )
    }
}

export default connect(state => {
    return {
        application: state.application,
        matches: state.matches,
        content: state.content
	}
})(MatchContent);
