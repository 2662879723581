const initialState = {
    application: {
        ready: false,
        authenticated: !!localStorage.getItem('token'),
        user: {},
        token: localStorage.getItem('token'),
        allProviders: [],
        activeProviders: [],
        providerId: parseInt(localStorage.getItem('providerId') || 1, 10),
        sportId: parseInt(localStorage.getItem('sportId') || 1, 10),
        currentProvider: null,
        providerMarkets: [],
        marketTypes: [],
        searchResults: {},
        searching: false,
        providersLoading: false,
        loading: false,
        basket: {
            id: null,
            items: [],
            content: [],
            saved: false,
        },
    },
    leagues: {
        currentLeague: null,
        items: []
    },
    events: {
        eventTypes: {},
        allEventTypes: [],
        currentEvent: {},
        items: []
    },

};

export default initialState;