import React from 'react';
import PropTypes from 'prop-types';
import {endsWith} from 'lodash/string';
import {
    Table, Input
} from 'semantic-ui-react';


class ThresholdFields extends React.Component {
    static propTypes = {
        market: PropTypes.object,
        name: PropTypes.string,
        onChange: PropTypes.func,
        showMarketLimits: PropTypes.bool,
        values: PropTypes.object
    }

    static defaultProps = {
        values: {}
    }

    state = {
        values: this.props.values || {}
    }

    static getDerivedStateFromProps(props, state) {
        if (props.values !== state.values) {
            return {
                values: props.values || {}
            }
        }
        return null;
    }

    handleChange = (ev, {name, value}) => {
        const {onChange} = this.props;
        const {values} = this.state;
        const [fieldName, fieldIndex] = name.split("__");
        if (!values?.[fieldName]) {
            values[fieldName] = ['', ''];
        }
        values[fieldName][parseInt(fieldIndex)] = value.indexOf('.') > -1 ? parseFloat(value) : parseInt(value, 10);
        this.setState(values, () => {
            onChange(ev, {
                name: this.props.name,
                value: values  
            })
        })
    }
 

    render() {
        const {market, showMarketLimits} = this.props;
        const {values} = this.state;
        const {thresholds} = market.meta;
        const keys = Object.keys(thresholds);
        return (
            <Table definition basic='very' collapsing columns={3}>
                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell/>
                        <Table.HeaderCell>From</Table.HeaderCell>
                        <Table.HeaderCell>To</Table.HeaderCell>
                    </Table.Row>
                </Table.Header>
                <Table.Body>
                    {keys.map((thresholdKey, index) => {
                        if (endsWith(thresholdKey, '_min') || endsWith(thresholdKey, '_max')) {
                            return false;
                        }
                        return (
                            <Table.Row key={index}>
                                <Table.Cell>
                                    {thresholdKey}
                                </Table.Cell>
                                <Table.Cell collapsing>
                                    <Input
                                        type='number'
                                        name={`${thresholdKey}__0`}
                                        value={values[thresholdKey]?.[0] || ''}
                                        onChange={this.handleChange}
                                    />
                                </Table.Cell>
                                <Table.Cell collapsing> 
                                    <Input
                                        type='number'
                                        name={`${thresholdKey}__1`}
                                        value={values[`${thresholdKey}`]?.[1] || ''}
                                        onChange={this.handleChange}
                                    /> 
                                </Table.Cell> 
                            </Table.Row>
                        )
                    })}
                </Table.Body>
            </Table>
        )
    }
}

export default ThresholdFields;