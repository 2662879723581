import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router'
import application from './app';
import leagues from './league';
import matches from './match';
import events from './event';
import content from './content';
import contacts from './contact';
import teams from './team';
import reports from './report';


const createRootReducer = (history) => combineReducers({
    router: connectRouter(history),
    application,
    leagues,
    matches,
    events,
    content,
    contacts,
    teams,
    reports,
});

export default createRootReducer;