import React, {useState, useEffect} from  'react';
import {parseContent, contentCompile} from '../../redux/actions/content';
import {useDispatch} from 'react-redux';
import {isEmpty} from 'lodash/lang';
import {Segment, Button} from 'semantic-ui-react';
import { Link } from 'react-router-dom';


export default function CompiledContentStack({
    providerId, sportId,
    homeId, awayId,
    leagueId, marketId,
    matchId=null, offer=null,
    selectionBehaviour=null,
    contentElements=null,
    side=null, matchCount=null,
    showTemplate=true,
    principal=null,
    minScore=null,
}) {
    const [lastX, setLastX] = useState(matchCount);
    const [compiled, setCompiled] = useState([]);
    const [parsed, setParsed] = useState([]);
    const [odds, setOdds] = useState(null);
    const dispatch = useDispatch();
    const compiler = () => {
        const params = {
            home_id: homeId,
            away_id: awayId,
            league_id: leagueId,
            market_id: marketId,
            behaviour: selectionBehaviour,
            side: side,
            principal: principal,
            content_elements: isEmpty(contentElements) ? null : contentElements,
            offer: {
                minutes: 10,
                outcomes: []
                //[{"operator_id":"4","label":"4","oddsDecimal":"1","odds":1000},{"operator_id":"3","label":"3","oddsDecimal":"1","odds":1000},{"operator_id":"1","label":"1","oddsDecimal":"2","odds":2000}]
            }
        }
        if (offer) {
            params.offer = offer;
        }
        contentCompile(providerId, sportId, params)(dispatch).then((response) => {
            if (!isEmpty(response?.payload?.response?.results)) {
                const results = response.payload.response.results;
                const separator = '';
                const fullContents = results.map(item => ({content: [`${item.title?.[0]?.content}${separator}${item?.content?.[0]?.content}${separator}${item?.cta?.[0]?.content}`]}))
                const extraParams = {
                    home_id: homeId,
                    away_id: awayId,
                    league_id: leagueId,
                    market_id: marketId,
                    match_limiter: lastX,
                    side: side,
                    principal: principal,
                    limiter: minScore ? {"min_score": minScore} : null,
                }
                setCompiled(results);
                parseContent(providerId, sportId, fullContents, matchId, null, extraParams)(dispatch).then((response) => {
                    setParsed(response.payload.response.results);
                    setOdds(response.payload.response.odds);
                })
            }
        })
    }
    const betslip = (ctaId) => {
        return () => {
            window.open(`https://bet.codere.mx/add-selns?bet_ref=${ctaId}`);
        }
    }
    useEffect(() => {
        compiler();
    }, [marketId, selectionBehaviour, lastX, minScore])
    if (!isEmpty(compiled)) {
        return (
            <div>
                {odds?.odds ? (
                    <Segment.Group horizontal>
                        {Object.keys(odds.odds).map((key, index) => (
                            <Segment key={`odd-${index}`}>
                                {odds.odds[key]?.label} /
                                {odds.odds[key]?.oddsFractional} /
                                {odds.odds[key]?.oddsDecimal} /
                                {odds.odds[key]?.oddsAmerican} /
                                <a
                                    href={`https://bet.codere.mx/add-selns?bet_ref=${odds.odds[key]?.id_alt}`}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >Betslip</a>
                            </Segment>
                        ))}
                    </Segment.Group>
                ) : false}
                {/* <Button.Group horizontal>
                    <Button primary={lastX === 3} onClick={()=>setLastX(3)}>Last 3</Button>
                    <Button primary={lastX === 6} onClick={()=>setLastX(6)}>Last 6</Button>
                    <Button primary={lastX === 9} onClick={()=>setLastX(9)}>Last 9</Button>
                    <Button primary={lastX === null} onClick={()=>setLastX(null)}>Season</Button>
                </Button.Group> */}
                {compiled.map((item, index) => {
                    const ctaTarget = item.cta?.[0]?.link_target;
                    const ctaId = odds?.odds?.[ctaTarget]?.id_alt;
                    return (
                        <Segment.Group key={index}>
                            { showTemplate ? (
                                <Segment color="red">
                                    {item?.title?.[0]?.content}<br/>
                                    {item?.content?.[0]?.content}<br/>
                                    {item?.cta?.[0]?.content}<br/>
                                </Segment>
                            ) : false }
                            <Segment
                                inverted
                                attached="top"
                                color="black"
                                onClick={() => {
                                    if (ctaId) betslip(ctaId);
                                }}
                            >
                                <span title={`Score: ${parsed?.[index]?.content?.[0]?.score}`}>
                                    {parsed?.[index]?.content?.[0]?.allow ? (
                                        parsed?.[index]?.content?.[0]?.rendered
                                    ) : (
                                        <s>{parsed?.[index]?.content?.[0]?.rendered}</s>
                                    )}
                                </span>
                            </Segment>
                        </Segment.Group>
                    )
                })}
            </div>
        )
    }
    return false;
}