import React from 'react';
import { Route, Switch } from 'react-router-dom';
import * as Pages from './pages';
import * as Leagues from './pages/leagues';
import * as Matches from './pages/matches';
import * as Events from './pages/events';
import * as Content from './pages/content';
import * as Contacts from './pages/contacts';
import * as Markets from './pages/markets';
import * as Teams from './pages/teams';
import * as Settings from './pages/settings';
import * as Reports from './pages/reports';

export default () => (
	<Switch>
		<Route path={['/', '/home']} exact component={Pages.Home}/>

		<Route path='/settings' component={Settings.ProviderSetup}/>

		<Route path='/leagues' component={Leagues.LeaguesGrid}/>

		<Route path='/league/:leagueId/matches' component={Matches.MatchesGrid}/>
		<Route path='/league/:leagueId/tables' exact component={Leagues.LeagueTables}/>
		<Route path='/league/:leagueId/tables/:seasonId' exact component={Leagues.LeagueTables}/>
		<Route path='/league/:leagueId/stats' exact component={Leagues.LeagueStats}/>
		<Route path='/league/:leagueId/stats/:seasonId' exact component={Leagues.LeagueStats}/>
		<Route path='/league/:leagueId/stats/:seasonId/team/:teamId' exact component={Leagues.LeagueStats}/>
		<Route path='/league/:leagueId/stats/:seasonId/team/:teamId/market/:marketId' exact component={Leagues.LeagueStats}/>

		<Route path='/league/:leagueId/stats2' exact component={Leagues.LeagueStats2}/>
		<Route path='/league/:leagueId/stats2/:seasonId/teams' exact component={Leagues.LeagueStats2}/>
		<Route path='/league/:leagueId/stats2/:seasonId/teams/:teamId/:otherTeamId' exact component={Leagues.LeagueStats2}/>
		<Route path='/league/:leagueId/stats2/:seasonId/teams/:teamId/:otherTeamId/market/:marketId' exact component={Leagues.LeagueStats2}/>

		<Route path='/matches' exact component={Matches.MatchesGrid}/>
		<Route path='/match/:matchId' exact component={Matches.MatchPage}/>
		<Route path='/match/:matchId/thresholds' exact component={Matches.MatchThresholds}/>
		<Route path='/match/:matchId/feed' exact component={Matches.MatchFeed}/>
		<Route path='/match/:matchId/delivery' exact component={Matches.MatchDelivery}/>
		<Route path='/match/:matchId/content' cexact component={Matches.MatchContent}/>
		<Route path='/trackers' exact component={Matches.TrackersGrid}/>

		<Route path='/events' exact component={Events.EventsGrid}/>
		<Route path='/events/:matchId' exact component={Events.EventsGrid}/>
		{/* <Route path='event' component={Events.EventForm}/> */}
		{/* <Route path='event/:eventId' component={Events.EventForm}/> */}
		<Route path='/event/:matchId/edit' exact component={Events.EventForm}/>

		<Route path='/cms' exact component={Content.ContentGrid}/>
		<Route path='/cms/edit' exact component={Content.ContentForm}/>
		<Route path='/cms/edit/:contentId' exact omponent={Content.ContentForm}/>
		<Route path='/cms/match/:matchId/edit' exact component={Content.MatchContent}/>
		<Route path='/cms/create/group' exact component={Content.GroupedContentForm}/>
		<Route path='/cms/group/:groupId/' exact component={Content.GroupedContentForm}/>
		<Route path='/cms/builder/' exact component={Content.ContentBuilder}/>

		<Route path='/contacts' exact component={Contacts.ContactsGrid}/>
		<Route path='/contacts/edit' exact component={Contacts.ContactsForm}/>
		<Route path='/contacts/:contactId/edit' exact component={Contacts.ContactsForm}/>

		<Route path='/markets' component={Markets.MarketSetup}/>

		<Route path='/teams' exact  component={Teams.TeamsPage}/>
		<Route path='/teams/league/:leagueId' exact component={Teams.TeamsPage}/>

		<Route path='/reports' exact component={Reports.DeliveriesGrid}/>

		<Route component={Pages.NotFound} />
	</Switch>
);
