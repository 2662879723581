import React from 'react';
import PropTypes from 'prop-types';
import {Link} from 'react-router-dom';
import Pagination from './pagination';
import {
    Table, Button
} from 'semantic-ui-react';


export default class DataGrid extends React.Component {
    static propTypes = {
        headers: PropTypes.array,
        items: PropTypes.array,
        totalRows: PropTypes.number,
        pageSize: PropTypes.number,
        onPaginate: PropTypes.func,
        onRowSelect: PropTypes.func,
        onSort: PropTypes.func,
        sortBy: PropTypes.string,
        sortDir: PropTypes.string,
        selectedRow: PropTypes.number,
        highlightRows: PropTypes.array,
        loading: PropTypes.bool,
    }

    static defaultProps = {
        pageSize: 20,
        sortBy: 'created_at',
        sortDir: 'descending',
        onPaginate: () => {},
        onSort: () => {},
        onRowSelect: null,
        highlightRows: [],
        loading: false,
    }

    state = {
        sortCol: this.props.sortBy,
        sortDir: this.props.sortDir
    }

    displayOptions = {}

    itemOptions = (header, item) => {
        const links = [];
        const options = header.options(item);
        options.forEach((option, index) => {
            if (option.component) {
                links.push((
                    <option.component
                        key={`${item?.id}-${index}`}
                        onClick={option.onClick}
                        icon={option.icon || 'remove'}
                    />
                ))
            } else if (option.onClick) {
                links.push((
                    <Button
                        key={index}
                        basic
                        icon={option.icon || null}
                        size='tiny'
                        title={option.title}
                        onClick={option.onClick}
                        loading={!!option.loading}
                        content={option.title}/>
                ));
            } else {
                const buttonProps = {}
                if (option.icon) {
                    buttonProps.icon = option.icon;
                }
                if (option.title) {
                    buttonProps.content = option.title;
                }
                links.push((
                    <Link key={index} to={`${option.path}`}>
                        <Button
                            basic
                            size='tiny'
                            {...buttonProps}
                        />
                    </Link>

                ));
            }
        })
        return links;
    }

    getItemCell = (header, item) => {
        if (header.renderer) {
            return header.renderer(item);
        } else if (header.name) {
            if (header.name.indexOf('.') > -1) {
                try {
                    // eslint-disable-next-line
                    return eval('item.' + header.name)
                } catch(e) {
                    return '';
                }
            } else {
                return item[header.name];
            }
        } else if (header.options) {
            return this.itemOptions(header, item);
        } else {
            return '';
        }
    }

    indexDisplayOptions = (header) => {
        if (this.displayOptions[header.name]) {
            return this.displayOptions[header.name];
        }
        if (header.align) {
            this.displayOptions[header.name].textAlign = header.align;
        }
        return this.displayOptions[header.name];
    }

    getCellDisplayOptions = (header) => {

    }

    paginate = (start) => {
        this.props.onPaginate(start);
    }

    setSort = (header) => {
        if (!header.name || !header.sortable) {
            return false;
        }
        this.setState({
            sortCol: header.name,
            sortDir: this.state.sortDir === 'ascending' ? 'descending' : 'ascending'
        }, () => {
            this.props.onSort({
                name: this.state.sortCol,
                direction: this.state.sortDir
            })
        })
    }

    getSort = (header) => {
        if (header.name === this.state.sortCol) {
            return this.state.sortDir;
        }
        return null;
    }

    handleRowSelect = (item, index) => {
        if (this.props.onRowSelect) {
            this.props.onRowSelect(item, index);
        }
    }

    render() {
        const {
            headers,
            items,
            totalRows,
            pageSize,
            onRowSelect,
            selectedRow,
            highlightRows,
            loading,
        } = this.props;
        return (
            <Table compact='very' sortable selectable>
                <Table.Header>
                <Table.Row>
                    {headers.map((header, index) => {
                        return (
                            <Table.HeaderCell
                                key={index}
                                name={header.name}
                                sorted={this.getSort(header)}
                                onClick={this.setSort.bind(null, header)}>
                                {header.title}
                            </Table.HeaderCell>
                        )
                    })}
                </Table.Row>
                </Table.Header>
                { loading ? (
                    <Table.Body>
                        <Table.Row colSpan={headers.length}>
                            <Table.Cell>
                                Loading...
                            </Table.Cell>
                        </Table.Row>
                    </Table.Body>
                ) : (
                    <Table.Body>
                        {items.map((item, index) => {
                            return (
                                <Table.Row key={index} onClick={()=>{
                                    if (!!onRowSelect) {
                                        onRowSelect(item, index);
                                    }
                                }}>
                                    {headers.map((header, hdrIndex) => {
                                        // const options = this.getCellDisplayOptions(header);
                                        return (
                                            <Table.Cell
                                                key={hdrIndex}
                                                positive={highlightRows.indexOf(item.id) > -1}
                                                active={item.id===selectedRow}>
                                                {this.getItemCell(header, item)}
                                            </Table.Cell>
                                        )
                                    })}
                                </Table.Row>
                            )
                        })}
                    </Table.Body>
                )}
                <Table.Footer>
                    <Table.Row>
                        <Table.HeaderCell colSpan={headers.length}>
                        <Pagination
                            totalRows={totalRows}
                            pageSize={pageSize}
                            onPaginate={this.paginate}
                        />
                        </Table.HeaderCell>
                    </Table.Row>
                </Table.Footer>
            </Table>
        )
    }
}