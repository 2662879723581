import React, { useRef } from 'react';
import { Input, Label, Icon } from 'semantic-ui-react';


export default function ({
	name, value = '', label = '', placeholder = '', accept,
	readOnly = false, error = null, icon = 'file',
	onChange = () => { },
}) {
	const ref = useRef();
	const formatValue = (value) => {
		if (typeof (value) === 'object' && value?.length && value?.length > 0) {
			return value[0].name;
		} else if (typeof (value) === 'string') {
			const parts = value.split('/');
			return parts[parts.length - 1];
		} else {
			return value?.name || '';
		}
	}
	const acceptTypes = {};
	if (accept) {
		acceptTypes.accept = accept;
	}

	return (
		<div>
			<Input
				name={name}
				placeholder={formatValue(value)}
				onClick={() => ref.current.click()}
				label={<Label basic content={label || ''} icon={icon} />}
				labelPosition='left'
			/>
			<input
				name='fileField'
				type='file'
				ref={ref}
				hidden
				onChange={(ev, props) => {
                    const value = ref.current.files;
					onChange(ev, {
						name: name,
						value: value
					})
				}}
				{...acceptTypes}
			/>
		</div>
	)
}
