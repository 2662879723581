import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {ContactSelector} from '../selectors';
import RelativeThresholdFields from './relative-thresholds';
import {find} from 'lodash/collection';
import {DEFAULT_RELATIVE_OUTCOME_THRESHOLDS } from './constants';
import {isEqual} from 'lodash/lang';

import {
    Form, Header, Button, Input, Divider, Checkbox
} from 'semantic-ui-react';
import { isEmpty } from 'lodash';
import { useInput } from '../../hooks';


export default function ({name, market=null, contacts, onChange, onSubmit, values={}}) {
    if (!market) {
        return false;
    }
    const formName = name;
    const [formData, dispatch] = useInput(values)
    // const relProps = isEmpty(formData.thresholds) ? DEFAULT_RELATIVE_THRESHOLDS : formData.thresholds;

    const handleChange = (ev, {value, name, type, checked}) => {
        dispatch({
            name: name,
            value: type === 'checkbox' ? checked : value
        })
    }
    const handleThresholdChange = (outcome, value) => {
        const thresholds = formData?.thresholds || {};
        thresholds[outcome] = value;
        dispatch({
            name: 'thresholds',
            value: thresholds
        })
    }
    return (
        <Form>
            <Form.Field>
                <Header as='h4'>
                    {market.name}
                    <Header.Subheader floated='right'>
                        <Button
                            primary
                            floated='right'
                            content='Submit'
                            onClick={() => {
                                onSubmit(formData)
                            }}
                        />
                    </Header.Subheader>
                </Header>
            </Form.Field>
            <Form.Field>
                <label>Contact</label>
                <ContactSelector
                    name='contactId'
                    value={formData?.contactId || ''}
                    contacts={contacts}
                    onChange={handleChange}
                />
            </Form.Field>
            <Form.Field>
                <label>Save as a template</label>
                <Input
                    name="saveAsTemplate"
                    placeholder="To save this as a template for future use, enter a name"
                    value={formData?.saveAsTemplate || ''}
                    onChange={handleChange}
                />
            </Form.Field>
            <Form.Field>
                <Checkbox
                    name="saveAsTemplateDefault"
                    value={''}
                    label='Set as default template'
                    checked={formData?.saveAsTemplateDefault}
                    onChange={handleChange}
                />
            </Form.Field>
            {market?.type?.outcomes.map((outcome, index) => {
                const relProps = isEmpty(formData?.thresholds?.[outcome]) ? DEFAULT_RELATIVE_OUTCOME_THRESHOLDS : formData.thresholds[outcome];
                return (
                    <Form.Field key={index}>
                        <RelativeThresholdFields
                            name='thresholds'
                            market={market}
                            outcome={outcome}
                            onChange={(ev, {value}) => handleThresholdChange(outcome, value)}
                            {...relProps}
                        />
                        <Divider/>
                    </Form.Field>
                )
            })}
        </Form>
    )
}
