import React from 'react';
import PropTypes from 'prop-types';
import {Dropdown} from 'semantic-ui-react';
import {connect} from 'react-redux'
import {search} from '../../redux/actions/app';


class TeamSelector extends React.Component {
    static propTypes = {
        name: PropTypes.string,
        value: PropTypes.array,
        options: PropTypes.array,
        onChange: PropTypes.func,
        multiple: PropTypes.bool,
    }

    static defaultProps = {
        name: 'team_id',
        value: [],
        options: [],
        multiple: true,
        onChange: () => {}
    }

    state = {
        value: this.props.value,
    }

    handleChange = (ev, {name, value}) => {
        this.setState({
            value
        })
    }

    handleSearch = (ev, {searchQuery}) => {
        const {providerId, sportId} = this.props.application;
        search(providerId, sportId, searchQuery, ['teams'], 15, 'name')(this.props.dispatch);

    }

    getOptions = () => {
        const {searching, searchResults} = this.props.application;
        const results = (searchResults
                         && searchResults.teams
                         && searchResults.teams.results) || [];
        const options = results && results.map((item, index) => {
            return {
                value: item.id,
                text: item.title,
                key: index
            }
        })
        return {
            searching: searching,
            options: options || []
        }
    }

    render() {
        const {
            name,
            multiple,
        } = this.props;
        const {
           value
        } = this.state;
        const {
            searching,
            options
        } = this.getOptions();
        return (
            <Dropdown
                search
                selection
                multiple={multiple}
                placeholder='Teams'
                name={name}
                value={value}
                loading={searching}
                options={options}
                onChange={this.handleChange}
                onSearchChange={this.handleSearch}
            />
        )
    }
}


export default connect(state => {
    return {
        application: state.application
    }
})(TeamSelector);
