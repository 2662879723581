import {Actions} from '../../constants';
import {createAction, createReceiver, getSortParams} from './utils';
import {Service} from '../../service';


export function fetchLeagues(providerId, sportId, search=null, start=0, sort='last_modified', statsOnly=false) {
	return function(dispatch) {
		dispatch(createAction(Actions.LEAGUES.GET));
		const params = {
			start,
			q: search,
			...getSortParams(sort)
		};
		if (statsOnly) {
			params.stats_only = true;
		}
		return Service.doGet({
			url: 'leagues',
			params: params,
			urlParams: {
				providerId,
				sportId
			}
		}).then(
			(response) => dispatch(createReceiver('LEAGUES', response, params))
		).catch(
			(response) => dispatch(createAction(Actions.LEAGUES.GET_FAIL, null, response)) );
	}
}

export function fetchLeague(providerId, sportId, leagueId) {
	return function(dispatch) {
		dispatch(createAction(Actions.LEAGUE.GET));
		return Service.doGet({
			url: 'league',
			urlParams: {
				providerId,
				sportId,
				leagueId
			}
		}).then(
			(response) => dispatch(createAction(Actions.LEAGUE.GET_SUCCESS, response, null))
		).catch(
			(response) => dispatch(createAction(Actions.LEAGUE.GET_FAIL, null, response)) );
	}
}


export function fetchSeason(sportId, leagueId, seasonId=null, teamId=null, homeAway=null, bank=1, matchCount=null) {
	const actionKeys = {
		1: [Actions.LEAGUE.GET_SEASON, Actions.LEAGUE.GET_SEASON_SUCCESS, Actions.LEAGUE.GET_SEASON_FAIL],
		2: [Actions.LEAGUE.GET_SEASON2, Actions.LEAGUE.GET_SEASON2_SUCCESS, Actions.LEAGUE.GET_SEASON2_FAIL]
	}
	const actionBank = actionKeys[bank];
	return function(dispatch) {
		dispatch(createAction(actionBank[0]));
		const params = {};
		const urlParams = {
			sportId,
			leagueId,
			seasonId,
		}
		if (teamId) {
			urlParams.teamId = teamId;
		}
		if (teamId && homeAway) {
			params.home_away = homeAway;
		}
		if (matchCount) {
			params.match_limiter = matchCount;
		}
		return Service.doGet({
			url: teamId ? 'leagueSeasonByTeam' : seasonId ? 'leagueSeason' : 'leagueSeason2',
			urlParams,
			params
		}).then(
			(response) => dispatch(createAction(actionBank[1], response, null))
		).catch(
			(response) => dispatch(createAction(actionBank[2], null, response)) );
	}
}
