import React from 'react'
import Layout from './layout';
import {connect} from 'react-redux'
import {fetchMarkets, fetchMarketTypes} from '../redux/actions/app';
import { ConnectedRouter } from 'connected-react-router';
import Routes from '../routes';


class Main extends React.Component{
    componentDidMount() {
        const {providerId, sportId} = this.props.application;
        fetchMarkets(providerId, sportId)(this.props.dispatch);
        fetchMarketTypes(sportId)(this.props.dispatch);
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        const {providerId, sportId} = nextProps.application; 
        if (providerId !== this.props.application.providerId ||
            sportId !== this.props.application.sportId) {
            fetchMarkets(providerId, sportId)(this.props.dispatch);
        }
    }

    render() {
        const {history} = this.props;
        return (
            <ConnectedRouter history={history}>

                <div className="App">
                    <Layout>
                        <Routes/>
                    </Layout>
                </div>

            </ConnectedRouter>
        )
    }
}

export default connect(state => {
    return {
        application: state.application
	}
})(Main);
