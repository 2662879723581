import React from 'react';
import ContentCard from './card';
import {Item} from 'semantic-ui-react';


export default function ContentCards({items}) {
    return (
        <Item.Group divided>
            {items.map((item, index) => {
                return (
                    <ContentCard key={index} content={item}/>
                )
            })}
        </Item.Group>
    )
}
