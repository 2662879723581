import {Actions} from '../../constants';

const initialContentState = {
    loading: false,
    items: [],
    total: 0,  // total resultset size
    limit: 0,  // page size
    currentContent: null,
    contentTypes: [],
    loadingContentId: null,
    contentGroups: [],
    currentContentGroup: {},
    contentCategories: [],
    queryTags: [],
    parsing: false,
    parsedContent: [],
};

function content(state=initialContentState, action) {
	switch(action.type) {
		case Actions.CONTENTS.GET:
			return {
				...state,
                loading: true
			};

		case Actions.CONTENTS.GET_SUCCESS:
			return {
				...state,
                loading: false,
                items: action.payload.response.results,
                total: action.payload.response.total,
                limit: action.payload.limit,
            }

        case Actions.CONTENTS.GET_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload.error
            }

        case Actions.CONTENT.GET:
			return {
				...state,
                loading: true,
                currentContent: null,
                loadingContentId: (action.payload && action.payload.id) || null
			};

        case Actions.CONTENT.GET_SUCCESS:
			return {
				...state,
                loading: false,
                loadingContentId: null,
                currentContent: action.payload.response.result
            }

        case Actions.CONTENT.GET_FAIL:
            return {
                ...state,
                loading: false,
                loadingContentId: null,
                error: action.payload.error
            }

        case Actions.CONTENT.GET_TYPES:
			return {
				...state,
                loading: true
			};

        case Actions.CONTENT.GET_TYPES_SUCCESS:
			return {
				...state,
                loading: false,
                contentTypes: action.payload.response.results
            }

        case Actions.CONTENT.GET_TYPES_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload.error
            }

        case Actions.CONTENT.CREATE:
            return {
                ...state,
                loading: true
            }

        case Actions.CONTENTS.GET_GROUPS:
            return {
                ...state,
                loading: true
            };

        case Actions.CONTENTS.GET_GROUPS_SUCCESS:
            return {
                ...state,
                loading: false,
                contentGroups: action.payload.response.results
            }

        case Actions.CONTENTS.GET_GROUPS_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload.error
            }

        case Actions.CONTENTS.GET_GROUP:
            return {
                ...state,
                loading: true
            };

        case Actions.CONTENTS.GET_GROUP_SUCCESS:
            return {
                ...state,
                loading: false,
                currentContentGroup: action.payload.response.result
            }

        case Actions.CONTENTS.GET_GROUP_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload.error
            }

        case Actions.CONTENT.CREATE_SUCCESS:
            return {
                ...state,
                loading: false,
                items: action.payload.response.results
            }

        case Actions.CONTENT.CREATE_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload.error
            }

        case Actions.CONTENT.UPDATE:
            return {
                ...state,
                loading: true
            }

        case Actions.CONTENT.UPDATE_SUCCESS:
            return {
                ...state,
                loading: false,
                items: action.payload.response.results
            }

        case Actions.CONTENT.UPDATE_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload.error
            }

        case Actions.CONTENT.CREATE_GROUP:
            return {
                ...state,
                loading: true
            }

        case Actions.CONTENT.CREATE_GROUP_SUCCESS:
            return {
                ...state,
                loading: false,
                contentGroups: action.payload.response.results
            }

        case Actions.CONTENT.CREATE_GROUP_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload.error
            }

        case Actions.CONTENTS.GET_CATEGORIES:
            return {
                ...state,
                contentCategories: []
            }

        case Actions.CONTENTS.GET_CATEGORIES_SUCCESS:
            return {
                ...state,
                contentCategories: action.payload.response.results
            }

        case Actions.CONTENTS.GET_CATEGORIES_FAIL:
            return {
                ...state,
                error: action.payload.error
            }

        case Actions.CONTENT.GET_QUERY_TAGS_SUCCESS:
            return {
                ...state,
                queryTags: action.payload.response.results
            }

        case Actions.CONTENT.GET_QUERY_TAGS_FAIL:
            return {
                ...state,
                error: action.payload.error
            }

        case Actions.CONTENT.PARSE:
            return {
                ...state,
                parsing: true
            }

        case Actions.CONTENT.PARSE_SUCCESS:
            return {
                ...state,
                parsing: false,
                parsedContent: action.payload.response.results
            }

        case Actions.CONTENT.PARSE_FAIL:
            return {
                ...state,
                parsing: false,
                error: action.payload.error
            }

        case Actions.CONTENT.TOGGLE_AUTO_BUILD_SUCCESS:
            state.items.forEach(item => {
                if (item.id === action.payload.response.result.id) {
                    item.auto_build = !item.auto_build;
                }
            })
            return {
                ...state,

            }

        case Actions.CONTENT.TOGGLE_ACTIVE_SUCCESS:
            state.items.forEach(item => {
                if (item.id === action.payload.response.result.id) {
                    item.deliver = !item.deliver;
                }
            })
            return {
                ...state,

            }

		default:
			return state;
	}
}

export default content;
