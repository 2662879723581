/**
When HOME|AWAY|OVER|UNDER odds DROP|RISE by X PERCENT|VALUE
within the last X MINUTES|SECONDS of GAME-TIME|REAL-TIME
**/
import React, {useEffect} from 'react';
import {isEmpty} from 'lodash/lang';
import { useInput } from '../../hooks';
import {
    Form, Input, Dropdown, Label
} from 'semantic-ui-react';
import {DEFAULT_RELATIVE_THRESHOLDS} from './constants';


export default function RelativeThresholdFields({outcome=null, onChange, name, market, ...props}) {
    const fieldName = name;
    const [values, dispatch] = useInput(props || DEFAULT_RELATIVE_THRESHOLDS);
    const thresholdKeys = Object.keys(market?.meta?.thresholds || [])

    const handleChange = (ev, {name, value}) => {
        dispatch({name, value})
    }


    useEffect(() => {
        onChange(null, {name, value: values})
    }, [values])

    return (
        <React.Fragment>
            <Form.Group widths={3}>
                <Form.Field>
                    <label>When</label>
                    {outcome ? (
                        <Label basic>{outcome}</Label>
                    ) : (
                        <Dropdown
                            selection
                            name="outcome"
                            placeholder="Outcome"
                            value={values.outcome}
                            onChange={handleChange}
                            options={thresholdKeys.map((outcome, index)=> {
                                return {
                                    key: `outcome-${index}`,
                                    text: outcome,
                                    value: outcome
                                }
                            })}
                        />
                    )}
                </Form.Field>
                <Form.Field>
                    <label>Rise or Drop</label>
                    <Dropdown
                        selection
                        name="direction"
                        placeholder="Select"
                        value={values.direction}
                        onChange={handleChange}
                        options={[
                            {key: 'rise', text: 'Rise', value: 'RISE'},
                            {key: 'drop', text: 'Drop', value: 'DROP'},
                        ]}
                    />
                </Form.Field>
                <Form.Field>
                    <label>By</label>
                    <Input name="value"
                        value={values.value || ''}
                        onChange={handleChange}
                        placeholder='% or value'
                        action={(
                            <Dropdown
                                selection
                                compact
                                name="metric"
                                value={values.metric}
                                onChange={handleChange}
                                options={[
                                    {key: 'percent', text: 'Percent %', value: 'PERCENT'},
                                    {key: 'value', text: 'Value', value: 'VALUE'},
                                ]}
                            />
                        )}
                    />
                </Form.Field>
            </Form.Group>
            <Form.Group widths={2}>
                <Form.Field>
                    <label>Within the last</label>
                    <Input name="time"
                        value={values.time || ''}
                        onChange={handleChange}
                        placeholder='Min / Sec'
                        action={(
                            <Dropdown
                                selection
                                name="timeMetric"
                                value={values.timeMetric}
                                onChange={handleChange}
                                options={[
                                    {key: 'min', text: 'Minutes', value: 'MINUTES'},
                                    {key: 'sec', text: 'Seconds', value: 'SECONDS'},
                                ]}
                            />
                        )}
                    />
                </Form.Field>
                <Form.Field>
                    <label>Of</label>
                    <Dropdown
                        selection
                        name="timeMetricType"
                        placeholder="Select"
                        value={values.timeMetricType}
                        onChange={handleChange}
                        options={[
                            {key: 'real', text: 'Real time', value: 'REAL'},
                            {key: 'game', text: 'Game time', value: 'GAME'},
                            {key: 'start', text: 'Match Start', value: 'START'},
                        ]}
                    />
                </Form.Field>
            </Form.Group>
        </React.Fragment>
    )
}
