import React from 'react';
import PropTypes from 'prop-types';
import {isEmpty} from 'lodash/lang';
import {MinutesInput} from '../../components/minutes';
import {
    Form,
    Dropdown,
    Button,
} from 'semantic-ui-react';


class StatisticEventForm extends React.Component {
    static propTypes = {
        name: PropTypes.string,
        eventTypes: PropTypes.array,
        match: PropTypes.object,
        event: PropTypes.object,
        onChange: PropTypes.func,
    }

    static defaultProps = {
        onChange: () => {}
    }

    state = {
        event: this.props.event,
        home: null,
        away: null,
    }

    static getDerivedStateFromProps(props, state) {
        if (props.match && props.match.home && props.match.away && (
                !state.home || !state.away || state.home.id !== props.match.home.id || state.away.id !== props.match.away.id
            )) {
            return {
                home: props.match.home,
                away: props.match.away
            }
        }
        return null;
    }

    propagateChange = (ev) => {
        this.props.onChange(ev, {
            name: this.props.name,
            value: this.state.event
        })
    }

    handleChange = (ev, {name, value, period, type}) => {
        const {event} = this.state;
        event[name] = value;
        if (period) {
            event.period = period;
        }
        this.setState({
            event: event
        }, () => {
            this.propagateChange(ev)
        })
    }

    handlePeriodSelect = (ev, {name}) => {
        const {event} = this.state;
        if (event.period === parseInt(name, 10)) {
            event.period = null;
        } else {
            event.period = parseInt(name, 10);
        }
        this.setState({
            event
        }, () => {
            this.propagateChange(ev)
        })
    }

    handleTeamSelect = (ev, {name}) => {
        const {event} = this.state;
        if (event.team && event.team.id === this.state[name].id) {
            event.team = null;
        } else {
            event.team = this.state[name];
        }
        this.setState({
            event
        }, () => {
            this.propagateChange(ev)
        })
    }

    getTeamName = (teamLabel) => {
        if (this.state[teamLabel] && this.state[teamLabel].name) {
            return `[${this.state[teamLabel].name}]`;
        } else {
            return '';
        }
    }

    getSelectedTeamLabel = () => {
        const {event, home} = this.state;
        console.log("getSelectedTeamLabel", this.state, this.props)
        if (!event.team) {
            return null;
        }
        return event.team.id === home.id ? 'home' : 'away';
    }

    getParticipants = (teamLabel) => {
        teamLabel = teamLabel || this.getSelectedTeamLabel();
        const {match} = this.props;
        if (!isEmpty(this.state[teamLabel]) &&
            !isEmpty(match.meta) &&
            !isEmpty(match.meta.participants)) {
            const team = this.state[teamLabel];
            const participants = match.meta.participants;
            return participants[team.name] || [];
        }
        return [];
    }

    getCurrentParticipants = () => {
        const participants = this.getParticipants();
        return participants && participants.map((part, index) => {
            return {
                text: part.name, //s.en_GB.name,
                value: part.id,
                key: index
            }
        })
    }

    render() {
        const {event, home, away} = this.state;
        const participants = this.getCurrentParticipants()
        return (
            <Form>
                <Form.Group >
                    <Form.Field>
                        <label>Event Type</label>
                        <Dropdown
                            selection
                            name='type'
                            value={event.type || 1}
                            options={this.props.eventTypes}
                            onChange={this.handleChange}
                        />
                    </Form.Field>
                    <Form.Field>
                        <label>Team</label>
                        <Button
                            name='home'
                            positive={event.team && event.team.id === home.id}
                            onClick={this.handleTeamSelect}>Home {this.getTeamName('home')}</Button>
                        <Button
                            name='away'
                            positive={event.team && event.team.id === away.id}
                            onClick={this.handleTeamSelect}>Away {this.getTeamName('away')}</Button>
                    </Form.Field>
                    <Form.Field>
                        <label>Game Time (minues)</label>
                        <MinutesInput
                            name='game_time'
                            value={event.game_time || ''}
                            period={event.period || null}
                            onChange={this.handleChange}/>
                    </Form.Field>
                    <Form.Field>
                        <label>Participant</label>
                        <Dropdown
                            search
                            selection
                            name='participant'
                            options={participants}
                            value={event.participant}
                            onChange={this.handleChange}
                        />
                    </Form.Field>
                </Form.Group>
            </Form>
        )
    }
}

export default StatisticEventForm;