import {Actions} from '../../constants';
import {createAction} from './utils';
import {Service} from '../../service';


export function setProviderContext(providerId) {
	localStorage.setItem('providerId', providerId);
    return {
        type: Actions.APP.SET_PROVIDER,
        payload: {
            providerId: providerId
        }
    }
}


export function setSportContext(sportId) {
	localStorage.setItem('sportId', sportId);
    return {
        type: Actions.APP.SET_SPORT,
        payload: {
            sportId: sportId
        }
    }
}

export function _addError(error) {
	if (process.env.NODE_ENV === 'development') {
		console.error("ERROR->", error)
	}
	return {
		type: Actions.APP.ADD_ERROR,
		payload: {'error': error}
	}
}

export function fetchProviders() {
	return function(dispatch) {
		dispatch(createAction(Actions.APP.GET_PROVIDERS));
		return Service.doGet({
			url: 'providers',
			params: {
				active_only: false
			}
		}).then(
			(response) => dispatch(createAction(Actions.APP.GET_PROVIDERS_SUCCESS, response, null))
		).catch(
			(response) => dispatch(createAction(Actions.APP.GET_PROVIDERS_FAIL, null, response)) );
	}
}

export function fetchProvider(providerId) {
	return function(dispatch) {
		dispatch(createAction(Actions.APP.GET_PROVIDER));
		return Service.doGet({
			url: 'providers',
			urlParams: {
				provider_id: providerId
			}
		}).then(
			(response) => dispatch(createAction(Actions.APP.GET_PROVIDER_SUCCESS, response, null))
		).catch(
			(response) => dispatch(createAction(Actions.APP.GET_PROVIDER_FAIL, null, response)) );
	}
}

export function fetchAllSports() {
	return function(dispatch) {
		dispatch(createAction(Actions.APP.GET_SPORTS));
		return Service.doGet({
			url: 'sports'
		}).then(
			(response) => dispatch(createAction(Actions.APP.GET_SPORTS_SUCCESS, response, null))
		).catch(
			(response) => dispatch(createAction(Actions.APP.GET_SPORTS_FAIL, null, response)) );
	}
}

export function fetchMarkets(providerId, sportId) {
	return function(dispatch) {
		dispatch(createAction(Actions.APP.GET_MARKETS));
		return Service.doGet({
			url: 'markets',
			urlParams: {
				providerId,
				sportId
			}
		}).then(
			(response) => dispatch(createAction(Actions.APP.GET_MARKETS_SUCCESS, response, null))
		).catch(
			(response) => dispatch(createAction(Actions.APP.GET_MARKETS_FAIL, null, response)) );
	}
}


export function fetchMarketTypes(sportId) {
	return function(dispatch) {
		dispatch(createAction(Actions.APP.GET_MARKET_TYPES));
		return Service.doGet({
			url: 'marketTypes',
			urlParams: {
				sportId
			}
		}).then(
			(response) => dispatch(createAction(Actions.APP.GET_MARKET_TYPES_SUCCESS, response, null))
		).catch(
			(response) => dispatch(createAction(Actions.APP.GET_MARKET_TYPES_FAIL, null, response)) );
	}
}

export function fetchRules(providerId, sportId) {
	return function(dispatch) {
		dispatch(createAction(Actions.APP.GET_RULES));
		return Service.doGet({
			url: 'rules',
			urlParams: {
				providerId,
				sportId
			}
		}).then(
			(response) => dispatch(createAction(Actions.APP.GET_RULES_SUCCESS, response, null))
		).catch(
			(response) => dispatch(createAction(Actions.APP.GET_RULES_FAIL, null, response)) );
	}
}

export function authenticate(email, password) {
	return function(dispatch) {
		dispatch(createAction(Actions.APP.AUTHENTICATE))
		return Service.doPost({
			url: 'authenticate',
			params: {
				email,
				password
			}
		}).then(
			(response) => dispatch(createAction(Actions.APP.AUTHENTICATE_SUCCESS, response, null))
		).catch(
			(response) => dispatch(createAction(Actions.APP.AUTHENTICATE_FAIL, null, response))
		)
	}
}

export function search(providerId, sportId, query, searchFor=null, limit=10, orderBy=null) {
	return function(dispatch) {
		dispatch(createAction(Actions.APP.SEARCH));
		return Service.doGet({
			url: 'search',
			urlParams: {
				providerId,
				sportId,
			},
			params: {
				q: query,
				type: searchFor,
				limit: limit,
				order_by: orderBy
			}
		}).then(
			(response) => dispatch(createAction(Actions.APP.SEARCH_SUCCESS, response, null))
		).catch(
			(response) => dispatch(createAction(Actions.APP.SEARCH_FAIL, null, response)) );
	}
}


export function updateMarket(providerId, sportId, marketId, updateSpec) {
	return function(dispatch) {
		dispatch(createAction(Actions.APP.UPDATE_MARKET))
		return Service.doPost({
			url: 'market',
			urlParams: {
				providerId,
				sportId,
				marketId
			},
			params: {
				market_meta: JSON.stringify(updateSpec)
			}
		}).then(
			(response) => dispatch(createAction(Actions.APP.UPDATE_MARKET_SUCCESS, response, null))
		).catch(
			(response) => dispatch(createAction(Actions.APP.UPDATE_MARKET_FAIL, null, response))
		)
	}
}

export function updateProvider(providerId, env=null, params=null) {
	return function(dispatch) {
		dispatch(createAction(Actions.APP.UPDATE_PROVIDER))
		return Service.doPost({
			url: env || 'provider',
			urlParams: {
				providerId,
			},
			params: params
		}).then(
			(response) => dispatch(createAction(Actions.APP.UPDATE_PROVIDER_SUCCESS, response, null))
		).catch(
			(response) => dispatch(createAction(Actions.APP.UPDATE_PROVIDER_FAIL, null, response))
		)
	}
}

export function addToBasket(content) {
	return createAction(Actions.APP.ADD_BASKET, {content});
}

export function toggleHold(content, index) {
	return createAction(Actions.APP.BASKET_TOGGLE_HOLD, {content, index});
}

export function removeFromBasket(content, index) {
	return createAction(Actions.APP.REMOVE_FROM_BASKET, {content, index});
}

export function saveBasket() {

}


export function fetchSavedThresholds(providerId, marketId=null) {
	return function(dispatch) {
		dispatch(createAction(Actions.APP.GET_SAVED_THRESHOLDS));
		return Service.doGet({
			url: marketId ? 'marketSavedThresholds' : 'savedThresholds',
			urlParams: {
				providerId,
				marketId,
			}
		}).then(
			(response) => dispatch(createAction(Actions.APP.GET_SAVED_THRESHOLDS_SUCCESS, response, null))
		).catch(
			(response) => dispatch(createAction(Actions.APP.GET_SAVED_THRESHOLDS_FAIL, null, response)) );
	}
}


export function deleteSavedThresholds(providerId, thresholdId) {
	return function(dispatch) {
		dispatch(createAction(Actions.APP.DELETE_SAVED_THRESHOLDS));
		return Service.doDelete({
			url: 'deleteSavedThresholds',
			urlParams: {
				providerId,
				thresholdId,
			}
		}).then(
			(response) => dispatch(createAction(Actions.APP.DELETE_SAVED_THRESHOLDS_SUCCESS, response, null))
		).catch(
			(response) => dispatch(createAction(Actions.APP.DELETE_SAVED_THRESHOLDS_FAIL, null, response)) );
	}
}
