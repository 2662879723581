import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux'
import {
    connectMatch,
    matchConnectSearch,
} from '../../redux/actions/match';
import {Search} from 'semantic-ui-react';


class MatchConnector extends React.Component {
    static propTypes = {
        matchId: PropTypes.number.isRequired,
        name: PropTypes.string,
    }

    state = {
        value: '',
    }

    handleResultSelect = (ev, {result}) => {
        const {application, name, matchId, dispatch} = this.props;
        const {currentProvider, providerId, sportId} = application;
        this.setState({
            value: '',
        }, () => {
            const confirmed = window.confirm(`Are you sure you want to reassign ${name} (of ${currentProvider.name}) as ${result.title}?`)
            if (confirmed) {
                connectMatch(sportId, providerId, matchId, result.id)(dispatch)
            }
        })
    }

    handleSearchChange = (ev, {value}) => {
        const {application, matchId, dispatch} = this.props;
        const {providerId, sportId} = application;
        if (value.endsWith('.')) {
            matchConnectSearch(providerId, sportId, matchId, this.state.value)(dispatch);
        } else {
            this.setState({
                value,
            })
        }
    }

    resultRenderer = (match) => {
        const providers = match.providers.map(prov => prov.name).join(', ');
        return `${match.title} (${providers}) ${match.start}`
    }

    render() {
        const {searchResults, loading} = this.props;
        const {value} = this.state;
        return (
            <Search
                loading={loading}
                onResultSelect={this.handleResultSelect}
                onSearchChange={this.handleSearchChange}
                results={searchResults}
                value={value}
                resultRenderer={this.resultRenderer}
            />
        )
    }
}

export default connect(state => {
    return {
        application: state.application,
        loading: state.matches.connectorLoading,
        searchResults: state.matches.connectorSearchItems
	}
})(MatchConnector);