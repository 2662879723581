import React from 'react';
import {connect} from 'react-redux'
import {fetchMatch, fetchMatchDeliveries} from '../../redux/actions/match';
import {isEmpty} from 'lodash/lang';
import {DataGrid} from '../../components/grid';
import MatchWrapper from './match-wrapper';
import ReactJson from 'react-json-view';
import {
    Grid, Loader, Divider, Message
} from 'semantic-ui-react';


function DeliveryStats({report}) {
    if (!report?.total) {
        return "N/A"
        //`I=${report?.ios?.sent} / ${report?.ios?.open} - A=${report?.android?.sent} / ${report?.android?.open}`
    } 
    return (
        <span title="Sent / Open">
            IOS={report?.ios?.sent} / {report?.ios?.open}<br/>
            AND={report?.android?.sent} / {report?.android?.open}<br/>
            TTL={report?.total?.sent} / {report?.total?.open}
        </span>
    )
}

class MatchDelivery extends React.Component {
    state = {
        deliveryData: null,
        selectedRow: null
    }

    componentDidMount() {
        const {providerId, sportId} = this.props.application;
        const {matchId} = this.props.match.params;
        if (matchId) {
            fetchMatch(providerId, sportId, matchId)(this.props.dispatch).then(res => {
                fetchMatchDeliveries(providerId, sportId, matchId)(this.props.dispatch)
            });

        }
    }

    hideRawView = () => {
        this.setState({
            deliveryData: null
        })
    }

    handleRowSelection = (item, index) => {
        const deliveryData = (this.state.deliveryData && item.id === this.state.deliveryData.id) ? null : item;
        this.setState({
            deliveryData: deliveryData,
            selectedRow: deliveryData && item.id
        })
    }

    loadDeliveries = (start=0) => {
        const {providerId, sportId} = this.props.application;
        const {matchId} = this.props.match.params;
        const params = {
            start: start
        }
        fetchMatchDeliveries(providerId, sportId, matchId, params)(this.props.dispatch)
    }

    handlePagination = (start) => {
        this.loadDeliveries(start)
    }

    render() {
        const {
            matches,
        } = this.props;
        const {
            deliveryData,
            selectedRow
        } = this.state;
        if (matches.loading || !matches.currentMatch || isEmpty(matches.currentMatch)) {
           return (<Loader active={true}/>)
        }
        console.log(deliveryData)
        return (
            <MatchWrapper activeTab='delivery' match={matches.currentMatch} title='Delivery'>
                <Grid>
                    <Grid.Column width={!!deliveryData ? 10 : 16}>
                        <DataGrid
                            headers={[
                                {name: 'id', title: 'Id'},
                                {name: 'channel', title: 'Channel'},
                                {name: 'market.name', title: 'Market'},
                                {name: 'contact', title: 'Contact'},
                                {name: 'language', title: 'Language'},
                                {title: 'Stats', renderer: item => (<DeliveryStats report={item?.stats?.report}/>)},
                                {name: 'status', title: 'Status'},
                                {name: 'sent_at', title: 'Sent At'},
                                {name: 'log.content', title: 'Content'},

                            ]}
                            items={matches.deliveries}
                            totalRows={matches.totalDeliveries}
                            pageSize={matches.deliveriesLimit}
                            onPaginate={this.handlePagination}
                            onRowSelect={this.handleRowSelection}
                            selectedRow={selectedRow}
                        />
                    </Grid.Column>
                    {deliveryData ? (
                        <Grid.Column width={6}>
                            <Message info>
                            {deliveryData.log.content}<br/>
                            {deliveryData.log.cta}
                            </Message>
                            <Divider/>
                            <ReactJson
                                src={deliveryData}
                                theme='monokai'
                                displayDataTypes={false}
                            />
                        </Grid.Column>
                    ) : false}
                </Grid>
            </MatchWrapper>
        )
    }
}

export default connect(state => {
    return {
        application: state.application,
        matches: state.matches
	}
})(MatchDelivery);
