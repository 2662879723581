/**
 * This module creates a function that receives the history object and returns
 * a transition middleware. This middleware will check if an action dispatched
 * has a transition key in its meta block and will transition to it.
 * Transitions are done by calling pushState on the history object.
 * If replaceState is required, the meta block can contain
 * a key "replace: true".
 */
export default history => store => next => action => {
	if ( !action.meta || !action.meta.transition || !action.meta.transition.path ) {
		return next(action);
	}
	const transition = action.meta.transition || {};
	const method = transition.replace ? 'replace' : 'push';
	history[method](transition.path);
	return next(action);
}
