import React from 'react';
import history from '../../utils/history';
import {Search} from 'semantic-ui-react';
import {connect} from 'react-redux'
import {search} from '../../redux/actions/app';


class SearchField extends React.Component {
    state = {
        value: '',
        loading: false
    }

    handleResultSelect = (ev, {result}) => {
        this.setState({
            value: '',
            loading: false
        }, () => {
            if (result.link) {
                history.replace(result.link);
            }
        })
    }

    handleSearchChange = (ev, {value}) => {
        const {providerId, sportId} = this.props.application;
        this.setState({
            value,
            loading: value.length === 0 ? false : true
        }, () => {
            search(providerId, sportId, value, ['leagues', 'matches'])(this.props.dispatch);
        })
    }

    render() {
        const {searchResults} = this.props.application;
        const {value, loading} = this.state;
        return (
            <Search
                category
                loading={loading}
                onResultSelect={this.handleResultSelect}
                onSearchChange={this.handleSearchChange}
                results={searchResults}
                value={value}
            />
        )
    }
}

export default connect(state => {
    return {
        application: state.application
	}
})(SearchField);
