import React from 'react';
import PropTypes from 'prop-types';
import {Dropdown} from 'semantic-ui-react';
import {connect} from 'react-redux'
import {intersection} from 'lodash/array';


class RuleSelector extends React.Component {
    static propTypes = {
        name: PropTypes.string,
        value: PropTypes.number,
        marketId: PropTypes.array,
        marketTypeId: PropTypes.array,
        onChange: PropTypes.func
    }

    static defaultProps = {
        value: null,
        marketId: [],
        marketTypeId: [],
    }

    state = {
        value: this.props.value
    }

    handleChange = (ev, {name, value}) => {
        this.setState({
            value: value
        }, () => {
            this.props.onChange(ev, {name, value})
        })
    }

    getRuleOptionsByMarket = () => {
        const {marketId, marketTypeId} = this.props;
        const {rules} = this.props.application;
        const marketRules = [];
        if (!marketId && !marketTypeId) {
            return rules.map((rule, index) => {
                return {value: rule.id, text: rule.name, key: index}
            });
        } else if (rules) {
            rules.forEach((rule) => {
                const ruleMarketIds = rule.markets.map(market => market.id);
                const ruleMarketTypeIds = rule.markets.map(market => market.type && market.type.id);
                const intersected = intersection(marketId.length > 0 ? marketId : marketTypeId, ruleMarketIds) 
                if (intersected.length > 0) {
                    marketRules.push({text: rule.name, value: rule.id, key: rule.id})
                }
            })
        }
        return marketRules;
    }

    render() {
        const {
            name,
        } = this.props;
        const {
            value
        } = this.state;

        return (
            <Dropdown
                selection
                fluid
                name={name || 'ruleset_id'}
                placeholder='Market Rule'
                value={value}
                options={this.getRuleOptionsByMarket()}
                onChange={this.handleChange}
            />
        )
    }
}

export default connect(state => {
    return {
        application: state.application
    }
})(RuleSelector);
