import {Actions} from '../../constants';
import {createAction} from './utils';
import {Service} from '../../service';


export function query(sportId, queryName, params={}) {
	return function(dispatch) {
		dispatch(createAction(Actions.QUERY.EXECUTE));
		return Service.doPost({
			url: 'query',
            params: {
                query: queryName,
                params
            },
            urlParams: {
				sportId,
			}
		})
		.then( (response) => dispatch(createAction(Actions.QUERY.EXECUTE_SUCCESS, response, null)) )
		.catch( (response) => dispatch(createAction(Actions.QUERY.EXECUTE_FAIL, null, response)) );
	}
}
