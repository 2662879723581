import React from 'react';
import history from '../../utils/history';
import {DataGrid} from '../../components/grid';
import {connect} from 'react-redux'
import {
    fetchTeams,
    updateTeam,
} from '../../redux/actions/team';
import {fetchLeague} from '../../redux/actions/league';
import {LeagueSelector} from '../../components/selectors';
import {TeamConnector} from '../../components/team-connector';
import {
    Segment, Header, Form, Input
} from 'semantic-ui-react';

class TeamsGrid extends React.Component {
    state = {
        leagueId: null,
        keyword: null,
    }

    componentDidMount() {
        const {providerId, sportId} = this.props.application;
        const {leagueId} = this.props.match.params;
        if (leagueId) {
            fetchLeague(providerId, sportId, leagueId)(this.props.dispatch);
        }
        fetchTeams(providerId, sportId, leagueId)(this.props.dispatch);
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        const {application} = this.props;
        const {providerId, sportId} = nextProps.application;
        const {leagueId} = nextProps.match.params;
        if (providerId !== application.providerId ||
            sportId !== application.sportId ||
            leagueId !== this.props.match.params.leagueId) {
            if (leagueId && leagueId !== this.props.match.params.leagueId) {
                fetchLeague(providerId, sportId, leagueId)(this.props.dispatch);
            }
            fetchTeams(providerId, sportId, leagueId)(this.props.dispatch);
        }
    }

    getHeader = () => {
        const {match, leagues, application} = this.props;
        const {params} = match;
        const {currentProvider} = application;
        return (
            <Segment raised clearing>
                <Header as='h2'>
                    { params.leagueId && leagues.currentLeague ? (
                        <span>
                            {leagues.currentLeague.name}
                            <Header.Subheader>Matches</Header.Subheader>
                        </span>
                    ) : (
                        `Teams for ${currentProvider && currentProvider.name}`
                    )}
                </Header>
                <Form>
                    <Form.Group widths={3}>
                        <Form.Field>
                            <LeagueSelector
                                width='auto'
                                multiple={false}
                                onChange={this.handleLeagueSelect}
                            />
                        </Form.Field>
                        <Form.Field>
                            <Input
                                name='keyword'
                                placeholder='Keyword or Match Id'
                                onKeyPress={this.handleKeywordFilter}
                            />
                        </Form.Field>
                    </Form.Group>
                </Form>
            </Segment>
        )
    }

    handleLeagueSelect = (ev, {name, value}) => {
        history.replace(`/teams/league/${value}/`);
    }

    handleKeywordFilter = (ev) => {
        if (ev.nativeEvent.keyCode === 13) {
            const {providerId, sportId} = this.props.application;
            const {leagueId} = this.props.match.params;
            const value = ev.target.value;
            fetchTeams(providerId, sportId, leagueId, null, value, 0, '-last_modified')(this.props.dispatch);
        }

    }

    handlePagination = (start) => {
        const {providerId, sportId} = this.props.application;
        const {leagueId} = this.props.match.params;
        fetchTeams(providerId, sportId, leagueId, null, null, start)(this.props.dispatch);
    }

    handleSorting = ({name, direction}) => {
        const {providerId, sportId} = this.props.application;
        const {leagueId} = this.props.match.params;
        const sortString = `${direction === 'descending' ? '-' : ''}${name}`;
        fetchTeams(providerId, sportId, leagueId, null, 0, sortString)(this.props.dispatch);
    }

    handleShortNameChange = (ev, {id, value}) => {
        const {providerId, sportId} = this.props.application;
        updateTeam(providerId, sportId, id, value)(this.props.dispatch);
    }

    render() {
        const {teams} = this.props;
        return (
            <Segment basic raised>
                {this.getHeader()}
                <DataGrid
                    headers={[
                        {name: 'id', title: 'Id', sortable: true},
                        {name: 'name', title: 'Name', sortable: true},
                        {name: 'provider_name', title: 'Provider Name', sortable: true},
                        {name: 'short_name', title: 'Short Name (Enter to save)', sortable: true, renderer: (team) => {
                            return (
                                <Input
                                    key={team.id}
                                    name={team.id}
                                    defaultValue={team.short_name || ''}
                                    onKeyPress={(ev) => {
                                        if (ev.nativeEvent.keyCode === 13) {
                                            this.handleShortNameChange(ev, {
                                                id: team.id,
                                                value: ev.target.value
                                            })
                                        }
                                    }}
                                />
                            )
                        }},
                        {name: 'connector', title: 'Connect To', sortable: false, renderer: (team) => {
                            return (
                                <TeamConnector
                                    key={team.id}
                                    teamId={team.id}
                                    name={team.name}
                                />
                            )
                        }},
                    ]}
                    items={teams.items}
                    totalRows={teams.total}
                    pageSize={teams.limit}
                    onPaginate={this.handlePagination}
                    onSort={this.handleSorting}
                />
            </Segment>
        )
    }
}

export default connect(state => {
    return {
        application: state.application,
        teams: state.teams,
        leagues: state.leagues
    }
})(TeamsGrid);
