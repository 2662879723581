export default [
    {value: '', text: 'Any'},

    {value: 'home', text: 'Home'},
    {value: 'away', text: 'Away'},
    {value: 'draw', text: 'Draw'},

    {value: 'over', text: 'Over'},
    {value: 'under', text: 'Under'},

    {value: 'yes', text: 'Yes'},
    {value: 'no', text: 'No'},

    {value: 'home_home', text: 'Home-Home'},
    {value: 'home_draw', text: 'Home-Draw'},
    {value: 'home_away', text: 'Home-Away'},
    {value: 'draw_home', text: 'Draw-Home'},
    {value: 'draw_draw', text: 'Draw-Draw'},
    {value: 'draw_away', text: 'Draw-Away'},
    {value: 'away_home', text: 'Away-Home'},
    {value: 'away_draw', text: 'Away-Draw'},
    {value: 'away_away', text: 'Away-Away'},

    {value: 'away_yes', text: 'Away-Yes'},
    {value: 'away_no', text: 'Away-No'},
    {value: 'home_yes', text: 'Home-Yes'},
    {value: 'home_no', text: 'Home-No'},
    {value: 'draw_yes', text: 'Draw-Yes'},
    {value: 'draw_no', text: 'Draw-No'},

    {value: '0', text: '0 (No Goals)'},
    {value: '1', text: '1 Goal'},
    {value: '2', text: '2 Goals'},
    {value: '3', text: '3 Goals'},
    {value: '4', text: '4+ Goals'},

    {value: '8:8', text: '8:8'},
    {value: '7:7', text: '7:7'},
    {value: '6:6', text: '6:6'},
    {value: '5:5', text: '5:5'},
    {value: '4:4', text: '4:4'},
    {value: '3:3', text: '3:3'},
    {value: '2:2', text: '2:2'},
    {value: '1:1', text: '1:1'},
    {value: '0:0', text: '0:0'},

    {value: '5:0', text: '5:0'},
    {value: '5:1', text: '5:1'},
    {value: '5:2', text: '5:2'},
    {value: '5:3', text: '5:3'},
    {value: '5:4', text: '5:4'},
    {value: '4:0', text: '4:0'},
    {value: '4:1', text: '4:1'},
    {value: '4:2', text: '4:2'},
    {value: '4:3', text: '4:3'},
    {value: '3:2', text: '3:2'},
    {value: '3:1', text: '3:1'},
    {value: '3:0', text: '3:0'},
    {value: '2:1', text: '2:1'},
    {value: '1:0', text: '1:0'},

    {value: '4:5', text: '4:5'},
    {value: '3:5', text: '3:5'},
    {value: '3:4', text: '3:4'},
    {value: '2:5', text: '2:5'},
    {value: '2:4', text: '2:4'},
    {value: '2:3', text: '2:3'},
    {value: '1:4', text: '1:4'},
    {value: '1:3', text: '1:3'},
    {value: '1:2', text: '1:2'},
    {value: '0:5', text: '0:5'},
    {value: '0:4', text: '0:4'},
    {value: '0:3', text: '0:3'},
    {value: '0:2', text: '0:2'},
    {value: '0:1', text: '0:1'},

    {value: 'home_over', text: 'Home-Over'},
    {value: 'away_over', text: 'Away-Over'},
    {value: 'draw_over', text: 'Draw-Over'},
    {value: 'home_under', text: 'Home-Under'},
    {value: 'away_under', text: 'Away-Under'},
    {value: 'draw_under', text: 'Draw-Under'},

    {value: 'home_zero', text: 'Home-No Goals'},
    {value: 'draw_goals', text: 'Draw-Goals'},
    {value: 'away_zero', text: 'Away-No Goals'},
    {value: 'draw_zero', text: 'Draw to zero'},

    {value: 'home_draw_over', text: 'Home-Draw-Over'},
    {value: 'home_away_over', text: 'Home-Away-Over'},
    {value: 'draw_away_over', text: 'Draw-Away-Over'},
    {value: 'home_draw_under', text: 'Home-Draw-Under'},
    {value: 'home_away_under', text: 'Home-Away-Under'},
    {value: 'draw_away_under', text: 'Draw-Away-Under'},
    {value: 'home_draw_yes', text: 'Home-Draw-Yes'},
    {value: 'home_away_yes', text: 'Home-Away-Yes'},
    {value: 'draw_away_yes', text: 'Draw-Away-Yes'},
    {value: 'home_draw_no', text: 'Home-Draw-No'},
    {value: 'home_away_no', text: 'Home-Away-No'},
    {value: 'draw_away_no', text: 'Draw-Away-No'},

    {value: '0_1', text: 'None or 1 goal'},
    {value: '2_3', text: '2 or 3 goals'},
    {value: '4_5', text: '4 or 5 goals'},
    {value: '6_', text: '6 or more goals'}

];
