import React from 'react';
import PropTypes from 'prop-types';
import {Dropdown} from 'semantic-ui-react';
import {connect} from 'react-redux'
import {range} from 'lodash/util';
import {keyBy} from 'lodash/collection';
import {productOf} from '../../utils';
import {compact} from 'lodash/array';


class ScoreSelector extends React.Component {
    static propTypes = {
        name: PropTypes.string,
        value: PropTypes.array,
        excludeScores: PropTypes.array,
        onChange: PropTypes.func,
        placeholder: PropTypes.string
    }

    static defaultProps = {
        value: [],
        excludeScores: []
    }

    state = {
        value: this.props.value
    }

    handleChange = (ev, {name, value}) => {
        this.setState({
            value: value
        }, () => {
            this.props.onChange(ev, {name, value})
        })
    }

    getScoreOptions = () => {
        const {sportId, currentProvider} = this.props.application;
        const sportIndex = keyBy(currentProvider.sports, v => v.id)
        const sportScoreMap = sportIndex[sportId].scores;
        const scores = range(0, sportScoreMap.max_score + 1, sportScoreMap.increment);
        const scoreOptions = productOf(scores, scores);
        return this.normaliseScoreOptions(scoreOptions);
    }

    normaliseScoreOptions = (scores) => {
        const {excludeScores} = this.props;
        const scoreOptions = [
            {
                text: 'PRE',
                value: 'PRE',
                key: 'PRE'
            },
            {
                text: 'INPLAY',
                value: 'INPLAY',
                key: 'INPLAY'
            }
        ].concat(compact(scores.map((score, index) => {
            score = `H${score[0]}-A${score[1]}`;
            if (excludeScores.indexOf(score) === -1) {
                return {
                    text: score,
                    value: score,
                    key: index
                }
            } else {
                return false;
            }
        })))
        return scoreOptions;
    }

    render() {
        const {
            name,
            placeholder
        } = this.props;
        const {
            value
        } = this.state;

        return (
            <Dropdown
                selection
                search
                multiple
                fluid
                placeholder={placeholder}
                name={name || 'scores'}
                value={value || []}
                options={this.getScoreOptions()}
                onChange={this.handleChange}
            />
        )
    }
}

export default connect(state => {
    return {
        application: state.application
	}
})(ScoreSelector);
