import React from 'react';
import {Link} from 'react-router-dom';
import {connect} from 'react-redux'
import {fetchMatch} from '../../redux/actions/match';
import {isEmpty} from 'lodash/lang';
import {
    LanguageSelector,
    ContentTypeSelector,
    ChannelSelector
} from '../../components/selectors';

import {
    Header, Loader, Grid, Form, TextArea
} from 'semantic-ui-react';

class MatchContent extends React.Component {
    componentDidMount() {
        const {matchId} = this.props.match.params;
        const {providerId, sportId} = this.props.application;
        if (matchId) {
            fetchMatch(providerId, sportId, matchId)(this.props.dispatch);
        }
    }

    render() {
        const {matches} = this.props;
        const {matchId} = this.props.match.params;
        if (!matches || isEmpty(matches.currentMatch)) {
            return <Loader active={true}/>;
        }
        return (
            <div>
                <Header as='h2'>
                    Match Content
                    <Header.Subheader>
                        <Link to={`/match/${matchId}`}>
                            {this.props.matches.currentMatch.name}
                        </Link>
                    </Header.Subheader>
                </Header>
                <Grid style={{height:'100vh'}}>
                    <Grid.Row columns={2}>
                        <Grid.Column>
                            <Form>
                                <Form.Group>
                                    <Form.Field>
                                        <label>Content Type</label>
                                        <ContentTypeSelector/>
                                    </Form.Field>
                                    <Form.Field>
                                        <label>Language</label>
                                        <LanguageSelector/>
                                    </Form.Field>
                                    <Form.Field>
                                        <label>Delivery Channel</label>
                                        <ChannelSelector/>
                                    </Form.Field>
                                </Form.Group>
                                <Form.Field>
                                    <TextArea rows={10}/>
                                </Form.Field>
                            </Form>
                        </Grid.Column>
                        <Grid.Column>

                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            </div>
        )
    }
}

export default connect(state => {
    return {
        application: state.application,
        matches: state.matches,
        events: state.events
	}
})(MatchContent);
