export default {
    CHANNELS: {
        1: {id: 1, code: 'PUSH', name: 'Push Notification'},
        2: {id: 2, code: 'EMAIL', name: 'Email'},
        3: {id: 3, code: 'TWITTER', name: 'Twitter Handler'},
        4: {id: 4, code: 'FEED', name: 'BetSense Feed'},
        5: {id: 5, code: 'CONTENT', name: 'BetSense Content'},
        6: {id: 6, code: 'SOLITICS', name: 'Solitics API'},
        7: {id: 7, code: 'WHATSAPP', name: 'WhatsApp'},
        8: {id: 8, code: 'DELASPORT', name: 'Delasport API'},
        9: {id: 9, code: 'TELEGRAM', name: 'Telegram'},
    }
};
