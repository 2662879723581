import React from 'react';
import {Dropdown} from 'semantic-ui-react';
import {Languages} from '../../constants';
import {languageOptionsList} from '../../utils';


export default function({name, value, onChange, selection, allowEmpty=false, languages=null}) {
    selection = selection === undefined ? true : selection;
    let languageOptions = languages ? languageOptionsList(languages) : Languages;
    if (allowEmpty) {
        languageOptions = [{value: null, text: 'All Languages', key: 'ALL'}].concat(languageOptions);
    }
    return (
        <Dropdown
            clearable
            selection={selection}
            placeholder='Language'
            name={name || 'language'}
            value={value}
            options={languageOptions}
            onChange={onChange}
        />
    )
}