import React from 'react';
import {Dropdown} from 'semantic-ui-react';

export default function({name, value, options, onChange, multiple, selection}) {
    return (
        <Dropdown
            search
            clearable
            selection={selection === false ? false : true}
            multiple={multiple || false}
            placeholder='Content Group'
            name={name || 'group_id'}
            value={value}
            options={options || []}
            onChange={onChange}
        />
    )
}