export const POSITIVE = '+';
export const NEGATIVE = '-';
export const NEUTRAL = '=';
export const EMPTY = '~';

export const WIDGET_COLOURS = {
    [POSITIVE]: 'positive',
    [NEGATIVE]: 'negative',
    [NEUTRAL]: 'neutral',
    [EMPTY]: '',
}
