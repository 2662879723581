import URLS, {APIS} from './constants';

/**
 * All url endpoints and a few encoding functions are defined here.
 * URLs should not have a trailing slash here as it is appended automatically
 * by the base service.
 * All URLs stem from the base URL /api/1/
 */
export function apiURL(api, url) {
	return `${api}${url}`;
}

/**
 * Replace URL parameters.
 * @param  {string} url
 * @param  {object} urlParams
 * @return {string}
 */
function _replaceUrlParams(url, urlParams) {
	return url.replace(/:(.*?)\//g, function(rawUrl, urlParam) {
		const value = urlParams[urlParam];
		if (!value && process.env.NODE_ENV === 'development') {
			throw new Error(`The URL provided "${url}" requires a "${urlParam}" parameter.`);
		}
		delete urlParams[urlParam];
		return rawUrl.replace(urlParam, value).replace(':', '');
	});
}

/**
 * Normalise URL.
 * @param  {string} apiMethod
 * @return {string}
 */
export function getUrl(apiMethod, urlParams) {
	if (process.env.NODE_ENV === 'development') {
		if (!URLS[apiMethod]) {
			throw new Error(`The URL provided "${apiMethod}" is not listed on the service.`);
		}
	}
    const urlSpec = URLS[apiMethod];
	const apiUrl = APIS[urlSpec.api];
	let url = urlSpec.method + '/';

	if (urlParams) {
		url = _replaceUrlParams(url, urlParams);
	}
	return apiURL(apiUrl, url);
}

/**
 * Encodes an object of key value pairs as a base64 encoded string
 * @param  {object} data key/value pairs of parameters to encode
 * @return {string}      base64 encoded JSON
 */
export function encodeUrlParams(data) {
	return btoa(encodeURIComponent(JSON.stringify(data)));
}

/**
 * Decodes base64 encoded url parameters into a data object
 * @param  {string} encodedData The base64 encoded string
 * @return {object}             Object with key/value pairs
 */
export function decodeUrlParams(encodedData) {
	return JSON.parse(decodeURIComponent(atob(encodedData)));
}

/**
 * For a given query param data, check if the `i` param exists
 * and return its base64, json parsed value if so.
 * i params represent initialisation data for new records.
 * @param  {object} query qury param object
 * @return {object}       evaluated query params
 */
export function initialiseFromQuery(query) {
	if (query.i) {
		return decodeUrlParams(query.i);
	}
	return null;
}

/**
 * Returns the location's hashbang value, excluding the actual hash.
 * @param  {object} location          location object from props
 * @param  {string} defaultValue      Default value if no hash found
 * @return {string}                   The hash value
 */
export function getLocationHash(location, defaultValue=null) {
	return location.hash.replace('#', '') || defaultValue;
}



// const URLS = {
// 	searchMatch: {
//         api: 'eventstore',
//         method: 'search/match'
//     },
//     leagues: {
//         api: 'eventstore',
//         method: 'leagues'
//     }
// };

// export default URLS;
