import React from 'react';
import Languages from '../constants/languages';
import {keyBy} from 'lodash/collection';


// convert any nulls to empty strings
export function sanitiseFormInput(input, exclude=[]) {
    // eslint-disable-next-line
    Object.keys(input).map((key, index) => {
        if (input[key] === null && exclude.indexOf(key) === -1) {
            input[key] = '';
        }
    })
    return input;
}


export function productOf() {
    return Array.prototype.reduce.call(arguments, (a, b) => {
      var ret = [];
      a.forEach((a) => {
        b.forEach((b) => {
          ret.push(a.concat([b]));
        });
      });
      return ret;
    }, [[]]);
}

// concatanates a full content block (title/content/cta)
// into one string.
export function contentConcat(content, typeSeparator='') {
    const keyOrder = ['title', 'content', 'cta'];
    const contentArray = keyOrder.map((key) => {
        return content[key].map(item => item.content)
    })
    return contentArray.join(typeSeparator);
}


export function contentConcatAndWrap(content, wrapper='List.Content') {
    const keyOrder = ['title', 'content', 'cta'];
    const contentArray = keyOrder.map((key) => {
        return (<wrapper>{content[key].map(item => item.content)}</wrapper>)
    })
    return contentArray;
}

export function evalDropdownDefaultValue(value, multiple) {
    if (typeof(value) === 'object') {
        if (!multiple && value.length === 0) {
            return '';
        }
    }
    return value;
}

/**
 * Reverse an object making the values into keys
 * @param {object} obj
 */
export function reverseMap(obj) {
    const reversed = {}
    Object.keys(obj).forEach((key) => {
        reversed[obj[key]] = key;
    })
    return reversed;
}

export function getMinuteFromItem(item) {
    if (item.prematch) {
        return 'Prematch';
    }
    return item.data && item.data.live && item.data.live.matchClock && item.data.live.matchClock.minute;
}


/**
 * for an array of provider language codes, return a full array of language objects
 * @param {array<string>} languages
 */
export function languageOptionsList(languages) {
    const langIndex = keyBy(Languages, 'value');
    return languages.map((langCode, index) => {
        const langItem = langIndex[langCode];
        if (!langItem) {
            throw(new Error(`Invalid language code: ${langCode}.`));
        }
        langItem.key = index;
        return langItem;
    })
}


export function fractionalFromDecimal(decimal) {
	decimal = parseFloat(decimal).toFixed(2);
	var num = (decimal-1) * 10000;
	var dom = 10000;

	num = Math.round(num);
	dom = Math.round(dom);

	var a = reduce(num,dom);
	num = a[0];
	dom = a[1];

	return(num+':'+dom);
}

export function reduce(a,b) {
	var n  = new Array(2);
	var f = GCD(a,b);
	n[0] = a/f;
	n[1] = b/f;
	return n;
}

export function GCD(num1,num2) {
	var a; var b;
	if (num1 < num2) {a = num2; b = num1;}
	else if (num1 > num2) {a = num1; b = num2;}
	else if (num1 === num2) {return num1;}
  while(1) {
    if (b === 0) {
			return a;
		}
    else {
     	var temp = b;
     	b = a % b;
     	a = temp;
    }
  }
}
