import React from 'react';
import {Dropdown} from 'semantic-ui-react';

export default function LineSelector({name, value, onChange}) {
    const getOptions = () => {
        const maxLine = 18;
        const lineOptions = [];
        for (let i=maxLine * -1; i <= maxLine; i = i + 0.5) {
            const line = i * 1000;
            lineOptions.push({
                text: line,
                value: line,
                key: i
            })
        }
        return lineOptions;
    }
    return (
        <Dropdown
            selection
            clearable
            search
            placeholder='Line'
            name={name || 'line'}
            value={value}
            onChange={(ev, {value}) => {
                onChange(ev, {name, value})
            }}
            options={getOptions()}
        />

    )
}
