import React from 'react';
import PropTypes from 'prop-types';
import {Container} from 'semantic-ui-react';
import {getMinuteFromItem} from '../../utils';
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'


const providerMarketLabelMap = {
    1: {
        '1': 'home',
        '2': 'away',
        'X': 'draw',
        'Over': 'over',
        'Under': 'under',
    }
}


class MatchGraph extends React.Component {
    static propTypes = {
        events: PropTypes.array,
        match: PropTypes.object,
        market: PropTypes.object,
    }

    state = {
        graphData: [],
        maxOdd: 0,
        totalEvents: this.props.totalEvents,
    }

    componentDidMount() {
        const {events} = this.props;
        this.setState({
            graphData: this.graphData(events),
            totalEvents: this.props.totalEvents
        })
    }

    UNSAFE_componentWillReceiveProps(nextProps) { 
        const {events} = nextProps;
        this.setState({
            graphData: this.graphData(events),
            totalEvents: nextProps.totalEvents
        })
    }

    xshouldComponentUpdate(nextProps, nextState) {
        console.log('should', nextState.totalEvents !== this.state.totalEvents, nextProps, nextState);
        if (nextState.totalEvents !== this.state.totalEvents) {
            return true;
        }
        return false;
    }

    graphData = (events) => {
        const {specs, specIndex} = this.getSeriesSpec()
        if (specs.length === 0) {
            return [];
        }
        let {maxOdd} = this.state;
        // specs['goals'] = {data: [], type: 'scatter', marker: {symbol: 'triangle'}}
        events && events.forEach((item, index) => {
            const {market} = this.props;
            const minute = getMinuteFromItem(item)
            const thresholdKeys = (market && Object.keys(market.meta.thresholds)) || [];
            const goal =  (index > 0 && events[index-1] && item.score !== events[index-1].score) ? item.score : false
            thresholdKeys.forEach((threshold, index) => {
                const odd = item.outcome_map[threshold] / 1000;
                if (odd > maxOdd) {
                    maxOdd = odd;
                }
                //specs[specIndex[threshold]].data.push([minute, odd]);
                specs[specIndex[threshold]].data.push({
                    x: minute, 
                    y: odd, 
                });
            }) 
            if (goal) { 
                specs[specIndex.goals].data.push({
                    x: minute, 
                    y: 0,  
                })
            }
            
            // item.outcomes && item.outcomes.forEach((outcome) => {
            //     const label = providerMarketLabelMap[1][outcome.label];
            //     if (specs[specIndex[label]]) {
            //         specs[specIndex[label]].data.push([minute, outcome.odds / 1000]);
            //     }
            // })
            
            // return _item;
        }) 
        console.log(specs)
        this.setState({value: this.state.value + 1, maxOdd: maxOdd})
        return specs;
    }

    getSeriesSpec = () => {
        const {market} = this.props;
        const thresholdKeys = (market && Object.keys(market.meta.thresholds)) || [];
        const specIndex = {}
        // console.log("thkeys", thresholdKeys)
        const specs = thresholdKeys.map((threshold, index) => {
            specIndex[threshold] = index;
            return {
                key: index,
                name: threshold,
                data: []
            }
        })
        specs.push({
            type: 'scatter', 
            data: [], 
            marker: {symbol: 'triangle'},
            color: 'red',
            name: 'goals',
            showInLegend: false,
            key: 'goals',
        })
        specIndex['goals'] = thresholdKeys.length
        return {
            specIndex,
            specs
        }
    }

    render() {
        const {graphData, maxOdd} = this.state;
        console.log("Render graphData", graphData)
        return (
            <Container fluid>
                <HighchartsReact
                    highcharts={Highcharts}
                    allowChartUpdate={true}
                    options={{
                        title: {
                            text: 'Match Offers'
                        },
                        xAxis: {
                            title: {
                                text: 'Minutes'
                            },
                            type: 'category',
                            tickPixelInterval: 150,
                            min: 0,
                            max: 100
                        },
                        yAxis: {
                            title: {
                                text: 'Odds'
                            },
                            type: 'linear',
                            min: 0,
                            max: maxOdd + 1,
                            plotLines: [{
                                value: 0,
                                width: 1,
                                color: '#808080'
                            }]
                        },
                        series: graphData
                    }}
                />
            </Container>
        )
    }
}


export default MatchGraph;
