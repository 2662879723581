import React from 'react';
import PropTypes from 'prop-types';
import {List, Checkbox} from 'semantic-ui-react'
import {Languages} from '../../constants';
import {languageOptionsList} from '../../utils';

export default class LanguagesSelector extends React.Component {
    static propTypes = {
        name: PropTypes.string,
        value: PropTypes.array,
        onChange: PropTypes.func,
        horizontal: PropTypes.bool,
        /**
         * Explicit list of languages to use (provider based)
         */
        languages: PropTypes.array,
    }

    static defaultProps = {
        name: 'language',
        horizontal: true,
        value: [],
        languages: null,
    }

    state = {
        value: this.props.value
    }

    static getDerivedStateFromProps(props, state) {
        if (props.value !== state.value) {
            return {
                value: props.value
            }
        }
        return null;
    }

    handleChange = (ev, {name, checked, value}) => {
        const fieldName = this.props.name;
        const checkedItems = this.state.value;
        if (checkedItems.indexOf(value) === -1) {
            checkedItems.push(value);
        } else if (!checked) {
            checkedItems.splice(checkedItems.indexOf(value), 1);
        }
        this.setState({
            value: checkedItems
        }, () => {
            this.props.onChange(ev, {
                name: fieldName,
                value: this.state.value
            })
        })
    }

    render() {
        const {name, horizontal, languages} = this.props;
        const {value} = this.state;
        const languageList = languages ? languageOptionsList(languages) : Languages;
        return (
            <List horizontal={horizontal}>
                {languageList.map((lang, index) => {
                    return (
                        <List.Item key={index}>
                        <Checkbox
                            name={name || 'language'}
                            checked={value.indexOf(lang.value)>-1}
                            value={lang.value}
                            label={lang.text}
                            onClick={this.handleChange}
                        />
                        </List.Item>
                    )
                })}
            </List>
        )
    }
}