import React from 'react';
import PropTypes from 'prop-types';
import {Button} from 'semantic-ui-react';


class TimerButton extends React.Component {
    static propTypes = {
        attached: PropTypes.oneOf(['left', 'right', 'top', 'bottom']),
        icon: PropTypes.string,
        onClick: PropTypes.func.isRequired, // confirmed state
    }

    static defaultProps = {
        icon: 'checkmark',
        attached: 'left',
        size: 'tiny'
    }

    state = {
        confirmState: 0,
        timer: null,
        counter: 5,
    }

    tick = () => {
        this.setState({
            counter: this.state.counter - 1
        }, () => {
            if (this.state.counter <= 0) {
                this.resetConfirmState()
            }
        });
    }

    resetConfirmState = () => {
        clearInterval(this.state.timer);
        this.setState({
            confirmState: 0,
            counter: 5
        })
    }

    evalState = () => {
        const {confirmState} = this.state;
        if (confirmState === 2) {
            this.props.onClick()
            this.resetConfirmState()
        } else if (confirmState === 1) {
            this.setState({
                timer: setInterval(this.tick, 1000),
                counter: 5
            })
        }
    }

    handleAction = (ev) => {
        ev.preventDefault()
        const {confirmState} = this.state;
        this.setState({
            confirmState: confirmState + 1
        }, () => {
            this.evalState()
        })
    }

    getButtonContent = () => {
        if (this.state.confirmState === 1) {
            return `${this.state.counter}`
        }
        return false
    }

    render() {
        const {
            attached,
            icon,
            size,
        } = this.props;
        return (
            <Button
                basic
                size={size}
                attached={attached}
                icon={icon}
                onClick={this.handleAction}
                content={this.getButtonContent()}
            />
        )
    }
}

export default TimerButton;