import React, {useEffect, useState} from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { fetchSavedThresholds, deleteSavedThresholds } from '../../redux/actions/app';
import { DataGrid } from '../grid';
import { TextArea } from 'semantic-ui-react';

export default function({marketId}) {
    const [thresholds, setThresholds] = useState(null);
    const {providerId, savedThresholds} = useSelector(state => state.application);
    const dispatch = useDispatch();

    useEffect(() => {
        fetchSavedThresholds(providerId, marketId)(dispatch);
    }, [marketId])
    return (
        <React.Fragment>
            {thresholds ? (
                <TextArea value={JSON.stringify(thresholds.thresholds, null, '\t')}/>

            ) : false}
            <DataGrid
                headers={[
                    {name: 'id', title: 'Id'},
                    {name: 'type', title: 'Type'},
                    {name: 'contact.name', title: 'Contact'},
                    {name: 'channel.name', title: 'Channel'},
                    {name: 'thresholds', title: 'Spec', renderer: (item) => {
                        console.log(item)
                        return false
                    }},
                    {name: null, title: 'Options', options: (item) => {
                        return [
                            {
                                icon: 'edit',
                                onClick: () => {
                                    console.log('-->',item)
                                    setThresholds(item)
                                }
                            },
                            {
                                icon: 'delete',
                                onClick: () => {
                                    if (window.confirm('Are you sure?')) {
                                        deleteSavedThresholds(providerId, item.id)(dispatch);
                                    }
                                }
                            }
                        ]
                    }}
                ]}
                items={savedThresholds || []}
            />
        </React.Fragment>
    )
}