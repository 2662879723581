import React from 'react';
import PropTypes from 'prop-types';
import {
    Header, Form, Input, Divider
} from 'semantic-ui-react';


class MarketForm extends React.Component {
    static propTypes = {
        market: PropTypes.object,
        onChange: PropTypes.func
    }

    static defaultProps = {
        market: null
    }

    state = {
        market: this.props.market
    }

    static getDerivedStateFromProps(props, state) {
        if (props.market !== state.market) {
            return {
                market: props.market
            }
        }
        return null;
    }

    handleChange = (ev, {name, value}) => {
        const {market} = this.state;
        if (market) {
            market.meta[name] = Number(value);
            this.props.onChange(ev, {
                name: 'market',
                value: market
            })
        }
    }

    setThreshold = (ev, {name, value}) => {
        const {market} = this.state;
        market.meta.thresholds[name] = value;
        this.props.onChange(ev, {
                name: 'market',
                value: market
        })
    }

    getMarketThresholds = () => {
        const {market} = this.props;
        const {thresholds} = market.meta;
        const threshKeys = Object.keys(thresholds).filter(
            item => item.indexOf('_min') === -1 && item.indexOf('_max') === -1
        )
        return threshKeys.map((threshold, index) => {
            return (
                <Form.Group key={index}>
                    <Form.Field>
                        <label>{threshold}</label>
                        <Input
                            type='number'
                            name={threshold}
                            value={thresholds[threshold] || ''}
                            onChange={this.setThreshold}
                        />
                    </Form.Field>
                    <Form.Field>
                        <label>{threshold} - Min</label>
                        <Input
                            type='number'
                            name={`${threshold}_min`}
                            value={thresholds[`${threshold}_min`] || ''}
                            onChange={this.setThreshold}
                        />
                    </Form.Field>
                    <Form.Field>
                        <label>{threshold} - Max</label>
                        <Input
                            type='number'
                            name={`${threshold}_max`}
                            value={thresholds[`${threshold}_max`] || ''}
                            onChange={this.setThreshold}
                        />
                    </Form.Field>
                </Form.Group>
            )
        })
    }

    render() {
        const {market} = this.props;
        if (market) {
            return (
                <Form>
                    <Header as='h3'>{market.name}</Header>
                    <Form.Group>
                        <Form.Field>
                            <label>First Minute</label>
                            <Input
                                name='first_minute'
                                type='number'
                                value={market.meta.first_minute}
                                onChange={this.handleChange}
                            />
                        </Form.Field>
                        <Form.Field>
                            <label>Last Minute</label>
                            <Input
                                name='last_minute'
                                type='number'
                                value={market.meta.last_minute}
                                onChange={this.handleChange}
                            />
                        </Form.Field>
                        <Form.Field>
                            <label>Send Delay (minutes)</label>
                            <Input
                                name='send_delay_minutes'
                                type='number'
                                value={market.meta.send_delay_minutes}
                                onChange={this.handleChange}
                            />
                        </Form.Field>
                    </Form.Group>
                    <Form.Group>
                        <Form.Field>
                            <label>Max Threshold</label>
                            <Input
                                name='max_threshold'
                                type='number'
                                value={market.meta.max_threshold}
                                onChange={this.handleChange}
                            />
                        </Form.Field>
                        <Form.Field>
                            <label>Pass Threshold</label>
                            <Input
                                name='pass_threshold'
                                type='number'
                                value={market.meta.pass_threshold}
                                onChange={this.handleChange}
                            />
                        </Form.Field>
                        <Form.Field>
                            <label>Pass Threshold Timeout</label>
                            <Input
                                name='pass_threshold_timeout'
                                type='number'
                                value={market.meta.pass_threshold_timeout}
                                onChange={this.handleChange}
                            />
                        </Form.Field>
                    </Form.Group>
                    <Divider/>
                    {this.getMarketThresholds()}
                </Form>
            )
        }
        return false;
    }
}

export default MarketForm;